import { StyleSheet } from 'react-native';
import { $bdCartoon, $robotoBold, $robotoRegular } from '../../constants/fonts';
import {
  $yellow,
  $black,
  $white,
  $green,
} from '../../constants/colors';

export const getProgramStyles = (width, height) => StyleSheet.create({
  carouselBeginButton: {
    height: '100%',
    resizeMode: 'contain',
    width: width * 0.18,
  },
  carouselCustomStyle: {
    marginTop: 12,
  },
  carouselDescription: {
    color: $black,
    fontFamily: $robotoRegular,
    fontSize: 28,
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'center',
    fontWeight: '400',
  },
  carouselItems: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginBottom: 15,
    padding: 10,
  },
  carouselItemsBtn: {
    bottom: -4,
    height: 85,
    position: 'absolute',
  },
  carouselItemsContainer: {
    alignItems: 'center',
    height: '90%',
  },
  carouselNavBtn: {
    height: height * 0.14,
    width: width * 0.05,
  },
  carouselNavBtnContainer: {
    justifyContent: 'center',
    position: 'absolute',
    top: '36%',
    zIndex: 10,
  },
  carouselNumber: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: 50,
    textShadowColor: $black,
    textShadowOffset: { width: 5, height: 5 },
    textShadowRadius: 10,
  },

  carouselQuestion: {
    fontFamily: $robotoRegular,
    fontSize: height*0.045,
    fontWeight: '400',
  },

  carouselQuestionBg: {
    display: 'flex',
    height: height*0.9,
    justifyContent: 'flex-start',
    paddingHorizontal: height*0.09,
    paddingVertical: height*0.09,
    width: width*0.9,
    position: "relative"
  },

  carouselQuestionCtn: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  carouselQuestionSubmit: {
    color: $white,
    fontFamily: $robotoRegular,
    fontSize: height*0.04,
    fontWeight: '400',
  },

  carouselQuestionSubmitBg: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  carouselQuestionSubmitCtn: {
    height: height*0.2,
    width: height*0.3,
  },

  carouselText: {
    color: $black,
    fontFamily: $robotoBold,
    fontSize: 40,
    textAlign: 'center',
    fontWeight: '900',
  },
  carouselView: {
    height: '97.5%',
    width: '100%',
  },
  carouselViewContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '95%',
    width: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  headerText: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.055,
    marginLeft: 20,
    marginTop: height*0.01,
    textAlign: 'center',
    textShadowColor: $black,
    textShadowOffset: { width: 5, height: 5 },
    textShadowRadius: 5,
  },

  imageLoading: {
    left: '48%',
    position: 'absolute',
    top: '42%',
  },

  markeModuleCompleteBtn: {
    backgroundColor: $green,
    justifyContent: "center",
    alignItems: "center",
    padding: height*0.02,
    shadowColor: $black,
    shadowOffset: {height: 4, width: 4},
    shadowRadius: 10,
    shadowOpacity: 0.5,
    elevation: 10,
    maxWidth: width*0.12
  },
  markeModuleCompleteBtnTxt: {
    color: $white,
    fontFamily: $bdCartoon,
    letterSpacing: 2,
    fontSize: height*0.05,
    fontWeight: '400',
    textTransform: "capitalize"
  },
  markeModuleCompleteCtn: {
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: '36%',
    zIndex: 10,
  },
  navleft: {
    left: 0,
  },
  navright: {
    right: 0,
  },
});

import { StyleSheet } from 'react-native';
import { $white, $yellow } from '../../constants/colors';
import { $robotoRegular } from '../../constants/fonts';

export const getStyle = (width, height) => StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
});

export const getLeftCtnStyle = (width, height) => StyleSheet.create({
  conatiner: {
    alignItems: 'center',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    zIndex: 99,
    width: "100%"
  },
});

export const getRightCtnStyle = (width, height) => StyleSheet.create({
  container: {
    flex: 1.5,
    position: "relative"
  },

  image: {
    height: '125%',
    position: 'relative',
    width: '100%',
  },

  imageLoading: {
    left: '48%',
    position: 'absolute',
    top: '42%',
  },

  inputContainer: {
    backgroundColor: $white,
    bottom: 2,
    left: -(height*0.04),
    position: 'absolute',
    width: '103.5%',
    zIndex: 99,
    flexDirection: "row",
  },
  input: {
    height: height*0.06,
    flex: 2,
    fontSize: height*0.03,
    paddingLeft: height*0.02,
    fontFamily: $robotoRegular,
    fontWeight: "600",
    marginRight: "1%",
    marginLeft: "1%",
    marginVertical: 4
  },
  submitButton: {
    height: height*0.06,
    width: "10%",
    backgroundColor: "rgb(33, 150, 243)",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: height*0.008,
    shadowColor: "black",
    shadowOffset: { height: 4, width: 2},
    shadowOpacity: 0.5,
    shadowRadius: 10,
    elevation: 10,
    marginRight: 5
  },
});

export const getCharacterListStyle = (width, height) =>  StyleSheet.create({
  profile: {
    height: height*0.3,
    margin: height*0.01,
    width: height*0.3,
  },

  selected: {
    backgroundColor: $yellow,
    borderColor: $yellow,
    borderRadius: height*0.15,
    borderWidth: height*0.004,
  },
});

import React from 'react';
import { ImageBackground, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';
import Config from '../../../config';

import { getTabHeaderstyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setLeaderboard } from '../../../actions/status';

const TabHeader = ({ type, label, left, right, setSelectedIndex }) => {
  const tab = useSelector(state => state.status.leaderboard.tab);
  const window = useWindowDimensions()

  const styles = getTabHeaderstyles(window.width, window.height)

  const dispatch = useDispatch();

  return (
    <ImageBackground
      style={[styles.bg, tab === type ? styles.active : '', left ? { left: "5%" } : { right: "6.5%"}]}
      source={{
        uri:
          Config.IMG_URL +
          `/abilities/title_tab_${tab === type ? 'active' : 'inactive'}.png`,
      }}>
      <TouchableOpacity
        onPress={() => {
          dispatch(setLeaderboard({ tab: type }));
          setSelectedIndex(0);
        }
        }
        style={styles.container}>
        <Text style={[styles.txt, tab === type ? styles.activeTxt : '']}>
          {label.toUpperCase()}
        </Text>
      </TouchableOpacity>
    </ImageBackground>
  );
};

TabHeader.propTypes = {
  type: PropTypes.string.isRequired,
};

export default TabHeader;

import React from 'react';
import { View, Image, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';

import Config from './../../config';
import Info from './info';

import { AvatarStyles as styles } from './styles';

const Avatar = () => {
  const charName = useSelector(state => state.player.profile.characterName);
  const superPower = useSelector(state => state.status.abilities.superPower);
  const window = useWindowDimensions();

  const imgUri = Config.IMG_URL + `/characters/${charName}_${superPower}.png`;

  return (
    <View style={styles.container}>
      <Info top={1} left={-window.height*0.05} />
      <Image
        resizeMode="contain"
        style={styles.image}
        source={{ uri: imgUri }}
      />
    </View>
  );
};

export default Avatar;

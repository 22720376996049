import React from 'react';
import { View, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';

import MarkerCmp from './marker';

import { getStyles } from './styles';

const WorldMap = () => {
  const cities = useSelector(state => state.cities.data);
  const window = useWindowDimensions()
  const styles = getStyles(window.width, window.height)

  return (
    <View style={styles.mapWrapper}>
        {cities.map(city => (
          <MarkerCmp city={city} key={city.id} />
        ))}
    </View>
  );
};

export default WorldMap;

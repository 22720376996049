import { getBackgroundAudioRef } from "../navigation";

let screens = [];
let interval = null
export const slowBackgroundAudioVolume = (screen) => {
	const audioPlayer = getBackgroundAudioRef();
	screens.push(screen);
	if (screens?.length > 1) {
		return;
	}
	if (!audioPlayer) return;
	if (interval) {
		clearInterval(interval);
		interval = null;
	}
	interval = setInterval(() => {
		if (audioPlayer?.volume <= 0) {
			clearInterval(interval);
			interval = null;
			return;
		}

		if (audioPlayer?.volume < 0.02) {
			audioPlayer.volume = 0;
		} else {
			audioPlayer.volume -= 0.02
		}
	}, 200)
}

export const increaseBackgroundAudioVolume = (screen) => {
	const audioPlayer = getBackgroundAudioRef();
	screens = screens.filter(s => s !== screen);
	if (screens?.length) {
		return;
	}

	if (!audioPlayer) return;
	if (interval) {
		clearInterval(interval);
		interval = null;
	}
	interval = setInterval(() => {
		if (audioPlayer?.volume >= 0.4) {
			clearInterval(interval);
			interval = null;
			return;
		}
		audioPlayer.volume += 0.02
	}, 200)
}

export const pauseMusic = () => {
	const audioPlayerRef = getBackgroundAudioRef();
	if (audioPlayerRef) {
		audioPlayerRef?.pause();
	}
}

export const playMusic = () => {
	const audioPlayerRef = getBackgroundAudioRef();
	if (audioPlayerRef) {
		audioPlayerRef?.play();
	}
}

document.addEventListener("deviceready", function () {
	document.addEventListener("pause", pauseMusic, false);
	document.addEventListener("resume", playMusic, false);
}, false)

document.addEventListener("DOMContentLoaded", function () {

	// Adds pause event
	window.addEventListener("focus", playMusic);

	// Adds resume event
	window.addEventListener("blur", pauseMusic);
});


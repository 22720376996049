import React, { useEffect } from 'react'
import { Image, ImageBackground, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import Config from '../config';
import { $black, $blue, $white } from '../constants/colors';
import { $bdCartoon, $robotoRegular } from '../constants/fonts';
import { SOUNDS } from '../constants/sfx';
import MissionReportModal from './missionReportModal';
import { useModal } from './ModalProvider';
import { useSFX } from './SFXConfig';

const statsData = [
    {
      type: 'cronos',
      name: 'C',
    },
    {
      type: 'xp',
      name: 'XP',
    },
    {
      type: 'ep',
      name: 'EP',
    },
  ];

const MissionCompletePopUp = ({
    closeModal,
    data
}) => {
    const { playSound } = useSFX();
    const { width, height } = useWindowDimensions();
    const styles = getStyles(width, height);
    const { openModal } = useModal();

    useEffect(() => {
        if(playSound){
            playSound({
                sound: SOUNDS.missionCompleteFailure
            })
        }
    },[playSound])

    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height
            }}
            onStartShouldSetResponder={() => {
                // closeModal()
                return false;
            }}
        >
            <View
                style={{
                    width: "29%",
                    height: "90%",
                    minHeight: height*0.7,
                    minWidth: width*0.27
                }}
            >
                <ImageBackground
                    source={{
                        uri: Config.IMG_URL+"/save_earth/mission_complete_modal.png"
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: height*0.24
                    }}
                >
                    <View
                        style={{
                            width: "100%",
                            alignItems: "center",
                            flex: 1,
                        }}
                    >
                        
                        <Text
                            style={{
                                fontFamily: $bdCartoon,
                                fontSize: height*0.1,
                                fontWeight: "900",
                                color: $blue,
                                width: "100%",
                                paddingHorizontal: 10,
                                textAlign: "center",
                                marginTop: 28,
                                textShadow: "-3px 3px #000, 3px 3px 0 #000, 3px -3px 0 #000, -3px -3px 0 #000",
                                letterSpacing: 8,
                            }}
                        >
                            {data?.cityName}
                        </Text>
                        <Text
                             style={{
                                fontFamily: $robotoRegular,
                                fontSize: height*0.06,
                                fontWeight: "900",
                                letterSpacing: 2,
                                color: $black,
                                width: "100%",
                                paddingHorizontal: 15,
                                textAlign: "center",
                                marginTop: 10
                            }}
                        >
                            Mission Successful
                        </Text>
                    </View>
                    <View
                        style={{
                            marginBottom: 30,
                            width: "100%",
                            alignItems: "center"
                        }}
                    >
                    <View
                        style={{
                            width: "60%",
                            justifyContent: "center",
                            alignItems: "flex-start"
                        }}
                    >
                        {statsData.map(item => (
                            <View
                            key={item.type}
                            style={[styles[item.type + 'Container'], styles.statsContainer]}>
                            <Image
                                resizeMode="contain"
                                style={[styles[item.type + 'Icon'], styles.statsIcon]}
                                source={{ uri: Config.IMG_URL + `/icons/${item.type}.png` }}
                            />
                                <Text style={styles.iconHeading}> 
                                    {
                                        data[item.type]
                                    }
                                </Text>
                            </View>
                        ))}
                    </View>
                    </View>
                    {Boolean(data.endVideoUrl) ?
                        <TouchableOpacity
                            style={{
                                height: width*0.036,
                                width: width*0.16,
                                marginBottom: -height*0.03
                            }}
                            onPress={() => {
                                if(data.success){
                                    data.success();
                                }
                                if(playSound){
                                    playSound({
                                        sound: SOUNDS.general
                                    })
                                }
                                openModal({
                                    open: true,
                                    component: MissionReportModal,
                                    data: {
                                    city: {
                                        endVideoUrl: data.endVideoUrl
                                    }
                                    },
                                    blur: true
                                })
                            }}
                        >
                            <Image
                                source={{
                                    uri: Config.IMG_URL+"/save_earth/report_button.png"
                                }}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        </TouchableOpacity> :
                        <TouchableOpacity
                            style={{
                                height: width*0.05,
                                width: width*0.13,
                                marginBottom: -height*0.03
                            }}
                            onPress={() => {
                                closeModal();
                                if(playSound){
                                    playSound({
                                        sound: SOUNDS.general
                                    })
                                }
                                if(data.success){
                                    data.success();
                                }
                            }}
                        >
                            <Image
                                source={{
                                    uri: Config.IMG_URL+"/save_earth/accept_button.png"
                                }}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        </TouchableOpacity>
                    }
                </ImageBackground>
            </View>
        </View>
    )
}

export const getStyles = (width, height) => StyleSheet.create({
    cronosContainer: {},
  
    cronosIcon: { height: height*0.07, width: height*0.07 },
  
    epContainer: {  },
  
    epIcon: { height: height*0.07, width: height*0.07 },
  
    iconHeading: {
      color: $black,
      flex: 4,
      fontSize: height*0.03,
      fontWeight: '700',
      position: 'relative',
      left: height*0.01
    },
  
    iconValue: {
      color: $white,
      flex: 5,
      fontSize: height*0.03,
      fontWeight: '700',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center'
    },
  
    statsContainer: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  
    statsIcon: {
      position: 'relative',
      zIndex: 99,
    },
  
    statsTop: {
      flex: 1.8,
      flexDirection: 'column',
      marginTop: height*0.08,
    },
  
    statsWindow: {
      display: 'flex',
      flexDirection: 'row',
      height: height*0.06,
      left: -20,
      position: 'relative',
      width: height*0.2,
    },
  
    xpContainer: { },
  
    xpIcon: { height: height*0.07, width: height*0.07 },
    xpStatMain: {
      left: -(height*0.015),
      top: -(height*0.006),
      justifyContent: 'center',
      alignItems: 'center'
    },
    epStatMain: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    cronosStatMain: {
      justifyContent: 'center',
      alignItems: 'center'
    }
  });
  

export default MissionCompletePopUp;


import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import { setRebeccaState } from '../../actions/status';

import Config from './../../config';
import { useDispatch } from 'react-redux';

import { BackdropStyles as styles } from './styles';

const Backdrop = () => {
  const dispatch = useDispatch();

  return (
    <TouchableOpacity
      style={styles.backdrop}
      onPress={() => dispatch(setRebeccaState(false))}>
      <Image
        style={styles.bg}
        resizeMode="contain"
        source={{
          uri: Config.IMG_URL + '/rebecca/backdrop.png',
        }}
      />
    </TouchableOpacity>
  );
};

export default Backdrop;

import React, { useState } from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import Carousel from '../../lib/carousel/Carousel';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CarouselItems from './carouselItems';
import { getProgramModulesStyle } from './styles';
import { setActiveProgramModule } from '../../actions/status';
import CarouselNavBtn from '../../components/carouselNavBtn';
import useMediaQuery from '../../hooks/useMediaQuery';

const RightCtn = ({ navigate }) => {
  const [currIndex, setCurrIndex] = useState(0);
  const programModules = useSelector(
    state => {
      let curIndex = 0;
      return state.status.program.programModules.filter(module => {
        if (curIndex <= 3 && !module.transaction.isCompleted) {
          curIndex = curIndex + 1;
          return true;
        } else if (module.transaction.isCompleted) {
          return true;
        }

        return false;
      })
    }
  );
  const window = useWindowDimensions();
  const { isMobile } = useMediaQuery()

  const styles = getProgramModulesStyle(window.width, window.height)

  const dispatch = useDispatch();

  const setProgramModule = item => {
    dispatch(setActiveProgramModule(item));
    navigate('ProgramSlides');
  };

  let carouselRef = '';

  return (
    <View style={styles.carouselViewContainer}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>Practice Missions</Text>
      </View>
      <View style={styles.carouselView}>
        {Boolean(currIndex !== 0) &&
          <CarouselNavBtn type="left" prev={() => carouselRef.snapToPrev()} />
        }
        <Carousel
          initialNumToRender={2}
          windowSize={2}
          ref={carousel => {
            carouselRef = carousel;
          }}
          data={programModules}
          renderItem={({ item, index }) => (
            <CarouselItems
              item={item}
              index={index}
              currIndex={currIndex}
              setProgramModule={setProgramModule}
            />
          )}
          sliderWidth={window.width}
          itemWidth={window.height * 0.72}
          inactiveSlideScale={0.9}
          enableMomentum
          enableSnap
          onSnapToItem={(index) => {
            if (isMobile) {
              setCurrIndex(index);
            } else {
              setCurrIndex(carouselRef.currentIndex)
            }
          }}
        />
        {Boolean(currIndex < programModules?.length - 1) &&
          <CarouselNavBtn type="right" next={() => carouselRef.snapToNext()} />
        }
      </View>
    </View>
  );
};

RightCtn.propTypes = {
  navigate: PropTypes.func,
};

export default RightCtn;

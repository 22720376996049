import React, { useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, Image, Text, useWindowDimensions, View } from 'react-native';

import PropTypes from 'prop-types';
import Config from '../config';
import { $white } from '../constants/colors';
import { $robotoBold } from '../constants/fonts';

const AbilityBadgeIcon = ({ type, head, name, active, bgStyle, style, enabled, item }) => {
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height)
  const [showActive, setShowActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if(enabled)
    interval = setInterval(() => {
      setShowActive(act => !act);
    }, 1000)

    return () => {
      if(interval){
        clearInterval(interval);
        interval = null
      }
    }
  }, [enabled])

  return (
    <>
      {head && (
        <ImageBackground
          resizeMode="stretch"
          source={{
            uri: Config.IMG_URL + '/abilities/shield_text_bg.png',
          }}
          style={styles.headBg}>
         
        </ImageBackground>
      )}
      <ImageBackground
        resizeMode="contain"
        style={bgStyle}
        source={{
          uri:
            Config.IMG_URL +
            `/icons/${type}_shield_${!(active && !showActive) ? 'active' : 'inactive'}.png`,
        }}>
        <Image
          resizeMode="contain"
          style={[styles.icon, style]}
          source={{
            uri: ((type === "virtues") ? ((active || showActive) ? item?.disableImageUrl : item?.enableImageUrl) : null) ||
              Config.IMG_URL +
              `/icons/${type.slice(0, -1)}/${
                (active || showActive) ? name + '_disabled' : name
              }.png`,
          }}
        />
      </ImageBackground>
      <View
        style={styles.textHeadContainer}
      >
        <Text  style={styles.head}>{head}</Text>
      </View>
    </>
  );
};

const getStyle = (width, height) => StyleSheet.create({
  head: {
    color: $white,
    fontFamily: $robotoBold,
    fontSize: height*0.02,
    textAlign: 'center',
    fontWeight: '900',
  },
  textHeadContainer: {
    width: height*0.16,
    position: 'absolute',
    top:  -(height*0.052),
    justifyContent: "center",
    alignItems: "center",
    minHeight: height*0.06,
    paddingHorizontal: 4
  },
  headBg: {
    height: height*0.1,
    justifyContent: 'center',
    alignItems: "center",
    padding: 1.5,
    paddingBottom: 15,
    position: 'absolute',
    top: -(height*0.06),
    width: height*0.16,
  },

  icon: {
    height: height*0.08,
    position: 'absolute',
    top: height*0.01,
    width: height*0.05,
  },
});

AbilityBadgeIcon.propTypes = {
  bgStyle: PropTypes.object,
  style: PropTypes.object,
  active: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  head: PropTypes.string,
};

export default React.memo(AbilityBadgeIcon);

import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  useWindowDimensions,
} from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { requestForgotPassword } from './../../../actions/user';
import { setLoadingState } from '../../../actions/status';

import { getForgotStyle } from './styles';
import { $bdCartoon } from '../../../constants/fonts';
import FormBGContainer from '../FormBGContainer';

const ForgotPasswordScreen = ({ navigation, modal, onLoginClick, closeModal }) => {
  const [email, setEmail] = useState('');
  const [validForm, setValidForm] = useState(true);
  const w = useWindowDimensions();

  const styles = getForgotStyle(w.width, w.height);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (email) {
      dispatch(setLoadingState(true));
      const { msg } = await requestForgotPassword(email);
      dispatch(setLoadingState(false));
      setValidForm(true);
      // if (error) {
      //   alert(
      //     (error && error.errors && error?.errors[0].detail) ||
      //       'some error occurred please try again later',
      //   );
      // }
      alert(
        msg
      );
      if (window && window.location.search) {
        window.location.href = "/"
      }
      if(modal){
        closeModal()
      } else {
        navigation.navigate('Login');
      }
    } else {
      alert('email is required');
      setValidForm(false);
    }
  };

  const onPressLogin = () => {
    if(modal){
      onLoginClick()
    } else {
      navigation.navigate('Login');
    }
  };

  return (
    <FormBGContainer hideBG={modal}>
      <Text style={styles.formTxt}>Email</Text>
      <TextInput
        value={email}
        onChangeText={email => setEmail(email)}
        returnKeyType={'next'}
        onSubmitEditing={() => handleSubmit()}
        disableFullscreenUI={true}
        blurOnSubmit={false}
        autoCapitalize="none"
        style={[
          styles.formInput,
          validForm ? '' : styles.invalidForm,
        ]}
      />
      <Text style={styles.fieldInfo}>
        We&apos;ll send you instructions on how to reset your password
        on this email.
      </Text>
      <View style={styles.otherOpt}>
        <TouchableOpacity onPress={onPressLogin}>
          <Text style={styles.forgotLabel}>Back To Login</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        style={[styles.submitButton, { marginTop: 10 }]}
        onPress={handleSubmit}
      >
        <Text
          style={[styles.formTxt, { color: "white", fontFamily: $bdCartoon, letterSpacing: 4 }]}
        >
          send me instructions
        </Text>
      </TouchableOpacity>
    </FormBGContainer>
  );
};

ForgotPasswordScreen.propTypes = {
  navigation: PropTypes.object,
};

export default ForgotPasswordScreen;

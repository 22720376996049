import React from 'react';
import { ImageBackground, Text, View, useWindowDimensions, TouchableOpacity } from 'react-native';
import Config from '../../../config';
import { $yellow } from '../../../constants/colors';
import { $bdCartoon } from '../../../constants/fonts';

const PlayerInlineCard = ({ 
    playerDetails = {
        name: "Test",
        position: "4",
        points: 103245672
    },
    onPress,
    selected
}) => {
  const window = useWindowDimensions()

  return (
      <TouchableOpacity onPress={onPress}>
    <ImageBackground
        source={{
        uri: Config.IMG_URL + `/leaderboard/${selected ? "player_listing" : "listing"}.png`
        }}
        style={{
            width: window.height*1.06,
            height: window.height*0.078,
            marginBottom: window.height*0.03,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingHorizontal: window.height*0.06
        }}
    >
        <View
            style={{
                flexDirection: "row",
                width: "15%"
            }}
        >
            <Text
                style={{
                    fontSize: window.height*0.03,
                    fontFamily: $bdCartoon,
                    fontWeight: "900",
                    letterSpacing: 3,
                    color: $yellow,
                }}
            >
                {
                    playerDetails?.position
                }
            </Text>
                <Text
                     style={{
                        fontSize: window.height*0.022,
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 3,
                        color: $yellow,
                        marginLeft: 2
                    }}
                >
                    {
                      "TH"

                    }
                </Text>
        </View>
        <View
            style={{
                width: Boolean(playerDetails?.tag) ? "42%" : "62%",
                paddingRight: 5
            }}
        >
            <Text
                style={{
                    fontSize: window.height*0.03,
                    fontFamily: $bdCartoon,
                    fontWeight: "900",
                    letterSpacing: 3,
                    color: $yellow,
                    textAlign: Boolean(playerDetails?.tag) ? "left" : "left"
                }}
                numberOfLines={1}
            >
                {playerDetails?.name}
            </Text>
        </View>
       {Boolean(playerDetails?.tag) && <View
        style={{
            width: "20%",
            paddingRight: 5
        }}
       >
            <Text
                style={{
                    fontSize: window.height*0.024,
                    fontFamily: $bdCartoon,
                    fontWeight: "900",
                    letterSpacing: 3,
                    color: $yellow,
                    textAlign: "left"
                }}
                numberOfLines={1}
            >
                {playerDetails?.tag}
            </Text>
        </View>}
        <View
            style={{
                width: "23%"
            }}
        >
            <Text
                style={{
                    fontSize: window.height*0.024,
                    fontFamily: $bdCartoon,
                    fontWeight: "900",
                    letterSpacing: 3,
                    color: $yellow,
                    textAlign: "right"
                }}
                numberOfLines={1}
            >
                {playerDetails?.points+"  PTS"}
            </Text>
        </View>
    </ImageBackground>
    </TouchableOpacity>
  );
};

export default PlayerInlineCard;

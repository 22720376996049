import React, { useCallback, useEffect } from 'react';
import { SafeAreaView, FlatList, Text, ImageBackground, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getGamesData } from '../../actions/games';
import { setLoadingState } from '../../actions/status';
import GameList from './gameList';
import Config from '../../config';

import { getGameStyle } from './styles';
import { TouchableOpacity } from 'react-native';
import { Image } from 'react-native';
import { requestLogout } from '../../actions/auth';
import { useSFX } from '../../components/SFXConfig';
import { View } from 'react-native';
import { SOUNDS } from '../../constants/sfx';

const GamesScreen = () => {
  const games = useSelector(state => state.games.data);
  const window = useWindowDimensions();
  const styles = getGameStyle(window.width, window.height);
  const { playSound } = useSFX();

  let activeGames = [];
  let otherGames = [];

  const dispatch = useDispatch();

  games.forEach(game => {
    game.startedAt = new Date(game.startedAt);
    switch (game.state) {
      case 'active':
        activeGames.push(game);
        break;
      default:
        otherGames.push(game);
    }
  });
  activeGames.sort((a, b) => b.startedAt - a.startedAt);
  otherGames.sort((a, b) => b.startedAt - a.startedAt);

  const getData = useCallback(async () => {
    dispatch(setLoadingState(true));
    const { error } = await dispatch(getGamesData());
    dispatch(setLoadingState(false));

    if (error) {
      alert(
        (error && error.errors && error?.errors[0].detail) ||
          'some error occurred please try again later',
      );
      console.warn(error);
    }
  },[dispatch])

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/login/background.png' }}
      style={styles.container}>
      <SafeAreaView style={styles.listContainer}>
        <View
          style={{
            width: window.width,
            height: 70,
            display: 'flex',
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Text style={styles.heading}>Select Mission</Text>
          <View
            style={{
              position: "absolute",
              right: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: window.width*0.055 + 20,
              maxHeight: 70,
              minHeight: 50,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                  if (playSound) {
                      playSound({
                          sound: SOUNDS.logoutButton
                      })
                  }
                  dispatch(requestLogout());
              }}
            >
              <Image
                  style={{
                      width: window.width*0.17,
                      height: window.width*0.055,
                      maxHeight: 50,
                      maxWidth: 145,
                      minHeight: 30,
                      minWidth: 85
                  }}
                  source={Config.IMG_URL + "/dashboard/logout.png"}
              />
            </TouchableOpacity>
          </View>
        </View>
        <FlatList
          data={[...activeGames, ...otherGames]}
          renderItem={({ item }) => <GameList game={item} />}
          keyExtractor={item => item.id}
        />
      </SafeAreaView>
    </ImageBackground>
  );
};

export default GamesScreen;

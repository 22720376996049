import React from 'react'
import { useWindowDimensions, View } from 'react-native'
import { useModal } from '../../../components/ModalProvider'
import { $white } from '../../../constants/colors'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { MENU_ITEMS, PAGE_ID } from '../constants'
import { EnrollmentForm } from './ContactUs'


const MenuComponent = ({ menuShow, setMenushow }) => {
    const w_obj = useWindowDimensions();
    const { isMobile } = useMediaQuery();
    const { openModal } = useModal()

    const onContactUsClick = () => {
        openModal({
            open: true,
            component: EnrollmentForm,
            blur: true,
            data: {
            }
        })
    }

    if(isMobile){
        return (
        <View
            style={{
                width: "100%",
                height: menuShow ? "290px" : "0px",
                transition: "height 0.5s ease",
                position: "fixed",
                top: "60px",
                left: 0,
                zIndex: 1000,
                overflow: "hidden",
                backgroundColor: "rgba(0,0,0,0.7)",
            }}
        >
            <ul
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                    listStyle: "none",
                    padding: "0px",
                    margin: "0px",
                    width: "100%",
                    color: $white,
                }}
            >
            {MENU_ITEMS?.length &&
                MENU_ITEMS?.map((item, index) => {

                    return (
                        <li
                            style={{
                                padding: "8px 20px",
                                backgroundColor: "rgba(0,0,0,0.7)",
                            }}
                            onClick={() => {
                                setMenushow(false)
                                if(item.id === PAGE_ID.contact_us){
                                    onContactUsClick()
                                }
                            }}
                        >
                            <a href={`#${item.id}`}
                                style={{
                                    display: "flex",
                                    textDecoration: "none",
                                    color: '#f9c184',
                                    fontFamily: "'Fredoka One', cursive",
                                    fontSize: w_obj.width * 0.07,
                                    width: "100%"
                                }}
                            >
                                {item.label}
                            </a>
                        </li>
                    )
                })
            }
            </ul>
        </View>
        )
    }

    return (
        <View
            style={{
                height: "40px",
                padding: "10px",
                justifyContent: "center",
                alignItems: "flex-end",
                opacity: 1,
                // transition: "opacity 0.3s ease",
                marginHorizontal: "10px"
            }}
        >
            <ul
                style={{
                    display: "flex",
                    gap: "19px",
                    paddingRight: "11px",
                    listStyle: "none",
                    color: $white,
                }}
            >
            {MENU_ITEMS?.length &&
                MENU_ITEMS?.map((item, index) => {

                    return (
                        <li
                            style={{
                                borderLeftWidth: index !== 0 ? "1px" : 0,
                                borderLeftColor: $white,
                                borderLeftStyle: "solid",
                                paddingLeft: "10px",
                            }}
                            onClick={() => {
                                if(item.id === PAGE_ID.contact_us){
                                    onContactUsClick()
                                }
                            }}
                        >
                            <a href={`#${item.id}`}
                                style={{
                                    textDecoration: "none",
                                    fontFamily: "'Fredoka One', cursive",
                                    fontSize: w_obj.height * 0.024
                                }}
                            >
                                {item.label}
                            </a>
                        </li>
                    )
                })
            }
            </ul>
        </View>
    )
}

export default MenuComponent
import AsyncStorage from '@react-native-community/async-storage';
import { getGames, getGame } from '../services/games';
import {
  SET_GAMES,
  SET_CURRENT_GAMES,
  SET_SELECTED_GAME_ID,
  SET_GAME_PLAYERS,
  SHOW_MISSION_POPUP,
} from '../constants/actions';
import { getEnabledVirtuesData, getPlayerData, getPreferences } from './player';
import { getProgramsData } from './programs';
import { fetchPendingMissionNotification, getCities } from './cities';
import { getLeaderboardData } from './leaderboards';
import { setLoadingState } from './status';
import { setSocket } from '../services/socket';

export const getGamesData = () => async dispatch => {
  try {
    const { data } = await getGames().catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    let games = [];
    data.data.forEach(element => {
      games.push({
        id: element.id,
        gameMasterId: element.attributes.game_master_id,
        name: element.attributes.name,
        state: element.attributes.state,
        isDemo: element.attributes.is_demo,
        startedAt: element.attributes.started_at,
        endedAt: element.attributes.ended_at,
        scheduledEndAt: element.attributes.scheduled_end_at,
        chatRoomId: element.attributes.chat_room_id,
        icon: element?.attributes?.icon || "https://d1ldtr09ujthyn.cloudfront.net/icons/superhero-within.png",
      });
    });
    dispatch({
      type: SET_GAMES,
      payload: { data: games },
    });

    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const getGameData = id => async dispatch => {
  try {
    const { data } = await getGame(id).catch(error => {
      if(error?.errors?.length){
        throw error.errors[0];
      }
      if (error?.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    const game = {
      id: data.data.attributes.id,
      gameMasterId: data.data.attributes.game_master_id,
      name: data.data.attributes.name,
      state: data.data.attributes.state,
      isDemo: data.data.attributes.is_demo,
      startedAt: data.data.attributes.started_at,
      endedAt: data.data.attributes.ended_at,
      leaderboardId: data?.data?.attributes?.leaderboard_id,
      scheduledEndAt: data.data.attributes.scheduled_end_at,
      chatRoomId: data.data.attributes.chat_room_id,
      game_tags: data?.data?.relationships?.game_tags?.data || []
    };
    let game_tags = [];
    let players = {};
    data.included.forEach(item => {
      if (item.type === 'player') {
        players[item.id] = {
          name: item.attributes.name,
          characterName: item.attributes.profile.data
            ? item.attributes.profile.data.attributes.character_name
            : item.attributes.profile.data,
        };
      } else if(game?.game_tags?.find(tag => tag.id.toString() === item.id.toString() && item.type === tag.type)){
        game_tags.push({
          key: item.id,
          label: item.attributes.display_name,
          value: item.attributes.name
        })
      }
    });
    game.game_tags = [...game_tags];
    dispatch(setCurrentGame(game));
    dispatch({
      type: SET_GAME_PLAYERS,
      payload: { data: players },
    });
    return { data: game };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const setCurrentGame = data => async dispatch => {
  try {
    AsyncStorage.setItem('selectedGame', (data?.id || "").toString());
    dispatch({
      type: SET_SELECTED_GAME_ID,
      payload: data?.id || null,
    });
    dispatch({
      type: SET_CURRENT_GAMES,
      payload: { data: data },
    });
  } catch (error) {
    console.log(error);
    return { error };
  }
};

let selectedGameId = "";

export const loadGameData = (gameId, update) => async (dispatch, getState) => {
  try {
    dispatch(setLoadingState(true))
    const { error: gError, data } = await dispatch(
      getGameData(gameId),
    );
    if (gError) {
      dispatch(setCurrentGame({}))
      dispatch(setLoadingState(false))
      if(gError?.code == 404){
        window.customAlert("Please contact your game's admin for access to this game.\n\nError code: cred00923")
        throw new Error({
          error: "Game is inactive!"
        })
      } else {
        alert(gError?.detail)
      }
      throw new Error({ error: 'Game is inactive' });
    }
    const { error: lError } = dispatch(getLeaderboardData(gameId, data.leaderboardId));
    const { error: plError, data: playerData } = await dispatch(getPlayerData());
    const { error: prError } = dispatch(getProgramsData());
    const { error: cError } = dispatch(getCities());
    const { error: prefError } = dispatch(getPreferences(playerData?.id));
    const { error: virtError } = dispatch(getEnabledVirtuesData(playerData?.id));

    dispatch(setLoadingState(false))
    if(selectedGameId.toString() !== gameId.toString() || update){
      selectedGameId = gameId;
      const playerId = getState()?.player?.id;
      setSocket()?.subscribe(selectedGameId, dispatch, playerId);
      dispatch({
        type: SHOW_MISSION_POPUP
      })
      dispatch(fetchPendingMissionNotification(playerId));
    }
    if (plError || prError || cError || gError || lError || prefError || virtError) {
      throw new Error({ error: 'error while loading data' });
    }
  } catch (error) {
    console.log(error);
    return { error };
  }
};

import React, { useImperativeHandle } from 'react';
import { View, useWindowDimensions } from 'react-native';
import Carousel from '../../lib/carousel/Carousel';
import PropTypes from 'prop-types';

import { fltiData } from '././../../constants/ftliData';
import CarouselItems from './carouselItems';
import { getVideoStyle } from './styles';

const CarouselCmp = ({ setCurrentIndex, currentIndex }, ref) => {
  let carouselRef = '';
  const window = useWindowDimensions()
  const styles = getVideoStyle(window.width, window.height);

  useImperativeHandle(ref, () => ({
    snapToNext() {
      carouselRef.snapToNext();
    },
    snapToPrev() {
      carouselRef.snapToPrev();
    },
  }));

  return (
    <View style={styles.carouselView}>
      <Carousel
        ref={c => (carouselRef = c)}
        initialNumToRender={2}
        windowSize={2}
        data={fltiData}
        renderItem={({ item, index }) => (
          <CarouselItems url={item.url} type={item.type} currentIndex={currentIndex} index={index} />
        )}
        sliderWidth={window.width}
        itemWidth={window.width}
        slideStyle={{ width: window.width }}
        containerCustomStyle={styles.carouselCustomStyle}
        onSnapToItem={() => setCurrentIndex(carouselRef.currentIndex)}
      />
    </View>
  );
};

CarouselCmp.propTypes = {
  setCurrentIndex: PropTypes.func,
};

const CarouselRefComponent = React.forwardRef(CarouselCmp);

export default React.memo(CarouselRefComponent);

import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import { useDispatch } from 'react-redux';

import { setRebeccaState } from './../../actions/status';
import Config from './../../config';

import { styles } from './styles';

const BackBtn = () => {
  const dispatch = useDispatch();

  return (
    <TouchableOpacity
      onPress={() => dispatch(setRebeccaState(false))}
      style={styles.backBtn}>
      <Image
        style={styles.full}
        resizeMode="contain"
        source={{
          uri: Config.IMG_URL + '/icons/arrow_top.png',
        }}
      />
    </TouchableOpacity>
  );
};

export default BackBtn;

import React, { useState, useEffect } from 'react';
import { Text, ImageBackground, FlatList, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import TraitsList from './traitList';
import { getCharacterChoices } from '../../actions/player';
import {
  setHelpMessage,
  setRebeccaState,
  setLoadingState,
} from '../../actions/status';
import { characterSTraitsHelpMessage } from '../../constants/ftliHelp';
import Config from '../../config';

import { getStyle } from './styles';
import { FTLI_HELP_AUDIO } from '../../constants/helpMessages';

const CharacterTraits = ({ route, navigation }) => {
  // const traits = route.params.traits;

  const [traitsData, setTraitsData] = useState(false);
  const [activeTrait, setActiveTrait] = useState(null);

  const playerTrait = useSelector(state => state.player.profile.traitId);
  const window = useWindowDimensions();
  const styles = getStyle(window.width, window.height);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      dispatch(setLoadingState(true));
      const { traits: tData } = await dispatch(getCharacterChoices());
      setTraitsData(tData);
      dispatch(setLoadingState(false));
    };

    dispatch(setHelpMessage(characterSTraitsHelpMessage, FTLI_HELP_AUDIO[1]));//2
    dispatch(setRebeccaState(true));
    getData();
  }, [dispatch]);

  useEffect(() => {
    playerTrait && setActiveTrait(playerTrait);
  }, [playerTrait])

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/practice_missions/background.png' }}
      style={styles.container}>
      <Text style={styles.head}>Select a persona</Text>
      <FlatList
        data={traitsData}
        renderItem={({ item }) => (
          <TraitsList
            navigate={navigation.navigate}
            desc={item.description}
            id={item.id}
            activeTrait={activeTrait}
            setActiveTrait={id => setActiveTrait(id)}
          />
        )}
        keyExtractor={item => item.name}
      />
    </ImageBackground>
  );
};

CharacterTraits.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
};

export default CharacterTraits;

import createReducer from '../lib/createReducer';
import { SET_USER, LOGOUT } from '../constants/actions';

const initialState = {
  name: '',
  displayName: '',
  email: '',
  locale: '',
  hasSignedUp: '',
};

export const user = createReducer(initialState, {
  [SET_USER](state, { payload: { data } }) {
    const {
      name,
      display_name: displayName,
      email,
      locale,
      has_signed_up: hasSignedUp,
    } = data.attributes;
    return { ...state, name, displayName, email, locale, hasSignedUp };
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

import React from 'react';
import { View, Text, ImageBackground, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setLoadingState } from '../../actions/status';
import { retryCity } from '../../actions/cities';
import { getStyle } from './styles';
import Config from '../../config';
import { getPlayerData } from '../../actions/player';

const Retry = ({ fare, cityId, critical }) => {
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height);
  const dispatch = useDispatch();

  const retry = async () => {
    dispatch(setLoadingState(true));
    const { error } = await dispatch(retryCity(cityId));
    if(critical)
    await dispatch(getPlayerData())
    dispatch(setLoadingState(false));
    if (error) {
      alert(
        (error && error.errors && error?.errors[0].detail) ||
          'some error occurred please try again later',
      );
    }
  };

  return (
    <>
      <View style={styles.boardCtn}>
        <View style={styles.boardLeftPnl}>
          <Text style={styles.fareHead}> Fare </Text>
          <ImageBackground
            resizeMode="stretch"
            style={styles.fareBg}
            source={{
              uri: Config.IMG_URL + '/save_earth/cronos.png',
            }}>
            <Text style={styles.fareValue}>{fare}</Text>
          </ImageBackground>
        </View>
        <View style={styles.boardRightPnl}>
          <TouchableOpacity style={styles.boardBtn} onPress={() => retry()}>
            <ImageBackground
              resizeMode="contain"
              style={styles.boardImg}
              source={{
                uri: Config.IMG_URL + '/save_earth/btn.png',
              }}>
              <Text style={styles.boardTxt}>Retry</Text>
            </ImageBackground>
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.activeCtn, styles.activeBrdCtn]}>
        <View style={[styles.activeProgress, styles.failedProgress]}></View>
        <Text style={[styles.activeTxt, styles.activeBrdTxt]}>Failed</Text>
      </View>
    </>
  );
};

Retry.propTypes = {
  fare: PropTypes.number,
  cityId: PropTypes.number,
};

export default React.memo(Retry);

import React, { useCallback, useEffect, useState } from 'react';
import branch from 'branch-sdk';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { requestVerifySignup } from '../../actions/user';
import { setLoadingState } from '../../actions/status';
import SplashScreen from './../../components/splash';
import { getGameData } from '../../actions/games';
import Config from '../../config';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useModal } from '../../components/ModalProvider';

let state = 'mount';

const Auth = ({ navigation }) => {
  const isUserLoggedIn = useSelector(state => state.player.isLoggedIn);
  const { isMobile } = useMediaQuery();
  const dispatch = useDispatch();
  const [blockNavigation, setBlockNavigation] = useState(true)
  const { openModal } = useModal()

  useEffect(() => {
    if(isMobile){
      let standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        android = /android/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

      if (ios && (!standalone && safari)) {
        navigation.navigate("Superhero Within")
      } else if(android && (!userAgent.includes('wv'))) {
        navigation.navigate("Superhero Within")
      } else {
        setBlockNavigation(false);
      }
    } else {
      setBlockNavigation(false);
    }
  }, [isMobile, openModal, navigation])

  const handleSignup = useCallback(async (email, token) => {
    const { data, error } = await requestVerifySignup(email, token);
    dispatch(setLoadingState(false));
    if (error) {
      alert(
        (error && error.errors && error?.errors[0].detail) ||
        'some error occurred please try again later',
      );
      navigation.navigate('Login');
      return;
    }
    if (!data.valid) {
      alert("Your sign up link has expired. Request your administrator to send you a new sign up link.");
      if(isMobile){
        navigation.navigate('Login');
      } else {
        navigation.navigate("Superhero Within")
      }
      return;
    }
    navigation.navigate('Signup', { token, email });
  }, [navigation, dispatch, isMobile])

  const handleLogin = useCallback(async id => {
    if (isUserLoggedIn) {
      dispatch(setLoadingState(true));
      const { error } = await dispatch(getGameData(id));
      dispatch(setLoadingState(false));
      if (error) {
        if(isMobile){
          navigation.navigate('Login');
        } else {
          navigation.navigate("Superhero Within")
        }
        return;
      }
    }
    // navigation.navigate('Login');
    navigation.navigate("Superhero Within")
  }, [navigation, isUserLoggedIn, dispatch, isMobile])

  useEffect(() => {
    if(blockNavigation){
      return;
    }
    var params = {};
    var query = window?.location?.search?.substring(1);
    var vars = query?.split('&');
    for (var i = 0; i < vars?.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    branch.init(Config.BRANCH_KEY, {
      branch_match_id: params["_branch_match_id"],
      metadata: {
        utm_source: params["utm_source"],
        utm_medium: params["utm_medium"]
      }
    }, (error, data) => {
      const params = data?.data_parsed
      if (error) {
        alert('some error occurred please try again later');
        if(isMobile){
          navigation.navigate('Login');
        } else {
          navigation.navigate("Superhero Within")
        }
        return;
      }
      if (params?.type === 'JOIN_GAME') {
        handleLogin(params?.game_id);
      } else if (params?.type === 'RESET_PASSWORD') {
        navigation.navigate('ResetPassword', {
          token: params?.reset_password_token,
        });
      } else if (params?.type === 'SIGN_UP') {
        dispatch(setLoadingState(true));
        handleSignup(params?.email, params?.sign_up_token);
      } else {
        if(isMobile){
          navigation.navigate('Login');
        } else {
          navigation.navigate("Superhero Within")
        }
      }
    });
    if ((state === 'unmount' || Object.keys(params)?.length === 0) && !isUserLoggedIn) {
      if(isMobile){
        navigation.navigate('Login');
      } else {
        navigation.navigate("Superhero Within")
      }
    }
    return () => {
      state = 'unmount';
      branch.logout((err) => {
        console.log(err);
      })
    };
  }, [isUserLoggedIn, dispatch, handleLogin, handleSignup, navigation, blockNavigation, isMobile]);

  return <SplashScreen />;
};

Auth.propTypes = {
  navigation: PropTypes.object,
};

export default Auth;

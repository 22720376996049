import React from 'react'
import { Text, useWindowDimensions, View } from "react-native";
import { $black, $yellowShadow } from '../constants/colors';
import { $robotoRegular } from '../constants/fonts';

const NotificationModal = ({
    closeModal,
    data
}) => {
    const { width, height } = useWindowDimensions();

    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height
            }}
            onStartShouldSetResponder={() => {
                closeModal()
                return false;
            }}
        >
            <View
                style={{
                    width: data?.position?.width,
                    maxHeight: 300,
                    bottom: height - data?.position?.top + 10,
                    left: data?.position?.left,
                    minWidth: width * 0.22,
                    position: "absolute",
                    backgroundColor: data?.backgroundColor || $yellowShadow,
                    paddingVertical: height * 0.01,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15
                }}
            >
                <Text
                    style={{
                        fontFamily: $robotoRegular,
                        fontSize: height * 0.025,
                        fontWeight: "400",
                        letterSpacing: 2,
                        color: $black,
                        width: "100%",
                        paddingHorizontal: 15,
                        textAlign: "center",
                        flex: 1,
                        textAlignVertical: "center"
                    }}
                >
                    {data.message}
                </Text>
            </View>
        </View>
    )
}

export default NotificationModal;
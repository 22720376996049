export const SOUNDS = {
    menuOpen: "menuopen.mp3",
    helpButton: "help.mp3",
    menuItem: "generalclick.mp3",
    settingButton: "generalclick.mp3",
    musicSetting: "generalclick.mp3",
    missionPlanet: "generalclick.mp3",
    boardButton: "generalclick.mp3",
    leaderboard: "generalclick.mp3",
    logoutButton: "logoutleavegame.mp3",
    leaveGameButton: "logoutleavegame.mp3",
    loginSignup: "loginsignup.mp3",
    boardModal: "boardedmission.mp3",
    missionCompleteFailure: "missioncomplete.mp3",
    abilityModal: "abilityactivated.mp3",
    useClick: "use.mp3",
    joinGame: "generalclick.mp3",
    missionFailureModal: "missionfail.mp3",
    boardSupport: "generalclick.mp3",
    general: "generalclick.mp3"
}

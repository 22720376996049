import React, { useEffect } from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { $robotoRegular } from '../constants/fonts';
import { $yellow } from '../constants/colors';
import * as RootNav from '../navigation/RootNav';
import { useDispatch } from 'react-redux';
import { setHelpMessage } from '../actions/status';
import { FTLI_HELP_AUDIO } from '../constants/helpMessages';

// 13 help audio

const ProgramsCompleteHelp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHelpMessage(null, FTLI_HELP_AUDIO[12]))
  }, [dispatch])

  return (
    <>
      <View style={styles.container}>
        <ScrollView>
          <Text style={styles.txt}>
            Great! You now know how to earn EP (Effort Points). <br></br><br></br>Take a look at the EP you just earned on the top left of the screen. Click the button below when you're ready to check out your Dashboard
          </Text>
        </ScrollView>
      </View>
      <TouchableOpacity
        style={styles.btn}
        onPress={() => RootNav.navigate('Dashboard')}>
        <Text>Go To Dashboard</Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    marginLeft: 10,
    marginTop: 10,
    position: 'relative',
    top: -5,
    width: 180,
  },

  container: {
    height: '74%',
    paddingHorizontal: 10,
  },

  txt: {
    fontFamily: $robotoRegular,
    fontSize: 16,
    marginRight: 10,
    marginTop: 10,
    fontWeight: '400',
  },
});

export default ProgramsCompleteHelp;

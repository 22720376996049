import axios from './index';
import Config from './../config';
import axiosConfig from './../constants/axiosConfig';

export const getUser = async () =>
  axios.get(Config.API_URL + '/users/me?include=members', await axiosConfig({ token: true }));

export const verifySignup = async (email, token) =>
  axios.get(
    Config.API_URL +
    `/users/verify_signup?email=${email}&signup_token=${token}`,
  );

export const registerUser = async data =>
  axios.post(Config.API_URL + '/users/sign_up', data);

export const forgotPassword = email =>
  axios.put(Config.API_URL + '/users/forgot_password', { email });

export const resetPassword = data =>
  axios.put(Config.API_URL + '/users/reset_password', data);

import React from 'react';
import { ImageBackground, useWindowDimensions, View } from 'react-native';
import PropTypes from 'prop-types';

import Config from '../../config';
import BoardCtn from './boardCtn';
import BadgesCtn from './badgesCtn';

import { getStyle } from './styles';

const RightCtn = ({ navigation }) => {
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height);

  return (
    <ImageBackground
      resizeMode="stretch"
      source={{ uri: Config.IMG_URL + '/save_earth/bg_right.png' }}
      style={styles.leftBg}>
      <View style={styles.leftContainer}>
        <BadgesCtn type="superpowers" label="Equipment" navigation={navigation} />
        <BadgesCtn type="virtues" label="Virtues" navigation={navigation} />
      </View>
      <BoardCtn />
    </ImageBackground>
  );
};

RightCtn.propTypes = {
  city: PropTypes.object,
  navigation: PropTypes.object
};

export default React.memo(RightCtn);

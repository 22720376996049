import createReducer from '../lib/createReducer';
import {
  SET_GAMES,
  SET_GAME_PLAYERS,
  SET_CURRENT_GAMES,
  LOGOUT,
} from '../constants/actions';

/**
 * array of objects
 * currentGame: {
 *   id
 *   gameMasterId
 *   name
 *   state
 *   isDemo
 *   startedAt
 *   endedAt
 *   scheduledEndAt
 *   chatRoomId
 * }
 * data: [array of games of same object like currentGame],
 */
const initialState = { data: [], currentGame: {}, players: [] };

export const games = createReducer(initialState, {
  [SET_GAMES](state, { payload: { data } }) {
    return { ...state, data: [...data] };
  },
  [SET_CURRENT_GAMES](state, { payload: { data } }) {
    return { ...state, currentGame: { ...data } };
  },
  [SET_GAME_PLAYERS](state, { payload: { data } }) {
    return { ...state, players: { ...data } };
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

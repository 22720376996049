import { StyleSheet } from 'react-native';

import {
  $yellow,
  $liteYellow,
  $white,
  $black,
  $blue,
} from './../../constants/colors';
import { $bdCartoon, $robotoRegular } from '../../constants/fonts';

const viewStyles = StyleSheet.create({
  background: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
    flexDirection: "row"
  },
  chatBackground: {
    height: '92%',
    marginLeft: 22,
    width: '100%',
    alignItems: "center"
  },
  head: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: 22,
    paddingBottom: 5,
  },
  inputContainer: {
    bottom: 10,
    height: 100,
    left: -20,
    paddingLeft: 20,
    paddingVertical: 10,
    position: 'absolute',
    width: '100%',
  },
  messagesContainer: {
    height: '100%',
    paddingBottom: 110,
    paddingRight: 20,
    paddingTop: 20,
  },
});

const messageStyles = StyleSheet.create({
  body: {
    paddingHorizontal: 30,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: 10,
    paddingVertical: 3,
    width: '100%',
  },
  head: {
    fontFamily: $bdCartoon,
    fontSize: 13,
  },
  leftText: {
    textAlign: 'left',
  },
  rightContainer: {
    justifyContent: 'flex-end',
  },
  rightText: {
    textAlign: 'right',
  },
  rightTextContainer: {
    backgroundColor: $white,
    marginRight: 20,
  },
  text: {
    fontFamily: $robotoRegular,
    fontSize: 12,
    fontWeight: '400',
  },
  textContainer: {
    backgroundColor: $liteYellow,
    borderRadius: 40,
    marginLeft: 10,
    paddingHorizontal: 15,
    paddingVertical: 12,
    width: '60%',
  },
});

const inputStyles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $blue,
    borderRadius: 5,
    justifyContent: 'center',
    marginLeft: 10,
    paddingHorizontal: 15,
  },
  btnTxt: {
    color: $white,
    fontFamily: $robotoRegular,
    fontWeight: '400',
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  input: {
    backgroundColor: $white,
    borderRadius: 3,
    borderWidth: 1,
    color: $black,
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 10,
    width: '90%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
  },
});

const flattenedStyles = {
  container: StyleSheet.flatten([
    messageStyles.container,
    messageStyles.rightContainer,
  ]),
  textContainer: StyleSheet.flatten([
    messageStyles.textContainer,
    messageStyles.rightTextContainer,
  ]),
  leftText: StyleSheet.flatten([messageStyles.leftText, messageStyles.text]),
  rightText: StyleSheet.flatten([messageStyles.rightText, messageStyles.text]),
};

export { viewStyles, messageStyles, inputStyles, flattenedStyles };

import axios from './index';

import axiosConfig from './../constants/axiosConfig';
import Config from './../config';

export const initiateHelpDesk = async () =>
  axios.post(Config.API_URL + '/players/initiate_support_message', null, await axiosConfig({ token: true, gameId: true, levelId: true }));

export const getProfileDetails = async (profileId) =>
  axios.get(Config.API_URL + `/players/${profileId}/details`, await axiosConfig({ token: true, gameId: true, levelId: true }));

export const sendCronosApi = async (id, amount) =>
  axios.post(Config.API_URL + `/cronos_transactions`, {
    recipient_id: id,
    amount: amount
  }, await axiosConfig({ token: true, gameId: true, levelId: true }));


import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { setSettingState } from '../../actions/status';
import { leaveGame } from '../../actions/player';
import { requestLogout } from '../../actions/auth';
import ModalComponent from './../modal';

import { styles } from './styles';

const Settings = () => {
  const visible = useSelector(state => state.status.isSettingVisible);

  const dispatch = useDispatch();
  return (
    <ModalComponent
      visible={visible}
      onTouchOutside={() => dispatch(setSettingState(false))}>
      <View style={styles.container}>
        <TouchableOpacity
          onPress={() => dispatch(leaveGame())}
          style={styles.btn}>
          <Text style={styles.txt}>Leave Game</Text>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => dispatch(requestLogout())}
          style={styles.btn}>
          <Text style={styles.txt}>Logout</Text>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => dispatch(setSettingState(false))}
          style={styles.btn}>
          <Text style={styles.txt}>Close</Text>
        </TouchableOpacity>
      </View>
    </ModalComponent>
  );
};

export default Settings;

import React, { useState } from 'react';
import {
  Text,
  TextInput,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setLoadingState } from '../../../actions/status';

import { getResetStyle } from './styles';
import { requestResetPassword } from '../../../actions/user';
import { $bdCartoon } from '../../../constants/fonts';
import useMediaQuery from '../../../hooks/useMediaQuery';
import FormBGContainer from '../FormBGContainer';

const ResetPasswordScreen = ({ navigation, route }) => {
  const [password, setPassword] = useState('');
  const [passwordCnf, setPasswordCnf] = useState('');
  const [validForm, setValidForm] = useState(true);
  const w = useWindowDimensions()
  const { isMobile } = useMediaQuery();

  const styles = getResetStyle(w.width, w.height, isMobile);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (password && passwordCnf) {
      if (password !== passwordCnf) {
        alert("password didn't match");
        return;
      }
      dispatch(setLoadingState(true));
      const resetData = {
        password,
        password_confirmation: passwordCnf,
        token: route.params.token,
      };
      const { error } = await requestResetPassword(resetData);
      dispatch(setLoadingState(false));
      setValidForm(true);
      if (window && window.location.search) {
        window.location.href = "/"
      }
      if (error) {
        alert(
          (error && error.errors && error?.errors[0].detail) ||
          'some error occurred please try again later',
        );
        return;
      }
      alert('password reset successful, please login to continue');
      navigation.navigate('Login');
    } else {
      alert('password is required');
      setValidForm(false);
    }
  };

  let passwordRef = '';

  return (
    <FormBGContainer>
      <Text style={styles.formTxt}>Password</Text>
      <TextInput
        value={password}
        onSubmitEditing={() => passwordRef.focus()}
        onChangeText={password => setPassword(password)}
        disableFullscreenUI={true}
        secureTextEntry={true}
        style={[
          styles.formInput,
          validForm ? '' : styles.invalidForm,
        ]}
      />
      <Text style={styles.formTxt}>Confirm Password</Text>
      <TextInput
        ref={input => {
          passwordRef = input;
        }}
        onSubmitEditing={handleSubmit}
        value={passwordCnf}
        onChangeText={passwordCnf => setPasswordCnf(passwordCnf)}
        returnKeyType={'next'}
        disableFullscreenUI={true}
        secureTextEntry={true}
        style={[
          styles.formInput,
          validForm ? '' : styles.invalidForm,
        ]}
      />
      <TouchableOpacity
        style={[styles.submitButton, { marginTop: 10 }]}
        onPress={handleSubmit}
      >
        <Text
          style={[styles.formTxt, { color: "white", fontFamily: $bdCartoon, letterSpacing: 4 }]}
        >
          Reset
        </Text>
      </TouchableOpacity>
    </FormBGContainer>
  );
};

ResetPasswordScreen.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
};

export default ResetPasswordScreen;

import React from 'react';
import { View, TouchableOpacity, Image, useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';

import Config from '../../config';
import { setCityDescState } from '../../actions/status';

import { getStyle } from './styles';
import useMediaQuery from '../../hooks/useMediaQuery';

const CLoseBtn = () => {
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const { isMobile } = useMediaQuery();
  const styles = getStyle(window.width, window.height);

  return (
    <View style={[styles.closeContainer, {
      top: isMobile ? undefined : 0,
      bottom: isMobile && "70vh",
      right: isMobile && "0px"
    }]}>
      <TouchableOpacity
        style={styles.closeImg}
        onPress={() => dispatch(setCityDescState(false))}>
        <Image
          resizeMode="stretch"
          style={styles.closeImg}
          source={{
            uri: Config.IMG_URL + '/save_earth/close.png',
          }}
        />
      </TouchableOpacity>
    </View>
  );
};

export default CLoseBtn;

import {
  SET_PROGRAM_MODULES,
  SET_PROGRAM_MODULES_QUESTIONS,
  UPDATE_PLAYER_CRONOS,
  SET_ACTIVE_PROGRAM,
  SET_PLAYER,
  UPDATE_PROGRAMS
} from './../constants/actions';
import {
  getProgramModule,
  completeProgramModule,
  submitProgramModuleAnswer,
} from './../services/programModules';

export const getProgramModuleData = moduleId => async dispatch => {
  try {
    const { data } = await getProgramModule(moduleId).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    let images = {};
    let questions = {};
    let videos = {};
    let unsortedData = [];
    const mediaType = data.data.attributes.media_type;

    if (mediaType === 'video') {
      const { id, attributes } = data.data.attributes.video_details.data;
      videos[id] = { id, url: attributes.url, provider: attributes.provider };
      /** videos can be only on start */
      unsortedData[0] = { id: id, type: 'video', sort: 0 };
    }

    data.included.forEach((ele, i) => {
      const type = ele.type === 'question' ? 'question' : 'image';
      unsortedData.push({ id: ele.id, type, sort: ele.attributes.sort });
      switch (type) {
        case 'image':
          images[ele.id] = {
            id: ele.id,
            type,
            url: ele.attributes.image.url,
            thumbUrl: ele.attributes.image?.standard?.url,
            standardUrl: ele.attributes.image?.thumb?.url,
            sort: ele.attributes.sort,
          };
          break;
        case 'question':
          const isMultiple = ele?.attributes?.answers?.filter(ans => ans.is_correct)?.length > 1;
          let answerIds = [];
          let isCorrect = true
          if(ele.attributes?.transaction?.[0]){
            answerIds = ele.attributes?.transaction?.map((ans) => {
              if(!ans.is_correct){
                isCorrect = false
              }
              return ans.answer_id;
            })
          } else {
            isCorrect = ele.attributes?.transaction?.is_correct;
          }
          questions[ele.id] = {
            id: ele.id,
            type,
            statement: ele.attributes.statement,
            options: ele.attributes.answers,
            multiple: isMultiple,
            transaction: {
              // eslint-disable-next-line camelcase
              isCorrect: isCorrect,
              // eslint-disable-next-line camelcase
              answerId: (!isMultiple && answerIds?.length) ? answerIds[0] : ele.attributes?.transaction?.answer_id,
              answerIds: (isMultiple && answerIds?.length) ? answerIds : undefined
            },
            sort: ele.attributes.sort,
          };
          break;
        default:
          break;
      }
    });
    const sortedData = unsortedData.sort((a, b) => a.sort - b.sort);
    const dispatchData = {
      mediaType,
      sortedData,
      images,
      videos,
      questions,
    };
    dispatch({
      type: SET_PROGRAM_MODULES,
      payload: { data: dispatchData },
    });

    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const submitAnswer = ({
  programModuleId,
  questionId,
  answerId,
  answerIds
}) => async (dispatch, getState) => {
  try {
    const paramData = {
      id: programModuleId,
      question_id: questionId,
    };
    if(answerId){
      paramData.answer_ids = [answerId];
    }
    if(answerIds){
      paramData.answer_ids = answerIds;
    }
    const { data } = await submitProgramModuleAnswer(paramData).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    const state = getState();
    const updatedModuleData = state.programModules.questions;

    updatedModuleData[questionId].transaction = {
      isCorrent: data.is_correct,
      answerIds: answerIds,
      answerId: answerId
    };
    const updatedPlayerData = state.player;
    updatedPlayerData.ep = state.player.ep + data.xp;
    updatedPlayerData.xp = state.player.xp + data.xp;

    dispatch({
      type: SET_PROGRAM_MODULES_QUESTIONS,
      payload: { data: updatedModuleData },
    });

    dispatch({
      type: SET_PLAYER,
      payload: { data: updatedPlayerData },
    });

    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const completeModule = id => async (dispatch, getState) => {
  try {
    const {
      data: { data },
    } = await completeProgramModule(id).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    const cronos =
      data.attributes.transaction.data.attributes.reward_details.data.attributes
        .awarded_cronos;

    console.log('cronos: ', cronos);

    //awarded_cronos: 2500
    //  awarded_ep: 241
     // awarded_xp: 241

    const state = getState();

    const program = state.status.program;
    const updatedModules = program.programModules.map(d => {
      if (d.id === id) {
        d.transaction.isCompleted = true;
      }
      return d;
    });
    program.programModules = updatedModules;

    dispatch({
      type: UPDATE_PLAYER_CRONOS,
      payload: { data: cronos },
    });

    dispatch({
      type: SET_ACTIVE_PROGRAM,
      payload: program,
    });
    dispatch({
      type: UPDATE_PROGRAMS,
      payload: program,
    });

    return { data: data.attributes.transaction.data.attributes.reward_details.data.attributes };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

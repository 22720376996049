//export const characterSelectionHelpMessage = `\nI’m so happy to see you awake, didn’t think i’d see the day. I know you must be confused about what’s going on. But for now you need to choose a new identity before Kaltron finds out you’re alive.`;
export const characterSelectionHelpMessage = `\nWelcome to the Task Force, my name is Astro. \n\nYou were handpicked to conduct a search and retrieve mission on Planet Xandar. In the next few minutes I'll tell you more about the mission and what you need to do. But for now, can you tell me a bit about yourself?\n\n(USE THE CHARACTERS ON THE LEFT TO SELECT YOUR AVATAR)`;

//export const characterSTraitsHelpMessage = `\nNow that you have chosen a new identity. Can you remember anything about yourself?`;
export const characterSTraitsHelpMessage = `\nThat's fantastic! \n\nCan you see which of these personas you can relate to? This will help you and your team members to get to know each other as you work on missions together.`;

export const virtuesHelpMessage = `With great powers comes great responsibility and virtues are the moral compass to display it. Earn more virtues to become more powerful.

Since we activated the first Virtue and Superpower for you, let me show you how to do it yourself.

Click on any Virtue you want to purchase and then click “Activate” to activate it.

Let’s go to the Superpowers tab and check out all the cool Superpowers `;

export const superpowerHelpMessage = `Superpowers are essentials to save cities. Kaltron has become more powerful and you'll need different superpowers to bring him down in every city..

Click on any Superpower you want to purchase and then click “Activate” to activate it.

To activate more Superpowers you will have to finish more  practice missions`;

export const practiceMissionHelp = `Practice missions will help you gain EPs and Cronos. Make sure you pay attention as these rewards will help you  travel from city to city and unlock superpowers and virtues

Great, now that you know how to earn points.  Let's go check out the Dashboard
`;

//export const cityContribHelpMessage = `\nLooks like you already have Courage abilities. Go ahead and board the city,  then click on initiate to Save Dubai.`;
export const cityContribHelpMessage = `\nSince you already have "Courage" Abilities which you will need to complete this mission, go ahead and click "Use" under Equipment and Virtues. After that click "Board"/"Support". By doing this, you're committing your abilities towards this mission.`;

//export const saveEarthHelpMessage = `\nTo dismantle Kaltron’s network in Dubai, go ahead and click on the circle next to Dubai.`;
export const saveEarthHelpMessage = `\nLet's take a look at what your missions will look like. We'll do a test mission here on Earth so you know what you're doing out there. \n\nClick on Earth to see the test mission`;

export const saveEarthHelpMessageLast = `Welcome aboard, this is the SRP Portal. Here you'l find all the
things required to fight the Robotic Resistance. Let’s head over to the
Virtues tab so I can show you around.`;

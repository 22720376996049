import { SET_LEADERBOARD_DATA, SET_TEAM_LEADERBOARD_DATA } from "../constants/actions";
import { getLeaderboards, getTeamLeaderboard } from "../services/leaderboard";


export const getLeaderboardData = (gameId, lId, searchParams, notCallTeam) => async dispatch => {
    try {
      const { data } = await getLeaderboards(gameId, searchParams).catch(error => {
        if (error.response === undefined) {
          throw new Error({ msg: 'Please check your internet connection' });
        }
        throw error.response.data;
      });
      const leaderboards = data?.data?.map((leader) => {
          const deatail = {
              name: leader.attributes.name,
              xp: Number(leader.attributes.xp),
              ep: Number(leader.attributes.ep),
              cronos: Number(leader.attributes.cronos),
              character_name: leader?.attributes?.profile?.data?.attributes?.character_name,
              id: leader.id,
              points: Number(leader.attributes?.score).toFixed(2),
              tag: leader.attributes.tag,
              position: leader.attributes.rank
          }
          deatail.missions = leader?.relationships.cities?.data.map(city => {
              const cityData = data.included.find(d => d.type === city.type && d.id === city.id);
              return cityData?.attributes?.display_name;
          })

          
         
          deatail.virtues = []
          leader?.relationships.virtues?.data.forEach(virtue => {
              const virtueData = data.included.find(d => d.type === virtue.type && d.id === virtue.id && d?.attributes?.is_activated);
              if(virtueData)
              deatail.virtues.push(virtueData?.attributes?.display_name);
          })
          deatail.superpowers = []
          leader?.relationships.superpowers?.data.forEach(superpower => {
              const superpowerData = data.included.find(d => d.type === superpower.type && d.id === superpower.id && d?.attributes?.is_activated);
              if(superpowerData)
              deatail.superpowers.push(superpowerData?.attributes?.display_name);
          })
          deatail.missionCount =  leader.attributes.completed_cities_count;
          deatail.superpowerCount = deatail.superpowers.length;
          deatail.virtueCount = deatail.virtues.length;
          
          return deatail;
        });

        if(Boolean(!notCallTeam)){
          if(!lId){
            dispatch({
              type: SET_TEAM_LEADERBOARD_DATA,
              payload: {
                data: []
              }
            })
          }
          const { data } = await getTeamLeaderboard(lId).catch(error => {
            if (error.response === undefined) {
              throw new Error({ msg: 'Please check your internet connection' });
            }
            throw error.response.data;
          });
          let leaderboardTeams = [];
          data.data.relationships.leaderboard_teams.data.forEach(leaderboard => {
              const leaderBoardData = data.included.find(inc => inc.type === leaderboard.type && inc.id === leaderboard.id);
              if(leaderBoardData){
                leaderboardTeams.push({
                  name: leaderBoardData?.attributes?.name,
                  id: leaderBoardData?.attributes.id,
                  missions: leaderBoardData?.attributes?.team_contributed_missions,
                  superpowerCount: leaderBoardData?.attributes?.team_active_superpowers_count,
                  virtueCount: leaderBoardData?.attributes?.team_active_virtues_count,
                  missionCount: leaderBoardData?.attributes?.team_successful_missions_count,
                  points: Number(leaderBoardData?.attributes?.score).toFixed(2)
                })
              } 
          })
          leaderboardTeams.sort((a,b) => b.points - a.points)
          dispatch({
            type: SET_TEAM_LEADERBOARD_DATA,
            payload: {
              data: leaderboardTeams.map((board, index) => {
                return {
                  ...board,
                  position: index+1
                }
              })
            }
          })
        }
       
        dispatch({
            type: SET_LEADERBOARD_DATA,
            payload:{ 
               data:{
                player_score: [...leaderboards],
                keepTopThree: Boolean(searchParams?.length > 2)
               }
            }
        })
    } catch (error) {
        console.log(error);
        return { error };
    }
}
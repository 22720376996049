import React from 'react'
import Config from '../../../config';
import { $white } from '../../../constants/colors';
import { $bdCartoon } from '../../../constants/fonts';
import './footer.css'

const Footer = () => {

    return (
    <div className="main_footer_inner d-flex" style={{ paddingTop: "30px"}}>
        <div className="footer_widget_list">
            <div className="footer_logo">
                <a href="#"><img aria-label="logo" width="215" height="79" src={Config.IMG_URL + '/login/title_text.png'} alt="" /></a>
            </div>
            <div className="footer_contact_desc">
                <p style={{ fontFamily: "'Fredoka One', cursive", fontSize: "1.2rem" }}>Multiplayer Gamification For Asynchronous Learning
                </p>
            </div>
            <div className="footer_social">
                <ul className="d-flex">
                    <li><a aria-label="facebook" className="facebook" href="https://www.facebook.com"><i className="icofont-facebook"></i></a></li>
                    <li><a aria-label="youtube" className="youtube" href="https://www.youtube.com"><i className="icofont-youtube-play"></i></a></li>
                    <li><a aria-label="twitter" className="twitter" href="https://twitter.com"><i className="icofont-twitter"></i></a></li>
                </ul>
            </div>
        </div>
        <div className="footer_widget_list footer_list_menu">
            <p style={{ fontFamily: $bdCartoon, fontSize: "2rem" }}>Resources</p>
            <div className="footer_menu">
                <ul style={{ color: $white}}>
                    <li><a style={{ fontFamily: "'Fredoka One', cursive", fontSize: "1.2rem" }} href="https://apps.apple.com/us/app/evivve-the-leadership-game/id1495148773" target="_blank">App Store (iOS)</a></li>
                    <li><a style={{ fontFamily: "'Fredoka One', cursive", fontSize: "1.2rem" }} href="https://play.google.com/store/apps/details?id=com.evivvemain.evivvemain&amp;hl=en_IN&amp;gl=US" target="_blank">Play Store (Android)</a></li>
                    <li><a style={{ fontFamily: "'Fredoka One', cursive", fontSize: "1.2rem" }} href="https://www.gamitar.com/terms-and-conditions" target="_blank">Privacy Policy</a></li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export default Footer;
import React from 'react';
import { useWindowDimensions, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { setCityDescState } from '../../actions/status';
import ModalComponent from '../../components/modal';
import LeftCtn from './leftCtn';
import CLoseBtn from './closeBtn';
import RightCtn from './rightCtn';

import { getStyle } from './styles';

const CityDescription = () => {
  const city = useSelector(state => state.status.slectedCity);
  const visible = useSelector(state => state.status.isCityDescVisible);
  const window = useWindowDimensions()

  const styles = getStyle(window.width, window.height);

  const dispatch = useDispatch();

  return (
    <ModalComponent
      visible={visible}
      onTouchOutside={() => dispatch(setCityDescState(false))}>
      <View style={styles.container}>
        <LeftCtn city={city} />
        <RightCtn />
        <CLoseBtn />
      </View>
    </ModalComponent>
  );
};

export default CityDescription;

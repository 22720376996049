import React, { useCallback, useEffect, useState } from 'react'
import { 
    View,
    Text,
    Linking,
    StyleSheet,
    useWindowDimensions,
    Platform
} from 'react-native';
import { $blue } from '../constants/colors';
import { $robotoRegular } from '../constants/fonts';


const QuestionStatement = ({
    statement
}) => {
    const [link, setLink] = useState("");
    const [leftText, setLeftText] = useState("");
    const [rightText, setRightText] = useState("");
    const { width, height } = useWindowDimensions()

    const styles = getStyle(width, height);

    const openLink = useCallback(async () => {
        if(Platform.OS === "web"){
            window.open(link, "_blank")
        } else if(await Linking.canOpenURL(link)){
            Linking.openURL(link)
        }
    }, [link])

    useEffect(() => {
        let links = statement.match(/\b(https|http)?:\/\/\S+/gi);
        if(links?.length){
            setLink(links[0])
            const splitStatement = statement.split(links[0]);
            setLeftText(splitStatement[0]);
            setRightText(splitStatement[1]);
        } else {
            setLeftText(statement)
        }
    }, [statement])

    return (
        <View
            style={{
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: height*0.03
            }}
        >
           
                <Text
                    style={styles.carouselQuestion}
                >
                    {leftText+" "}
                    {Boolean(link) && <span onClick={openLink} style={{
                        fontFamily: $robotoRegular,
                        fontSize: height*0.045+"px",
                        color: $blue,
                        cursor: "pointer"
                    }}>Click Here</span>}
                    {rightText}
                </Text>
            {/* {Boolean(link) &&
               
                    <Text
                        style={styles.linkCarouselQuestion}
                        onPress={openLink}
                    >
                        Click Here 
                    </Text>
            } */}
            
                {/* <Text
                    style={[styles.carouselQuestion]}
                >
                    {rightText.substring(0, 60)}
                </Text> */}
                {/* <Text
                    style={[styles.carouselQuestion]}
                >
                    {rightText}
                </Text> */}
        </View>
    )
}

const getStyle = (width, height) => StyleSheet.create({
    carouselQuestion: {
        fontFamily: $robotoRegular,
        fontSize: height*0.045,
        lineHeight: height*0.05,
        fontWeight: '400',
        maxWidth: width*0.75,
        display: "inline"
    },
    linkCarouselQuestion: {
        fontFamily: $robotoRegular,
        fontSize: height*0.045,
        fontWeight: '400',
        color: $blue,
    }
})

export default QuestionStatement;

import React, { useEffect, useMemo, useState } from 'react';
import { Text, useWindowDimensions, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { getCities, getDisabledCities } from '../../actions/cities';
import { $white, $yellow } from '../../constants/colors';
import { $bdCartoon } from '../../constants/fonts';

let interval = null


const UpcomingCity = () => {
    const city = useSelector(state => state.cities.upcomingCity);
    const showBanner = useSelector(state => state.cities.showUpcomingBanner);
    const cities = useSelector(state => state.cities?.data);
    const [time, setTimes] = useState(null)
    const window = useWindowDimensions()
    const dispatch = useDispatch();

    const isBossFight = useMemo(() => {
        if (cities?.length)
            return Boolean(
                cities?.find(city => city.isBossFight)
            )
        else return false;
    }, [cities])

    useEffect(() => {
        if (!showBanner) {
            return;
        }
        if (interval) {
            clearInterval(interval);
            interval = null;
        }
        if (isBossFight) {
            return;
        }
        const clearAll = () => {
            clearInterval(interval);
            interval = null;
            dispatch(getCities());
            dispatch(getDisabledCities());
        }
        interval = setInterval(() => {
            const newDate = new Date()?.getTime();
            const cityReleaseTime = new Date(city?.scheduled_release_at)?.getTime();
            let leftTime = cityReleaseTime - newDate;
            if (leftTime < 0) {
                clearAll();
            }
            const day = parseInt(leftTime / (24 * 60 * 60 * 1000));
            leftTime = leftTime - (day * 24 * 60 * 60 * 1000);
            const hours = parseInt(leftTime / (60 * 60 * 1000));
            leftTime = leftTime - hours * 60 * 60 * 1000;
            const minutes = parseInt(leftTime / (60 * 1000));
            setTimes({
                day,
                hours,
                minutes
            })
        }, 1000)

        return () => {
            if (interval) {
                clearInterval(interval);
                interval = null;
            }
        }
    }, [city?.id, city?.scheduled_release_at, dispatch, showBanner, isBossFight])

    if (!city || !showBanner || !time || isBossFight) {
        return null;
    }

    return (
        <View
            style={{
                position: "absolute",
                top: window.height / 2 - window.height * 0.12,
                left: window?.width * 0.005,
                minHeight: window.height * 0.02,
                minWidth: window.width * 0.146,
                padding: 10,
                backgroundColor: $yellow,
                borderRadius: 5,
                shadowColor: $yellow,
                shadowOffset: { width: 0, height: 0 },
                shadowOpacity: 10,
                shadowRadius: 20,
                elevation: 20
            }}
        >
            <Text
                style={{
                    color: $white,
                    fontSize: window?.height * 0.04,
                    fontFamily: $bdCartoon,
                    letterSpacing: 2,
                    textAlign: "center"
                }}
            >
                Next Mission {"\n" + time?.day + "D : " + time?.hours + "H : " + time?.minutes + "M"}
            </Text>
        </View>
    );
};

export default UpcomingCity;

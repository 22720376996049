import Config from '../config';

export const fltiData = [
  {
    type: 'video',
    url: Config.VID_URL + '/space_intro.mp4',
  },
  {
    type: 'img',
    url: Config.IMG_URL + '/mission_start_briefing/space_01.png',
  },
  {
    type: 'img',
    url: Config.IMG_URL + '/mission_start_briefing/space_02.png',
  },
  {
    type: 'img',
    url: Config.IMG_URL + '/mission_start_briefing/space_03.png',
  },
  {
    type: 'img',
    url: Config.IMG_URL + '/mission_start_briefing/space_04.png',
  },
  // {
  //   type: 'img',
  //   url: Config.IMG_URL + '/mission_start_briefing/05.png',
  // },
];

export const TAGS_OPTIONS = [
  {
    label: "GSC Cairo",
    value: "GSC Cairo",
    key: "GSC Cairo",
  },
  {
    label: "GSC & HTC China",
    value: "GSC & HTC China",
    key: "GSC & HTC China",
  },
  {
    label: "GSC Colombo",
    value: "GSC Colombo",
    key: "GSC Colombo",
  },
  {
    label: "GSC Malaysia",
    value: "GSC Malaysia",
    key: "GSC Malaysia",
  },
  {
    label: "GSC Philippines",
    value: "GSC Philippines",
    key: "GSC Philippines",
  },
  {
    label: "GSC Poland",
    value: "GSC Poland",
    key: "GSC Poland",
  },
  {
    label: "GSC & Tech India",
    value: "GSC & Tech India",
    key: "GSC & Tech India",
  },
]
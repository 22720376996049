import React, { useCallback, useEffect } from 'react';
import { useWindowDimensions, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import {
  setCityContribState,
  setHelpMessage,
  setRebeccaState,
  setLoadingState,
} from '../../actions/status';
import ModalComponent from '../../components/modal';
import SaveEarthHelp from '../../components/saveEarthHelp';
import { cityContribHelpMessage } from '../../constants/ftliHelp';
import LeftCtn from './leftCtn';
import CloseBtn from './closeBtn';
import RightCtn from './rightCtn';

import { getStyle } from './styles';
import { getCities } from '../../actions/cities';
import { FTLI_HELP_AUDIO } from '../../constants/helpMessages';

const CityContribution = ({ navigation }) => {
  const visible = useSelector(state => state.status.isCityContribVisible);
  const levelId = useSelector(state => state.player.level.id);
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height);

  const dispatch = useDispatch();

  const loadCityData = useCallback(async () => {
    await dispatch(setLoadingState(true));
    await dispatch(getCities());
    await dispatch(setLoadingState(false));
  }, [dispatch])

  useEffect(() => {
    loadCityData();
    if (levelId === 1) {
      if (visible) {
        dispatch(setHelpMessage(cityContribHelpMessage, FTLI_HELP_AUDIO[3]));//4
      } else {
        dispatch(setHelpMessage(SaveEarthHelp, FTLI_HELP_AUDIO[4]));//5
      }
      dispatch(setRebeccaState(true));
    }
  }, [visible, levelId, dispatch, loadCityData]);

  const onClose = () => {
    dispatch(setCityContribState(false));
  };

  return (
    <ModalComponent visible={visible} onTouchOutside={() => onClose()}>
      <View style={styles.container}>
        <LeftCtn />
        <RightCtn navigation={navigation} />
        <CloseBtn close={onClose} />
      </View>
    </ModalComponent>
  );
};

export default CityContribution;

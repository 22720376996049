import Config from "../../../config"

export const PAGE_ID = {
    pricing: "pricing",
    top_section: "top_section",
    intro_video: "video",
    contact_us: "contact_us",
    how_it_works: "how_it_works",
    publisher: "publisher",
    designer: "designer"
}

export const MENU_ITEMS = [
    {
        id: PAGE_ID.intro_video,
        label: "About",
    },
    {
        id: PAGE_ID.designer,
        label: "Designer",
    },
    {
        id: PAGE_ID.publisher,
        label: "Publisher",
    },
    {
        id: PAGE_ID.how_it_works,
        label: "How it Works",
    },
    {
        id: PAGE_ID.pricing,
        label: "Pricing",
    },
    {
        id: PAGE_ID.contact_us,
        label: "Contact",
    },
]

export const HOW_IT_WORKS_TYPE = {
    admin: "admin",
    player: "player"
}
//  /add_players.mp4 Text: Add Players
// /add_content.mp4 Text: Add Learning Content & Start Game
//  /player_joins_game.mp4 Text: Learner Joins Game
//  /player_learns_to_play.mp4 Text: Learner Learns to Play
//  /player_unlocks_abilities.mp4 Text: Learner Unlocks Abilities
//  /player_learns.mp4 Text: Learner Consumes Content
//  /download_report.mp4 Text: Download Progress Report
export const HOW_IT_WORKS_DATA = [
    {
        type: HOW_IT_WORKS_TYPE.admin,
        data_type: "video",
        uri: Config.VID_URL + "/create_a_game.mp4",
        key: "admin_1",
        heading: "Create a Game"
    },
    {
        type: HOW_IT_WORKS_TYPE.admin,
        data_type: "video",
        uri: Config.VID_URL + "/add_players.mp4",
        key: "admin_2",
        heading: "Add Players"
    },
    {
        type: HOW_IT_WORKS_TYPE.admin,
        data_type: "video",
        uri: Config.VID_URL + "/add_content.mp4",
        key: "admin_3",
        heading: "Add Learning Content & Start Game"
    },
    {
        type: HOW_IT_WORKS_TYPE.player,
        data_type: "video",
        uri: Config.VID_URL + "/player_joins_game.mp4",
        key: "player_1",
        heading: "Learner Joins Game"
    },
    {
        type: HOW_IT_WORKS_TYPE.player,
        data_type: "video",
        uri: Config.VID_URL + "/player_learns_to_play.mp4",
        key: "player_2",
        heading: "Learner Learns to Play"
    },
    {
        type: HOW_IT_WORKS_TYPE.player,
        data_type: "video",
        uri: Config.VID_URL + "/player_unlocks_abilities.mp4",
        key: "player_3",
        heading: "Learner Unlocks Abilities"
    },
    {
        type: HOW_IT_WORKS_TYPE.player,
        data_type: "video",
        uri: Config.VID_URL + "/player_learns.mp4",
        key: "player_4",
        heading: "Learner Consumes Content"
    },
    {
        type: HOW_IT_WORKS_TYPE.admin,
        data_type: "video",
        uri: Config.VID_URL + "/download_report.mp4",
        key: "admin_4",
        heading: "Download Progress Report"
    },
]


export const PRICING_DATA = [
    {
        key: "pricing_1",
        cost: "$8",
        credit: "1 - 49"
    },
    {
        key: "pricing_2",
        cost: "$7",
        credit: "50 - 99"
    },
    {
        key: "pricing_3",
        cost: "$6",
        credit: "100 - 499"
    },
    {
        key: "pricing_4",
        cost: "$5",
        credit: "500 - 999"
    },
    {
        key: "pricing_5",
        cost: "$4",
        credit: "1000 - 2499"
    },
    {
        key: "pricing_6",
        cost: "$3",
        credit: "2500 - 4999"
    },
    {
        key: "pricing_7",
        cost: "$2",
        credit: "5000 - 9999"
    },
    {
        key: "pricing_8",
        cost: "$1",
        credit: "10000+"
    },
]
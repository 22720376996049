import React from 'react';
import { View, ImageBackground, useWindowDimensions } from 'react-native';

import Config from '../../config';
import TabHeader from './tabHeader';
import TabStats from './tabStats';
import Badges from './badges';

import { getTabStyle } from './styles';
import useMediaQuery from '../../hooks/useMediaQuery';

const Tabs = ({ navigation }) => {
  const window = useWindowDimensions()
  const { isMobile } = useMediaQuery();
  const styles = getTabStyle(window.width, window.height, isMobile)

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        {['superpowers', 'virtues'].map(d => (
          <TabHeader key={d} type={d} label={d === "superpowers" ? "equipment" : d} />
        ))}
      </View>
      <ImageBackground
        resizeMode="stretch"
        source={{ uri: Config.IMG_URL + '/abilities/window_bg.png' }}
        style={styles.bg}>
        <TabStats />
        <Badges navigation={navigation} />
      </ImageBackground>
    </View>
  );
};

export default Tabs;

import { StyleSheet } from 'react-native';

export const getStyle = (width, height) => StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: height,
    width: width,
  },
});

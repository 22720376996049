import React, { useEffect, useState } from 'react'
import { Text, useWindowDimensions } from 'react-native';
import { ActivityIndicator, Image, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { getPlayerData } from '../../actions/player';
import MissionStartPopUp from '../../components/missionStartPopup';
import { useModal } from '../../components/ModalProvider';
import Config from '../../config';
import { SHOW_MISSION_POPUP } from '../../constants/actions';
import { $darkYellow } from '../../constants/colors';
import { $zIndexMax } from '../../constants/zIndex';
import { increaseBackgroundAudioVolume, slowBackgroundAudioVolume } from '../../helpers';
import useMediaQuery from '../../hooks/useMediaQuery';
import { convertToCronos } from '../../services/games';

let script = null;

const keys = 
{
    a: {
        key: 'ArrowLeft',
        keyCode: 37,
        which: 37,
        code: "ArrowLeft",
        isTrusted: true
    },
    d: {
        key: 'ArrowRight',
        keyCode: 39,
        which: 39,
        code: "ArrowRight",
        isTrusted: true
    },
    w: {
        key: 'ArrowUp',
        keyCode: 38,
        which: 38,
        code: "ArrowUp",
        isTrusted: true
    },
    s: {
        key: 'ArrowDown',
        keyCode: 40,
        which: 40,
        code: "ArrowDown",
        isTrusted: true
    },
    fire: {
        key: '',
        keyCode: 32,
        which: 32,
        code: "",
        isTrusted: true
    },
    missile: {
        key: 'ControlKey',
        keyCode: 17,
        which: 17,
        code: "ControlKey",
        isTrusted: true
    },
    shield: {
        key: 'e',
        keyCode: 69,
        which: 69,
        code: "KeyE",
        isTrusted: true
    },
}


window.simulateKeyPress = (key, fieldRef) => {
    const eventDown = new KeyboardEvent('keydown', keys[key]);
    const eventUp = new KeyboardEvent('keyup', keys[key]);
    fieldRef.dispatchEvent(eventDown);
    setTimeout(() => {
        fieldRef.dispatchEvent(eventUp);
    }, 30)
}


let keyDownIntervals = {

};

let simulateKeyDown = (key) => {
    const eventDown = new KeyboardEvent('keydown', keys[key]);
    window.document.dispatchEvent(eventDown);
    keyDownIntervals[key] = setInterval(() => {
        window.document.dispatchEvent(eventDown);
    }, 30)
}

let simulateKeyUp = (key) => {
    if(keyDownIntervals[key]){
        clearInterval(keyDownIntervals[key]);
        keyDownIntervals[key] = null;
    }
    const eventUp = new KeyboardEvent('keyup', keys[key]);
    window.document.dispatchEvent(eventUp);
}

const PlanetryTravelGame = ({
    closeGame,
    game
}) => {
    const [loading, setLoading] = useState("initial");
    const [blockControl, setBlockControls] = useState(true);
    const dispatch = useDispatch();
    const { openModal } = useModal();
    const { width } = useWindowDimensions();
    const { isMobile } = useMediaQuery();

    useEffect(() => {
        if(isMobile){
            setBlockControls(false)
        } else {
            setBlockControls(true)
        }
    }, [isMobile])

    useEffect(() => {
        setLoading(true)
        script = document.createElement("script");
        window.convert_to_cronos = async () => {
            setLoading(true);
            const { error } = await convertToCronos(parseInt(localStorage.getItem("Current Score")), "Won through planetry travel Game.");
            if (!error)
                dispatch(getPlayerData());
            if (script) {
                document.getElementById("fb-root")?.firstChild?.remove();
                script.remove();
                script = null;
            }
            setLoading("initial");
            setTimeout(() => {
                closeGame();
                if (!game?.data?.notFromMissionStart)
                    openModal({
                        open: true,
                        data: { ...game.data, showResult: true },
                        component: MissionStartPopUp,
                        blur: true
                    })
            }, 1100)
        }
        slowBackgroundAudioVolume('mini-game');
        script.src = "https://space-sw-master.s3.ap-south-1.amazonaws.com/mini-games/planetary-travel/c2runtime.js";
        script.async = true;
        script.onload = () => {
            setTimeout(() => {
                window.cr_createRuntime({
                    exportType: "html5"
                });
                const currRendererAndLoader = window?.cr?.runtime?.prototype?.initRendererAndLoader
                window.cr.runtime.prototype.initRendererAndLoader = function(){
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                    currRendererAndLoader.call(this);
                }
            }, 1100)
        }

        document.body.appendChild(script);

        return () => {
            window.cr_k.setSuspended(!0);
            window.cr_k.setSuspended = () => { };
            increaseBackgroundAudioVolume('mini-game');
        }
    }, [dispatch, game.data, closeGame, openModal])

    const performClose = () => {
        if (script) {
            document.getElementById("fb-root")?.firstChild?.remove();
            script.remove();
            script = null;
        }
        setLoading(true);
        setTimeout(() => {
            setLoading("initial");
        }, 400)
        setTimeout(() => {
            dispatch({
                type: SHOW_MISSION_POPUP
            })
            closeGame();
        }, 1600)
    }


    return (
        <div
            id="fb-root"
            style={{
                position: 'absolute',
                top: "0px",
                left: "0px",
                right: "0px",
                bottom: "0px",
                zIndex: $zIndexMax,
                ...(loading ? {
                } : {
                    background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${Config.IMG_URL + "/save_earth/space_bg.png"})`
                }),
                backgroundColor: loading === "initial" ? "rgb(0,0,0,0.01)" : "black",
                transition: "background-color 1000ms ease-out",
            }}
        >
            {!loading && <View
                style={{
                    position: "absolute",
                    top: width*0.013,
                    left: width*0.04
                }}
            >
                <TouchableOpacity
                    style={{
                        width: width*0.03,
                        height: width*0.03,
                        minWidth: 20,
                        minHeight: 20,
                        maxHeight: 60,
                        maxWidth: 60,
                    }}
                    onPress={() => {
                        performClose()
                    }}>
                    <Image
                        resizeMode="stretch"
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        source={{
                            uri: Config.IMG_URL + '/save_earth/close.png',
                        }}
                    />
                </TouchableOpacity>
            </View>}
            {!loading && !blockControl && <View
                style={{
                    position: "absolute",
                    bottom: width*0.19,
                    right: width < 700 ? width*0.06 : width*0.08
                }}
            >
                <View
                    style={{
                        display: "flex",
                        position: "relative",
                        flexDirection: "row",
                        width: width*0.18,
                        flexWrap: "wrap",
                    }}
                >
                    <TouchableOpacity
                        style={{
                            width: width*0.08,
                            height: width*0.08,
                            position: "absolute",
                            left: 0,
                            top: width*0.085,
                        }}
                        onPressIn={() => simulateKeyDown("s")}
                        onPressOut={() => simulateKeyUp("s")}
                    >
                        <img
                            src={Config.IMG_URL + "/icons/move-down.png"}
                            style={{
                                height: width*0.08,
                                width: width*0.08,
                            }}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{
                            width: width*0.08,
                            height: width*0.08,
                            position: "absolute",
                            left: width*0.085,
                            top: 0,
                        }}
                        onPressIn={() => simulateKeyDown("d")}
                        onPressOut={() => simulateKeyUp("d")}
                    >
                        <img
                            src={Config.IMG_URL + "/icons/move-right.png"}
                            style={{
                                height: width*0.08,
                                width: width*0.08,
                            }}
                        />
                    </TouchableOpacity>
                </View>
            </View>}
            {!loading && !blockControl && <View
                style={{
                    position: "absolute",
                    bottom: width*0.18,
                    left: width < 700 ? width*0.06 : width*0.08
                }}
            >
                <View
                    style={{
                        display: "flex",
                        position: "relative",
                        flexDirection: "row",
                        width: width*0.18,
                        flexWrap: "wrap",
                    }}
                >
                    <TouchableOpacity
                        style={{
                            width: width*0.08,
                            height: width*0.08,
                            position: "absolute",
                            left: width*0.085,
                            top: 0,
                        }}
                        onPressIn={() => simulateKeyDown("w")}
                        onPressOut={() => simulateKeyUp("w")}
                    >
                        <img
                            src={Config.IMG_URL + "/icons/move-up.png"}
                            style={{
                                height: width*0.08,
                                width: width*0.08,
                            }}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{
                            width: width*0.08,
                            height: width*0.08,
                            position: "absolute",
                            left: 0,
                            top: width*0.085,
                        }}
                        onPressIn={() => simulateKeyDown("a")}
                        onPressOut={() => simulateKeyUp("a")}
                    >
                        <img
                            src={Config.IMG_URL + "/icons/move-left.png"}
                            style={{
                                height: width*0.08,
                                width: width*0.08,
                            }}
                        />
                    </TouchableOpacity>
                </View>
            </View>}
            {loading && <div
                style={{
                    position: 'absolute',
                    top: "0px",
                    left: "0px",
                    right: "0px",
                    bottom: "0px",
                    zIndex: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: loading === "initial" ? "rgb(0,0,0,0.01)" : "black",
                    transition: "background-color 1000ms ease-out",
                }}
            >
                {Boolean(loading) &&
                    <ActivityIndicator
                        size="large"
                        color={$darkYellow}
                    />
                }
            </div>}
        </div>
    )
}

export default PlanetryTravelGame;

import React from 'react';
import PropTypes from 'prop-types';

import ImageSlides from './imageSlide';
import VideoSlide from './videoSlide';
import useMediaQuery from '../../hooks/useMediaQuery';

const CarouselItems = ({ type, url, index, currentIndex }) => {
  const { isMobile } = useMediaQuery();

  if (type === 'video') {
    return <VideoSlide url={url} pause={index !== currentIndex} videoStyle={{
      height: isMobile && "155%",
      width: "100%"
    }} />;
  }
  return <ImageSlides url={url} />;
};

CarouselItems.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
};

export default React.memo(CarouselItems);

import { SET_LEADERBOARD_DATA, SET_TEAM_LEADERBOARD_DATA } from "../constants/actions";
import createReducer from "../lib/createReducer";


const initialState = { data: {
  team_score: [],
  player_score: []
} };

export const leaderboards = createReducer(initialState, {
  [SET_TEAM_LEADERBOARD_DATA](state, { payload: { data } }) {
    return { ...state, data: {...state?.data, team_score: [...data]} };
  },
  [SET_LEADERBOARD_DATA](state, { payload: { data } }) {
    return { 
      ...state, 
      data: {
        ...state?.data, 
        player_score: data?.keepTopThree ? 
        [...(state?.data?.player_score?.filter((_, index) => index <= 2)), ...data.player_score] : 
        [...data.player_score]
      } 
    };
  },
});
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, ImageBackground, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import CharacterList from './characterlist';
import Config from '../../config';
import { getLeftCtnStyle } from './styles';
import { useSelector } from 'react-redux';

const LeftCtn = ({
  charactersData,
  setSelectedCharacter,
  selectedCharacter,
  player: {
    profile: { characterId, characterName },
  },
}) => {
  const listRef = useRef();
  const [curIndex, setCurrentIndex] = useState(0);
  const data = !selectedCharacter.name
    ? { id: characterId, name: characterName }
    : selectedCharacter;
  const window = useWindowDimensions();
  const styles = getLeftCtnStyle(window.width, window.height);
  const visible = useSelector(state => state.status.isRebeccaVisible);

  useEffect(() => {
    if(listRef?.current && charactersData?.length && (charactersData?.length/2 - 1) > curIndex && visible){
      listRef?.current?.scrollToIndex({
        animated: true,
        index: curIndex,
        viewPosition: 0
      })
     setTimeout(() => {
      setCurrentIndex(curIndex + 1);
     }, 5000)
    }
  }, [curIndex, charactersData, visible])

  return (
    <ImageBackground
      resizeMode="stretch"
      source={{ uri: Config.IMG_URL + '/practice_missions/left_bg.png' }}
      style={styles.conatiner}>
      <FlatList
        ref={listRef}
        data={charactersData}
        getItemLayout={(data, index) => {
          return {
            length: window?.height * 0.32,
            offset: window.height * 0.32 * index,
            index
          }
        }}
        numColumns={2}
        extraData={data}
        renderItem={({ item }) => (
          <CharacterList
            name={item.name}
            id={item.id}
            selectedCharacter={data}
            setSelectedCharacter={(name, id) =>
              setSelectedCharacter({ name, id })
            }
          />
        )}
        keyExtractor={item => item.id}
      />
    </ImageBackground>
  );
};

LeftCtn.propTypes = {
  charactersData: PropTypes.array,
  selectedCharacter: PropTypes.object,
  player: PropTypes.object,
  setSelectedCharacter: PropTypes.func,
};

export default LeftCtn;

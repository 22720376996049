import React from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

import Config from '../config';
import useMediaQuery from '../hooks/useMediaQuery';

const CarouselNavBtn = ({ type, style, next, prev }) => {
  const { isMobile } = useMediaQuery();
  const styles = getStyles(isMobile)

  return (
    <View style={[styles.container, styles['nav' + type], style]}>
      <TouchableOpacity
        onPress={() => {
          type === 'left' ? prev() : next();
        }}>
        <Image
          source={{
            uri: Config.IMG_URL + `/practice_missions/${type}_arrow.png`,
          }}
          style={styles.btn}
          resizeMode="contain"
        />
      </TouchableOpacity>
    </View>
  );
}

CarouselNavBtn.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
  next: PropTypes.func,
  prev: PropTypes.func,
};

export default React.memo(CarouselNavBtn);

const getStyles = (isMobile) => StyleSheet.create({
  btn: {
    height: isMobile ? 50 : 70,
    width: 90,
  },

  container: {
    justifyContent: 'center',
    position: 'absolute',
    top: '45%',
    zIndex: 10,
  },

  navleft: {
    left: -20,
  },

  navright: {
    right: -20,
  },
});

import React from 'react';
import { View, Text, Image, ImageBackground, useWindowDimensions } from 'react-native';
import Config from '../../config';
import { $black } from '../../constants/colors';
import { $robotoRegular } from '../../constants/fonts';

const AbilityEnabledMessage = ({
  item
}) => {
  const { height } = useWindowDimensions();

  return (
    <View style={{
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: height*0.12
    }}>
      <Text
        style={{
            fontFamily: $robotoRegular,
            fontSize: height*0.03,
            fontWeight: "600",
            letterSpacing: 2,
            color: $black,
            width: "100%",
            paddingHorizontal: 15,
            textAlign: "center",
            marginTop: height*0.01,
            textAlignVertical: "center"
        }}
      >
          {/* {item.type === "virtue" ? "Virtue " : "Equipment "}Activated! {"\n"} */}
          You have earned the {item.displayName} {item.type === "virtue" ? "virtue!" : "equipment!"}
      </Text>
      <ImageBackground
            resizeMode="contain"
            style={{
                width: height*0.1,
                height: height*0.1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                margin: height*0.02
            }}
            source={{
            uri: Config.IMG_URL +
                `/icons/${item.type === "virtue" ? "virtues_shield_active" : "superpowers_shield_active"}.png`,
            }}
        >
            <Image
                    resizeMode="contain"
                    style={{
                        width: height*0.07,
                        height: height*0.07
                    }}
                    source={{
                    uri: ((item.type === "virtue") ? item.enableImageUrl : null) ||
                        Config.IMG_URL +
                        `/icons/${item.type === "virtue" ? "virtue" : "superpower"}/${
                        item.name
                        }.png`,
                    }}
            />
        </ImageBackground>
        <Text
            style={{
                fontFamily: $robotoRegular,
                fontSize: height*0.03,
                fontWeight: "600",
                letterSpacing: 2,
                color: $black,
                width: "100%",
                paddingHorizontal: 15,
                textAlign: "center",
                textAlignVertical: "center"
            }}
        >
            Head over to the abilities section to activate and equip it.
        </Text>
    </View>
  );
};

export default AbilityEnabledMessage;

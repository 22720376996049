import React, { Fragment, useState, useEffect } from 'react';
import { View, Image, ScrollView, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Config from '../../config';
import AbilityBadgeIcon from './../../components/abilityBadgeIcon';
import BadgeDescription from './badgeDescription';

import { getBadgeStyles } from './styles';
import { setAbilities } from '../../actions/status';

const getActiveRangeValue = (currIndx, actIndx, total) => {
  if (actIndx === null || actIndx === undefined) return;
  const tempIndex = Math.ceil((actIndx + 1) / 4) * 4 - 1;
  if (
    actIndx > total - 4 &&
    total % 4 !== 0 &&
    Math.ceil(tempIndex / 4) >= Math.ceil(total / 4)
  ) {
    return currIndx === total - 1;
  }
  return currIndx === tempIndex;
};

const Badges = ({navigation}) => {
  const [selectedBadge, setSelectedBadge] = useState({});
  const window = useWindowDimensions()
  const styles = getBadgeStyles(window.width, window.height)

  const tab = useSelector(state => state.status.abilities.tab);
  const badges = useSelector(state => state[tab]);

  const dispatch = useDispatch();
  const total = badges.length;

  useEffect(() => {
    setSelectedBadge({});
  }, [tab]);

  useEffect(() => {
    const params = navigation?.dangerouslyGetState()?.routes?.find((r) => r.name === "Abilities")?.params;
    if(params && badges?.length && !selectedBadge?.id && tab === "virtues"){
      let badge = params?.selectedVirtue;
      let index = 0;
      if(badge?.id){
        badges?.forEach((b, i) => {
            if(b.id === badge?.id){
              index = i;
            }
        });
        setSelectedBadge({
          ...badge,
          index: index
        })
      }
    }
  }, [navigation, badges, selectedBadge?.id, tab])

  return (
    <ScrollView
      style={{
        top: window.height * 0.05
      }}
    >
      <View style={styles.container}>
        {badges.map((item, index) => (
          <Fragment key={item.name}>
            <TouchableOpacity
              onPress={() => {
                tab !== 'virtues' &&
                  dispatch(setAbilities({ superPower: item.name }));
                setSelectedBadge({ index, ...item });
              }}
              style={styles.wrapper}>
              <AbilityBadgeIcon
                type={tab}
                active={!item.isActivated}
                enabled={item.isEnabled}
                head={item.displayName}
                name={item.name}
                bgStyle={{ height: '100%', width: '100%' }}
                style={{ height: '100%', width: '100%' }}
                item={item}
              />
              {item.name === selectedBadge.name && (
                <Image
                  resizeMode="stretch"
                  style={styles.arrow}
                  source={{
                    uri:
                      Config.IMG_URL + `/abilities/description_box_arrow.png`,
                  }}
                />
              )}
            </TouchableOpacity>
            {getActiveRangeValue(index, selectedBadge.index, total) && (
              <BadgeDescription
                badge={selectedBadge}
                hideDesc={() => setSelectedBadge({})}
                navigation={navigation}
              />
            )}
          </Fragment>
        ))}
      </View>
    </ScrollView>
  );
};

export default Badges;

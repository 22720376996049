import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Text, View, ImageBackground, useWindowDimensions, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Config from '../../config';
import CarouselNavBtn from '../../components/carouselNavBtn';
import CarouselCmp from './carousel';
import MarkComplete from './markComplete';

import { getProgramStyles } from './styles';
import { getProgramModuleData } from '../../actions/programModules';
import { setLoadingState } from '../../actions/status';
import { $bdCartoon } from '../../constants/fonts';
import { $black } from '../../constants/colors';
import Info from '../abilities/info';
import { increaseBackgroundAudioVolume, slowBackgroundAudioVolume } from '../../helpers';

const SlidesScreen = ({ navigation }) => {
  const [currIndex, setCurrentIndex] = useState(0);
  const [question, setQuestion] = useState(null);
  const questions = useSelector(state => state.programModules.questions);
  const slidesData = useSelector(state => state.programModules.sortedData);
  const moduleName = useSelector(state => state.status.programModule.name);
  const moduleId = useSelector(state => state.status.programModule.id);
  const window = useWindowDimensions()

  const styles = getProgramStyles(window.width, window.height);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    dispatch(setLoadingState(true));
    const { error } = await dispatch(getProgramModuleData(moduleId));
    dispatch(setLoadingState(false));
    if (error) {
      alert('failed to load data');
    }
  }, [moduleId, dispatch])

  useEffect(() => {
    if (slidesData?.length && questions && slidesData[currIndex]?.type === "question") {
      setQuestion(questions[slidesData[currIndex]?.id]);
    } else {
      setQuestion(null);
    }
  }, [slidesData, currIndex, questions])

  useEffect(() => {
    loadData();
    slowBackgroundAudioVolume('programs');

    return () => {
      increaseBackgroundAudioVolume('programs');
    }
  }, [loadData]);

  let carouselRef = useRef();
  const enableNav = Boolean(currIndex !== slidesData.length - 1) && Boolean(!question || question?.transaction?.answerId || question?.transaction?.answerIds);

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/practice_missions/background.png' }}
      style={styles.container}>
      <Info top={1} left={-window.height * 0.05} />
      <View style={styles.headerViewContainer}>
        <Text style={styles.headerText}>{moduleName}</Text>
      </View>
      <View
        style={{
          position: "absolute",
          top: 3,
          flexDirection: "row",
          right: window.width * 0.073 < 100 ? 100 : window.width * 0.073
        }}
      >
        <TouchableOpacity
          style={{
            height: "100%",
            width: "100%"
          }}
          onPress={() => {
            navigation.goBack()
          }}
        >
          <Text
            style={{
              color: "#FA8072",
              fontFamily: $bdCartoon,
              fontWeight: "450",
              fontSize: window.height * 0.061,
              textShadowColor: $black,
              textShadowRadius: 10,
              textShadowOffset: { height: 2, width: 2 },
              transform: [{
                scaleX: 2
              }]
            }}
          >
            X
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.carouselViewContainer}>
        {currIndex !== 0 && (
          <CarouselNavBtn
            type="left"
            style={{ top: '40%' }}
            prev={() => carouselRef.current.snapToPrev()}
          />
        )}
        <CarouselCmp ref={carouselRef} enableNav={enableNav} setCurrentIndex={setCurrentIndex} currIndex={currIndex} />
        {enableNav && (
          <CarouselNavBtn
            style={{ top: '40%' }}
            type="right"
            next={() => carouselRef.current.snapToNext()}
          />
        )}
        {Boolean(currIndex === slidesData.length - 1) && Boolean(!question || question?.transaction?.answerId || question?.transaction?.answerIds) && (
          <MarkComplete navigate={navigation.navigate} />
        )}
      </View>
    </ImageBackground>
  );
};

SlidesScreen.propTypes = {
  navigation: PropTypes.object,
};

export default SlidesScreen;

import React, { useEffect, useState } from 'react';
import { ImageBackground, TouchableOpacity, useWindowDimensions } from 'react-native';
import './app.css';

import Config from './../../config';
import { viewStyles as styles } from './styles';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, ChannelList, useChannelStateContext, useChatContext, } from 'stream-chat-react';

import 'stream-chat-react/dist/css/index.css';
import { $darkPurple, $white, $yellowShadow } from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import { setHelpMessage, setRebeccaState } from '../../actions/status';
import { CHAT_HELP_AUDIO, CHAT_HELP_MESSAGE } from '../../constants/helpMessages';
import { Text } from 'react-native';
import { $robotoRegular } from '../../constants/fonts';
import { initiateHelpDesk } from '../../services/chat';
import { useModal } from '../../components/ModalProvider';
import ChatProfileDetailPopup from '../../components/chatProfileDetailPopup';
import useMediaQuery from '../../hooks/useMediaQuery';

// I have deployed changes wrt stream chat..

// chat_token will be received in the players/me api
// game and tag channels will be automatically created and players will be added to it who joins the game

// 2 new APIs added for creating 1o1 channels..
// POST       /api/players/initiate_direct_message
// body: { player_id: 'ID of the player to chat with' }

// POST       /api/players/initiate_support_message
// no request body needed
// Astro at Help Desk
// Also, city mission configuration flags are being sent now
// revet_contibutions_on_failue
// winnable
// retryable

const chatClient = StreamChat.getInstance(Config.GETSTREAM_APP_ID);

const CustomMessageList = (props) => {
  var _a = useChannelStateContext(), contextMessages = _a.messages;

  useEffect(() => {
    if (contextMessages?.length) {
      const el = document.getElementsByClassName("scroll_div")?.[0];
      if (el) {
        const offset = el.children[0]?.getElementsByClassName("str-chat__li")?.[contextMessages?.length - 1]?.offsetTop;
        el.scrollTop = offset - el.clientHeight;
      }
    }
  }, [contextMessages])

  return (
    <MessageList
      {...props}
    />
  )
}

const LeftChatList = ({
  showInitiateHelp,
  setShowInitiateHelp,
  height,
  playerId,
}) => {
  const { navOpen } = useChatContext();
  const { isDesktop } = useMediaQuery();

  return (
    <div
      style={{
        display: (isDesktop || navOpen) ? "flex" : "none",
        backgroundColor: $darkPurple,
        overflowY: "auto",
        flexDirection: "column",
        justifyContent: isDesktop ? "center" : "flex-start",
        alignItems: "center",
        position: isDesktop ? "relative" : "absolute",
        zIndex: 100000,
        left: !isDesktop && "0px",
        top: !isDesktop && "0px",
        maxWidth: !isDesktop && "350px",
        width: !isDesktop && "350px",
        height: !isDesktop && "100%",
      }}
    >
      {Boolean(showInitiateHelp) && <TouchableOpacity
        onPress={async () => {
          const res = await initiateHelpDesk();
          if (res) {
            setShowInitiateHelp(false)
          }
        }}
        style={{
          marginTop: height * (isDesktop ? 0.1 : 0.02),
          height: height * (isDesktop ? 0.06 : 0.1),
          width: "90%",
          justifyContent: "center",
          alignItems: "center",
          borderWidth: 1,
          borderColor: $yellowShadow,
          backgroundColor: $white,
          borderRadius: 10
        }}
      >
        <Text
          style={{
            fontFamily: $robotoRegular,
            fontSize: height * (isDesktop ? 0.02 : 0.04),
            fontWeight: "600"
          }}
        >
          Contact Help Desk
        </Text>
      </TouchableOpacity>}
      <ChannelList
        filters={
          {
            members: {
              $in: [playerId]
            },
          }
        }
      />
    </div>
  )
}

const ChatScreen = () => {
  const { height } = useWindowDimensions()
  const { isMobile } = useMediaQuery();
  const [isConnected, setIsConnected] = useState(false);
  const [showInitiateHelp, setShowInitiateHelp] = useState(false);
  const navigation = useSelector(state => state.status.nav);
  const { openModal } = useModal();
  const playerId = useSelector(state => state.player.id);
  const gameId = useSelector(state => state?.games?.currentGame?.id);
  const playerName = useSelector(state => state.player.name);
  const chatToken = useSelector(state => state.player.chat_token);
  const characterName = useSelector(
    state => state.player.profile.characterName,
  );
  const dispatch = useDispatch()

  const keyboardHandler = () => {
    if (isMobile) {
      const chatMessageContainer = document.getElementById("chat-message-input");
      const textElement = chatMessageContainer?.getElementsByTagName("textarea")?.[0];
      textElement.onfocus = () => {
        chatMessageContainer.style.position = "absolute";
        chatMessageContainer.style.top = "17%";
        chatMessageContainer.style.left = 20;
        chatMessageContainer.style.zIndex = 1000;
      }
      textElement.onblur = () => {
        chatMessageContainer.style = "";
        chatMessageContainer.style.width = "100%";
      }
    }
  }

  useEffect(() => {
    if (navigation === "Chat") {
      dispatch(setHelpMessage(CHAT_HELP_MESSAGE, CHAT_HELP_AUDIO))
      dispatch(setRebeccaState(false))
    }
  }, [dispatch, navigation])

  useEffect(() => {
    setIsConnected(false);
    chatClient.disconnectUser();
    setTimeout(() => {
      chatClient.connectUser(
        {
          id: playerId,
          name: playerName,
          image: Config.IMG_URL + `/characters/${characterName}_civilian.png`,
        },
        chatToken,
      );
      setIsConnected(true);
      setTimeout(async () => {
        const channels = await chatClient.queryChannels({ id: `${playerId}-astro-${gameId}` });
        if (channels?.length) {
          if (channels[0]?.initialized) {
            setShowInitiateHelp(false)
          } else {
            setShowInitiateHelp(true)
          }
        } else {
          setShowInitiateHelp(true)
        }
      }, 700)
    }, 600)
    return () => {
      chatClient.disconnectUser();
    }
  }, [playerId, playerName, chatToken, characterName, gameId])

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/chat/chat_window_bg.png' }}
      resizeMode="cover"
      style={styles.background}>
      {Boolean(isConnected) &&
        <Chat client={chatClient} theme='team light'>
          <LeftChatList
            showInitiateHelp={showInitiateHelp}
            setShowInitiateHelp={setShowInitiateHelp}
            playerId={playerId}
            height={height}
            isMobile={isMobile}
          />
          <div
            style={{
              display: "flex",
              flex: 1
            }}
          >
            <Channel
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    width: "95%",
                    height: "10%",
                  }}
                >
                  <ChannelHeader

                  />
                </div>
                <CustomMessageList
                  internalInfiniteScrollProps={{
                    className: "scroll_div"
                  }}
                  onUserClick={(event, user) => {
                    if (user.id.toString() !== playerId.toString())
                      openModal({
                        open: true,
                        component: ChatProfileDetailPopup,
                        data: {
                          user: user
                        },
                        blur: true
                      })
                  }}
                />
                <div
                  style={{
                    width: "100%",
                  }}
                  id="chat-message-input"
                  ref={(ref) => {
                    if (ref) {
                      keyboardHandler();
                    }
                  }}
                >

                  <MessageInput
                    publishTypingEvent
                  />
                </div>
              </div>
              <Thread />
            </Channel>
          </div>
        </Chat>}
      {/* <div
          style={{
            display: "flex",
            backgroundColor: $darkPurple,
            width: "20%",
            height: "100%",
            borderRight: "solid 4px black"
          }}
        >
            
        </div> */}
    </ImageBackground>
  );
};

export default ChatScreen;

import React, { useRef } from 'react' 
import { 
    FlatList,
    Image,
    Text, 
    TouchableOpacity,  
    useWindowDimensions, 
    View 
} from 'react-native';
import Config from '../config';
import { $black, $blue, $darkGray, $gray, $white, $yellow } from '../constants/colors';
import { $robotoRegular } from '../constants/fonts';
import { useModal } from './ModalProvider';

const OptionModal = ({
    closeModal,
    options,
    position,
    onSelectionChange,
    selected
}) => {
    const { height } = useWindowDimensions();

    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000
            }}
            onStartShouldSetResponder={() => {
                closeModal()
                return false;
            }}
        >
            <View
                style={{
                    width: position?.width,
                    maxHeight: 600,
                    bottom: height - position?.top,
                    left: position?.left,
                    backgroundColor: $blue,
                    minHeight: 30,
                    position: "absolute",
                    shadowColor: $darkGray,
                    shadowOffset: { width: 4, height: 4 },
                    shadowOpacity: 0.6,
                    shadowRadius: 10,
                    elevation: 10,
                    borderRadius: 5
                }}
            >
                {Boolean(options?.length) &&
                <FlatList
                    renderItem={({ item }) => {
                        const active = item.value === selected.value;

                        return (
                            <TouchableOpacity
                                style={{
                                    width: "100%",
                                    height: height*0.06,
                                    justifyContent: "center",
                                    borderBottomColor: $white,
                                    borderBottomWidth: 1,
                                    paddingHorizontal: height*0.02,
                                    backgroundColor: active ? $yellow : "transparent"
                                }}
                                class={"dropdown_list_item"}
                                onPress={() => {
                                    if(onSelectionChange)
                                    onSelectionChange({
                                        ...item
                                    })
                                    if(closeModal)
                                    closeModal()
                                }}
                            >
                                <Text
                                    style={{
                                        fontWeight: "400",
                                        fontFamily: $robotoRegular,
                                        fontSize: height*0.024
                                    }}
                                    numberOfLines={1}
                                >
                                    {item?.label}
                                </Text>
                            </TouchableOpacity>
                        )
                    }}
                    data={options}
                />
                }
            </View>
        </View>
    )
}


const ModalDropDown = ({
    selected,
    modalContainerStyle,
    containerStyle,
    options,
    onSelectionChange,
    placeholder
}) => {
    const { openModal, closeModal, open } = useModal();
    const compRef = useRef(null);
    const {height} = useWindowDimensions();

    return (
        <View
            ref={compRef}
            renderToHardwareTextureAndroid
            style={{
                flex: 1,
                margin: 4
            }}
        >
            <TouchableOpacity
                onPress={() => {
                    if(compRef?.current) {
                        compRef.current?.measure((x, y, xWidth, xHeight, px, py) => {
                            openModal({
                                open: true,
                                component: () => <OptionModal 
                                    options={options} 
                                    selected={selected} 
                                    containerStyle={modalContainerStyle}
                                    closeModal={closeModal}
                                    onSelectionChange={onSelectionChange}
                                    position={{
                                        top: py,
                                        left: px,
                                        width: xWidth,
                                        height: xHeight
                                    }}  
                                />
                            })
                        })
                    }
                }}
                style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    paddingHorizontal: "5%",
                    borderWidth: 1,
                    borderColor: $darkGray,
                    borderRadius: 4,
                    flexDirection: 'row',
                    justifyContent: "space-between"
                }}
            >
               {Boolean(selected?.value) ? 
                    <Text
                    style={{
                        fontFamily: $robotoRegular,
                        fontSize: height*0.03,
                        fontWeight: "400",
                        color: $black
                    }}
                    >
                        {
                            selected?.label
                        }
                    </Text>:
                    <Text
                        style={{
                            fontFamily: $robotoRegular,
                            fontSize: height*0.03,
                            fontWeight: "400",
                            color: $gray
                        }}
                    >
                        {placeholder}
                    </Text>
                }
                <Image
                    style={{
                        height: "40%",
                        width: "10%",
                        transform: [
                            {
                                rotate: open ? "0deg" : "180deg"
                            }
                        ]
                    }}
                    resizeMode="contain"
                    source={{
                    uri: Config.IMG_URL + '/icons/arrow_top.png',
                    }}
                />
            </TouchableOpacity>
        </View>
    )
}

export default ModalDropDown;
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  headingBg: {
    height: '100%',
    width: '100%',
  },
  headingContainer: {
    height: '50%',
    width: '70%',
  },
});

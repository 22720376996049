import React, { createElement, useEffect, useState } from 'react';
import { Text, ImageBackground, ScrollView, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

import Config from '../../config';
import HelpBtn from './helpBtn';

import { styles } from './styles';
import BackBtn from './backBtn';
import RebeccaIcon from './rebeccaIcon';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from './backdrop';
import "./rebecca.css"
import { setRebeccaState } from '../../actions/status';
import { useHelpSound } from '../HelpSoundConfig';
import { increaseBackgroundAudioVolume, slowBackgroundAudioVolume } from '../../helpers';
import useMediaQuery from '../../hooks/useMediaQuery';
// import LeaveGame from './leaveGame';

const Rebecca = () => {
  const [index, setIndex] = useState(0)
  const visible = useSelector(state => state.status.isRebeccaVisible);
  const message = useSelector(state => state.status.helpMessage);
  const audioFile = useSelector(state => state.status.helpAudio);
  const { isMobile } = useMediaQuery();
  const dispatch = useDispatch()
  const { playSound } = useHelpSound();

  useEffect(() => {
    if (isMobile && document.getElementById) {
      document.getElementById("root").style.overflow = "hidden";
    } else if (document.getElementById) {
      document.getElementById("root").style.overflow = undefined;
    }
  }, [isMobile])

  useEffect(() => {
    if (Array.isArray(message)) {
      setIndex(0)
    }
  }, [message, visible])

  useEffect(() => {
    if (audioFile && visible) {
      let audio = '';
      if (Array.isArray(audioFile)) {
        audio = audioFile[index];
      } else {
        audio = audioFile;
      }
      slowBackgroundAudioVolume("help_audio");
      playSound({
        sound: "helptext/" + audio,
        force: true
      })
    } else {
      increaseBackgroundAudioVolume("help_audio")
      playSound({
        sound: '',
        force: true
      })
    }
  }, [audioFile, playSound, index, visible])

  if (!visible) {
    return <HelpBtn />;
  }

  return (
    <>
      <Backdrop />
      <ImageBackground
        style={styles.bg}
        resizeMode="stretch"
        source={{
          uri: Config.IMG_URL + '/rebecca/bg.png',
        }}>
        <View style={styles.messageCtn}>
          {typeof message === 'string' && (
            <ScrollView
              ref={ref => {
                if (ref?.setNativeProps)
                  ref.setNativeProps({ scroll: "show_scroll" })
              }}
            >
              <Text style={styles.message}>{message}</Text>
            </ScrollView>
          )}
          {Array.isArray(message) &&
            <ScrollView
              ref={ref => {
                if (ref?.setNativeProps)
                  ref.setNativeProps({ scroll: "show_scroll" })
              }}
            >
              <Text style={[styles.message, { fontSize: 20 }]}>{message[index]}</Text>
            </ScrollView>
          }
          {Array.isArray(message) &&
            <TouchableOpacity style={styles.btn} onPress={() => {
              if (index === message.length - 1) {
                dispatch(setRebeccaState(false))
              } else {
                setIndex(index + 1)
              }
            }}>
              <Text>{index === message.length - 1 ? "Finish" : "Next"}</Text>
            </TouchableOpacity>
          }
          {typeof message === 'function' && createElement(message)}
        </View>
      </ImageBackground>
      <BackBtn />
      <RebeccaIcon />
      {/* <LeaveGame /> */}
    </>
  );
};

Rebecca.propTypes = {
  message: PropTypes.string,
};

export default Rebecca;

import Axios from "./index";
import Config from "../config";
import axiosConfig from "../constants/axiosConfig";


export const getLeaderboards = async (gameId, searchParams) =>
  Axios.get(Config.API_URL + `/games/${gameId}/player_standings?query=${searchParams || ""}`, await axiosConfig({ token: true }));

export const getTeamLeaderboard = async (id) =>
  Axios.get(Config.API_URL + `/leaderboards/${id}`, await axiosConfig({ token: true, gameId: true  }));

import React, { useEffect } from 'react';
import { ImageBackground } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Config from '../../config';
import { setHelpMessage, setRebeccaState } from '../../actions/status';
import VirtuesHelp from '../../components/virtuesHelp';
import SuperpowerHelp from '../../components/superpowerHelp';
import Avatar from './avatar';
import Tabs from './tabs';

import { styles } from './styles';
import { ABILITY_HELP_AUDIO, ABILITY_HELP_MESSAGE } from '../../constants/helpMessages';

const AbilitiesScreen = ({ navigation }) => {
  const tab = useSelector(state => state.status.abilities.tab);

  const levelId = useSelector(state => state.player.level.id);
  const nav = useSelector(state => state.status.nav);

  const dispatch = useDispatch();

  useEffect(() => {
    if(nav === 'Abilities')
    if (levelId === 1) {
      if (tab === 'superpowers') {
        dispatch(setHelpMessage(SuperpowerHelp));
      } else if (tab === 'virtues') {
        dispatch(setHelpMessage(VirtuesHelp));
      }
      dispatch(setRebeccaState(true));
    } else {
      dispatch(setHelpMessage(ABILITY_HELP_MESSAGE, ABILITY_HELP_AUDIO));
      dispatch(setRebeccaState(false));
    }
  }, [levelId, nav, dispatch, tab])

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/abilities/background.png' }}
      resizeMode="cover"
      style={styles.container}>
      <Avatar />
      <Tabs navigation={navigation} />
    </ImageBackground>
  );
};

export default AbilitiesScreen;

import React from 'react';
import { Image, Text, useWindowDimensions, View } from 'react-native';
import { useSelector } from 'react-redux';

import Config from '../../config';

import { getTabStatStyles } from './styles';

const TabStats = () => {
  const tab = useSelector(state => state.status.abilities.tab);
  const window = useWindowDimensions()

  const styles = getTabStatStyles(window.width, window.height)

  const actives = useSelector(state => state.status[tab]);
  const total = useSelector(state => state[tab]).length;

  const activeLength = Object.keys(actives).length;

  return (
    <View style={styles.container}>
      <Image
        resizeMode="contain"
        style={styles.img}
        source={{ uri: Config.IMG_URL + '/icons/unlock.png' }}
      />
      <Text style={styles.txt}>{activeLength}</Text>
      <Image
        resizeMode="contain"
        style={styles.img}
        source={{ uri: Config.IMG_URL + '/icons/lock.png' }}
      />
      <Text style={styles.txt}>{total - activeLength}</Text>
    </View>
  );
};

export default TabStats;

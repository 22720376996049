import {
  getUser,
  forgotPassword,
  resetPassword,
  registerUser,
  verifySignup,
} from '../services/users';
import { SET_USER, SET_USER_LOGIN } from '../constants/actions';
import AsyncStorage from '@react-native-community/async-storage';

export const getUserData = () => async dispatch => {
  try {
    const { data } = await getUser().catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    dispatch({
      type: SET_USER,
      payload: data,
    });

    return { data };
  } catch (error) {
    return { error };
  }
};

export const requestVerifySignup = async (email, token) => {
  try {
    const { data } = await verifySignup(email, token).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    return { data };
  } catch (error) {
    return { error };
  }
};

export const registerNewUser = params => async dispatch => {
  try {
    const userdata = {
      email: params.email,
      signup_token: params.token,
      name: params.name,
      password: params.password,
      password_confirmation: params.password,
      locale: 'en',
    };
    const { data } = await registerUser(userdata).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    console.log(data);
    await AsyncStorage.setItem('userToken', data.access_token);
    dispatch({
      type: SET_USER_LOGIN,
      payload: true,
    });
    return { data };
  } catch (error) {
    return { error };
  }
};

export const requestForgotPassword = async email => {

  return new Promise((resolve, reject) => {
    forgotPassword(email)
      .then(res => {
        resolve({
          msg: res?.data?.detail || res?.response?.data?.errors?.[0]?.detail
        })
      })
      .catch(error => {
        console.log(error)
      });
  })
};

export const requestResetPassword = async param => {
  try {
    const { data } = await resetPassword(param).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    return { data };
  } catch (error) {
    return { error };
  }
};

import React, { useRef } from 'react';
import { View, Text, ImageBackground, TouchableOpacity, useWindowDimensions, Image } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Config from '../../config';
import { getStyle } from './styles';
import { boardCity, supportCity } from '../../actions/cities';
import { useModal } from '../../components/ModalProvider';
import NotificationModal from '../../components/notificationModal';
import { useSFX } from '../../components/SFXConfig';
import { SOUNDS } from '../../constants/sfx';
import AlertPopup from '../../components/alertPopup';
import BoardMessagePopup from './boardMessagePopup';

const Board = props => {
  const { cityId, percent, requirements, hidePercent, contributions, duration, critical, support } = props;
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height);
  const { openModal } = useModal()
  const durationRef = useRef();
  const fareRef = useRef();
  const criticalRef = useRef();
  const { playSound } = useSFX();
  const day = parseInt(duration / (60 * 60 * 24));
  const hour = parseInt((duration - (day * 60 * 60 * 24)) / (60 * 60));
  const minutes = parseInt((duration - (day * 60 * 60 * 24) - (hour * 60 * 60)) / (60));

  const playerId = useSelector(state => state.player.id);
  const cronos = useSelector(state => state.player.cronos);
  const players = useSelector(state => state.status.slectedCity.players);
  const virtuesByName = useSelector(state => state.status.virtuesByName);
  const superpowersByName = useSelector(
    state => state.status.superpowersByName,
  );

  const dispatch = useDispatch();

  const board = async () => {
    if (playSound) {
      playSound({
        sound: SOUNDS.boardSupport
      })
    }
    const virtues = [];
    const superpowers = [];
    // openModal({
    //   open: true,
    //   component: MissionStartPopUp,
    //   blur: true,
    //   data: {
    //     cityName: "Xandar",
    //     playerRequired: 6,
    //     cityDismantleTime: `5 Days, 6 Hours, 7 Minutes`
    //   }
    // })
    const superpowersContrib = requirements.superpowers.every(e => {
      if (superpowersByName[e.name]) {
        superpowers.push(superpowersByName[e.name].id);
      }
      return isContributed('superpowers', e.name);
    });
    const virtuesContrib = requirements.virtues.every(e => {
      if (virtuesByName[e.name]) {
        virtues.push(virtuesByName[e.name].id);
      }
      return isContributed('virtues', e.name);
    });
    if (!superpowersContrib || !virtuesContrib) {
      alert('You need to contribute your abilities first. Please click "?" on the top right for help.');
      return;
    }
    if (cronos < requirements.fare) {
      alert("You don't have enough cronos");
      return;
    }

    openModal({
      open: true,
      component: AlertPopup,
      data: {
        sound: SOUNDS.boardModal,
        hideButton: true,
        headingLabelMarginTop: 1,
        heading: "Are You Sure?",
        yesText: "Yes",
        "noText": "No, Later",
        messageComponent: ({ data, closeModal }) => <BoardMessagePopup data={{ id: cityId, duration: duration, critical: critical, success: data.success, closeModal: closeModal }} />,
        success: async () => {
          const boardData = {
            id: cityId,
            virtue_ids: virtues.join(','),
            super_power_ids: superpowers.join(','),
            playerId,
          };
          if (support) {
            const { error } = await dispatch(supportCity(boardData, openModal));
            if (error) {
              alert(
                (error && error.errors && error?.errors[0].detail) ||
                'some error occurred please try again later',
              );
            }
          } else {
            const { error } = await dispatch(boardCity(boardData, openModal));
            if (error) {
              alert(
                (error && error.errors && error?.errors[0].detail) ||
                'some error occurred please try again later',
              );
            }
          }
        },
        failure: () => {

        }
      }
    })

  };

  const isContributed = (type, name) => {
    return (
      contributions[type].filter(
        d => d.name === name && parseInt(d.playerId) === parseInt(playerId),
      ).length > 0
    );
  };

  return (
    <>
      <View style={[styles.boardCtn, {
        marginBottom: support ? window.height * 0.038 : players.length <= 0 ? window.height * 0.034 : 0
      }]}>
        <View
          ref={fareRef}
          style={{
            flexDirection: "row",
            paddingRight: window.height * 0.03
          }}
        >
          <Text style={styles.fareHead}> Fare </Text>
          <ImageBackground
            resizeMode="stretch"
            style={styles.fareBg}
            source={{
              uri: Config.IMG_URL + '/save_earth/cronos.png',
            }}>
            <Text style={styles.fareValue}>{requirements.fare}</Text>
          </ImageBackground>
          <TouchableOpacity
            onPress={() => {
              if (fareRef?.current) {
                fareRef.current?.measure((x, y, xWidth, xHeight, px, py) => {
                  openModal({
                    open: true,
                    component: NotificationModal,
                    data: {
                      position: {
                        top: py,
                        left: px,
                        width: xWidth,
                        height: xHeight
                      },
                      message: "This is how much it'll cost to travel to this mission."
                    }
                  })
                })
              }
            }}
          >
            <Image
              source={{
                uri: Config.IMG_URL + "/save_earth/info_icon.png"
              }}
              style={{
                width: window.height * 0.04,
                height: window.height * 0.04,
                marginTop: window.height * 0.005,
                marginLeft: window.height * 0.006
              }}
            />
          </TouchableOpacity>
        </View>
        <View
          ref={durationRef}
          style={{
            flexDirection: "row"
          }}
        >
          <Text style={[styles.fareHead, {
            marginRight: 5
          }]}> Duration </Text>
          <ImageBackground
            resizeMode="stretch"
            style={[styles.fareBg, {
              width: window.height * 0.28
            }]}
            source={{
              uri: Config.IMG_URL + '/save_earth/clock.png',
            }}>
            <Text style={[styles.fareValue, {
              marginLeft: window.height * 0.09
            }]}>{
                `${day}D:${hour}H:${minutes}M`
              }</Text>
          </ImageBackground>
          <TouchableOpacity
            onPress={() => {
              if (durationRef?.current) {
                durationRef.current?.measure((x, y, xWidth, xHeight, px, py) => {
                  openModal({
                    open: true,
                    component: NotificationModal,
                    data: {
                      position: {
                        top: py,
                        left: px,
                        width: xWidth,
                        height: xHeight
                      },
                      message: "This is how long the mission will take when the first person starts it. All required players must join the mission within this time."
                    }
                  })
                })
              }
            }}
          >
            <Image
              source={{
                uri: Config.IMG_URL + "/save_earth/info_icon.png"
              }}
              style={{
                width: window.height * 0.04,
                height: window.height * 0.04,
                marginTop: window.height * 0.005,
                marginLeft: window.height * 0.006
              }}
            />
          </TouchableOpacity>
        </View>
        {Boolean(critical) && <View
          ref={criticalRef}
          onStartShouldSetResponder={() => {
            if (criticalRef?.current) {
              criticalRef.current?.measure((x, y, xWidth, xHeight, px, py) => {
                openModal({
                  open: true,
                  component: NotificationModal,
                  data: {
                    position: {
                      top: py,
                      left: px,
                      width: window.width * 0.25,
                      height: xHeight
                    },
                    message: "This mission will cause you to lose the abilities you used if it fails. "
                      + " \n Tip: try to encourage other members to join this mission in order to increase its chances of success."
                  }
                })
              })
            }
            return false
          }}
        >
          <ImageBackground
            resizeMode="stretch"
            style={{
              width: window.height * 0.06,
              height: window.height * 0.06,
              marginLeft: window.height * 0.05
            }}
            source={{
              uri: Config.IMG_URL + '/save_earth/critical.png',
            }}>
          </ImageBackground>
        </View>
        }
        <View style={styles.boardRightPnl}>
          <TouchableOpacity style={styles.boardBtn} onPress={() => board()}>
            <ImageBackground
              resizeMode="contain"
              style={styles.boardImg}
              source={{
                uri: Config.IMG_URL + '/save_earth/btn.png',
              }}>
              <Text style={styles.boardTxt}>{support ? "Support" : "Board"}</Text>
            </ImageBackground>
          </TouchableOpacity>
        </View>
      </View>
      {(players.length > 0 && !hidePercent) && (
        <View style={[styles.activeCtn, styles.activeBrdCtn]}>
          <View
            style={[styles.activeProgress, { width: percent + '%' }]}></View>
          <View style={styles.activeBg}></View>
          <Text style={[styles.activeTxt, styles.activeBrdTxt]}>
            ({percent}%) Planet Mission in Progress..
          </Text>
        </View>
      )}
    </>
  );
};

Board.propTypes = {
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initiatorId: PropTypes.number,
  cityId: PropTypes.number,
  requirements: PropTypes.object,
  contributions: PropTypes.object,
  superpowersByName: PropTypes.object,
  virtuesByName: PropTypes.object,
};

export default Board;

import React from 'react';
import { ImageBackground, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';
import Config from '../../config';

import { getTabHeaderstyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setAbilities } from '../../actions/status';
import useMediaQuery from '../../hooks/useMediaQuery';

const TabHeader = ({ type, label }) => {
  const tab = useSelector(state => state.status.abilities.tab);
  const window = useWindowDimensions()
  const { isMobile } = useMediaQuery()

  const styles = getTabHeaderstyles(window.width, window.height, isMobile)

  const dispatch = useDispatch();

  return (
    <ImageBackground
      style={[styles.bg, tab === type ? styles.active : '']}
      source={{
        uri:
          Config.IMG_URL +
          `/abilities/title_tab_${tab === type ? 'active' : 'inactive'}.png`,
      }}>
      <TouchableOpacity
        onPress={() =>
          dispatch(setAbilities({ tab: type, superPower: 'civilian' }))
        }
        style={styles.container}>
        <Text style={[styles.txt, tab === type ? styles.activeTxt : '']}>
          {label.toUpperCase()}
        </Text>
      </TouchableOpacity>
    </ImageBackground>
  );
};

TabHeader.propTypes = {
  type: PropTypes.string.isRequired,
};

export default TabHeader;

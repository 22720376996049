import React from 'react';
import { View, ActivityIndicator, StyleSheet, Image, Text } from 'react-native';
import PropTypes from 'prop-types';
import Config from '../config';
import { useSelector } from 'react-redux';
import { $white, $overlay } from '../constants/colors';
import { $robotoRegular } from '../constants/fonts';

const Loading = ({ size, state = false, color, route }) => {
  const loading = useSelector(state => state.status.loading);
  const loadingColor = useSelector(state => state.status.loadingColor);

  if (!loading && !state) {
    return <></>;
  }

  return (
    <>
      {route?.params?.imageBg && (
        <Image
          source={{ uri: Config.IMG_URL + '/practice_missions/background.png' }}
          style={styles.imageBg}
        />
      )}
      <View style={styles.container}>
        <View style={styles.wrapper}>
          <ActivityIndicator
            size={size || 'large'}
            color={color || loadingColor}
          />
          <Text style={styles.txt}>Loading...</Text>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 99999,
  },
  imageBg: {
    height: '100%',
    width: '100%',
  },
  txt: {
    color: $white,
    fontFamily: $robotoRegular,
    fontWeight: '400',
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: $overlay,
    borderRadius: 10,
    display: 'flex',
    height: 100,
    justifyContent: 'center',
    width: 100,
  },
});

Loading.propTypes = {
  state: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  route: PropTypes.object,
};

export default Loading;

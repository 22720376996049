import { StyleSheet } from 'react-native';
import {
  $bdCartoon,
  $robotoRegular,
  $robotolight,
} from './../../constants/fonts';
import {
  $yellow,
  $white,
  $blue,
  $gray,
  $green,
  $black,
  $red,
} from './../../constants/colors';

export const getGameStyle = (width, height) => StyleSheet.create({
  active: {
    backgroundColor: $green,
    color: $black,
  },

  badge: {
    backgroundColor: $gray,
    borderRadius: 5,
    color: $white,
    fontFamily: $robotolight,
    fontSize: height*0.026,
    marginLeft: height*0.02,
    paddingVertical: height*0.006,
    paddingHorizontal: height*0.02,
  },

  btn: {
    alignItems: 'center',
    backgroundColor: $gray,
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    width: width*0.06,
    maxHeight: 40,
    minWidth: 50
  },

  btnJoin: {
    backgroundColor: $blue,
  },

  btnTxt: {
    color: $white,
    fontFamily: $robotoRegular,
    paddingVertical: height*0.015,
    fontWeight: '400',
  },

  container: {
    height: height,
    width: width,
  },

  gameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "center",
    left: width*0.02,
    paddingVertical: width*0.01,
    width: width*0.95,
  },

  gameHead: {
    color: $white,
    fontFamily: $robotoRegular,
    fontSize: height*0.032,
    fontWeight: '400',
  },

  heading: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.04,
    textAlign: 'center',
  },

  hr: {
    borderBottomColor: $white,
    borderBottomWidth: 0.5,
    left: width*0.02,
    position: 'relative',
    width: width*0.95,
  },

  listContainer: {
    marginTop: 10,
    paddingBottom: 40,
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1
  },

  lost: {
    backgroundColor: $red,
    // color: $black,
  },

  won: {
    backgroundColor: $yellow,
    color: $black,
  },
});

import React, { useState } from 'react';
import {
  ImageBackground,
  Image,
  View,
  TouchableOpacity,
  Text,
  useWindowDimensions,
} from 'react-native';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';

import { setCityContribution } from '../../actions/cities';
import AbilityBadgeIcon from '../../components/abilityBadgeIcon';
import { contibuteCount, isEveryThingContributed } from './helper';
import Config from '../../config';
import { getStyle } from './styles';
import { SOUNDS } from '../../constants/sfx';
import { useSFX } from '../../components/SFXConfig';
import { useModal } from '../../components/ModalProvider';
import AlertPopup from '../../components/alertPopup';
import { setAbilities } from '../../actions/status';

const Badges = ({ name, count, type, navigation, item }) => {
  const [visible, setVisible] = useState(false);
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height);
  const { playSound } = useSFX();
  const { openModal } = useModal()

  const players = useSelector(state => state.games.players);
  const playerId = useSelector(state => state.player.id);
  const city = useSelector(state => state.status.slectedCity);
  const requirements = useSelector(state => state.cities.requirements[city.id]);

  const badgesByname = useSelector(
    state =>
      state.status[type === 'virtues' ? 'virtuesByName' : 'superpowersByName'],
  );
  const contributions = useSelector(
    state => state.cities.contributions[city.id],
  );

  const dispatch = useDispatch();

  const isContributed = type => {
    return (
      contributions[type].filter(
        d => d.name === name && parseInt(d.playerId) === parseInt(playerId),
      ).length > 0
    );
  };

  const getContribBtnImgUrl = type => {
    let url = `/save_earth/contributed.png`;
    if (badgesByname[name]) {
      url = `/save_earth/${type}_${
        isContributed(type, name) ? 'tick' : 'contribute'
      }.png`;
    }
    return { uri: Config.IMG_URL + url };
  };

  const contribute = () => {
    const badge = badgesByname[name];
    if (!badge || isContributed(type, name)) {
      return;
    }
    const cityData = { id: city.id, type, name, playerId };
    dispatch(setCityContribution(cityData));
  };

  const handleLockedPopup = () => {
    if(!isContributed(type, name) && !badgesByname[name]){
      openModal({
        open: true,
        component: AlertPopup,
        blur: true,
        data: {
          sound: SOUNDS.helpButton,
          heading: "Uh Oh!",
          headingImage: Config.IMG_URL+"/nav/abilities.png",
          yesText: "Yes",
          "noText": "No, Later",
          message: "You do not have this ability.\n\n"+
          "Would you like to head to the Abilities section to activate this ability?",
          success: () => {
            dispatch(setAbilities({ tab: type, superPower: 'civilian' }))
            navigation.navigate("Abilities")
          },
          failure: () => {

          }
        }
      })
    }
  }

  return (
    <TouchableOpacity key={name} style={styles.badgeWrapper}
      onPress={handleLockedPopup}
    >
      <TouchableOpacity onPress={() => {setVisible(!visible); handleLockedPopup();}}>
        <AbilityBadgeIcon
          type={type}
          active={false}
          name={name}
          bgStyle={styles.badgeBg}
          style={styles.badgeIcon}
          item={item}
        />
      </TouchableOpacity>
      <View style={styles.badgeInfo}>
        <Text style={styles.badgeHead}>{name.replace(/_/g, ' ')}</Text>
        <View style={styles.badgeContribCtn}>
          <Image
            resizeMode="contain"
            style={styles.badgeContrib}
            source={{
              uri:
                Config.IMG_URL +
                `/save_earth/${type +
                  (isContributed(type, name) ? '_activated' : '')}.png`,
            }}
          />
          <Text style={styles.badgeCount}>
            {contibuteCount(contributions[type], name) + ' / ' + count}
          </Text>
        </View>
        {visible && (
          <View style={styles.playerIcons}>
            {city.players.map(p => (
              <Image
                key={p}
                style={styles.playerIcon}
                source={{
                  uri:
                    Config.IMG_URL +
                    `/characters/${players[p].characterName}_profile.png`,
                }}
              />
            ))}
          </View>
        )}
      </View>
      {city.state !== 'retry' &&
        (!isEveryThingContributed(requirements, contributions) || 
        (city.state === "completed" && !city.players.find(p => parseInt(p) === parseInt(playerId)))) 
        && (
          <TouchableOpacity
            style={styles.contribBtn}
            onPress={() => {
              if(playSound && badgesByname[name]){
                playSound({
                    sound: SOUNDS.useClick
                })
              }
              contribute();
              handleLockedPopup();
            }}>
            <ImageBackground
              resizeMode="contain"
              style={styles.contribImg}
              source={getContribBtnImgUrl(type, name)}>
              {!isContributed(type, name) && (
                <Text style={styles.contribBtnTxt}>
                  {badgesByname[name] ? 'Use' : `Locked`}
                </Text>
              )}
            </ImageBackground>
          </TouchableOpacity>
        )}
    </TouchableOpacity>
  );
};

Badges.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  count: PropTypes.number,
  city: PropTypes.object,
  badgesByname: PropTypes.object,
  contributions: PropTypes.object,
};

export default React.memo(Badges);

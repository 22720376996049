import React from 'react';
import { TouchableOpacity, Text, View, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { updateTrait, updateSession } from '../../actions/player';
import { getStyle } from './styles';
import { setLoadingState } from '../../actions/status';

const TraitsList = ({ desc, id, activeTrait, setActiveTrait }) => {
  const playerId = useSelector(state => state.player.id);
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const styles = getStyle(window.width, window.height);

  const handlepress = async id => {
    setActiveTrait(id);
    dispatch(setLoadingState(true));
    await updateTrait({
      id: playerId,
      trait_id: id,
    });
    dispatch(
      updateSession({ id: playerId, stage: 'save_earth', level_id: '1' }),
    );
    dispatch(setLoadingState(false));
  };
  return (
    <TouchableOpacity onPress={() => handlepress(id)}>
      <View style={[styles.list, id === activeTrait && styles.active]}>
        <Text style={styles.desc}>{desc}</Text>
      </View>
    </TouchableOpacity>
  );
};

TraitsList.propTypes = {
  desc: PropTypes.string,
  id: PropTypes.number,
  setLoading: PropTypes.func,
  navigate: PropTypes.func,
  activeTrait: PropTypes.number,
  setActiveTrait: PropTypes.func,
};

export default TraitsList;

import { getPrograms } from '../services/programs';
import { SET_PROGRAMS } from '../constants/actions';

export const getProgramsData = () => async dispatch => {
  try {
    const { data } = await getPrograms().catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    let programModules = {};
    data.included.forEach(ele => {
      if (ele.type === 'program_module') {
        programModules[ele.id] = {
          id: ele.id,
          name: ele.attributes.name,
          description: ele.attributes.description,
          sort: ele.attributes.sort,
          isPublished: ele.attributes.is_published,
          published_at: ele?.attributes?.published_at,
          max_cronos: ele?.attributes?.max_cronos,
          max_xp: ele?.attributes?.max_xp,
          transaction: {
            id: ele.attributes?.transaction?.data?.id,
            isCompleted:
              // eslint-disable-next-line camelcase
              ele.attributes?.transaction?.data?.attributes?.is_completed,
          },
        };
      }
    });
    let programs = [];
    data.data.forEach(element => {
      const program = {
        id: element.attributes.id,
        completedOn: element.attributes.completed_on,
        name: element.attributes.name,
        description: element.attributes.description,
        sort: element.attributes.sort,
        isPublished: element.attributes.is_published,
        isTutorial: element.attributes.is_tutorial,
        isCompleted: element.attributes.is_completed,
        levelTemplateId: element.relationships.level_template.data.id,
        programModules: element.relationships.program_modules.data.map(elem => {
          return programModules[elem.id];
        }),
      };
      programs.push(program);
    });
    dispatch({
      type: SET_PROGRAMS,
      payload: { data: programs },
    });

    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

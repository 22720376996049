import React, { useImperativeHandle } from 'react';
import { View, useWindowDimensions } from 'react-native';
import Carousel from '../../lib/carousel/Carousel';
import PropTypes from 'prop-types';
import CarouselItems from './carouselItems';

import { getProgramStyles } from './styles';
import { useSelector } from 'react-redux';
import useMediaQuery from '../../hooks/useMediaQuery';

const CarouselCmp = ({ setCurrentIndex, currIndex, enableNav }, ref) => {
  let carouselRef = '';
  const { isMobile } = useMediaQuery();
  const slidesData = useSelector(state => state.programModules.sortedData);
  // const questions = useSelector(state => state.programModules.questions);
  const window = useWindowDimensions()

  const styles = getProgramStyles(window.width, window.height);

  useImperativeHandle(ref, () => ({
    snapToNext() {
      carouselRef.snapToNext();
    },
    snapToPrev() {
      carouselRef.snapToPrev();
    },
  }));

  return (
    <View style={styles.carouselView}>
      <Carousel
        ref={c => (carouselRef = c)}
        data={slidesData}
        initialNumToRender={2}
        windowSize={2}
        renderItem={({ item, index }) => (
          <CarouselItems id={parseInt(item.id)} type={item.type} index={index} currentIndex={currIndex} />
        )}
        sliderWidth={window.width}
        itemWidth={window.width}
        inactiveSlideOpacity={1}
        inactiveSlideScale={1}
        scrollEnabled={enableNav}
        containerCustomStyle={styles.carouselCustomStyle}
        enableSnap
        enableMomentum
        onSnapToItem={(index) => {
          if (isMobile) {
            setCurrentIndex(index);
            setTimeout(() => carouselRef?._snapToItem(index), 300)
          } else {
            setCurrentIndex(carouselRef.currentIndex)
          }
        }}
      />
    </View>
  );
};

CarouselCmp.propTypes = {
  setCurrentIndex: PropTypes.func,
};

const CarouselRefComponent = React.forwardRef(CarouselCmp);

export default React.memo(CarouselRefComponent);

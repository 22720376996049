import axios from 'axios'
import { requestLogout } from '../actions/auth';
import { SET_USER_LOGIN } from '../constants/actions';

let dispatch = null;
let loggedIn = false;

export const setDispatch = (newDispatch) => {
    dispatch = newDispatch;
}

export const setLoggedIn = (val) => {
    loggedIn = val;
}

const client = axios.create({
    baseURL: ''
})

client.interceptors.response.use(undefined, (err) => {
    const error = err.response;
    // if error is 401 
    if (error.status === 401 && dispatch && loggedIn) {
        loggedIn = false;
        alert('session expired');
        dispatch({
            type: SET_USER_LOGIN,
            payload: false
        });
        dispatch(requestLogout())
    }
    return Promise.reject(error.data)
})

export default client;



export const verifyEmail = (email) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(email && String(email)?.match(validRegex)){
        return true;
    }
}

export const verifyPhone = (phone) => {
    const phoneRegEx = /^\d{3,}$/;
    if(phone && String(phone)?.match(phoneRegEx)){
        return true;
    }
}

export const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        ((rect.height >=  (window.innerHeight || document.documentElement.clientHeight) && rect.top < rect.height*0.2)
        || (rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

import { StyleSheet } from 'react-native';
import { $yellow, $white, $blue, $black } from './../../../constants/colors';
import { $robotoBold, $bdCartoon } from './../../../constants/fonts';

export const getLeftPanelStyles = (width, height) => StyleSheet.create({
  container: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    marginLeft: 30,
    marginTop: 40,
    zIndex: 1
  },

  dashboardTitle: {
    color: $blue,
    fontFamily: $robotoBold,
    fontSize: height*0.04,
    fontWeight: '900',
  },

  playerTitle: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.07,
    textShadowColor: $black,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 5,
  },

  settings: {
    height: height*0.038,
    left: height*0.21,
    position: 'absolute',
    top: height*0.002,
    width: height*0.038,
  },
});

export const LogStyles = StyleSheet.create({
  flatList: { height: '90%' },

  logContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 8,
  },

  logText: {
    color: $yellow,
    fontSize: 16,
    fontWeight: 'bold',
  },

  logTextContainer: {
    flexDirection: 'row',
  },

  logTextLeft: {
    flex: 1.5,
    paddingLeft: 5,
  },

  logTextRight: {
    flex: 1,
  },

  logWindowContainer: {
    flex: 9,
    flexDirection: 'row',
  },

  logWindowImg: {
    height: '95%',
    width: '92%',
  },

  logs: {
    color: $white,
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 15,
    width: '90%',
  },

  safeArea: { margin: 20 },
});

import React, { useEffect, useState } from 'react';
import { ImageBackground, useWindowDimensions, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { getLeaderboardData } from '../../actions/leaderboards';
import { setHelpMessage, setLeaderboard, setRebeccaState } from '../../actions/status';
import { LEADERBOARD_HELP_AUDIO, LEADERBOARD_HELP_MESSAGES } from '../../constants/helpMessages';
import Config from './../../config';
import Tabs from './leftPanel/tabs';
import PlayerPointCard from './rightPanel/playerPointCard';
import PlayerWinCard from './rightPanel/playerWinCard';

import { getStyle } from './styles';

//new api for player's leaderboard added
// /api/games/:id/player_standings

const LeaderboardScreen = () => {
  const levelId = useSelector(state => state.player.level.id);
  const slectedGameId = useSelector(state => state.player.slectedGameId);
  const leaderboardData = useSelector(state => state.leaderboards.data);
  const tab = useSelector(state => state.status.leaderboard.tab);
  const player = useSelector(state => state.player);
  const navigation = useSelector(state => state.status.nav);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchParams, setSearchParams] = useState("");

  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const styles = getStyle(window.width, window.height);

  useEffect(() => {
    if(slectedGameId && searchParams?.length !== 1){
      dispatch(getLeaderboardData(slectedGameId, null, searchParams, true))
    }
  },[slectedGameId, dispatch, searchParams])

  useEffect(() => {
    console.log(player?.tag)
    if(!player?.tag){
      dispatch(setLeaderboard({ tab: "player_score" }));
    }
  }, [player?.tag, dispatch])

  useEffect(() => {
    if(navigation === "Leaderboard"){
      dispatch(setHelpMessage(LEADERBOARD_HELP_MESSAGES, LEADERBOARD_HELP_AUDIO));
      dispatch(setRebeccaState(false));
    }
  }, [dispatch, levelId, navigation])

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/leaderboard/leaderboard_bg.png' }}
      resizeMode="cover"
      style={styles.container}>
        <Tabs 
          setSelectedIndex={setSelectedIndex} 
          selectedIndex={selectedIndex} 
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          isTag={Boolean(player?.tag)}
        />
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2
          }}
        >
            {Boolean(leaderboardData?.[tab]?.[selectedIndex]) &&
              <PlayerWinCard 
                  playerDetails={leaderboardData?.[tab]?.[selectedIndex]}
                  type={leaderboardData?.[tab]?.[selectedIndex]?.position?.toString()}
                  height={window.height*0.27}
                  width={window.height*0.43}
                  disable
                />}
              {Boolean(leaderboardData?.[tab]?.[selectedIndex]) &&
                <View
                style={{
                  marginTop: window.height*0.03,
                  zIndex: 1
                }}
              >
                  <PlayerPointCard
                       playerDetails={leaderboardData?.[tab]?.[selectedIndex]}
                      height={window.height*0.63}
                      width={window.height*0.68}
                  />
              </View>}
        </View>
    </ImageBackground>
  );
};

export default LeaderboardScreen;

import { StyleSheet } from 'react-native';
import { $yellow } from '../../constants/colors';
import { $bdCartoon, $robotolight } from '../../constants/fonts';

const $transparent = 'transparent';

export const getStyle = (width, height) => StyleSheet.create({
  board: {
    fontFamily: $bdCartoon,
    fontSize: height*0.13,
    position: 'relative',
    textAlign: 'center',
    top: 10,
  },
  closeContainer: {
    height: 20,
    right: "3%",
    position: 'absolute',
    top: "-2%",
    width: 20,
  },

  closeImg: {
    height: '100%',
    width: '100%',
  },

  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '80%',
    position: 'absolute',
    width: '90%',
  },

  desc: {
    fontFamily: $robotolight,
    fontSize: height*0.035,
    lineHeight: height * 0.037
  },

  descHead: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.08,
  },

  leftBg: {
    flex: 3.3,
    height: '94%',
    left: "8%",
    position: 'relative',
    top: 10,
    width: '86%',
    zIndex: 100,
  },

  leftContainer: {
  },

  rightBg: {
    flex: 1,
    height: '100%',
    width: '100%',
    zIndex: 110,
    right: "4.2%"
  },

  rightContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: 10,
  },

  rightImg: {
    height: '55%',
    width: '90%',
  },

  transBg: {
    backgroundColor: $transparent,
  },
});

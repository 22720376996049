import React, { useEffect, useState } from 'react'
import { Text, useWindowDimensions } from 'react-native';
import { View } from 'react-native';
import { $white } from '../../../constants/colors';
import { $bdCartoon } from '../../../constants/fonts';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Video from '../../../lib/video/Video';
import { HOW_IT_WORKS_DATA, HOW_IT_WORKS_TYPE, PAGE_ID } from '../constants'
import { isInViewport } from '../util';


const HowItWorks = () => {
    const [stickHeader, setStickHeader] = useState(false);
    const { height, width } = useWindowDimensions();
    const { isMobile } = useMediaQuery();
    const [showHeading, setShowHeading] = useState({
        admin: false,
        player: false
    })

    useEffect(() => {
        const mainElm = document?.getElementById("main_content");
        const pageElm = document.getElementById(PAGE_ID.how_it_works)
        const stickyHeaderFunction = () => {
            if(mainElm.scrollTop > pageElm?.offsetTop + pageElm.offsetHeight - 60){
                setStickHeader(false);
            } else if (mainElm.scrollTop > pageElm.offsetTop - 60) {
                setStickHeader(true)
            } else {
                setStickHeader(false);
            }
            let video_playing = false
            // auto play the intro video when scrolled in viewport
            // const intro_vedio_elm = document.getElementById("player_intro_video");
            // if(intro_vedio_elm && isInViewport(intro_vedio_elm)){
            //     intro_vedio_elm.play()
            //     return
            // } else if(intro_vedio_elm){
            //     intro_vedio_elm.pause()
            // }

            HOW_IT_WORKS_DATA?.forEach((item) => {
                if(item.data_type === "video"){
                    const video_id = "player_hw_"+item.key;
                    const elm = document.getElementById(video_id);
                    if (video_playing){
                        if(elm){
                            elm.pause()
                        }
                        return;
                    }
                    if(elm && isInViewport(elm)){
                        if(isMobile){
                            setShowHeading({
                                admin: item.type === HOW_IT_WORKS_TYPE.admin,
                                player: item.type === HOW_IT_WORKS_TYPE.player,
                            })
                        }
                        elm.play()
                        video_playing = true
                    } else if(elm){
                        elm.pause()
                    }
                }
            })
        }
        mainElm?.addEventListener('scroll', stickyHeaderFunction);

        if(!isMobile){
            setShowHeading({
                admin: true,
                player: true
            })
        }

        return () => {
            mainElm.removeEventListener("scroll", stickyHeaderFunction)
        }
    }, [isMobile])

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                position: "relative",
                backgroundColor: "rgba(0,0,0,0.5)",
                paddingTop: "60px"
            }}
            id={PAGE_ID.how_it_works}
        >
            <div
                style={{
                    position: stickHeader ? "fixed" : "relative",
                    height: "65px",
                    top: stickHeader && "60px",
                    left: stickHeader && "0px",
                    width: "100%",
                    zIndex: 100,
                    backgroundColor: '#f9c184',
                    padding: "10px 0px"
                }}
            >
             <View
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "20px",
                }}
             >
                <Text 
                    style={{
                        fontFamily: $bdCartoon,
                        color: $white,
                        fontSize: height*0.042
                    }}
                >
                    How it works
                </Text>
             </View>
             <View
                style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "30px",
                    flexDirection: "row",
                    width: "100%"
                }}
             >
                <View
                    style={{
                        width: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                   {showHeading?.admin && 
                   <Text
                        style={{
                            fontFamily: "'Fredoka One', cursive",
                            color: $white,
                            fontSize: height*0.034
                        }}
                    >
                        Admin Side
                    </Text>}
                </View>
                <View
                    style={{
                        width: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {showHeading.player &&
                    <Text
                        style={{
                            fontFamily: "'Fredoka One', cursive",
                            color: $white,
                            fontSize: height*0.034
                        }}
                    >
                        Player Side
                    </Text>}
                </View>
             </View>
            </div>
            <div
                style={{
                    height: stickHeader ? "80px" : "25px"
                }}
            >
            </div>
            {HOW_IT_WORKS_DATA?.length && 
            HOW_IT_WORKS_DATA?.map((item, index) => {

                return (
                    <View
                        key={item.key}
                        style={{
                            alignItems: isMobile ? "center" : item.type === HOW_IT_WORKS_TYPE.admin ? "flex-start" : "flex-end",
                            justifyContent: "center",
                            width: "100%",
                            height: isMobile ? width*0.7  : "430px"
                        }}
                    >
                        <View
                            style={{
                                width: isMobile ? "100%" : "50%",
                                height: "100%",
                                padding: isMobile ? "5px" : "30px"
                            }}
                        >
                            <View
                                style={{
                                    width: "100%",
                                    alignItems: "flex-start",
                                    paddingBottom: "10px",
                                    marginTop: "2px"
                                }}
                            >
                                <Text 
                                    style={{
                                        color: '#f9c184',
                                        fontFamily: $bdCartoon,
                                        fontSize: isMobile ? width*0.075 : height * 0.045
                                    }}
                                >
                                    {item.heading}
                                </Text>
                            </View>
                            {item.data_type === "video" ?
                                 <Video
                                    paused={true}
                                    muted={true}
                                    loop={true}
                                    source={{ uri: item.uri}}
                                    hideSkipControl
                                    posterResizeMode="center"
                                    resizeMode="cover"
                                    videoStyle={{
                                        width: "100%",
                                        borderRadius: isMobile ? "10px" : "15px"
                                    }}
                                    playerId={"_hw_"+item.key}
                                    playbackRate={1.25}
                                />
                             :
                                <img
                                    src={item.uri}
                                    alt={item.key}
                                    style={{
                                        height: "100%",
                                        width: "100%"
                                    }}
                                />
                            }
                        </View>
                    </View>
                )
            })
            }
        </div>
    )
}

export default HowItWorks
import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity, Image, Animated, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { HelpBtnstyles as styles } from './styles';
import { setRebeccaState } from './../../actions/status';
import Config from './../../config';
import { useSFX } from '../SFXConfig';
import { SOUNDS } from '../../constants/sfx';
// import { Vibration } from 'react-native';

const HelpBtn = () => {
  const { playSound } = useSFX()
  const dispatch = useDispatch();
  const levelId = useSelector(state => state.player.level.id);
  const [shakeAnimation] = useState(new Animated.Value(0))

  const startShake = useCallback(() => {
    if(parseInt(levelId) === 1){
      // Vibration.vibrate([
      //   1000,
      //   2000,
      //   3000
      // ])
      Animated.sequence([
        Animated.timing(shakeAnimation, { toValue: 10, duration: 100, useNativeDriver: Platform.OS !== "web" }),
        Animated.timing(shakeAnimation, { toValue: -10, duration: 100, useNativeDriver: Platform.OS !== "web" }),
        Animated.timing(shakeAnimation, { toValue: 10, duration: 100, useNativeDriver: Platform.OS !== "web" }),
        Animated.timing(shakeAnimation, { toValue: 0, duration: 100, useNativeDriver: Platform.OS !== "web" })
      ]).start(() => {
          setTimeout(() => {
            startShake();
          }, 5000)
      });
    }
  },[levelId, shakeAnimation])

  useEffect(() => {
    startShake();
  }, [startShake])

  return (
    <TouchableOpacity
        style={styles.btn}
        onPress={() => {
          if(playSound){
            playSound({
              sound: SOUNDS.helpButton
            })
          }
          dispatch(setRebeccaState(true))
        }}>
          <Animated.View
            style={{ 
              height: "100%",
              width: "100%",
              transform: [{translateX: shakeAnimation}] 
            }}
          >
        <Image
          style={styles.full}
          resizeMode="contain"
          source={{
            uri: Config.IMG_URL + '/icons/help.png',
          }}
        />
    </Animated.View>
      </TouchableOpacity>
  );
};

export default HelpBtn;

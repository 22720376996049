import React from 'react';
import PropTypes from 'prop-types';
import Video from '../../lib/video/Video'


import { getProgramStyles } from './styles';
import { useWindowDimensions } from 'react-native';


const VideoSlide = ({ url, height, hideSkipControl, pause }) => {
  const window = useWindowDimensions()

  const styles = getProgramStyles(window.width, window.height);

  return (
    <>
      <Video
        paused={pause}
        source={{ uri: url }}
        resizeMode="cover"
        posterResizeMode="center"
        style={styles.carouselView}
        hideSkipControl={hideSkipControl}
        muted={false}
        videoStyle={{
          height: height || "100%",
          zIndex:3
        }}
      />
      {/* {Boolean(pause) &&
        <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 4
        }}
        onStartShouldSetResponder={() => {
          setPause(!pause)
        }}
      >
        <Image
            source={{
              uri: Config.IMG_URL + '/video/loading.gif',
              cache: 'reload'
            }}
            style={{
              height: window.height*0.4,
              width: window.height*0.4
            }}
        />
      </View>} */}
    </>
  );
};

VideoSlide.propTypes = {
  url: PropTypes.string,
};

export default React.memo(VideoSlide);

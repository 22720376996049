import createReducer from '../lib/createReducer';
import {
  SET_PLAYER,
  SET_SELECTED_GAME_ID,
  SET_LEVEL,
  SET_USER_LOGIN,
  UPDATE_PLAYER_CRONOS,
  LOGOUT,
  LEAVE_GAME,
} from '../constants/actions';

const initialState = {
  id: '',
  name: '',
  isEnabled: '',
  isActive: '',
  xp: '',
  ep: '',
  cronos: '',
  hasProfile: '',
  isLoggedIn: false,
  slectedGameId: null,
  level: {
    id: '1',
    state: '',
  },
  profile: {
    id: '',
    characterId: '',
    traitId: '',
    characterName: '',
    trait: '',
  },
};

export const player = createReducer(initialState, {
  [SET_PLAYER](state, { payload: { data } }) {
    return { ...state, ...data };
  },
  [SET_USER_LOGIN](state, { payload }) {
    return { ...state, isLoggedIn: payload };
  },
  [SET_SELECTED_GAME_ID](state, { payload }) {
    return { ...state, slectedGameId: payload };
  },
  [SET_LEVEL](state, { payload }) {
    return { ...state, level: { ...payload } };
  },
  [UPDATE_PLAYER_CRONOS](state, { payload: { data } }) {
    return { ...state, cronos: state.cronos + data };
  },
  [LEAVE_GAME](state, { payload }) {
    return { ...initialState, isLoggedIn: true };
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setLoadingState } from '../../../actions/status';
import Step1 from './step1';
import Step2 from './step2';
import { registerNewUser } from '../../../actions/user';
import { useSFX } from '../../../components/SFXConfig';
import { SOUNDS } from '../../../constants/sfx';
import FormBGContainer from '../FormBGContainer';

let formData = {};

const SignupScreen = ({ route }) => {
  const [paramsData, setParamsData] = useState('');
  const [step1Visible, setStep1Visible] = useState(true);
  const { playSound } = useSFX();

  React.useEffect(() => {
    if (route.params) {
      setParamsData(route.params);
    }
  }, [route.params]);

  const dispatch = useDispatch();

  const handleSignup = async data => {
    formData = { ...formData, ...data };
    if (data.step === 1) {
      setStep1Visible(false);
      return;
    }
    dispatch(setLoadingState(true));
    if (playSound) {
      playSound({
        sound: SOUNDS.loginSignup,
        force: true
      })
    }
    // formData.token = paramsData.token;
    const { error } = await dispatch(
      registerNewUser({ ...formData, ...paramsData }),
    );
    dispatch(setLoadingState(false));
    if (window && window.location.search) {
      window.location.href = "/"
    }
    if (error) {
      alert(
        (error && error.errors && error?.errors[0].detail) ||
        'some error occurred please try again later',
      );
    }
  };

  return (
    <FormBGContainer>
      {step1Visible ? (
        <Step1 email={paramsData.email} sumbit={handleSignup} />
      ) : (
        <Step2 sumbit={handleSignup} />
      )}
    </FormBGContainer>
  );
};

SignupScreen.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
};

export default SignupScreen;

import React, { createRef } from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  FlatList,
  useWindowDimensions
} from 'react-native';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AbilityBadgeIcon from '../../../../components/abilityBadgeIcon';
import Config from '../../../../config';

import { getBadgeStyles } from './styles';

const List = ({ type }) => {
  const values = useSelector(state => state.status[type]);
  const flatList = createRef();
  const window = useWindowDimensions()

  const styles = getBadgeStyles(window.width, window.height);

  return (
    <View style={styles.badgeWrapper}>
       <TouchableOpacity
        style={styles.scrollBarBtnCtn}
        onPress={() => {
          flatList.current.scrollToIndex({
            index: 0
          });
        }}>
        <Image
          style={[styles.scrollBarBtn,
          {
            transform: [
              { rotate: "180deg"
              }
            ],
            marginTop: -15
          }
          ]}
          resizeMode={'center'}
          source={{
            uri: Config.IMG_URL + '/dashboard/vertical_scroll_button.png',
          }}
        />
      </TouchableOpacity>
      <View style={styles.badge}
      >
        <FlatList
          horizontal
          ref={flatList}
          data={Object.keys(values)}
          renderItem={({ item }) => (
            <View style={{ marginHorizontal: 3 }}>
              <AbilityBadgeIcon
                type={type}
                name={values[item].name}
                style={{ height: window.height*0.1, width: window.height*0.09 }}
                bgStyle={{ height: window.height*0.11, width: window.height*0.1 }}
                item={item}
              />
            </View>
          )}
          keyExtractor={item => values[item].name}
        />
      </View>
      <TouchableOpacity
        style={styles.scrollBarBtnCtn}
        onPress={() => {
          flatList.current.scrollToEnd();
        }}>
        <Image
          style={styles.scrollBarBtn}
          resizeMode={'center'}
          source={{
            uri: Config.IMG_URL + '/dashboard/vertical_scroll_button.png',
          }}
        />
      </TouchableOpacity>
    </View>
  );
};

List.propTypes = {
  type: PropTypes.string.isRequired,
};

export default List;

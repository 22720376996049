import axios from 'axios';
import Config from '../../../config';

const client = axios.create({
    baseURL: Config.API_URL
})

export const guestEnroll =  (payload = {}) => {
    let curResolve = null;

    client.post('users/guests/enroll', {...payload}, {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }).then((res) => {
        curResolve(res.data)
      })
      .catch(errors => {
        curResolve(errors.response.data)
      })

    return new Promise((resolve) => {
        curResolve = resolve
    })
}

export const guestContactUs =  (payload = {}) => {
    let curResolve = null;

    client.post('users/guests/contact_us', {...payload}, {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }).then((res) => {
        curResolve(res.data)
      })
      .catch(errors => {
        curResolve(errors.response.data)
      })

    return new Promise((resolve) => {
        curResolve = resolve
    })
}


import { StyleSheet } from 'react-native';
import {
  $bdCartoon,
  $robotolight,
  $robotoBold,
  $robotoRegular,
} from './../../constants/fonts';
import {
  $transparent,
  $yellow,
  $purple,
  $black,
  $white,
  $overlay,
  $green,
  $red,
} from './../../constants/colors';

export const getStyle = (width, height) =>  StyleSheet.create({
  activeBg: {
    alignItems: 'center',
    backgroundColor: $overlay,
    flex: 1,
    justifyContent: 'center',
  },

  activeBrdCtn: {
  },

  activeBrdTxt: {
  },

  activeCtn: {
    display: 'flex',
    flexDirection: 'row',
    height: height * 0.06,
    justifyContent: 'center',
    width: '100%',
    marginBottom: height*0.05
  },

  activeProgress: {
    backgroundColor: $yellow
  },

  activeTxt: {
    position: 'absolute',
    top: height*0.018,
    fontSize: height * 0.02,
    fontWeight: '600',
    width: "100%",
    textAlign: "center"
  },

  badgeBg: {
    height: height * 0.15,
    width: height * 0.15,
    justifyContent: 'center',
    alignItems: 'center'
  },

  badgeContrib: {
    height: height * 0.07,
    marginLeft: 2,
    width: height * 0.07,
  },

  badgeContribCtn: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
  },

  badgeCount: {
    fontFamily: $robotoRegular,
    fontSize: height * 0.04,
    paddingLeft: height * 0.02,
    fontWeight: '400',
  },

  badgeHead: {
    fontFamily: $robotoBold,
    fontSize: height*0.05,
    paddingLeft: 20,
    textTransform: 'capitalize',
    fontWeight: '900',
  },

  badgeIcon: {
    height: height * 0.14,
    // left: 2.5,
    // position: 'relative',
    width: height *0.14,
  },

  badgeInfo: {
    flex: 3,
    paddingTop: height * 0.015
  },

  badgeTxt: {
    paddingBottom: 5,
    textShadowColor: $black,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 5,
  },

  badgeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginRight: 10
  },

  badgesContainer: {
    flex: 1,
    paddingTop: 10,
  },

  badgesSafeArea: {
    flex: 1,
    marginTop: 5,
    width: '100%',
    padding: 10
  },

  boardBtn: {
    height: height * 0.08,
    width: height * 0.19,
  },

  boardCtn: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
    width: '100%',
    height: height*0.09,
    marginBottom: 0,
    justifyContent: "space-between"
  },

  boardImg: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  boardLeftPnl: {
    flex: 3,
    flexDirection: 'row',
    top: -10,
    width: '100%',
  },

  boardRightPnl: {
    flex: 1,
    top: -15,
    alignItems: "flex-end",
    paddingRight: height*0.04
  },

  boardTxt: {
    color: $white,
    fontFamily: $robotoRegular,
    fontSize: height * 0.03,
    fontWeight: '400',
  },

  cityName: {
    fontFamily: $bdCartoon,
    fontSize: height*0.11,
    textAlign: 'center',
  },

  closeContainer: {
    height: 20,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 20,
  },

  closeImg: {
    height: '100%',
    width: '100%',
  },

  completedBg: {
    backgroundColor: $green,
  },

  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: '80%',
    position: 'absolute',
    width: '90%',
  },

  contribBtn: {
    height: height * 0.08,
    marginRight: 5,
    marginTop: height * 0.03,
    // position: 'absolute',
    // right: 1000,
    width: height * 0.16,
  },

  contribBtnTxt: {
    fontFamily: $robotoRegular,
    fontSize: height * 0.03,
    fontWeight: '400',
    // textAlign: 'center',
  },

  contribImg: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  desc: {
    fontFamily: $robotolight,
  },

  descHead: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: 22,
  },

  failedProgress: {
    backgroundColor: $red,
    width: '100%',
  },

  fareBg: {
    height: height * 0.083,
    position: 'relative',
    top: -10,
    width: height * 0.2,
  },

  fareHead: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height * 0.04,
    textShadowColor: $black,
    textShadowOffset: { width: 5, height: 5 },
    textShadowRadius: 10,
  },

  fareValue: {
    color: $black,
    fontFamily: $robotoBold,
    fontSize: height * 0.03,
    marginLeft: height * 0.067,
    marginTop: height * 0.023,
    fontWeight: '900'
  },

  leftBg: {
    flex: 3.3,
    height: '95%',
    left: -20,
    position: 'relative',
    top: 15,
    width: '100%',
    zIndex: 100,
    padding: 20
  },

  leftContainer: {
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 10,
    paddingTop: 10,
    flex: 1,
    marginBottom: height * 0.04,
  },

  playerIcon: {
    height: 28,
    marginRight: 5,
    width: 28,
  },

  playerIcons: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 20,
  },

  rightBg: {
    flex: 1,
    height: '100%',
    left: 5,
    width: '100%',
    zIndex: 110,
  },

  rightContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: 10,
  },

  rightImg: {
    height: '55%',
    width: '90%',
  },

  superpowers: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height * 0.06
  },

  transBg: {
    backgroundColor: $transparent,
  },

  virtues: {
    color: $purple,
    fontFamily: $bdCartoon,
    fontSize: height * 0.06
  },
});

import React from 'react';
import { ImageBackground, TouchableOpacity, Image, Text, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Config from '../../config';
import { getProgramModulesStyle } from './styles';
import Info from '../abilities/info';

const LeftCtn = ({ navigation }) => {
  const program = useSelector(state => state.status.program);
  const window = useWindowDimensions()

  const styles = getProgramModulesStyle(window.width, window.height)

  return (
    <ImageBackground
      resizeMode="stretch"
      source={{ uri: Config.IMG_URL + '/practice_missions/left_bg.png' }}
      style={styles.leftBg}>
      <Info top={1} left={-window.height*0.05} />
      <TouchableOpacity
        style={styles.leftBackContainer}
        onPress={() => {
          navigation.goBack();
        }}>
        <Image
          resizeMode="contain"
          source={{
            uri: Config.IMG_URL + '/practice_missions/left_arrow.png',
          }}
          style={styles.leftBack}
        />
      </TouchableOpacity>
      <Text style={styles.leftIndex}>{program.index + 1}</Text>
      <Text style={[styles.leftName, {
        fontSize: window?.height*0.08 - Math.min(Math.max((program?.name?.length - 30)*0.22, 0), window?.height*0.054)
      }]}>{program.name}</Text>
      <Text style={styles.leftDesc}>{program.description}</Text>
    </ImageBackground>
  );
};

LeftCtn.propTypes = {
  navigation: PropTypes.object,
};

export default LeftCtn;

import axios from './index';
import Config from '../config';
import axiosConfig from './../constants/axiosConfig';

export const getProgramModule = async module =>
  axios.get(
    Config.API_URL + '/program_modules/' + module,
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const completeProgramModule = async id =>
  axios.put(
    Config.API_URL + '/program_modules/' + id + '/complete',
    {},
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const submitProgramModuleAnswer = async data =>
  axios.put(
    Config.API_URL + '/program_modules/' + data.id + '/answer',
    data,
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

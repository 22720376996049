import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text, TouchableOpacity, useWindowDimensions, View, ImageBackground } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  $yellow,
  $overlay,
  $red,
  $green
} from '../../constants/colors.js';
import { getStyles } from './styles';
import Config from '../../config';
import {
  setLoadingState,
  setCityDescState,
  setSelectedCity,
} from '../../actions/status.js';
import { getTopLeftFromPosition } from './helper.js';
import { SOUNDS } from '../../constants/sfx.js';
import { useSFX } from '../../components/SFXConfig.js';

const getPercentArray = (percent) => {
    let i = 1;
    const arr = []
    for(i ; i <= percent; i++){
      arr.push(i)
    }

    return arr
}

const MarkerCmp = ({ city, ...props }) => {
  const [percent, setPercent] = useState(0);
  const { height, width } = useWindowDimensions()
  const playerId = useSelector(state => state.player.id);
  const styles = getStyles(width, height);
  const { playSound } = useSFX()

  const timerInerval = useRef(null);

  const playerIsInGame = useMemo(() => {
    return city?.players.find(data => parseInt(data) === parseInt(playerId));
  }, [city?.players, playerId])

  

  const getPercent = useCallback(() => {
    const startedAt = new Date(city.startedAt);
    const duration = city.duration;
    let percent = (Date.now() - startedAt) / duration / 1000;
    // Make sure the percentage is never negative
    percent = Math.max(percent, 0);
    // Make sure the percentage is never > 100
    percent = Math.min(percent * 100, 100);
    if (percent >= 100) {
      clearInterval(timerInerval.current);
      // if (city.state !== 'retry' || city.state !== 'completed') {
      //   dispatch(getCities());
      // }
    }
    setPercent(parseInt(percent));
  },[city])

  useEffect(() => {
    if ((city.isActive || city.initiatorId) && city.startedAt) {
      getPercent();
      timerInerval.current = setInterval(getPercent, 1000 * 5);
    }
    return () => {
      clearInterval(timerInerval.current);
    };
  }, [city.isActive, city.initiatorId, city.startedAt, city.state, timerInerval, getPercent]);

  const dispatch = useDispatch();

  const onMarkerClick = async city => {
    if(playSound){
      playSound({
        sound: SOUNDS.missionPlanet
      })
    }
    await dispatch(setLoadingState(true));
    await dispatch(setSelectedCity(city));
    dispatch(setCityDescState(true));
    dispatch(setLoadingState(false));
  };

  return (
    <View
      style={{...getTopLeftFromPosition(city.latitude, city.longitude, height, width), position: 'absolute'}}
    >
    <TouchableOpacity
    onPress={() => onMarkerClick(city)}
      key={city.name}
      style={{
        flex: 1
      }}
      {...props}>
      <View
        style={[styles.circle,{
          backgroundColor:(!playerIsInGame && $overlay) || (city.isCompleted && city.state === 'completed' && $green) ||
          ((city.isFailed || city.state === 'retry') && $red) ||
          (city.players.length > 0 && $yellow) ||
          $overlay,
          zIndex:2
        }
        ]}
      >
        {Boolean(percent > 0 && percent !== 100) &&
          getPercentArray(percent).map(per => {

            return (
              <View
                style={{
                  position: 'absolute',
                  height: '97%',
                  width: 2,
                  left: height * 0.0175,
                  top: 0,
                  backgroundColor: $green,
                  zIndex: 100,
                  transform: [
                    {
                      rotate: (2*per)+'deg'
                    }
                  ]
                }}
              >
              </View>
            )
          })
        }
      </View>
     {city.youAreHere && <ImageBackground
          style={{
            position: "absolute",
            width: height*0.04,
            height: height*0.08,
            zIndex: 3,
            bottom: height*0.038,
            left: height*0.003
          }}
          source={{ uri: Config.IMG_URL + '/save_earth/here_icon.png' }}
        >

        </ImageBackground>}
      <ImageBackground
        source={{ uri: Config.IMG_URL + '/save_earth/city_label.png' }}
        style={{
          minHeight: height*0.072,
          width: height*0.172,
          alignItems: 'center',
          justifyContent: "center",
          // paddingRight: height*0.005,
          // paddingTop: height*0.02,
          position: "relative",
          zIndex: 1,
        }}
      >
       
        <Text
          style={styles.tag}
          numberOfLines={1}
        >
          {city.displayName}
        </Text>
      </ImageBackground>
    </TouchableOpacity>
    </View>
  );
};

MarkerCmp.propTypes = {
  city: PropTypes.object,
};

export default MarkerCmp;

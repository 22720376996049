import React from 'react';
import {
    View,
    ImageBackground,
    Image,
    useWindowDimensions,
} from 'react-native';

import Config from '../../config';

import { getStyle } from './styles';
import useMediaQuery from '../../hooks/useMediaQuery';

const FormBGContainer = ({ children, hideBG }) => {
    const w = useWindowDimensions();
    const { isMobile } = useMediaQuery();

    const styles = getStyle(w.width, w.height, isMobile);

    if(hideBG){
        return (
        <View
            style={styles.background}
        >
            <View style={styles.wrapper}>
                {!isMobile && <Image
                    source={{ uri: Config.IMG_URL + '/login/title_text.png' }}
                    resizeMode="contain"
                    style={styles.headingBg}
                />}
                <Image
                    source={{ uri: Config.IMG_URL + '/login/box_bg.png' }}
                    resizeMode="contain"
                    style={styles.formBg}
                />
                <Image
                    source={{ uri: Config.IMG_URL + '/login/form_bg.png' }}
                    resizeMode="contain"
                    style={styles.formConatinerBg}
                />
                <View style={styles.formConatiner}>
                    {children}
                </View>
            </View>
        </View>
        )
    }

    return (
        <ImageBackground
            source={{ uri: Config.IMG_URL + '/login/new-login-bg-sw.png' }}
            resizeMode="cover"
            style={styles.background}>
            <View style={styles.wrapper}>
                {!isMobile && <Image
                    source={{ uri: Config.IMG_URL + '/login/title_text.png' }}
                    resizeMode="contain"
                    style={styles.headingBg}
                />}
                <Image
                    source={{ uri: Config.IMG_URL + '/login/box_bg.png' }}
                    resizeMode="contain"
                    style={styles.formBg}
                />
                <Image
                    source={{ uri: Config.IMG_URL + '/login/form_bg.png' }}
                    resizeMode="contain"
                    style={styles.formConatinerBg}
                />
                <View style={styles.formConatiner}>
                    {children}
                </View>
            </View>
        </ImageBackground>
    );
};

export default FormBGContainer;

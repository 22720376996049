import React, { useState } from 'react'
import { Image } from 'react-native'
import { Text, useWindowDimensions } from 'react-native'
import { View } from 'react-native'
import Config from '../../../config'
import { $black, $white } from '../../../constants/colors'
import { $bdCartoon, $robotoRegular } from '../../../constants/fonts'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { PAGE_ID, PRICING_DATA } from '../constants'
import './pricing.css'
import TryNowForm from './TryNowForm'

const hoverIconText = " Each user needs one user credit to play one game. Users can still access the game and its content after the game is over."


const PricingComponent = () => {
    const { height, width } = useWindowDimensions();
    const { isMobile } = useMediaQuery();
    const [tooltip, setTooltip] = useState({
        open: false,
        message: ""
    })

    return (
        <div
        style={{
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: "60px 0px 40px 0px"
        }}
        id={PAGE_ID.pricing}
        >
            {Boolean(tooltip?.open) && 
                <View
                    style={{
                        position: "absolute",
                        zIndex: 100,
                        top: tooltip?.elm?.bottom + 20,
                        left: tooltip?.elm?.left - 80,
                        paddingVertical: height*0.014,
                        paddingHorizontal: width*0.007,
                        backgroundColor: $white,
                        borderRadius: 10,
                        maxWidth: width*0.24
                    }}
                >
                    <Text
                        style={{
                            color: $black,
                            fontSize: height*0.02,
                            fontFamily: $robotoRegular,
                            textAlign: "center"
                        }}
                    >
                        {tooltip.message}
                    </Text>
                </View>
            }
            <View
                style={{
                    height: "80px",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Text
                    style={{
                        fontFamily: $bdCartoon,
                        color: $white,
                        fontSize: height*0.042
                    }}
                >
                    Pricing
                </Text>
            </View>
            <View
                style={{
                    width: isMobile ? "100%" : "40%",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <table id="pricing_table">
                    <tr>
                        <th
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: height*0.03
                            }}
                        >
                            User Credits 
                            <div
                                onMouseEnter={(evt) => {
                                    setTooltip({
                                        open: true,
                                        message: hoverIconText,
                                        elm: evt.target.getBoundingClientRect()
                                    })
                                }}
                                onMouseLeave={(evt) => {
                                    setTooltip({
                                        open: false,
                                        message: "",
                                        elm: evt.target.getBoundingClientRect()
                                    })
                                }}
                            >
                                <Image
                                    source={{
                                        uri: Config.IMG_URL + "/save_earth/info_icon.png"
                                    }}
                                    style={{
                                        width: height * 0.03,
                                        height: height * 0.03,
                                        marginTop: height * 0.005,
                                        marginLeft: height * 0.006
                                    }}
                                />
                            </div>
            
                        </th>
                        <th
                            style={{
                                fontSize: height*0.03
                            }}
                        >Cost per User</th>
                    </tr>
                    {PRICING_DATA?.length &&
                        PRICING_DATA?.map((price) => {

                            return (
                                <tr key={price.key}>
                                    <td style={{
                                fontSize: height*0.025
                            }}>{price.credit}</td>
                                    <td style={{
                                fontSize: height*0.025
                            }}>{price.cost}</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </View>
            <TryNowForm section={true} />
        </div>
    )
}

export default PricingComponent
import React, { useState, useRef } from 'react';
import { View, ImageBackground, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import CarouselNavBtn from './../../components/carouselNavBtn';
import Config from '../../config';
import SelectCharacterBtn from './selectCharBtn';
import { fltiData } from './../../constants/ftliData';
import CarouselCmp from './carousel';

import { getVideoStyle } from './styles';

const FtliSlides = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const window = useWindowDimensions()
  const styles = getVideoStyle(window.width, window.height);

  let carouselRef = useRef();

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/practice_missions/background.png' }}
      style={styles.container}>
      <View style={styles.carouselViewContainer}>
        {currentIndex !== 0 && (
          <CarouselNavBtn
            type="left"
            prev={() => carouselRef.current.snapToPrev()}
          />
        )}
        <CarouselCmp ref={carouselRef} setCurrentIndex={setCurrentIndex} currentIndex={currentIndex} />
        {currentIndex !== fltiData.length - 1 && (
          <CarouselNavBtn
            type="right"
            next={() => carouselRef.current.snapToNext()}
          />
        )}
        {currentIndex === fltiData.length - 1 && <SelectCharacterBtn />}
      </View>
    </ImageBackground>
  );
};

FtliSlides.propTypes = {
  navigation: PropTypes.object,
};

export default FtliSlides;

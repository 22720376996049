import { StyleSheet } from 'react-native';
import { $yellow } from '../../../../constants/colors';
import { $robotoBold } from '../../../../constants/fonts';

export const getBadgeStyles = (width, height) => StyleSheet.create({
  badge: {
    paddingRight: 5,
    width: height*0.72
  },

  badgeWrapper: {
    flexDirection: 'row',
    marginTop: height*0.02,
  },

  scrollBarBtn: {
    height: '100%',
    width: '100%',
  },

  scrollBarBtnCtn: {
    height: height*0.1,
    width: height*0.04,
    zIndex: 99,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4
  },

  seperator: {
    alignItems: 'center',
    paddingTop: 2,
  },

  seperatorImg: {
    height: 3,
    left: -15,
    position: 'relative',
    width: 200,
  },

  title: {
    color: $yellow,
    fontFamily: $robotoBold,
    fontSize: height*0.034,
    fontWeight: '900',
  },

  wrapper: {
    flexDirection: 'column',
    marginTop: height*0.038,
  },
});

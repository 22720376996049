import React from 'react';
import { TouchableOpacity, Image, ImageBackground, Text, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import Config from './../../config';
import { getStyle } from '../styles';
import { useSFX } from '../../components/SFXConfig';
import { SOUNDS } from '../../constants/sfx';

const RedailBtn = ({ type, styleName, name, navigate }) => {
  const { playSound } = useSFX();
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height)
  
  return (
  <TouchableOpacity onPress={() => {
    if(playSound){
      playSound({
        sound: SOUNDS.menuItem
      })
    }
    navigate();
  }} style={[styles[styleName], styles.btnCtn]}>
    <ImageBackground
      style={[styles.btnBg, styles[styleName + 'Bg']]}
      resizeMode="contain"
      source={{
        uri: Config.IMG_URL + `/nav/${type}_bg.png`,
      }}>
      <Image
        style={[styles.btnImg, styles['btnImg' + type]]}
        resizeMode="contain"
        source={{
          uri:
            Config.IMG_URL +
            `/nav/${name}${type === 'active' ? '_active' : ''}.png`,
        }}
      />
      <Text style={[styles.btnTxt, styles['btnTxt' + type]]}>
        {name.replace(/_/g, ' ').toUpperCase()}
      </Text>
    </ImageBackground>
  </TouchableOpacity>
)};

RedailBtn.propTypes = {
  type: PropTypes.string,
  style: PropTypes.string,
  name: PropTypes.string,
  navigate: PropTypes.func,
};

export default RedailBtn;

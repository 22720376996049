import React from 'react';
import { useWindowDimensions, View } from 'react-native';

import Timer from './timer';
import Stats from './stats';
// import Chest from './chest';

import { RightPanelStyles } from './styles';

const RightPanel = ({ navigation }) => {
  const window = useWindowDimensions()
  const styles = RightPanelStyles(window.width, window.height)

  return (
    <View style={styles.wrapper}>
      <Stats navigation={navigation} />
      <Timer />
      {/* <Chest /> */}
    </View>
  );
};

export default RightPanel;

import React, { useCallback, useState } from 'react' 
import Config from '../config';
import { increaseBackgroundAudioVolume } from '../helpers';

const HelpSoundContext = React.createContext({});

export const useHelpSound = () => React.useContext(HelpSoundContext);

const HelpSoundConfig = ({
    children
}) => {
    const [currentSound, setCurrentSound] = useState({
        sound: "",
    })

    const playSound = useCallback((data) => {
        setCurrentSound(data)
    }, [setCurrentSound])

    return (
        <HelpSoundContext.Provider
            value={{
                playSound: playSound
            }}
        >
           {currentSound?.sound &&
                <audio
                    ref={(ref) => {
                        if(ref?.volume){
                            ref.volume = 0.8
                        }
                    }}
                    id="sfx_sound"
                    src={Config.IMG_URL+`/sounds/${currentSound?.sound}`}
                    autoPlay
                    onEndedCapture={() => {
                        increaseBackgroundAudioVolume("help_audio")
                        setCurrentSound({
                            sound: "",
                        })
                    }}
                />
           }
            {
                children
            }
        </HelpSoundContext.Provider>
    )
}

export default HelpSoundConfig;
import React, { useRef } from 'react' 
import { ImageBackground, View, Text, Image } from 'react-native'
import { useModal } from '../../../components/ModalProvider';
import NotificationModal from '../../../components/notificationModal';
import Config from '../../../config';
import { $yellow } from '../../../constants/colors';
import { $bdCartoon } from '../../../constants/fonts';


const PlayerPointCard = ({
    width,
    height,
    playerDetails
}) => {
    const missionRef = useRef(null);
    const { openModal } = useModal()


    return (
        <ImageBackground
            source={{
                uri: Config.IMG_URL + "/leaderboard/glass_panel.png"
            }}
            style={
                {
                    width: width,
                    height: height,
                    justifyContent: "center",
                    alignItems: "center",
                    padding: height*0.1
                }
            }
        >
            <View>
                <Text
                    style={{
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 4,
                        fontSize: height*0.08,
                        color: $yellow
                    }}
                >
                    {playerDetails?.points + " PTS"}
                </Text>
            </View>
            <View>
                <Text
                     style={{
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 4,
                        fontSize: height*0.06,
                        color: $yellow,
                        marginTop: height*0.02
                    }}
                >
                    Mission contributed to:
                </Text>
            </View>
            <View
                ref={missionRef}
                onStartShouldSetResponder={() => {
                    if(missionRef?.current && playerDetails?.missions?.join(", ")?.length > 40) {
                        missionRef.current?.measure((x, y, xWidth, xHeight, px, py) => {
                              openModal({
                                  open: true,
                                  component: NotificationModal,
                                  data: {
                                    position: {
                                      top: py+100,
                                      left: px-100,
                                      width: xWidth,
                                      height: xHeight
                                    },
                                    message: playerDetails?.missions?.join(", ")
                                  }
                              })
                          })
                      }
                }}
            >
                <Text
                     style={{
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 4,
                        fontSize: height*0.06,
                        color: $yellow,
                        marginTop: height*0.008
                    }}
                    numberOfLines={2}
                >
                    {playerDetails?.missions?.join(", ")}
                </Text>
            </View>
            <View
                style={{
                    marginTop: height*0.08
                }}
            >
                <View
                    style={{
                        height: height*0.15,
                        width: height*0.55,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: height*0.015,
                        alignItems: "center"
                    }}
                >
                    <Image
                        source={{
                            uri: Config.IMG_URL + "/leaderboard/abilities_2.png"
                        }}
                        style={{
                            width: height*0.15,
                            height: "100%"
                        }}
                    />
                    <Text
                         style={{
                            fontFamily: $bdCartoon,
                            fontWeight: "900",
                            letterSpacing: 4,
                            fontSize: height*0.08,
                            color: $yellow
                        }}
                    >
                        X
                    </Text>
                    <Text
                         style={{
                            fontFamily: $bdCartoon,
                            fontWeight: "900",
                            letterSpacing: 4,
                            fontSize: height*0.08,
                            color: $yellow
                        }}
                    >
                        {playerDetails?.virtueCount}
                    </Text>
                </View>
                <View
                    style={{
                        height: height*0.15,
                        width: height*0.55,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: height*0.015,
                        alignItems: "center"
                    }}
                >
                    <Image
                        source={{
                            uri: Config.IMG_URL + "/leaderboard/abilities_1.png"
                        }}
                        style={{
                            width: height*0.15,
                            height: "100%"
                        }}
                    />
                    <Text
                         style={{
                            fontFamily: $bdCartoon,
                            fontWeight: "900",
                            letterSpacing: 4,
                            fontSize: height*0.08,
                            color: $yellow
                        }}
                    >
                        X
                    </Text>
                    <Text
                         style={{
                            fontFamily: $bdCartoon,
                            fontWeight: "900",
                            letterSpacing: 4,
                            fontSize: height*0.08,
                            color: $yellow
                        }}
                    >
                        {playerDetails?.superpowerCount}
                    </Text>
                </View>
                <View
                    style={{
                        height: height*0.15,
                        width: height*0.55,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Image
                        source={{
                            uri: Config.IMG_URL + "/leaderboard/missions.png"
                        }}
                        style={{
                            width: height*0.15,
                            height: "100%"
                        }}
                    />
                    <Text
                         style={{
                            fontFamily: $bdCartoon,
                            fontWeight: "900",
                            letterSpacing: 4,
                            fontSize: height*0.08,
                            color: $yellow
                        }}
                    >
                        X
                    </Text>
                    <Text 
                         style={{
                            fontFamily: $bdCartoon,
                            fontWeight: "900",
                            letterSpacing: 4,
                            fontSize: height*0.08,
                            color: $yellow
                        }}
                    >
                        {playerDetails?.missionCount}
                    </Text>
                </View>
            </View>
        </ImageBackground>
    )
}

export default PlayerPointCard;
import React, { useState } from 'react';
import { Text, View, TouchableOpacity, StyleSheet, useWindowDimensions, Image } from 'react-native';
import PropTypes from 'prop-types';
import { $blue, $black, $white, $green, $darkGray } from '../constants/colors';
import { $robotoRegular, $robotolight } from '../constants/fonts';
import Config from '../config';
import NotificationModal from './notificationModal';
import { useModal } from './ModalProvider';

let refs = [];

const CheckboxButtons = ({
  getSelectedValue,
  options,
  keys,
  selectIds,
  showSelectedBadge,
  selectedBadgeTxt,
}) => {
  const [val, setVal] = useState([]);
  const window = useWindowDimensions();
  const { openModal } = useModal();
  const styles = getStyles(window.width, window.height)

  const setActiveBtn = (value, id) => {
    if (showSelectedBadge) return;
    const valueId = value[id];

    let newVal = val
    if (val.includes(valueId)) {
      newVal = val.filter(v => v !== valueId);
    } else {
      newVal.push(valueId)
    }
    getSelectedValue(newVal);
    setVal([...newVal]);
  };

  refs = [];

  return (
    <View style={styles.container}>
      {options.map((item, index) => {
        return (
          <TouchableOpacity
            ref={(ref) => {
              if (ref) {
                refs?.push(ref);
              }
            }}
            key={item[keys.id]}
            onPress={() => setActiveBtn(item, keys.id)}
            style={styles.buttonContainer}>
            <View style={styles.circle}>
              {(val.includes(item[keys.id]) || selectIds.includes(item[keys.id])) && (
                <View style={styles.checkedCircle} />
              )}
            </View>
            <Text style={styles.text}>{item[keys.text]}</Text>
            {showSelectedBadge && (
              <Text style={item.is_correct && styles.badgeTrue}>
                {selectedBadgeTxt || item.is_correct ? 'Correct' : ''}
              </Text>
            )}
            {(showSelectedBadge && item?.feedback) && (
              <TouchableOpacity
                onPress={() => {
                  if (refs[index]) {
                    refs[index]?.measure((x, y, xWidth, xHeight, px, py) => {
                      openModal({
                        open: true,
                        component: NotificationModal,
                        data: {
                          backgroundColor: $white,
                          color: $darkGray,
                          position: {
                            top: py,
                            left: px,
                            width: xWidth,
                            height: xHeight
                          },
                          message: item?.feedback
                        }
                      })
                    })
                  }
                }}
              >
                <Image
                  source={{
                    uri: Config.IMG_URL + "/save_earth/info_icon.png"
                  }}
                  style={{
                    width: window.height * 0.04,
                    height: window.height * 0.04,
                    marginTop: window.height * 0.005,
                    marginLeft: window.height * 0.006
                  }}
                />
              </TouchableOpacity>
            )

            }
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

CheckboxButtons.propTypes = {
  options: PropTypes.array,
  keys: PropTypes.object,
  selectId: PropTypes.number,
  getSelectedValue: PropTypes.func,
  showSelectedBadge: PropTypes.bool,
  selectedBadgeTxt: PropTypes.string,
};

export default CheckboxButtons;

const getStyles = (width, height) => StyleSheet.create({
  badgeTrue: {
    backgroundColor: $green,
    borderRadius: 2,
    color: $white,
    fontFamily: $robotolight,
    fontSize: 10,
    marginLeft: 10,
    padding: 4,
  },

  buttonContainer: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center'
  },

  checkedCircle: {
    backgroundColor: $blue,
    borderRadius: 4,
    height: height * 0.032,
    width: height * 0.032,
  },

  circle: {
    alignItems: 'center',
    borderColor: $black,
    borderRadius: 4,
    borderWidth: 1,
    height: height * 0.04,
    justifyContent: 'center',
    width: height * 0.04,
  },

  container: {
    paddingLeft: height * 0.04,
    justifyContent: 'center',
  },

  text: {
    fontFamily: $robotoRegular,
    fontSize: height * 0.03,
    marginLeft: height * 0.02,
    fontWeight: '400',
  },
});

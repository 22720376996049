import React, { useCallback, useState } from 'react' 
import { useSelector } from 'react-redux';
import Config from '../config';

const SFXContext = React.createContext({});

export const useSFX = () => React.useContext(SFXContext);

const SFXConfig = ({
    children
}) => {
    const sfxIsOn = useSelector(state => state.status.sfxIsON);
    const levelId = useSelector(state => state.player.level.id);//for turning the volume down to 30% during ftli
    const [currentSound, setCurrentSound] = useState({
        sound: "",
        force: false
    })

    const playSound = useCallback((data) => {
        if(sfxIsOn || data?.force){
            setCurrentSound(data)
        }
    }, [sfxIsOn, setCurrentSound])

    return (
        <SFXContext.Provider
            value={{
                playSound: playSound
            }}
        >
           {Boolean((sfxIsOn || currentSound?.force) && currentSound?.sound) &&
                <audio
                    ref={(ref) => {
                        if(ref?.volume){
                            ref.volume = levelId === 1 ? 0.3 : 0.8
                        }
                    }}
                    id="sfx_sound"
                    src={Config.IMG_URL+`/sounds/${currentSound?.sound}`}
                    autoPlay
                    onEndedCapture={() => {
                        setCurrentSound({
                            sound: "",
                            force: false
                        })
                    }}
                />
           }
            {
                children
            }
        </SFXContext.Provider>
    )
}

export default SFXConfig;
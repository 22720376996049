import React, { useEffect, useState } from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { $robotoRegular } from '../constants/fonts';
import { $yellow } from '../constants/colors';
import { useDispatch } from 'react-redux';
import { setHelpMessage, setRebeccaState } from '../actions/status';
import { FTLI_HELP_AUDIO } from '../constants/helpMessages';

const data = [
  //`Superpowers and Virtues are essentials to save cities. To defeat the Robotic Resistance you’ll have to master these.`,
  //`Since you already had Courage Superpower and Virtue. Let’s learn how to equip other Abilities. ${'\n\n'}Click on the Ability you want to equip,  then click “Activate” to activate it.`,
  `Equipment and Virtues are essentials to complete missions on every planet. You must master these for the future of our planet and the humankind..`,
  `Since you already have Courage ability that you've used in your test mission just now, let’s learn how to equip other Abilities that you may use in your future missions. ${'\n\n'}You would click on the Ability you want to equip,  then click “Activate” to activate it.`,
  `IMPORTANT:${'\n\n'}Keep in mind that you'll need EP (Effort Points) in order to activate abilities and right now you don't have enough EP as you can see on the top left of the screen. So that means, you won't be able to activate any abilities at the moment. ${'\n\n'}But don't hesitate to play around with the abilities. Try clicking on various icons under both sections (Virtues and Equipment)`
];

//6, 7, 8 help audio

const VirtuesHelp = () => {
  const [index, setIndex] = useState(0);

  const dispatch = useDispatch();

  const handlePress = async () => {
    if (index === data.length - 1) {
      dispatch(setRebeccaState(false));
      return;
    }
    setIndex(index + 1);
  };

  useEffect(() => {
    dispatch(setHelpMessage(null, FTLI_HELP_AUDIO[index+5]))
  }, [index, dispatch])

  return (
    <>
      <View style={styles.container}>
        <ScrollView>
          <Text style={styles.txt}>{data[index]}</Text>
        </ScrollView>
      </View>
      <TouchableOpacity style={styles.btn} onPress={() => handlePress()}>
        <Text>{index === data.length - 1 ? 'Close' : 'Next'}</Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    marginLeft: 10,
    marginTop: 10,
    position: 'relative',
    top: -5,
    width: 180,
  },

  container: {
    height: '74%',
    paddingHorizontal: 10,
  },

  txt: {
    fontFamily: $robotoRegular,
    fontSize: 16,
    marginRight: 10,
    marginTop: 10,
    fontWeight: '400',
  },
});

export default VirtuesHelp;

import { StyleSheet } from 'react-native';
import { $white } from '../../constants/colors';
import { $robotoBold } from '../../constants/fonts';

const $transparent = 'transparent';

export const getStyles = (width, height) => StyleSheet.create({
  bg: {
    aspectRatio: height/width,
    width: width,
    height: height
  },

  container: {
    alignItems: 'center',
    ...StyleSheet.absoluteFillObject,
  },

  mapWrapper: {
    width: width - 60,
    height: height - 60,
    top: 30,
    left: 30,
    position: 'relative',
    flexDirection: 'row'
  },

  transBg: {
    backgroundColor: $transparent,
  },
  tagBg: {
    width: 30,
    height: 40,
  },
  tag: {
    fontSize: height*0.02,
    color: $white,
    lineHeight: height*0.03,
    fontFamily: $robotoBold,
    fontWeight: '900',
    marginLeft: height*0.05
  },
  circle: {
    width: height * 0.04,
    height: height *0.04,
    borderRadius: height * 0.02,
    borderWidth: 1,
    borderColor: $white,
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    top: height*0.016,
    left: height*0.003
  }
});

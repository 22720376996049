import React from 'react';
import { View, Image, ImageBackground, Text, useWindowDimensions, TouchableOpacity } from 'react-native';
import Config from '../../../config';

import { StatStyles } from './styles';
import { useSelector } from 'react-redux';
import { SOUNDS } from '../../../constants/sfx';
import { useSFX } from '../../../components/SFXConfig';

const statsData = [
  {
    type: 'cronos',
    name: 'C',
  },
  {
    type: 'xp',
    name: 'XP',
  },
  {
    type: 'ep',
    name: 'EP',
  },
];

const Stats = ({
  navigation,
  values,
  hideLeaderboard
}) => {
  const { playSound } = useSFX();
  const xp = useSelector(state => state.player.xp);
  const ep = useSelector(state => state.player.ep);
  const cronos = useSelector(state => state.player.cronos);
  let data = { ep, xp, cronos };
  const window = useWindowDimensions()
  const styles = StatStyles(window.width, window.height)

  if (values) {
    data = values;
  }

  return (
    <View style={[styles.statsTop, hideLeaderboard ? { marginTop: 0, justifyContent: "center", marginLeft: "20%", zIndex: 100 } : { zIndex: 100 }]}>
      {statsData.map(item => (
        <View
          key={item.type}
          style={[styles[item.type + 'Container'], styles.statsContainer]}>
          <Image
            resizeMode="contain"
            style={[styles[item.type + 'Icon'], styles.statsIcon]}
            source={{ uri: Config.IMG_URL + `/icons/${item.type}.png` }}
          />
          <ImageBackground
            resizeMode="contain"
            style={[styles.statsWindow, styles[item.type + 'StatMain']]}
            source={{
              uri: Config.IMG_URL + '/dashboard/stat_window.png',
            }}>
            <Text style={styles.iconHeading}> {item.name} </Text>
            <Text style={styles.iconValue}>{data[item.type]}</Text>
          </ImageBackground>
        </View>
      ))}
      {Boolean(!hideLeaderboard) &&
        <TouchableOpacity
          onPress={() => {
            if (playSound) {
              playSound({
                sound: SOUNDS.leaderboard
              })
            }
            navigation.navigate("Leaderboard");
          }}
        >
          <ImageBackground
            style={{
              width: window.height * 0.31,
              height: window.height * 0.15,
              left: window.width * 0.06
            }}
            source={Config.IMG_URL + "/leaderboard/lb_icon.gif"}
          >
          </ImageBackground>
        </TouchableOpacity>
      }
    </View>
  );
};

export default Stats;

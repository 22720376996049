import axios from './index';
import axiosConfig from '../constants/axiosConfig';
import Config from '../config';

export const getCityMissions = async () =>
  axios.get(
    Config.API_URL + '/city_missions',
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const getLevelTemplate = async templateId =>
  axios.get(
    Config.API_URL + '/level_templates/' + templateId,
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const getDisabledCity = async () =>
  axios.get(
    Config.API_URL + '/city_missions/disabled',
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const board = async data =>
  axios.put(
    Config.API_URL + `/city_missions/${data.id}/board`,
    data,
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const support = async data =>
  axios.put(
    Config.API_URL + `/city_missions/${data.id}/support`,
    data,
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const dismantle = async id =>
  axios.put(
    Config.API_URL + `/city_missions/${id}/dismantle_network`,
    {},
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const retry = async id =>
  axios.put(
    Config.API_URL + `/city_missions/${id}/retry`,
    {},
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

export const getPendingMissionNotification = async playerId =>
  axios.get(
    Config.API_URL + `/players/${playerId}/pending_mission_notifications`,
    await axiosConfig({ token: true, gameId: true }),
  );

export const setPendingMissionNotification = async (playerId, missinId) =>
  axios.put(
    Config.API_URL + `/players/${playerId}/set_mission_notification`,
    {
      mission_id: missinId
    },
    await axiosConfig({ token: true, gameId: true, levelId: true }),
  );

import React, { useEffect } from 'react'
import { Image } from 'react-native';
import { ImageBackground, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import Config from '../config';
import { $black, $blue, $white } from '../constants/colors';
import { $bdCartoon, $robotoRegular } from '../constants/fonts';
import useMediaQuery from '../hooks/useMediaQuery';
import { useSFX } from './SFXConfig';

const AlertPopup = ({
    closeModal,
    data
}) => {
    const { playSound } = useSFX();
    const { width, height } = useWindowDimensions();
    const { messageComponent: MessageComponent } = data;
    const { isMobile } = useMediaQuery();

    useEffect(() => {
        if (playSound && data?.sound) {
            playSound({
                sound: data.sound
            })
        }
    }, [playSound, data?.sound])

    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height
            }}
            onStartShouldSetResponder={() => {
                // closeModal()
                if (data.clickOutside) {
                    data.clickOutside();
                }
                return false;
            }}
        >
            <View
                style={{
                    width: data.width || "32%",
                    height: data.height || "80%",
                }}
            >
                <ImageBackground
                    source={{
                        uri: data?.modalBackground || Config.IMG_URL + "/save_earth/main_popup.png"
                    }}
                    style={{
                        width: "100%",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        paddingVertical: 10
                    }}
                >
                    {Boolean(data?.heading) &&
                        <View
                            style={{
                                minHeight: Boolean(data?.headingImage) ? height * 0.25 : height * 0.1,
                                marginTop: data?.headingMarginTop || 0,
                                marginBottom: data?.headingMarginTop ? 0 : height * 0.03
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: $bdCartoon,
                                    fontSize: height * (isMobile ? 0.08 : 0.1),
                                    fontWeight: "900",
                                    color: $blue,
                                    width: "100%",
                                    paddingHorizontal: 10,
                                    textAlign: "center",
                                    marginTop: data?.headingLabelMarginTop || 28,
                                    textShadow: "-3px 3px #000, 3px 3px 0 #000, 3px -3px 0 #000, -3px -3px 0 #000",
                                    letterSpacing: 8,
                                }}
                            >
                                {data?.heading}
                            </Text>
                            {Boolean(data?.headingImage) &&
                                <View
                                    style={{
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Image
                                        source={{
                                            uri: data?.headingImage
                                        }}
                                        style={{
                                            height: height * 0.14,
                                            width: height * 0.155
                                        }}
                                    />
                                </View>
                            }
                        </View>}
                    <View
                        style={{
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flex: data?.hideButton ? 1 : undefined
                        }}
                    >
                        {Boolean(MessageComponent) ?
                            <MessageComponent data={data} closeModal={closeModal} />
                            :
                            <Text
                                style={{
                                    fontFamily: $robotoRegular,
                                    fontSize: height * 0.03,
                                    fontWeight: "600",
                                    letterSpacing: 2,
                                    color: $black,
                                    width: "100%",
                                    paddingHorizontal: 15,
                                    textAlign: "center",
                                    marginTop: height * 0.01,

                                    textAlignVertical: "center"
                                }}
                            >
                                {data?.message}
                            </Text>
                        }
                    </View>
                    <View
                        style={{
                            height: height * 0.03
                        }}
                    >
                    </View>
                    {Boolean(!data?.hideButton) &&
                        <View
                            style={{
                                position: "absolute",
                                flexDirection: "row",
                                justifyContent: data?.singleButton ? "center" : "space-between",
                                paddingHorizontal: height * 0.03,
                                width: "100%",
                                bottom: -(height * 0.02),
                                left: 0
                            }}
                        >
                            <TouchableOpacity
                                style={{
                                    minHeight: width * 0.05,
                                    minWidth: width * 0.12
                                }}
                                onPress={() => {
                                    closeModal();
                                    if (data.success) {
                                        data.success()
                                    }
                                }}
                            >
                                <ImageBackground
                                    source={{
                                        uri: Config.IMG_URL + '/save_earth/btn.png'
                                    }}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: $white,
                                            fontFamily: $robotoRegular,
                                            fontSize: height * 0.03,
                                            fontWeight: '400',
                                            marginHorizontal: height * 0.02,
                                            marginVertical: height * 0.027
                                        }}
                                    >
                                        {data?.yesText}
                                    </Text>
                                </ImageBackground>
                            </TouchableOpacity>
                            {Boolean(!data?.singleButton) && <TouchableOpacity
                                style={{
                                    minHeight: width * 0.05,
                                    minWidth: width * 0.12
                                }}
                                onPress={() => {
                                    closeModal()
                                    if (data.failure) {
                                        data.failure()
                                    }
                                }}
                            >
                                <ImageBackground
                                    source={{
                                        uri: Config.IMG_URL + '/save_earth/btn.png'
                                    }}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: $white,
                                            fontFamily: $robotoRegular,
                                            fontSize: height * 0.03,
                                            fontWeight: '400',
                                        }}
                                    >
                                        {data?.noText}
                                    </Text>
                                </ImageBackground>
                            </TouchableOpacity>}
                        </View>
                    }
                </ImageBackground>
            </View>
        </View>
    )
}

export default AlertPopup;
import React, { useEffect, useState } from 'react';
import { ImageBackground, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import LeftCtn from './leftCtn';
import RightCtn from './rightCtn';
import { getCharacterChoices } from '../../actions/player';
import { setHelpMessage, setRebeccaState } from './../../actions/status';
import { characterSelectionHelpMessage } from './../../constants/ftliHelp';
import Config from '../../config';

import { getStyle } from './styles';
import { FTLI_HELP_AUDIO } from '../../constants/helpMessages';

const CharacterSelction = ({ navigation }) => {
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState({});
  const [traits, setTraits] = useState([]);

  const playerProfile = useSelector(state => state.player);
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const styles = getStyle(window.width, window.height);

  useEffect(() => {
    const getData = async () => {
      const { characters, traits } = await dispatch(getCharacterChoices());
      setCharacters(characters);
      setTraits(traits);
    };
    dispatch(setHelpMessage(characterSelectionHelpMessage, FTLI_HELP_AUDIO[0]));//1
    dispatch(setRebeccaState(true));
    getData();
  }, [dispatch]);

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/practice_missions/background.png' }}
      style={styles.container}>
      <LeftCtn
        charactersData={characters}
        selectedCharacter={selectedCharacter}
        setSelectedCharacter={setSelectedCharacter}
        player={playerProfile}
      />
      <RightCtn
        traits={traits}
        selectedCharacter={selectedCharacter}
        player={playerProfile}
        navigate={navigation.navigate}
      />
    </ImageBackground>
  );
};

CharacterSelction.propTypes = {
  navigation: PropTypes.object,
};

export default CharacterSelction;

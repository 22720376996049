import React, { useState } from 'react'
import { useWindowDimensions, View } from 'react-native'
import Config from '../../config';
import useMediaQuery from '../../hooks/useMediaQuery';
import AboutDesigner from './components/AboutDesigner';
import AboutCompany from './components/AboutCompany';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Header from './components/Header';
import HowItWorks from './components/HowItWorks';
import IntroVideoSection from './components/IntroVideoSection';
import LoginComponent from './components/LoginComponent';
import PricingComponent from './components/PricingComponent';
import TopSection from './components/TopSection';
import './index.css'


const MainPage = ({ navigation }) => {
    const window_obj = useWindowDimensions();
    const { isMobile } = useMediaQuery()
    const [menuShow, setMenuShow] = useState(false);
    

    return (
       
             <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: "column",
                    height: window_obj.height,
                    width: window_obj.width,
                    zIndex: 1000,
                    backgroundImage: `url("${Config.IMG_URL + '/login/new-login-bg-sw.png'}")`,
                    overflow: "auto",
                    paddingTop: "60px"
                }}
                className="parallax"
                id="main_content"
            >
                <View
                    style={{
                        width: window_obj.width,
                        height: "60px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                        paddingHorizontal: isMobile ? "8px" : "20px",
                        paddingVertical: isMobile ? "5px" : "10px",
                        position: "fixed",
                        top: "0px",
                        left: 0,
                        backgroundColor: "rgba(0,0,0, 1)",
                        zIndex: 101,
                        transition: "top 0.5s ease"
                    }}
                >
                    <Header setMenuShow={setMenuShow} showMenu={menuShow} />
                    <LoginComponent navigation={navigation} menuShow={menuShow} setMenushow={setMenuShow} />
                </View>
                <TopSection />
                <IntroVideoSection />
                <HowItWorks />
                <PricingComponent />
                <View
                    style={{
                        width: "100%",
                        flexDirection: "column",
                        backgroundColor: "rgba(0,0,0,0.5)"
                    }}
                >
                    <AboutDesigner />
                    <AboutCompany />
                    <ContactUs />
                    <Footer />
                </View>
            </div>
    )
}

export default MainPage
import createReducer from '../lib/createReducer';
import { SET_PROGRAMS, LOGOUT, LEAVE_GAME, UPDATE_PROGRAMS } from '../constants/actions';

/**
 * array of objects
 * {
 *    id
 *    name
 *    description
 *    sort
 *    isPublished
 *    isTutorial
 *    isCompleted
 *    levelTemplateId
 *    programModules: [name, description, sort, isPublished, transaction: {id, isCompleted}]
 * }
 */

const initialState = [];

export const programs = createReducer(initialState, {
  [SET_PROGRAMS](state, { payload: { data } }) {
    return [...data];
  },
  [UPDATE_PROGRAMS](state, { payload }) {
    let currentProgram = state.find(pr => pr.id.toString() === payload?.id?.toString())
    if(currentProgram?.id){
      Object.keys(payload)?.forEach(key => {
        currentProgram[key] = payload[key];
      })
      return JSON.parse(JSON.stringify(state));
    } else {
      return state;
    }
  },
  [LEAVE_GAME](state, { payload }) {
    return initialState;
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

import createReducer from '../lib/createReducer';
import { SET_SUPERPOWERS, LOGOUT, LEAVE_GAME, UPDATE_SUPERPOWER_ATTRIBUTES } from '../constants/actions';

/**
 * array of objects
 * {
 *   id
 *   state
 *   name
 *   displayName
 *   description
 *   requiredEp
 *   isEnabled
 *   isDisabled
 *   isActivated
 * }
 */
const initialState = [];

export const superpowers = createReducer(initialState, {
  [SET_SUPERPOWERS](state, { payload: { data } }) {
    return [...data];
  },
  [UPDATE_SUPERPOWER_ATTRIBUTES](state, { payload: { data } }) {
    return state.map(sup => {
      if(sup.id === data.id){
        sup = {
          ...sup,
          ...data
        }
      }
      return sup
    });
  },
  [LEAVE_GAME](state, { payload }) {
    return initialState;
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View, ImageBackground, Text, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';

import Config from '../../../config';

import { TimerStyles } from './styles';

const SEC = 1000;
const MIN = SEC * 60;
const HOUR = MIN * 60;
const DAY = HOUR * 24;

const Timer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [secs, setSecs] = useState(0);
  const window = useWindowDimensions()
  const styles = TimerStyles(window.width, window.height)

  const navigation = useSelector(state => state.status.nav);
  const gameEndAt = useSelector(
    state => state.games.currentGame.scheduledEndAt,
  );
  const gameEndDate = useMemo(() => {
    return new Date(gameEndAt);
  },[gameEndAt]) 
  let timerInerval = useRef(null);

  const tick = useCallback(() => {
    const now = new Date();
    const diffDate = gameEndDate - now;

    if (diffDate <= 0) {
      clearInterval(timerInerval.current);
    } else {
      const days = Math.floor(diffDate / DAY);
      const hours = Math.floor((diffDate % DAY) / HOUR);
      const mins = Math.floor((diffDate % HOUR) / MIN);
      const secs = Math.floor((diffDate % MIN) / SEC);

      setDays(days);
      setHours(hours);
      setMins(mins);
      setSecs(secs);
    }
  },[gameEndDate, timerInerval])

  useEffect(() => {
    if (navigation === 'Dashboard') {
      timerInerval.current = setInterval(tick, SEC);
    }
    return () => {
      clearInterval(timerInerval.current);
    };
  }, [navigation, tick]);

  return (
    <View style={styles.wrapper}>
      <ImageBackground
        style={styles.bg}
        resizeMode="stretch"
        source={{
          uri: Config.IMG_URL + '/dashboard/detonation_timer.png',
        }}>
        <View style={styles.detonation}>
          <Text style={styles.txt}> Mission Deadline </Text>
          <Text style={styles.timerDays}>{days + ' days'}</Text>
          <Text style={styles.timer}>{`${hours}:${mins}:${secs}`}</Text>
        </View>
      </ImageBackground>
    </View>
  );
};

export default Timer;

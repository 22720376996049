import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native';
import { Image, ImageBackground, ScrollView, Text, useWindowDimensions, View } from "react-native";
import { useSelector } from 'react-redux';
import Config from '../../config';
import { $black, $white } from '../../constants/colors';
import { $bdCartoon, $robotoRegular } from '../../constants/fonts';
import { getStyle } from './styles';

const BoardMessagePopup = ({
    data
}) => {
    const { width, height } = useWindowDimensions();
    const { duration } = data;
    const [tooltip, setTooltip] = useState(false);
    const requirements = useSelector(state => state.cities.requirements[data.id]);
    const name = useSelector(state => state.status.slectedCity.displayName);
    const allVirtues = useSelector(state => state["virtues"]);
    const allSuperpowers = useSelector(state => state["superpowers"]);
    const virtues = requirements["virtues"].map(item => {
        return allVirtues.find(virtue => virtue.name === item.name);
    });
    const superpowers = requirements["superpowers"].map(item => {
        return allSuperpowers.find(superpower => superpower.name === item.name);
    });
    const styles = getStyle(width, height);
    const day = parseInt(duration / (60 * 60 * 24));
    const hour = parseInt((duration - (day * 60 * 60 * 24)) / (60 * 60));
    const minutes = parseInt((duration - (day * 60 * 60 * 24) - (hour * 60 * 60)) / (60));

    return (
        <>
            {Boolean(tooltip?.open) && <View
                style={{
                    position: "absolute",
                    zIndex: 100,
                    bottom: height * 0.06,
                    paddingVertical: height * 0.014,
                    paddingHorizontal: width * 0.007,
                    backgroundColor: $white,
                    borderRadius: 10,
                    maxWidth: width * 0.24
                }}
            >
                <Text
                    style={{
                        color: $black,
                        fontSize: height * 0.02,
                        fontFamily: $robotoRegular,
                        textAlign: "center"
                    }}
                >
                    {tooltip.message}
                </Text>
            </View>}
            <ScrollView
                ref={ref => {
                    if (ref?.setNativeProps)
                        ref.setNativeProps({ scroll: "show_scroll" })
                }}
                contentContainerStyle={{
                    justifyContent: "center",
                    alignItems: "center"
                }}
                style={{
                    flex: 1,
                }}
            >
                <Text
                    style={{
                        fontFamily: $robotoRegular,
                        fontSize: height * 0.03,
                        fontWeight: "bold",
                        letterSpacing: 2,
                        color: $black,
                        width: "100%",
                        paddingHorizontal: 15,
                        textAlign: "center",
                        marginTop: height * 0.01,
                        flex: 1,
                        textAlignVertical: "center"
                    }}
                >
                    {"In this mission you will be using:"}
                </Text>
                <View
                    style={{
                        flexDirection: "row",
                        width: "95%",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: height * 0.02
                    }}
                >
                    {superpowers?.length &&
                        superpowers?.map(superpower => {
                            return (
                                <View
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: height * 0.01,
                                        marginHorizontal: height * 0.02
                                    }}
                                >
                                    <ImageBackground
                                        resizeMode="contain"
                                        style={{
                                            width: height * 0.08,
                                            height: height * 0.08,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        source={{
                                            uri:
                                                Config.IMG_URL +
                                                `/icons/superpowers_shield_active.png`,
                                        }}
                                    >
                                        <Image
                                            resizeMode="contain"
                                            style={{
                                                width: height * 0.07,
                                                height: height * 0.07
                                            }}
                                            source={{
                                                uri:
                                                    Config.IMG_URL +
                                                    `/icons/superpower/${superpower.name
                                                    }.png`,
                                            }}
                                        />
                                    </ImageBackground>
                                    <Text
                                        style={{
                                            flex: 1,
                                            fontFamily: $robotoRegular,
                                            fontSize: height * 0.022,
                                            fontWeight: "600",
                                            letterSpacing: 2,
                                            color: $black,
                                            textAlign: "left",
                                            textAlignVertical: "center",
                                            marginLeft: height * 0.006
                                        }}
                                    >
                                        {superpower.displayName}
                                    </Text>
                                </View>
                            )
                        })
                    }
                    {/* </View> */}

                    {virtues?.length &&
                        virtues?.map(virtue => {
                            return (
                                <View
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: height * 0.01,
                                        marginHorizontal: height * 0.02
                                    }}
                                >
                                    <ImageBackground
                                        resizeMode="contain"
                                        style={{
                                            width: height * 0.08,
                                            height: height * 0.08,
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        source={{
                                            uri:
                                                Config.IMG_URL +
                                                `/icons/virtues_shield_active.png`,
                                        }}
                                    >
                                        <Image
                                            resizeMode="contain"
                                            style={{
                                                width: height * 0.07,
                                                height: height * 0.07
                                            }}
                                            source={{
                                                uri:
                                                    Config.IMG_URL +
                                                    `/icons/virtue/${virtue.name
                                                    }.png`,
                                            }}
                                        />
                                    </ImageBackground>
                                    <Text
                                        style={{
                                            flex: 1,
                                            fontFamily: $robotoRegular,
                                            fontSize: height * 0.022,
                                            fontWeight: "600",
                                            letterSpacing: 2,
                                            color: $black,
                                            textAlign: "left",
                                            textAlignVertical: "center",
                                            marginLeft: height * 0.006
                                        }}
                                    >
                                        {virtue.displayName}
                                    </Text>
                                </View>
                            )
                        })
                    }
                </View>
                <Text
                    style={{
                        fontFamily: $robotoRegular,
                        fontSize: height * 0.03,
                        fontWeight: "bold",
                        letterSpacing: 2,
                        color: $black,
                        width: "100%",
                        paddingHorizontal: 15,
                        textAlign: "center",
                        marginTop: height * 0.01,
                        flex: 1,
                        textAlignVertical: "center",
                        marginBottom: height * 0.02
                    }}
                >
                    {"To travel to this planet, you will be spending:"}
                </Text>
                <View
                    style={{
                        flexDirection: "row",
                        paddingRight: height * 0.03
                    }}
                >
                    <Text style={styles.fareHead}> Fare </Text>
                    <ImageBackground
                        resizeMode="stretch"
                        style={styles.fareBg}
                        source={{
                            uri: Config.IMG_URL + '/save_earth/cronos.png',
                        }}>
                        <Text style={styles.fareValue}>{requirements.fare}</Text>
                    </ImageBackground>
                </View>
                <View
                    style={{
                        flexDirection: "row"
                    }}
                >
                    <Text style={[styles.fareHead, {
                        marginRight: 5
                    }]}> Duration </Text>
                    <ImageBackground
                        resizeMode="stretch"
                        style={[styles.fareBg, {
                            width: height * 0.28
                        }]}
                        source={{
                            uri: Config.IMG_URL + '/save_earth/clock.png',
                        }}>
                        <Text style={[styles.fareValue, {
                            marginLeft: height * 0.09
                        }]}>{
                                `${day}D:${hour}H:${minutes}M`
                            }</Text>
                    </ImageBackground>
                </View>
                <Text
                    style={{
                        fontFamily: $robotoRegular,
                        fontSize: height * 0.03,
                        fontWeight: "bold",
                        letterSpacing: 2,
                        color: $black,
                        width: "100%",
                        paddingHorizontal: 15,
                        textAlign: "center",
                        marginTop: height * 0.01,
                        flex: 1,
                        textAlignVertical: "center"
                    }}
                >
                    {"Impact of failing the mission:"}
                </Text>

                <View>
                    <Text
                        style={{
                            fontFamily: $robotoRegular,
                            fontSize: height * 0.026,
                            fontWeight: "600",
                            letterSpacing: 2,
                            color: $black,
                            width: "100%",
                            paddingHorizontal: 15,
                            textAlign: "center",
                            marginTop: height * 0.01,
                            flex: 1,
                            textAlignVertical: "center"
                        }}
                    >
                        You will lose the cronos you spent in traveling to the planet.
                    </Text>
                    {data?.critical &&
                        <Text
                            style={{
                                fontFamily: $robotoRegular,
                                fontSize: height * 0.026,
                                fontWeight: "600",
                                letterSpacing: 2,
                                color: $black,
                                width: "100%",
                                paddingHorizontal: 15,
                                textAlign: "center",
                                marginTop: height * 0.01,
                                flex: 1,
                                textAlignVertical: "center"
                            }}
                        >
                            You will lose the abilities you used in this mission
                        </Text>
                    }
                </View>
                <Text
                    style={{
                        fontFamily: $bdCartoon,
                        fontSize: height * 0.03,
                        fontWeight: "bold",
                        letterSpacing: 2,
                        color: "#333333",
                        width: "100%",
                        paddingHorizontal: 30,
                        textAlign: "center",
                        flex: 1,
                        textAlignVertical: "center",
                        marginTop: 20
                    }}
                >
                    {"Tip: Try and invite more people to join this mission before the mission time runs out"}
                </Text>
                <View
                    style={{
                        width: "95%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: height * 0.04,
                        marginTop: 30
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 15
                        }}
                    >
                        <TouchableOpacity
                            style={{
                                paddingHorizontal: width * 0.004
                            }}
                            onPress={() => {
                                if (data?.closeModal)
                                    data.closeModal()
                            }}
                        >
                            <Text
                                style={{
                                    color: $white,
                                    fontSize: height * 0.038,
                                    fontFamily: $bdCartoon,
                                    fontWeight: "600",
                                    textShadowColor: $black,
                                    textShadowOffset: { width: 0, height: 4 },
                                    textShadowRadius: 10,
                                    letterSpacing: 2
                                }}
                            >
                                Let me think about this
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                setTooltip({
                                    open: !tooltip?.open,
                                    message: "Return to previous screen"
                                })
                            }}
                        >
                            <Image
                                source={{
                                    uri: Config.IMG_URL + "/save_earth/info_icon.png"
                                }}
                                style={{
                                    width: height * 0.04,
                                    height: height * 0.04,
                                    marginTop: height * 0.005,
                                    marginLeft: height * 0.006
                                }}
                            />
                        </TouchableOpacity>
                    </View>
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        <TouchableOpacity
                            style={{
                                paddingHorizontal: width * 0.004
                            }}
                            onPress={() => {
                                if (data?.success) {
                                    data?.success();
                                }
                            }}
                        >
                            <Text
                                style={{
                                    color: $white,
                                    fontSize: height * 0.038,
                                    fontFamily: $bdCartoon,
                                    fontWeight: "600",
                                    textShadowColor: $black,
                                    textShadowOffset: { width: 0, height: 4 },
                                    textShadowRadius: 10,
                                    letterSpacing: 2
                                }}
                            >
                                I'm Sure
                            </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                setTooltip({
                                    open: !tooltip?.open,
                                    message: `Initates departure to the ${name}`
                                })
                            }}
                        >
                            <Image
                                source={{
                                    uri: Config.IMG_URL + "/save_earth/info_icon.png"
                                }}
                                style={{
                                    width: height * 0.04,
                                    height: height * 0.04,
                                    marginTop: height * 0.005,
                                    marginLeft: height * 0.006
                                }}
                            />
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        </>
    )
}

export default BoardMessagePopup;
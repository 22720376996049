import React from 'react';
import { Image, TouchableOpacity, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import Config from '../../config';
import { getCharacterListStyle } from './styles';

const CharacterList = ({
  name,
  setSelectedCharacter,
  selectedCharacter,
  id,
}) => {
  const window = useWindowDimensions();
  const styles = getCharacterListStyle(window.width, window.height);

  return (
    <TouchableOpacity onPress={() => setSelectedCharacter(name, id)}>
      <Image
        style={[
          styles.profile,
          name === selectedCharacter.name && styles.selected,
        ]}
        source={{ uri: Config.IMG_URL + `/characters/${name}_profile.png` }}
      />
    </TouchableOpacity>
  );
};

CharacterList.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  setSelectedCharacter: PropTypes.func,
  selectedCharacter: PropTypes.object,
};

export default CharacterList;

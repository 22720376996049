import React, { useEffect, useState } from 'react' 
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getCities } from '../actions/cities';
import Config from '../config';
import { CLEAR_RELEASED_MISSIONS, REMOVE_CITY_COMPLETED_OR_FAILED_DATA, SET_ENABLE_VIRTUES } from '../constants/actions';
import { SOUNDS } from '../constants/sfx';
import { pauseMusic, playMusic } from '../helpers';
import PlanetryTravelGame from '../lib/planetryGame';
import AbilityEnabledMessage from '../screens/abilities/AbilityEnabledMessage';
import { setVirtueNotifications } from '../services/player';
import { setPendingMissionNotification } from '../services/saveEarth';
import AlertPopup from './alertPopup';
import MissionCompletePopUp from './missionCompletePopup';
import MissionFailedPopUp from './missionFailedPopup';
import * as RootNav from '../navigation/RootNav';
import { setAbilities } from '../actions/status';

const ModalContext = React.createContext({});

export const useModal = () => React.useContext(ModalContext);

const ModalProvider = ({
    children
}) => {
    const [modal, setModal] = React.useState({
        open: false,
        component: null,
        blur: false,
        data: {},
        openGame: true
    })
    const [game, setGame] = useState({
        open: false,
        name: "",
        data: {}
    })
    const popupMissions = useSelector(state => state.cities.completed_failed_missions);
    const showMissionPopupModal = useSelector(state => state.status.showMissionPopupModal);
    const missions = useSelector(state => state.cities.data);
    const released_missions = useSelector(state => state.cities.released_missions);
    const playerId = useSelector(state => state.player.id);
    const musicIsPlaying = useSelector(state => state.status.musicIsPlaying);
    const badges = useSelector(state => state["virtues"]);
    const enabled_virtues = useSelector(state => state.status.virtue_enabled);
    const dispatch = useDispatch();
    const { height } = useWindowDimensions();

    //customizing window alert function
    useEffect(() => {
        window.customAlert = (message) => {
            setModal({
                open: true,
                component: AlertPopup,
                blur: true,
                data: {
                    message: message,
                    yesText: "Okay",
                    singleButton: true,
                }
            })
        }
    }, [])

    useEffect(() => {
        if(musicIsPlaying){
            playMusic();
        } else {
            pauseMusic();
        }
    },[musicIsPlaying])

    const { component: ModalComponent } = modal;

    useEffect(() => {
        if(!modal?.open && badges?.length && enabled_virtues?.length){
            const badge = badges.find(b => b?.id?.toString() === enabled_virtues[0]);
            if(!badge?.id){
                return;
            }
            setModal({
                open: true,
                component: AlertPopup,
                blur: true,
                data: {
                sound: SOUNDS.boardModal,
                headingMarginTop: height*0.23,
                heading: "Virtue Awarded!",
                yesText: "Go to Abilities",
                width: "28%",
                height: "87%",
                singleButton: true,
                modalBackground: Config.IMG_URL + "/abilities/modal/onestar_modal.png",
                messageComponent: () => <AbilityEnabledMessage item={{...badge, type: "virtue"}} />,
                success: () => {
                    dispatch({
                        type: SET_ENABLE_VIRTUES,
                        payload: {
                            virtues: enabled_virtues?.filter(id => id !== enabled_virtues[0])
                        }
                    })
                    setVirtueNotifications(playerId, enabled_virtues[0]);
                    setModal({
                        open: false,
                        component: null,
                        blur: false,
                        data: {},
                    })
                    dispatch(setAbilities({ tab: "virtues", superPower: 'civilian' }));
                    RootNav.navigate("Abilities", { selectedVirtue: badge });
                }
                }
            })
        }
    }, [badges, modal, height, enabled_virtues, dispatch, playerId])

    useEffect(() => {
        if(released_missions?.length){
            dispatch(getCities());
            dispatch({
                type: CLEAR_RELEASED_MISSIONS
            });
        }
    },[released_missions, dispatch])

    useEffect(() => {
        if(showMissionPopupModal && !modal.open && popupMissions?.length && playerId && missions.length){
            const mission = missions.find(city => city.id === popupMissions[0]?.id);
            if(!mission || !mission?.players?.find(pId => parseInt(pId) === parseInt(playerId))){
                dispatch({
                    type: REMOVE_CITY_COMPLETED_OR_FAILED_DATA,
                    payload: {
                       data: { 
                           id: popupMissions[0]?.id
                       }
                    }
                })
                return;
            }
            const isSuppport = mission?.players?.length > mission?.playerNeededCount
            setModal({
                open: true,
                component: mission?.isCompleted ? MissionCompletePopUp : MissionFailedPopUp,
                data: {
                    cityName: mission.name,
                    ep: "Gained "+(isSuppport ? mission.ep_reward/2 : mission.ep_reward)+" EP",
                    xp: "Gained "+(isSuppport ? mission.ep_reward/2 : mission.ep_reward)+" XP",
                    cronos: "Earned "+(isSuppport ? mission.crono_reward/2 : mission.crono_reward)+" Cronos",
                    critical: mission.critical,
                    id: mission.id,
                    endVideoUrl: mission.endVideoUrl,
                    playerInMission: mission.players.find(data => parseInt(data) === parseInt(playerId)),
                    success: () => {
                        dispatch({
                            type: REMOVE_CITY_COMPLETED_OR_FAILED_DATA,
                            payload: {
                                data: mission
                            }
                        })
                        setPendingMissionNotification(playerId, mission.id);
                    }
                },
                blur: true
            })
        }
    }, [popupMissions, modal, dispatch, missions, playerId, showMissionPopupModal])

    return (
        <ModalContext.Provider
            value={{
                openModal: setModal,
                closeModal: () => {
                    setModal({
                        open: false,
                        component: null,
                        blur: false
                    })
                },
                open: modal?.open,
                blur: modal?.blur || game.blur,
                openGame: setGame
            }}
        >
            {Boolean(game.open) && <PlanetryTravelGame closeGame={() => setGame({ open: false })} game={game}  />}
            {Boolean(modal?.open && ModalComponent) &&
                <ModalComponent
                    closeModal={() => {
                        setModal({
                            open: false,
                            component: null
                        })
                    }}
                    data={modal?.data}
                />
            }
            {
                children
            }
        </ModalContext.Provider>
    )
}

export default ModalProvider;
import React, { useEffect } from 'react'
import { Image, ImageBackground, ScrollView, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { useSelector } from 'react-redux';
import Config from '../config';
import { $black, $blue } from '../constants/colors';
import { $bdCartoon, $robotoRegular } from '../constants/fonts';
import { SOUNDS } from '../constants/sfx';
import { useSFX } from './SFXConfig';

const MissionFailedPopUp = ({
    closeModal,
    data
}) => {
    const { playSound } = useSFX();
    const { width, height } = useWindowDimensions();
    const requirements = useSelector(state => state.cities.requirements[data.id]);
    const allVirtues = useSelector(state => state["virtues"]);
    const allSuperpowers = useSelector(state => state["superpowers"]);
    const virtues = requirements["virtues"].map(item => {
        return allVirtues.find(virtue => virtue.name === item.name);
    });
    const superpowers = requirements["superpowers"].map(item => {
        return allSuperpowers.find(superpower => superpower.name === item.name);
    });

    useEffect(() => {
        if(playSound){
            playSound({
                sound: SOUNDS.missionFailureModal
            })
        }
    },[playSound])

    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height
            }}
            onStartShouldSetResponder={() => {
                // closeModal()
                return false;
            }}
        >
            <View
                style={{
                    width: data?.critical ? "32%" : "28%",
                    height: data?.critical ? "80%" : "68%",
                    minHeight: height*0.6,
                    minWidth: width*0.22
                }}
            >
                <ImageBackground
                    source={{
                        uri: Config.IMG_URL+"/save_earth/main_popup.png"
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <View
                        style={{
                            width: "100%",
                            alignItems: "center",
                            flex: 1,
                        }}
                    >
                        <Text
                            style={{
                                fontFamily: $bdCartoon,
                                fontSize: height*0.1,
                                fontWeight: "900",
                                color: $blue,
                                width: "100%",
                                paddingHorizontal: 10,
                                textAlign: "center",
                                marginTop: 28,
                                textShadow: "-3px 3px #000, 3px 3px 0 #000, 3px -3px 0 #000, -3px -3px 0 #000",
                                letterSpacing: 8,
                            }}
                        >
                            {data?.cityName}
                        </Text>
                        <Text
                             style={{
                                fontFamily: $robotoRegular,
                                fontSize: height*0.06,
                                fontWeight: "900",
                                letterSpacing: 2,
                                color: $black,
                                width: "100%",
                                paddingHorizontal: 10,
                                textAlign: "center",
                                marginTop: height*0.01
                            }}
                        >
                            Mission Failure
                        </Text>
                        <ScrollView
                            ref={ref => {
                                if(ref?.setNativeProps)
                                ref.setNativeProps({scroll:"show_scroll"})
                              }}
                              contentContainerStyle={{
                                  justifyContent: "center",
                                  alignItems: "center"
                              }}
                        >
                            <Text
                                style={{
                                    fontFamily: $robotoRegular,
                                    fontSize: height*0.03,
                                    fontWeight: "600",
                                    letterSpacing: 2,
                                    color: $black,
                                    width: "100%",
                                    paddingHorizontal: 15,
                                    textAlign: "center",
                                    marginTop: height*0.01,
                                    flex: 1,
                                    textAlignVertical: "center"
                                }}
                            >
                                {"Your team was not able to work together to complete this mission.\n\nHead back to Save Earth and try again."}
                                {data.critical ? data?.playerInMission ? 
                                `\n\nYou lost the following abilities due to the criticality of the mission:\n` 
                                :
                                `\n\nMembers that attempted this mission lost the following abilities due to the mission's criticality:\n`
                                : ""}
                            </Text>
                            <View
                                style={{
                                    flexDirection: "row",
                                    width: "95%",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    marginTop: height*0.02
                                }}
                            >
                                {/* <View
                                    style={{
                                        width: "45%"
                                    }}
                                > */}
                            {data.critical && superpowers?.length && 
                                superpowers?.map(superpower => {
                                    return (
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                paddingHorizontal: height*0.01,
                                                marginBottom: height*0.01,
                                                width: "48%"
                                            }}
                                        >
                                             <ImageBackground
                                            resizeMode="contain"
                                            style={{
                                                width: height*0.08,
                                                height: height*0.08,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                            source={{
                                            uri:
                                                Config.IMG_URL +
                                                `/icons/superpowers_shield_active.png`,
                                            }}
                                        >
                                            <Image
                                                 resizeMode="contain"
                                                 style={{
                                                     width: height*0.07,
                                                     height: height*0.07
                                                 }}
                                                 source={{
                                                   uri:
                                                     Config.IMG_URL +
                                                     `/icons/superpower/${
                                                       superpower.name
                                                     }.png`,
                                                 }}
                                            />
                                        </ImageBackground>
                                            <Text
                                                 style={{
                                                     flex: 1,
                                                    fontFamily: $robotoRegular,
                                                    fontSize: height*0.022,
                                                    fontWeight: "600",
                                                    letterSpacing: 2,
                                                    color: $black,
                                                    textAlign: "left",
                                                    textAlignVertical: "center",
                                                    marginLeft: height*0.006
                                                }}
                                            >
                                                {superpower.displayName}
                                            </Text>
                                        </View>
                                    )
                                })
                            }
                            {/* </View> */}
                            
                            {data.critical && virtues?.length && 
                                virtues?.map(virtue => {
                                    return (
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                paddingHorizontal: height*0.01,
                                                marginBottom: height*0.01,
                                                width: "48%"
                                            }}
                                        >
                                        <ImageBackground
                                            resizeMode="contain"
                                            style={{
                                                width: height*0.08,
                                                height: height*0.08,
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                            source={{
                                            uri:
                                                Config.IMG_URL +
                                                `/icons/virtues_shield_active.png`,
                                            }}
                                        >
                                            <Image
                                                 resizeMode="contain"
                                                 style={{
                                                     width: height*0.07,
                                                     height: height*0.07
                                                 }}
                                                 source={{
                                                   uri:
                                                     Config.IMG_URL +
                                                     `/icons/virtue/${
                                                        virtue.name
                                                     }.png`,
                                                 }}
                                            />
                                        </ImageBackground>
                                            <Text
                                                 style={{
                                                     flex: 1,
                                                    fontFamily: $robotoRegular,
                                                    fontSize: height*0.022,
                                                    fontWeight: "600",
                                                    letterSpacing: 2,
                                                    color: $black,
                                                    textAlign: "left",
                                                    textAlignVertical: "center",
                                                    marginLeft: height*0.006
                                                }}
                                            >
                                                {virtue.displayName}
                                            </Text>
                                        </View>
                                    )
                                })
                            }
                            {/* </View> */}
                            </View>
                        </ScrollView>
                    </View>
                    <TouchableOpacity
                        style={{
                            height: width*0.05,
                            width: width*0.13,
                            marginBottom: -height*0.01
                        }}
                        onPress={() => {
                            if(playSound){
                                playSound({
                                    sound: SOUNDS.general
                                })
                            }
                            closeModal()
                            if(data.success){
                                data.success()
                            }
                        }}
                    >
                        <Image
                            source={{
                                uri: Config.IMG_URL+"/save_earth/ok_button.png"
                            }}
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </TouchableOpacity>
                </ImageBackground>
            </View>
        </View>
    )
}

export default MissionFailedPopUp;
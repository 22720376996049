import { StyleSheet } from 'react-native';
import { $bdCartoon, $robotoRegular } from '../../../constants/fonts';
import { $white, $red } from '../../../constants/colors';

export const getSignupStyles = (width, height, isMobile) => StyleSheet.create({
  background: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  formBg: {
    height: height * 0.65,
    left: height * 0.002,
    position: 'absolute',
    top: height * 0.09,
    width: width * 0.7,
  },

  formConatiner: {
    paddingVertical: isMobile ? '8%' : "10%",
    paddingHorizontal: isMobile ? "15%" : "10%",
    width: "100%",
    height: "100%"
  },

  formConatinerBg: {
    height: '100%',
    justifyContent: 'center',
    alignItems: "center",
    width: '100%',
    top: "10%"
  },

  formInput: {
    borderColor: $white,
    borderRadius: 5,
    borderWidth: 0.7,
    marginBottom: 10,
    marginTop: 1,
    paddingVertical: 7,
    height: height * (isMobile ? -0.09 : 0.07),
    paddingHorizontal: height * 0.02,
    fontSize: height * 0.035,
    textTransform: "lowercase"
  },

  formTxt: {
    fontFamily: $robotoRegular,
    fontSize: height * 0.045,
    fontWeight: '400',
  },

  headingBg: {
    height: height * 0.4,
    position: 'absolute',
    width: width * 0.37,
    zIndex: 99,
    top: -(height * 0.14),
    left: width * 0.15
  },

  invalidForm: {
    borderColor: $red,
  },

  loadingContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 9999,
  },

  otherOpt: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },

  wrapper: {
    width: width * 0.7,
    height: height * 0.7,
    position: 'relative'
  },
  submitButton: {
    height: height * 0.1,
    width: "100%",
    backgroundColor: "rgb(33, 150, 243)",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: height * 0.008,
    shadowColor: "black",
    shadowOffset: { height: 4, width: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 10,
    elevation: 10
  },
  forgotLabel: {
    fontSize: height * 0.03,
    lineHeight: height * 0.04,
    fontFamily: $bdCartoon,
    fontWeight: "300",
    marginVertical: height * 0.01
  }
});

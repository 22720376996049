import React from 'react';
import { Image, ImageBackground, TouchableOpacity, Text, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setCityDescState, setCityContribState } from '../../actions/status';
import Config from '../../config';
import { getStyle } from './styles';

const LeftCtn = () => {
  const name = useSelector(state => state.status.slectedCity.displayName);
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height);

  const dispatch = useDispatch();

  const handleBackPress = () => {
    dispatch(setCityDescState(true));
    dispatch(setCityContribState(false));
  };

  return (
    <ImageBackground
      resizeMode="contain"
      source={{ uri: Config.IMG_URL + '/save_earth/arrow_bg.png' }}
      style={styles.rightBg}>
      <TouchableOpacity
        style={styles.rightContainer}
        onPress={() => handleBackPress()}>
        <Image
          resizeMode="contain"
          style={styles.rightImg}
          source={{
            uri: Config.IMG_URL + '/save_earth/arrow_left.png',
          }}
        />
        <Text style={styles.cityName}>{name}</Text>
      </TouchableOpacity>
    </ImageBackground>
  );
};

LeftCtn.propTypes = {
  name: PropTypes.string,
};

export default LeftCtn;

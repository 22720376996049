import React, { useCallback, useState } from 'react' 
import { Image, TextInput, TouchableOpacity, useWindowDimensions, View, Animated, Easing } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerData } from '../../actions/player';
import Config from '../../config';
import { $white } from '../../constants/colors';
import { $robotoRegular } from '../../constants/fonts';
import { sendCronosApi } from '../../services/chat';


const SendCronos = ({
    user
}) => {
    const { height } = useWindowDimensions();
    const [showInput, setShowInput] = useState(false);
    const cronos = useSelector(state => state.player.cronos);
    const [numCrono, setNumCrono] = useState("0");
    const [textAnimate] = useState(new Animated.Value(0))
    const dispatch = useDispatch()

    const sendCronos = useCallback(async () => {
        if(parseInt(numCrono) > cronos){
            alert("You don't have enough cronos to send.")
            return;
        }
        const { error } = await sendCronosApi(user.id, parseInt(numCrono));
        if(error){
            alert(error.message);
        } else {
            alert("Cronos successfully sent.")
            dispatch(getPlayerData())
        }
    },[user, numCrono, cronos, dispatch])

    return (
        <View
            style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
            }}
        >
            <Animated.View
                style={{
                    width: textAnimate.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0%', '40%'],
                      }),
                      marginRight: textAnimate.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0, 15],
                      }),
                }}
            >
                <TextInput
                    value={numCrono}
                    onChangeText={val => setNumCrono(val)}
                    returnKeyType={'next'}
                    onSubmitEditing={() => sendCronos()}
                    disableFullscreenUI={true}
                    autoCapitalize="none"
                    blurOnSubmit={false}
                    keyboardType="numeric"
                    style={{
                        borderColor: $white,
                        borderRadius: 5,
                        borderWidth: showInput ? 1 : 0,
                        paddingVertical: 7,
                        height: height*0.07,
                        paddingHorizontal: showInput ? height*0.012 : 0,
                        fontSize: height*0.037,
                        color: $white,
                        fontWeight: "600",
                        fontFamily: $robotoRegular
                    }}
                    autoCompleteType="number"
                />
            </Animated.View>
            <TouchableOpacity
                style={{
                    width: height*0.2,
                    height: height*0.07
                }}
                onPress={() => {
                    if(showInput){
                        sendCronos()
                    } else {
                        Animated.timing(textAnimate, {
                            toValue: 1,
                            duration: 500,
                            easing: Easing.linear,
                          }).start();
                          setTimeout(() => {
                              setShowInput(true)
                          },50)
                    }
                }}
            >
                <Image
                    source={{
                        uri: Config.IMG_URL+"/chat/send_cronos.png"
                    }}
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                />
            </TouchableOpacity>
        </View>
    )
}

export default SendCronos;

import React from 'react';
import {
  View,
  ImageBackground,
  Text,
  Image,
  TouchableOpacity,
  useWindowDimensions
} from 'react-native';
import PropTypes from 'prop-types';

import Config from '../../config';
import { getProgramModulesStyle } from './styles';
import { $black } from '../../constants/colors';
import { $robotoRegular } from '../../constants/fonts';

const CarouselItems = ({ item, index, currIndex, setProgramModule }) => {
  const window = useWindowDimensions();

  const styles = getProgramModulesStyle(window.width, window.height)

  const bgImage =
    Config.IMG_URL +
    `/practice_missions/${
      currIndex === index ? 'active_slide_yellow' : 'tertiary_slide_yellow'
    }.png`;

  return (
    <View key={index} style={styles.carouselItemsContainer}>
      <ImageBackground
        resizeMode="contain"
        source={{ uri: bgImage }}
        style={styles.carouselItemsBg}>
        <View
          style={{
            position: "absolute",
            top: window.height*0.038,
            right: window.height*0.09,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              paddingRight: 15,
              marginRight: 15,
              borderRightWidth: window.height*0.006,
              borderColor: $black
            }}
          >
            <Image
              resizeMode="contain"
              style={{
                width: window.height*0.05,
                height: window.height*0.05,
              }}
              source={{ uri: Config.IMG_URL + `/icons/cronos.png` }}
            />
            <Text
              style={{
                fontFamily: $robotoRegular,
                fontWeight: "600",
                fontSize: window.height*0.03,
                marginLeft: 5
              }}
            >
              {`${item?.max_cronos}`}
            </Text>
          </View>
          <View
             style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Image
              resizeMode="contain"
              style={{
                width: window.height*0.05,
                height: window.height*0.05,
              }}
              source={{ uri: Config.IMG_URL + `/icons/ep.png` }}
            />
            <Text
               style={{
                fontFamily: $robotoRegular,
                fontWeight: "600",
                fontSize: window.height*0.03,
                marginLeft: 5
              }}
            >
              {`${item?.max_xp}`}
            </Text>
          </View>
        </View>
        <View style={styles.carouselItems}>
          <Text numberOfLines={4} style={[styles.carouselText, {
        fontSize: window?.height*0.065 - Math.min(Math.max((item?.name?.length - 30)*0.2, 0), window?.height*0.042)
      }]}>{item.name}</Text>
          <Text numberOfLines={4} style={styles.carouselDescription}>{item.description}</Text>
          <TouchableOpacity
            style={styles.carouselItemsBtnCtn}
            onPress={() => setProgramModule(item)}>
            <Image
              source={{
                uri:
                  Config.IMG_URL +
                  `/practice_missions/${
                    item.transaction.isCompleted ? 'review' : 'begin'
                  }.png`,
              }}
              style={styles.carouselItemsBtn}
            />
          </TouchableOpacity>
        </View>
      </ImageBackground>
    </View>
  );
};

CarouselItems.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  currIndex: PropTypes.number,
  setProgramModule: PropTypes.func,
};

export default React.memo(CarouselItems);

import { useEffect, useLayoutEffect, useState } from "react"


const useMediaQuery = () => {
    const [mediaData, setMediaData] = useState({
        isMobile: true,
        isDesktop: false,
    })

    useLayoutEffect(() => {
        const isMobile = window.outerWidth < 996;

        setMediaData({
            isDesktop: !isMobile,
            isMobile: isMobile
        })
    }, [])

    useEffect(() => {
        const handler = (event) => {
            const isMobile = window.outerWidth < 996;

            setMediaData({
                isDesktop: !isMobile,
                isMobile: isMobile
            })
        }
        window.addEventListener("resize", handler);

        return () => [
            window.removeEventListener("resize", handler)
        ]
    }, [])


    return mediaData;
}

export default useMediaQuery;
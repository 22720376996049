import React from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import List from './list';

import { getBadgeStyles } from './styles';

const Badges = ({ type, data }) => {
  const window = useWindowDimensions()

  const styles = getBadgeStyles(window.width, window.height);

  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </Text>
      <List type={type} data={data} />
    </View>
  );
};

Badges.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Badges;

export const $gray = '#5f5f5f';
export const $darkGray = '#4d4d4d';
export const $lightGray = '#9b9b9b';
export const $white = '#ffffff';
export const $blue = '#5CB6F2';
export const $black = '#000000';
export const $green = '#6DD0A3';
export const $darkGreen = '#0b9366';
export const $red = '#d1383d';
export const $yellow = '#ffc247';
export const $yellowShadow = '#ffc247';
export const $liteYellow = '#fffbcd';
export const $darkYellow = '#e7cd20';
export const $purple = '#b898ff';
export const $darkPurple = "#625172";
export const $brown = "#792827";
export const $orange = "#ce8338";
export const $lightPurple = "#8f8f8f";
export const $darkBlack = "#272415";

export const $overlay = 'rgba(0, 0, 0, 0.4)';

export const $transparent = 'transparent';

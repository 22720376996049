import React from 'react';
import { View, Text, Image, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
// import { setSettingState } from '../../../actions/status';

import ChatProfileDetailPopup from '../../../components/chatProfileDetailPopup';
import { useModal } from '../../../components/ModalProvider';
import { useSFX } from '../../../components/SFXConfig';
import Config from '../../../config';
import { SOUNDS } from '../../../constants/sfx';
import Badges from './badges';
// import Logs from './logs';

import { getLeftPanelStyles } from './styles';

const LeftPanel = ({ navigation }) => {
  const { playSound } = useSFX();
  const player = useSelector(state => state.player);
  const playerName = player.name;
  const { openModal } = useModal();
  const window = useWindowDimensions()
  // const dispatch = useDispatch();
  const styles = getLeftPanelStyles(window.width, window.height);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => {
        // dispatch(setSettingState(true));
        if(playSound){
          playSound({
            sound: SOUNDS.settingButton
          })
        }
         openModal({
          open: true,
          component: ChatProfileDetailPopup,
          data: {
            fromDashboard: true,
            user: {
              name: playerName,
              id: player.id,
              image: Config.IMG_URL + `/characters/${player.profile.characterName}_civilian.png`
            }
          },
          blur: true
        })
      }}>
        <Text style={styles.dashboardTitle}>Dashboard</Text>
        <Image
          resizeMode={'center'}
          style={styles.settings}
          source={{ uri: Config.IMG_URL + '/icons/settings.png' }}
        />
        <Text style={styles.playerTitle}>{playerName}</Text>
      </TouchableOpacity>
      <Badges type={'superpowers'} label={"equipment"} />
      <Badges type={'virtues'} label={"virtues"} />
      {/* <Logs /> */}
    </View>
  );
};

export default LeftPanel;

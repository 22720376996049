import React, { useState } from 'react';
import { View, Text, TextInput, Keyboard, useWindowDimensions, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

import { getSignupStyles } from './styles';
import { $bdCartoon } from '../../../constants/fonts';

const Step2 = ({ sumbit }) => {
  const [password, setPassword] = useState('');
  const [passwordCnf, setPasswordCnf] = useState('');
  const [validForm, setValidForm] = useState(true);
  const window = useWindowDimensions()

  const styles = getSignupStyles(window.width, window.height);

  const handleSubmit = () => {
    Keyboard.dismiss();
    if (password && passwordCnf) {
      if (password !== passwordCnf) {
        alert("password didn't match");
        return;
      }
      sumbit({ password, step: 2 });
      setValidForm(true);
    } else {
      alert('name and email is required');
      setValidForm(false);
    }
  };

  return (
    <View style={styles.formConatiner}>
      <Text style={styles.formTxt}>Password</Text>
      <TextInput
        value={password}
        onSubmitEditing={Keyboard.dismiss}
        onChangeText={password => setPassword(password)}
        disableFullscreenUI={true}
        secureTextEntry={true}
        style={[styles.formInput, validForm ? '' : styles.invalidForm]}
      />
      <Text style={styles.formTxt}>Confirm Password</Text>
      <TextInput
        onSubmitEditing={handleSubmit}
        value={passwordCnf}
        onChangeText={passwordCnf => setPasswordCnf(passwordCnf)}
        returnKeyType={'next'}
        disableFullscreenUI={true}
        secureTextEntry={true}
        style={[styles.formInput, validForm ? '' : styles.invalidForm]}
      />
      <View>

      </View>
      <TouchableOpacity
        style={[styles.submitButton, { marginTop: 10 }]}
        onPress={handleSubmit}
      >
          <Text
            style={[styles.formTxt, { color: "white", fontFamily: $bdCartoon, letterSpacing: 4 }]}
          >
            Signup
          </Text>
      </TouchableOpacity>
    </View>
  );
};

Step2.propTypes = {
  sumbit: PropTypes.func,
};

export default Step2;

import React from 'react';
import { View, Image } from 'react-native';
import { useSelector } from 'react-redux';

import { AvatarStyles as styles } from './styles';
import Config from './../../config';

const Avatar = () => {
  const characterName = useSelector(
    state => state.player.profile.characterName,
  );
  // const current_mission = useSelector(state => state.player?.current_mission?.data || {})
  // const contributions = useSelector(state => state?.cities?.contributions)
  // const activeSuperPowerName = useMemo(() => {
  //   if(current_mission?.id && contributions[current_mission?.id]){
  //     return contributions[current_mission?.id]?.superpowers?.[0]?.name === "courage" ? "hero" : contributions[current_mission?.id]?.superpowers?.[0]?.name;
  //   } else {
  //     return "civilian";
  //   }
  // },[current_mission, contributions])


  return (
    <View style={styles.wrapper}>
      <Image
        style={styles.avatar}
        resizeMode="contain"
        source={{
          uri: Config.IMG_URL + `/characters/${characterName}_${/*activeSuperPowerName*/"spacehero" || "civilian"}.png`,
        }}
      />
    </View>
  );
};

export default Avatar;

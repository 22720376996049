import createReducer from '../lib/createReducer';
import {
  SET_PROGRAM_MODULES,
  SET_PROGRAM_MODULES_QUESTIONS,
  LOGOUT,
  LEAVE_GAME,
} from '../constants/actions';

/**
 * sorted Data(array of objects)
 * {
 *    id
 *    type: image|video|question
 * }
 *
 * images objects
 * [id]{
 *    id
 *    type: image
 *    url
 *    thumbUrl
 *    standardUrl
 *    sort
 * }
 *
 * questions objects
 * [id]:{
 *    id
 *    type: question
 *    statement
 *    options: [
 *        id
 *        answer
 *        isCorrect
 *    ]
 *    transaction: {
 *        isCorrent
 *        answeId
 *    }
 *    sort
 * }
 *
 * video object
 * {
 *    id
 *    url
 *    provider
 * }
 */

const initialState = {
  mediaType: '',
  sortedData: [],
  images: {},
  questions: {},
  videos: {},
};

export const programModules = createReducer(initialState, {
  [SET_PROGRAM_MODULES](state, { payload: { data } }) {
    return { ...state, ...data };
  },
  [SET_PROGRAM_MODULES_QUESTIONS](state, { payload: { data } }) {
    return { ...state, questions: { ...data } };
  },
  [LEAVE_GAME](state, { payload }) {
    return initialState;
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

import React, { useCallback, useEffect } from 'react';
import { View, Image, useWindowDimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import CityDescription from '../cityDescription';
import CityContribution from '../cityContribution';
import { saveEarthHelpMessage } from '../../constants/ftliHelp';
import { setRebeccaState, setHelpMessage } from '../../actions/status';
import WorldMap from './worldMap'

import { getStyles } from './styles';
import Config from '../../config';
import { FTLI_HELP_AUDIO, SAVE_EARTH_HELP_AUDIO, SAVE_EARTH_HELP_MESSAGE } from '../../constants/helpMessages';
import Info from '../abilities/info';
import { getDisabledCities } from '../../actions/cities';
import UpcomingCity from './upcomingcity';

const SaveEarthScreen = ({ navigation }) => {
  const levelId = useSelector(state => state.player.level.id);
  const nav = useSelector(state => state.status.nav);
  const window = useWindowDimensions();

  const dispatch = useDispatch();
  const styles = getStyles(window.width, window.height)

  const getDisabledCityData = useCallback(async () => {
    dispatch(getDisabledCities());
  }, [dispatch])

  useEffect(() => {
    if(nav === "SaveEarth")
    if (levelId === 1) {
      dispatch(setHelpMessage(saveEarthHelpMessage, FTLI_HELP_AUDIO[2]));//3
      dispatch(setRebeccaState(true));
    } else {
      getDisabledCityData();
      dispatch(setHelpMessage(SAVE_EARTH_HELP_MESSAGE, SAVE_EARTH_HELP_AUDIO));
      dispatch(setRebeccaState(false));
    }
  }, [levelId, dispatch, nav, getDisabledCityData]);

  return (
    <>
      <View style={styles.container}>
        <Image
          source={{
            uri: Config.IMG_URL+"/save_earth/space_bg.png", cache: "reload"
          }}
          style={styles.bg}
        />
        <View
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <WorldMap />
        </View>
      </View>
      <Info top={1} left={-window.height*0.05} crono />
      <CityDescription />
      <CityContribution navigation={navigation} />
      <UpcomingCity />
    </>
  );
};

export default SaveEarthScreen;

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { StatusBar } from 'react-native';
import configureStore from './stores/configureStore';
import Navigation from './navigation';
import ModalProvider from './components/ModalProvider';
import SFXConfig from './components/SFXConfig';
import HelpSoundConfig from './components/HelpSoundConfig';

const { persistor, store } = configureStore();

// This code is to mute the sound if app is in inactive state. only for android
function mute(muted) {
  document.querySelectorAll('video, audio').forEach(audio => {
    audio.muted = muted;
  });
}

document.addEventListener('message', (e) => {
  if(e.data === 'mute') {
    mute(true);
  }
  else if(e.data === 'unmute') {
    mute(false);
  }
});

const App = () => {
  useEffect(() => {
    StatusBar.setHidden(true);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <HelpSoundConfig>
          <SFXConfig>
            <ModalProvider>
                <Navigation />
            </ModalProvider>
          </SFXConfig>
          </HelpSoundConfig>
      </PersistGate>
    </Provider>
  );
};

export default App;

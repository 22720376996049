import React from 'react';
import { TouchableOpacity, Image, useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';

import { setRedailBtnState } from '../../actions/status';
import Config from '../../config';

import { getStyle } from './../styles';

const BackBtn = () => {
  const dispatch = useDispatch();
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height)

  return (
    <TouchableOpacity
      onPress={() => dispatch(setRedailBtnState(false))}
      style={styles.backBtn}>
      <Image
        style={styles.full}
        resizeMode="contain"
        source={{
          uri: Config.IMG_URL + '/nav/back.png',
        }}
      />
    </TouchableOpacity>
  );
};

export default BackBtn;

import React, { useEffect, useState } from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { $robotoRegular } from '../constants/fonts';
import { $yellow } from '../constants/colors';
import { updateSession } from '../actions/player';
import {
  setLoadingState,
  setRebeccaState,
  setHelpMessage,
} from '../actions/status';
import { FTLI_HELP_AUDIO } from '../constants/helpMessages';

const data = [
  `This is your personal Dashboard. You’ll be able to see your avatar transform every time you are on a mission on any Planet you travel to.${'\n\n'}The top right corner displays your Cronos (money for travel), EP (Effort Points used to activate abilities), and XP (Experience Points determines your ranking) you earn.`,
  `Below that is your leaderboard where you'll be able to see how you and your teams are performing. ${'\n\n'}Click on the leaderboard and then the "?" button (on the top right) to learn more about how scoring works.`,
  `Below that, you'll notice a countdown of the time you have left before you must complete the available missions.${'\n\n'}Hang on, I'm almost done showing you around.`,
  `On the left you'll notice your name and the settings button. ${'\n\n'}Here you can change your display name, sound or music preferences, and leave or log out of the system.`,
  `Below that are the Equipment and Virtues you’ve already activated. ${'\n\n'}Remember, activating these abilities are essential for you to progress and win.`,
  //`Below that, is your log: which will show you what your friends are up to through a recent activity list.`,
  `I think that's all for now. You’re a fast learner! ${'\n\n'}Oh one last thing! You need to report now for lift off to Xandar. Your other teammates and I are already there waiting for you. Don’t be late!${'\n\n'}CLICK "FINISH". THEN OPEN THE MENU BY CLICKING THE LOGO ON THE BOTTOM LEFT AND CLICK 'SAVE EARTH' TO REPORT.`,
];

// 14, 15, 16, 17, 18, 19 help audio

const DashboardHelp = () => {
  const [index, setIndex] = useState(0);
  const playerId = useSelector(state => state.player.id);
  const dispatch = useDispatch();

  const handlePress = async () => {
    if (index === data.length - 1) {
      dispatch(setLoadingState(true));
      dispatch(setHelpMessage(''));
      dispatch(setRebeccaState(false));
      await dispatch(
        updateSession({ id: playerId, stage: 'completed', level_id: '1' }),
      );
      dispatch(setLoadingState(false));
      return;
    }
    setIndex(index + 1);
  };

  useEffect(() => {
    dispatch(setHelpMessage(null, FTLI_HELP_AUDIO[index+13]))
  }, [index, dispatch])

  return (
    <>
      <View style={styles.container}>
        <ScrollView>
          <Text style={styles.txt}>{data[index]}</Text>
        </ScrollView>
      </View>
      <TouchableOpacity style={styles.btn} onPress={() => handlePress()}>
        <Text>{index === data.length - 1 ? 'Finish' : 'Next'}</Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    marginLeft: 10,
    marginTop: 10,
    position: 'relative',
    top: -5,
    width: 180,
  },

  container: {
    height: '74%',
    paddingHorizontal: 10,
  },

  txt: {
    fontFamily: $robotoRegular,
    fontSize: 16,
    marginRight: 5,
    marginTop: 10,
    fontWeight: '400',
  },
});

export default DashboardHelp;

const Config = {
  API_URL: process.env.REACT_APP_API_URL,
  WEB_SOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL,
  IMG_URL: process.env.REACT_APP_IMG_URL,
  VID_URL: process.env.REACT_APP_VID_URL,
  BRANCH_KEY: process.env.REACT_APP_BRANCH_KEY,
  GETSTREAM_APP_ID: process.env.REACT_APP_GETSTREAM_APP_ID
};

export default Config;

import React from 'react';
import { TouchableOpacity, Image, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { NavBtnStyles as styles } from './styles';
import { setRedailBtnState } from '../actions/status';
import Config from '../config';
import { useSFX } from '../components/SFXConfig';
import { SOUNDS } from '../constants/sfx';

const NavBtn = () => {
  const { playSound } = useSFX();
  const isRedailBtnOpen = useSelector(state => state.status.isRedailBtnOpen);
  const dispatch = useDispatch();
  const window = useWindowDimensions()

  return (
    <TouchableOpacity
      style={[styles.btn, {
        height: window.height * 0.18,
        width: window.height * 0.19,
        left: -(window.height * 0.025),
        zIndex: isRedailBtnOpen ? 0 : 1
      }]}
      onPress={() => {
        if(playSound){
          playSound({
            sound: SOUNDS.menuOpen
          })
        }
        dispatch(setRedailBtnState(true))
      }}>
      <Image
        style={styles.full}
        resizeMode="contain"
        source={{
          uri: Config.IMG_URL + '/icons/navigation.png',
        }}
      />
    </TouchableOpacity>
  );
};

export default NavBtn;

import { StyleSheet } from 'react-native';
import { $bdCartoon, $robotoRegular } from '../../constants/fonts';
import { $yellow, $black } from '../../constants/colors';

export const getProgramStyle = (width, height) => StyleSheet.create({
  carouselBeginButton: {
    height: '100%',
    resizeMode: 'contain',
    width: width * 0.18,
  },
  carouselCustomStyle: {
    marginTop: 12,
  },
  carouselDescription: {
    color: $black,
    fontFamily: $robotoRegular,
    fontSize: 20,
    paddingHorizontal: 15,
    textAlign: 'center',
    fontWeight: '400',
    width: "85%"
  },
  carouselItems: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginBottom: '15%',
    padding: height*0.008,
    width: width*0.32
  },
  carouselItemsBtn: {
    bottom: height*0.01,
    height: height*0.23,
    position: 'absolute',
  },
  carouselItemsContainer: {
    alignItems: 'center',
    height: '90%',
    position: 'relative',
  },
  carouselNumber: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.18,
    fontWeight: '600',
    textShadowColor: $black,
    textShadowOffset: { width: 5, height: 5 },
    textShadowRadius: height*0.012,
  },
  carouselText: {
    color: $black,
    fontFamily: $robotoRegular,
    fontWeight: '900',
    fontSize: height*0.075,
    paddingHorizontal: 22,
    textAlign: 'center',
    width: "86%"
  },
  carouselView: {
    height: '93%',
    width: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
  },
  headerText: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.14,
    marginTop: height*0.01,
    textAlign: 'center',
    textShadowColor: $black,
    textShadowOffset: { width: 5, height: 5 },
    textShadowRadius: height*0.01,
  },
});

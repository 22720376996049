import createReducer from '../lib/createReducer';
import {
  CITIES,
  CITY_REQUIREMENTS,
  CITY_CONTRIBUTIONS,
  SET_CITY_CONTRIBUTION,
  LOGOUT,
  LEAVE_GAME,
  REMOVE_CITY_COMPLETED_OR_FAILED_DATA,
  ADD_CITY_COMPLETED_OR_FAILED_DATA,
  UPDATE_CITY_MISSION_ATTRIBUTES,
  CLEAR_RELEASED_MISSIONS,
  SAVE_UPCOMING_CITY,
  TOGGLE_UPCOMING_CITY_BANNER
} from '../constants/actions';

/**
 * array of objects ( cities details )
 * data: [{
 *   id
 *   name
 *   displayName
 *   description
 *   latitude
 *   longitude
 *   state
 *   percentCompletion
 *   isInactive
 *   isActive
 *   isCompleted
 *   isFailed
 *   isTutorial
 *   initiatorId
 *   duration
 *   startedAt
 *   endedAt
 *   isEnabled
 *   isBossFight
 * }],
 *
 * object of city requirements
 * requirements: {
 *  [cityId]: {
 *    fare: int
 *    virtues: [
 *      {
 *        name,
 *        count
 *      }
 *    ]
 *    superpowers: [
 *      {
 *        name,
 *        count
 *      }
 *    ]
 *  }
 * }
 *
 * object of city contributions
 * contributions: {
 *  [cityId]: {
 *   virtues: {
 *     id
 *     name
 *     playerId
 *     cityMissionId
 *     createdAt
 *   }
 *   superpowers: {
 *     id
 *     name
 *     playerId
 *     cityMissionId
 *     createdAt
 *   }
 *  }
 * }
 */

const initialState = {
  data: [],
  requirements: {},
  contributions: {},
  completed_failed_missions: [],
  released_missions: [],
  upcomingCity: null,
  showUpcomingBanner: false
};

export const cities = createReducer(initialState, {
  [CITIES](state, { payload: { data } }) {
    return { ...state, data: [...data] };
  },
  [CLEAR_RELEASED_MISSIONS](state) {
    return { ...state, released_missions: [] };
  },
  [TOGGLE_UPCOMING_CITY_BANNER](state, {payload}) {
    return { ...state, showUpcomingBanner: payload };
  },
  [SAVE_UPCOMING_CITY](state, { payload }) {
    return { ...state, upcomingCity: payload };
  },
  [UPDATE_CITY_MISSION_ATTRIBUTES](state, { payload: { data } }) {
    let cityData = state?.data?.find(city => city.id === data?.id);
    if(cityData){
      cityData = {
        ...cityData,
        ...data
      }
    } else {
      return {
        ...state,
        released_missions: [
          ...state?.released_missions,
          data
        ]
      }
    }
    return { ...state, data: [...state?.data?.filter(city => city?.id !== data?.id), cityData] };
  },
  [CITY_REQUIREMENTS](state, { payload: { data } }) {
    return { ...state, requirements: { ...data } };
  },
  [CITY_CONTRIBUTIONS](state, { payload: { data } }) {
    return { ...state, contributions: { ...data } };
  },
  [ADD_CITY_COMPLETED_OR_FAILED_DATA](state, { payload: { data } }) {
    return { ...state, completed_failed_missions: [...state.completed_failed_missions, data] };
  },
  [REMOVE_CITY_COMPLETED_OR_FAILED_DATA](state, { payload: { data } }) {
    return { ...state, completed_failed_missions: state.completed_failed_missions.filter(city => city.id !== data.id) };
  },
  [SET_CITY_CONTRIBUTION](state, { payload: { data } }) {
    return {
      ...state,
      contributions: {
        [data.id]: {
          superpowers: [...data.superpowers],
          virtues: [...data.virtues],
        },
      },
    };
  },
  [LEAVE_GAME](state, { payload }) {
    return initialState;
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

import MissionStartPopUp from '../components/missionStartPopup';
import {
  CITIES,
  CITY_REQUIREMENTS,
  CITY_CONTRIBUTIONS,
  SET_CITY_CONTRIBUTION,
  UPDATE_SELECTED_CITY,
  ADD_CITY_COMPLETED_OR_FAILED_DATA,
  HIDE_MISSION_POPUP,
  SHOW_MISSION_POPUP,
  UPDATE_CITY_MISSION_ATTRIBUTES,
  SAVE_UPCOMING_CITY,
  TOGGLE_UPCOMING_CITY_BANNER
} from '../constants/actions';
import { setCurrentMission } from '../services/player';
import {
  getCityMissions,
  board,
  dismantle,
  retry,
  getPendingMissionNotification,
  support,
  getDisabledCity,
} from '../services/saveEarth';
import { getPlayerData } from './player';

export const SHOW_MAX_RELEASED_PLANET = 12;

export const getDisabledCities = () => async (dispatch) => {
  try {
    const { data } = await getDisabledCity();
    if (data?.data) {
      let upcomingCities = data?.data?.filter(city => {
        return city?.attributes?.scheduled_release_at
      })
      if (upcomingCities?.length) {
        upcomingCities = upcomingCities.sort((cityA, cityB) => {
          return new Date(cityA.attributes.scheduled_release_at).getTime() - new Date(cityB?.attributes.scheduled_release_at);
        })

        dispatch(
          {
            type: SAVE_UPCOMING_CITY,
            payload: {
              id: upcomingCities[0]?.id,
              name: upcomingCities[0]?.attributes?.name,
              scheduled_release_at: upcomingCities[0]?.attributes?.scheduled_release_at
            }
          })
        return {
          id: upcomingCities[0]?.id,
          name: upcomingCities[0]?.attributes?.name,
          scheduled_release_at: upcomingCities[0]?.attributes?.scheduled_release_at
        };
      }
    }
    dispatch({
      type: SAVE_UPCOMING_CITY,
      payload: null
    })
    return null;
  } catch (error) {
    return {
      error
    }
  }
}

export const getCities = () => async (dispatch, getState) => {
  try {
    const { data } = await getCityMissions().catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    let cities = [];
    let requirements = {};
    let contributions = {};
    let newReleasedCities = [];
    let releasedRequirements = {};
    let releasedContributions = {};
    let prevContributions = getState()?.cities?.contributions;
    const currentMissionId = getState()?.player?.currentMissionId;
    const playerId = getState()?.player?.id;
    let nav = getState()?.status?.nav;

    data.data.forEach(item => {
      let isNewReleased = item?.attributes?.scheduled_release_at && item?.attributes?.retry_count === 0 &&
        (!item.relationships.players.data?.find((p) => p.id === playerId) || (item?.attributes?.state === "inactive" && item?.attributes?.initiator_id === null));
      let currentCities = cities;
      let currentRequirement = requirements;
      let currentContribution = contributions;
      if (isNewReleased) {
        currentCities = newReleasedCities;
        currentContribution = releasedContributions;
        currentRequirement = releasedRequirements;
      }
      currentCities.push({
        id: item.id,
        name: item.attributes.name,
        displayName: item.attributes.display_name,
        description: item.attributes.description,
        latitude: item.attributes.latitude,
        longitude: item.attributes.longitude,
        state: item.attributes.state,
        percentCompletion: item.attributes.percent_completion,
        isInactive: item.attributes.is_inactive,
        isActive: item.attributes.is_active,
        isCompleted: item.attributes.is_completed,
        isFailed: item.attributes.is_failed,
        isTutorial: item.attributes.is_tutorial,
        initiatorId: item.attributes.initiator_id,
        duration: item.attributes.duration,
        videoUrl: item.attributes?.start_video_url,
        endVideoUrl: item.attributes?.end_video_url,
        crono_reward: item.attributes?.crono_reward,
        critical: item?.attributes?.revert_contribution_on_failure,
        ep_reward: item.attributes?.ep_reward,
        startedAt: item.attributes.started_at,
        endedAt: item.attributes.ended_at,
        isEnabled: item.attributes.is_enabled,
        isBossFight: item.attributes.is_boss_fight,
        playerNeededCount: item.attributes.requirements.virtues?.[0].count,
        players: item.relationships.players.data.map(p => parseInt(p.id)),
        youAreHere: Boolean(item.id.toString() === currentMissionId?.toString()),
        scheduled_release_at: item?.attributes?.scheduled_release_at
      });
      // if(item.attributes.started_at){
      //   if(!lastStartedDate || new Date(item.attributes.started_at)?.getTime() > new Date(lastStartedDate).getTime()) {
      //     youAreHereCityId = item.id;
      //     lastStartedDate = item.attributes.started_at;
      //   } 
      // }
      currentRequirement[item.id] = {
        fare: item.attributes.requirements.fare,
        virtues: item.attributes.requirements.virtues,
        superpowers: item.attributes.requirements.superpowers,
      };
      // eslint-disable-next-line camelcase
      let virtues = item.attributes?.virtue_contributions.data.map(virtue => ({
        id: virtue.id,
        name: virtue.attributes.name,
        playerId: virtue.attributes.player_id,
        cityMissionId: virtue.attributes.city_mission_id,
        createdAt: virtue.attributes.created_at,
      }));
      // eslint-disable-next-line camelcase
      let superpowers = item.attributes?.superpower_contributions.data.map(
        superpower => ({
          id: superpower.id,
          name: superpower.attributes.name,
          playerId: superpower.attributes.player_id,
          cityMissionId: superpower.attributes.city_mission_id,
          createdAt: superpower.attributes.created_at,
        }),
      );
      if (prevContributions?.[item.id] && nav === "SaveEarth")
        prevContributions[item.id].superpowers.forEach(superpower => {
          if (!superpower?.id && superpower?.name && !superpowers?.find(sup => sup.name === superpower.name)) {
            superpowers.push({
              name: superpower.name,
              playerId: superpower.playerId
            })
          }
        })
      if (prevContributions?.[item.id] && nav === "SaveEarth")
        prevContributions[item.id].virtues.forEach(virtue => {
          if (!virtue?.id && virtue?.name && !virtues?.find(vir => vir.name === virtue.name)) {
            virtues.push({
              name: virtue.name,
              playerId: virtue.playerId
            })
          }
        })
      currentContribution[item.id] = {
        virtues,
        superpowers,
      };
    });

    if (newReleasedCities?.length) {
      newReleasedCities = newReleasedCities?.sort((cityA, cityB) => {
        return new Date(cityA.scheduled_release_at).getTime() - new Date(cityB?.scheduled_release_at);
      })
      if (newReleasedCities?.length >= SHOW_MAX_RELEASED_PLANET) {
        dispatch({
          type: TOGGLE_UPCOMING_CITY_BANNER,
          payload: false
        })
      } else {
        dispatch({
          type: TOGGLE_UPCOMING_CITY_BANNER,
          payload: true
        })
      }
      const length = newReleasedCities?.length < SHOW_MAX_RELEASED_PLANET ? newReleasedCities?.length : SHOW_MAX_RELEASED_PLANET;
      for (let i = 0; i < length; i++) {
        cities?.push(newReleasedCities[i])
        requirements[newReleasedCities[i]?.id] = {
          ...releasedRequirements[newReleasedCities[i]?.id]
        }
        contributions[newReleasedCities[i]?.id] = {
          ...releasedContributions[newReleasedCities[i]?.id]
        }
      }
    } else {
      dispatch({
        type: TOGGLE_UPCOMING_CITY_BANNER,
        payload: true
      })
    }

    dispatch({
      type: CITIES,
      payload: { data: cities },
    });
    dispatch({
      type: CITY_REQUIREMENTS,
      payload: { data: requirements },
    });
    dispatch({
      type: CITY_CONTRIBUTIONS,
      payload: { data: contributions },
    });

    return { data: { cities, requirements, contributions } };
  } catch (error) {
    return { error };
  }
};

export const setCityContribution = ({ id, type, name, playerId }) => (
  dispatch,
  getState,
) => {
  try {
    const state = getState();
    const updatedContribution = state.cities.contributions[id];
    updatedContribution[type].push({ name, playerId });
    dispatch({
      type: SET_CITY_CONTRIBUTION,
      payload: {
        data: {
          id,
          superpowers: updatedContribution.superpowers,
          virtues: updatedContribution.virtues,
        },
      },
    });
  } catch (error) {
    return { error };
  }
};

export const retryCity = id => async (dispatch, getState) => {
  try {
    const { data } = await retry(id).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    await dispatch(getCities());
    const state = getState();
    const updatedSelectedCity = state.status.slectedCity;
    updatedSelectedCity.state = 'inactive';
    updatedSelectedCity.players = [];
    updatedSelectedCity.percentCompletion = 0;
    updatedSelectedCity.startedAt = null;
    dispatch({
      type: UPDATE_SELECTED_CITY,
      payload: updatedSelectedCity,
    });
    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const boardCity = (param, openModal) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HIDE_MISSION_POPUP
    })
    const { data } = await board(param).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    const state = getState();
    const selectedCity = state.status.slectedCity;
    await setCurrentMission(state?.player?.id, param.id);
    dispatch(getPlayerData());
    if (!selectedCity.startedAt) {
      await dispatch(dismantleCity(param.id, param.playerId, openModal));
      return { data };
    }
    const updatedSelectedCity = state.status.slectedCity;
    updatedSelectedCity.players.push(parseInt(param.playerId));
    dispatch({
      type: UPDATE_SELECTED_CITY,
      payload: { ...updatedSelectedCity },
    });
    dispatch({
      type: UPDATE_CITY_MISSION_ATTRIBUTES,
      payload: {
        data: { ...updatedSelectedCity }
      },
    });
    const requirements = state.cities.requirements[updatedSelectedCity.id];
    const lapseTime = updatedSelectedCity?.startedAt ? (new Date().getTime() - new Date(updatedSelectedCity?.startedAt).getTime()) : 0;
    let duration = parseInt(selectedCity?.duration - Math.floor(lapseTime / 1000));
    if (duration < 1) {
      duration = 1;
    }
    const day = parseInt(duration / (60 * 60 * 24));
    const hour = parseInt((duration - (day * 60 * 60 * 24)) / (60 * 60));
    const minutes = parseInt((duration - (day * 60 * 60 * 24) - (hour * 60 * 60)) / (60));
    if (openModal)
      openModal({
        open: true,
        component: MissionStartPopUp,
        blur: true,
        data: {
          cityName: selectedCity?.name,
          playerRequired: requirements.superpowers?.[0]?.count - updatedSelectedCity.players.length,
          cityDismantleTime: `${day} Days, ${hour} Hours, ${minutes} Minutes`
        }
      })
    return { data };
  } catch (error) {
    console.log(error);
    dispatch({
      type: SHOW_MISSION_POPUP
    })
    return { error };
  }
};
export const supportCity = (param, openModal) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HIDE_MISSION_POPUP
    })
    const { data } = await support(param).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    const state = getState();
    const selectedCity = state.status.slectedCity;
    await setCurrentMission(state?.player?.id, param.id);
    dispatch(getPlayerData());
    const updatedSelectedCity = state.status.slectedCity;
    updatedSelectedCity.players.push(parseInt(param.playerId));
    dispatch({
      type: UPDATE_SELECTED_CITY,
      payload: { ...updatedSelectedCity },
    });
    dispatch({
      type: UPDATE_CITY_MISSION_ATTRIBUTES,
      payload: {
        data: { ...updatedSelectedCity }
      },
    });
    // const requirements = state.cities.requirements[updatedSelectedCity.id];
    if (openModal)
      openModal({
        open: true,
        component: MissionStartPopUp,
        blur: true,
        data: {
          cityName: selectedCity?.name,
          playerRequired: 0,
          cityDismantleTime: `${0} Days, ${0} Hours, ${0} Minutes`
        }
      })
    return { data };
  } catch (error) {
    dispatch({
      type: SHOW_MISSION_POPUP
    })
    console.log(error);
    return { error };
  }
};

export const dismantleCity = (cityId, playerId, openModal) => async (
  dispatch,
  getState,
) => {
  try {
    const { data } = await dismantle(cityId).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    const state = getState();
    const updatedSelectedCity = state.status.slectedCity;
    updatedSelectedCity.startedAt = new Date();
    updatedSelectedCity.players.push(playerId);
    const requirements = state.cities.requirements[updatedSelectedCity.id];
    const lapseTime = updatedSelectedCity?.startedAt ? (new Date().getTime() - new Date(updatedSelectedCity?.startedAt).getTime()) : 0;
    let duration = parseInt(updatedSelectedCity?.duration - Math.floor(lapseTime / 1000));
    if (duration < 1) {
      duration = 1;
    }
    const day = parseInt(duration / (60 * 60 * 24));
    const hour = parseInt((duration - (day * 60 * 60 * 24)) / (60 * 60));
    const minutes = parseInt((duration - (day * 60 * 60 * 24) - (hour * 60 * 60)) / (60));
    if (openModal)
      openModal({
        open: true,
        component: MissionStartPopUp,
        blur: true,
        data: {
          cityName: updatedSelectedCity?.name,
          playerRequired: requirements.superpowers?.[0]?.count - updatedSelectedCity.players.length,
          cityDismantleTime: `${day} Days, ${hour} Hours, ${minutes} Minutes`
        }
      })
    dispatch({
      type: UPDATE_SELECTED_CITY,
      payload: updatedSelectedCity,
    });
    dispatch({
      type: UPDATE_CITY_MISSION_ATTRIBUTES,
      payload: {
        data: { ...updatedSelectedCity }
      },
    });
    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const fetchPendingMissionNotification = (playerId) => async (
  dispatch,
) => {
  try {
    const { data } = await getPendingMissionNotification(playerId).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    data?.mission_ids?.forEach(missionId => {
      if (missionId) {
        dispatch({
          type: ADD_CITY_COMPLETED_OR_FAILED_DATA,
          payload: {
            data: {
              id: missionId
            }
          }
        })
      }
    })
    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

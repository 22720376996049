import React from 'react';
import { View, Image } from 'react-native';
import Config from '../../../../config';

const Seprator = ({
  styles
}) => (
  <View style={styles.seperator}>
    <Image
      style={styles.seperatorImg}
      resizeMode={'center'}
      source={{ uri: Config.IMG_URL + '/dashboard/seperator.png' }}
    />
  </View>
);

export default Seprator;

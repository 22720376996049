import React from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import { $black } from '../../constants/colors';
import { $robotoRegular } from '../../constants/fonts';
import Stats from '../dashboard/rightPanel/stats';

const PractiseMissionCompleteMessage = ({
  values
}) => {
  const { height } = useWindowDimensions();

  return (
    <View style={{
        width: "100%",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: height*0.03
    }}>
      <Text
        style={{
            fontFamily: $robotoRegular,
            fontSize: height*0.03,
            fontWeight: "600",
            letterSpacing: 2,
            color: $black,
            width: "100%",
            paddingHorizontal: 15,
            textAlign: "center",
            marginTop: height*0.01,
            textAlignVertical: "center",
        }}
      >
          You Earned: 
      </Text>
      <Stats  hideLeaderboard values={values} />
    </View>
  );
};

export default PractiseMissionCompleteMessage;

import React, { useEffect, useState } from 'react'
import { Image, ImageBackground, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { useDispatch } from 'react-redux';
import { setCityContribState, setCityDescState } from '../actions/status';
import Config from '../config';
import { SHOW_MISSION_POPUP } from '../constants/actions';
import { $black, $blue, $white } from '../constants/colors';
import { $bdCartoon, $robotoRegular } from '../constants/fonts';
import { SOUNDS } from '../constants/sfx';
import { useModal } from './ModalProvider';
import { useSFX } from './SFXConfig';

const MissionStartPopUp = ({
    closeModal,
    data
}) => {
    const { playSound } = useSFX();
    const { width, height } = useWindowDimensions();
    const { openGame, openModal } = useModal();
    const dispatch = useDispatch()
    const [tooltip, setTooltip] = useState({
        open: false,
        message: ""
    })

    useEffect(() => {
        if(playSound){
            playSound({
                sound: SOUNDS.boardModal
            })
        }
    },[playSound])

    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height
            }}
            onStartShouldSetResponder={() => {
                // closeModal()
                setTooltip({
                    open: false,
                    message: ""
                })
                return false;
            }}
        >
            <View
                style={{
                    width: "29%",
                    height: height*0.9,
                    minHeight: height*0.6,
                    minWidth: width*0.22
                }}
            >
                <ImageBackground
                    source={{
                        uri: Config.IMG_URL+"/save_earth/mission_initiate_modal.png"
                    }}
                    style={{
                        flex: 1,
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {Boolean(tooltip?.open) && <View
                        style={{
                            position: "absolute",
                            zIndex: 100,
                            bottom: height*0.1,
                            paddingVertical: height*0.014,
                            paddingHorizontal: width*0.007,
                            backgroundColor: $white,
                            borderRadius: 10,
                            maxWidth: width*0.24
                        }}
                    >
                        <Text
                            style={{
                                color: $black,
                                fontSize: height*0.02,
                                fontFamily: $robotoRegular,
                                textAlign: "center"
                            }}
                        >
                            {tooltip.message}
                        </Text>
                    </View>}
                    <View
                        style={{
                            flex: 1,
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: height*0.28,
                        }}
                    >
                        <Text
                             style={{
                                fontFamily: $robotoRegular,
                                fontSize: height*0.03,
                                fontWeight: "600",
                                letterSpacing: 2,
                                color: $black,
                                width: "100%",
                                paddingHorizontal: 10,
                                textAlign: "center"
                            }}
                        >
                           {data.showResult ? " You have arrived in " : "You have boarded the ship to"}
                        </Text>
                        <Text
                            style={{
                                fontFamily: $bdCartoon,
                                fontSize: height*0.1,
                                fontWeight: "900",
                                letterSpacing: 8,
                                color: $blue,
                                textShadow: "-3px 3px #000, 3px 3px 0 #000, 3px -3px 0 #000, -3px -3px 0 #000",
                                width: "100%",
                                paddingHorizontal: 10,
                                textAlign: "center",
                                marginTop: 20
                            }}
                        >
                            {data?.cityName}
                        </Text>
                        {!data.showResult && <Text
                            style={{
                                fontFamily: $robotoRegular,
                                fontSize: height*0.03,
                                fontWeight: "600",
                                letterSpacing: 2,
                                color: $black,
                                width: "100%",
                                paddingHorizontal: width*0.016,
                                textAlign: "center",
                                marginTop: 20
                            }}
                        >
                            {data?.playerRequired} more member(s) required to complete this mission in {data?.cityDismantleTime}
                        </Text>}
                        {data.showResult && <Text
                            style={{
                                fontFamily: $robotoRegular,
                                fontSize: height*0.03,
                                fontWeight: "600",
                                letterSpacing: 2,
                                color: $black,
                                width: "100%",
                                paddingHorizontal: width*0.016,
                                textAlign: "center",
                                marginTop: 20
                            }}
                        >
                           and your mission is underway. <br></br><br></br>No more action is required from you in this mission. You may join other missions while you wait for this mission to complete.
                        </Text>}
                    </View>
                   {!data.showResult && <View
                        style={{
                            height: height*0.05
                        }}
                    >
                        <Text
                            style={{
                                color: $black,
                                fontSize: height*0.032,
                                fontFamily: $robotoRegular,
                                fontWeight: "600"
                            }}
                        >
                            Select your mode of travel:
                        </Text>
                    </View>}
                    {!data.showResult && <View
                        style={{
                            flexDirection: "row",
                            width: "86%",
                            justifyContent: "space-between",
                            marginBottom: data.showResult ?  height*0.04 : height*0.13
                        }}
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <TouchableOpacity
                                style={{
                                    paddingHorizontal: width*0.004
                                }}
                                onPress={() => {
                                    closeModal()
                                    openModal({
                                        open: true,
                                        data: {...data, showResult: true },
                                        component: MissionStartPopUp,
                                        blur: true
                                    })
                                }}
                            >
                                <Text
                                     style={{
                                        color: $white,
                                        fontSize: height*0.038,
                                        fontFamily: $bdCartoon,
                                        fontWeight: "600",
                                        textShadowColor: $black,
                                        textShadowOffset: { width: 0, height: 4 },
                                        textShadowRadius: 10,
                                        letterSpacing: 2
                                    }}
                                >
                                    Warp Drive
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => {
                                    setTooltip({
                                        open: !tooltip?.open,
                                        message: "Immediately arrive at your destination by paying full fare in cronos."
                                    })
                                }}
                            >
                                <Image
                                    source={{
                                    uri: Config.IMG_URL + "/save_earth/info_icon.png"
                                    }}
                                    style={{
                                    width: height*0.04,
                                    height: height*0.04,
                                    marginTop: height*0.005,
                                    marginLeft: height*0.006
                                    }}
                                />
                            </TouchableOpacity>
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                           <TouchableOpacity
                                style={{
                                    paddingHorizontal: width*0.004
                                }}
                                onPress={() => {
                                    closeModal()
                                    if(openGame){
                                        openGame({
                                            open: true,
                                            name: "travel",
                                            blur: true,
                                            data: data
                                        })
                                    }
                                }}
                            >
                                <Text
                                    style={{
                                        color: $white,
                                        fontSize: height*0.038,
                                        fontFamily: $bdCartoon,
                                        fontWeight: "600",
                                        textShadowColor: $black,
                                        textShadowOffset: { width: 0, height: 4 },
                                        textShadowRadius: 10,
                                        letterSpacing: 2
                                    }}
                                >
                                    Hyper Drive
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => {
                                    setTooltip({
                                        open: !tooltip?.open,
                                        message: "Navigate your spaceship to your destination and earn Cronos by gaining and cashing raw materials through your travel."
                                    })
                                }}
                            >
                                <Image
                                    source={{
                                    uri: Config.IMG_URL + "/save_earth/info_icon.png"
                                    }}
                                    style={{
                                    width: height*0.04,
                                    height: height*0.04,
                                    marginTop: height*0.005,
                                    marginLeft: height*0.006
                                    }}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>}
                    {data.showResult && <TouchableOpacity
                        style={{
                            height: width*0.05,
                            width: width*0.13,
                            marginBottom: -height*0.01
                        }}
                        onPress={() => {
                            dispatch(setCityDescState(false));
                            dispatch(setCityContribState(false));
                            closeModal();
                            dispatch({
                                type: SHOW_MISSION_POPUP
                            })
                        }}
                    >
                        <Image
                            source={{
                                uri: Config.IMG_URL+"/save_earth/ok_button.png"
                            }}
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </TouchableOpacity>}
                </ImageBackground>
            </View>
        </View>
    )
}

export default MissionStartPopUp;
/*
 * combines all th existing reducers
 */
import * as player from './player';
import * as user from './user';
import * as games from './games';
import * as superpowers from './superpowers';
import * as virtues from './virtues';
import * as programs from './programs';
import * as programModules from './programModules';
import * as status from './status';
import * as cities from './cities';
import * as chat from './chat';
import * as leaderboards from './leaderboard';

export default Object.assign(
  cities,
  player,
  user,
  games,
  superpowers,
  virtues,
  programs,
  programModules,
  status,
  chat,
  leaderboards
);



export const getTopLeftFromPosition = (lat, long, height, width) => {
    const unitHeight = parseInt((height)/180);
    const unitWidth = parseInt((width)/360);
    let top = 0;
    let left = 0;

    if(lat < 0){
        top = unitHeight * 90 + (parseFloat(lat)*-1) * unitHeight 
    } else {
        top = unitHeight * (90 - parseFloat(lat))
    }

    if(long < 0){
        left = (180 - (parseInt(long)*-1)) * unitWidth 
    } else {
        left = unitWidth * parseInt(long) + (unitWidth * 180) 
    }

    return {
        top: parseInt(top),
        left: parseInt(left)
    }
}
import React from 'react';
import { ImageBackground, TouchableOpacity, Image, Text, useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';

import { setCityDescState, setCityContribState } from '../../actions/status';
import { useSFX } from '../../components/SFXConfig';
import Config from '../../config';
import { SOUNDS } from '../../constants/sfx';
import { getStyle } from './styles';

const RightCtn = () => {
  const window = useWindowDimensions()
  const dispatch = useDispatch();
  const { playSound } = useSFX();

  const styles = getStyle(window.width, window.height)

  const handleBoardPress = () => {
    if(playSound){
      playSound({
        sound: SOUNDS.boardButton
      })
    }
    dispatch(setCityDescState(false));
    dispatch(setCityContribState(true));
  };

  return (
    <ImageBackground
      resizeMode="contain"
      source={{ uri: Config.IMG_URL + '/save_earth/arrow_bg.png' }}
      style={styles.rightBg}>
      <TouchableOpacity
        style={styles.rightContainer}
        onPress={() => handleBoardPress()}>
        <Image
          resizeMode="contain"
          style={styles.rightImg}
          source={{ uri: Config.IMG_URL + '/save_earth/arrow_right.png' }}
        />
        <Text style={styles.board}>BOARD</Text>
      </TouchableOpacity>
    </ImageBackground>
  );
};

export default RightCtn;

import { StyleSheet } from 'react-native';
import { $yellow, $white, $black, $overlay } from '../../constants/colors';
import { $bdCartoon, $robotoRegular } from '../../constants/fonts';

export const getStyle = (width, height) => StyleSheet.create({
  active: {
    backgroundColor: $yellow,
  },

  container: {
    alignItems: 'center',
    height: height,
    justifyContent: 'center',
    width: width,
  },

  desc: {
    color: $white,
    fontFamily: $robotoRegular,
    fontSize: height*0.03,
    fontWeight: '400',
  },

  head: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.05,
    marginBottom: height*0.015,
    textShadowColor: $black,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 5,
  },

  list: {
    backgroundColor: $overlay,
    margin: height*0.015,
    marginHorizontal: height*0.03,
    minHeight: height*0.05,
    padding: height*0.015,
  },
});

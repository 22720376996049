import React from 'react';
import { View, ImageBackground, Text, ScrollView, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import ActivateBadge from './activateBadge';
import Config from '../../config';
import { getBadgeDescStyles } from './styles';
import useMediaQuery from '../../hooks/useMediaQuery';

const BadgeDescription = ({ badge, hideDesc, navigation }) => {
  const window = useWindowDimensions()
  const { isMobile } = useMediaQuery();
  const styles = getBadgeDescStyles(window.width, window.height, isMobile)

  return (
    <View style={styles.container}>
      <ImageBackground
        resizeMode="stretch"
        source={{
          uri: Config.IMG_URL + '/abilities/description_bg.png',
        }}
        style={styles.bg}>
        <View style={styles.desc}>
          <Text style={styles.head}>{badge.displayName}</Text>
          <View style={styles.costConatiner}>
            <Text style={styles.costHead}>Cost</Text>
            <ImageBackground
              resizeMode="stretch"
              style={styles.costBg}
              source={{
                uri: Config.IMG_URL + '/abilities/ep_icon_bar_description.png',
              }}>
              <Text style={styles.costTxt}>EP {badge.requiredEp}</Text>
            </ImageBackground>
          </View>
          <View style={styles.txtCtn}>
            <ScrollView nestedScrollEnabled={true}>
              <Text style={styles.txt}>{badge.description}</Text>
            </ScrollView>
            <ActivateBadge badge={badge} hideDesc={() => hideDesc()} navigation={navigation} />
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};

BadgeDescription.propTypes = {
  badge: PropTypes.object,
  hideDesc: PropTypes.func,
  navigation: PropTypes.object
};

export default BadgeDescription;

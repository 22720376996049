import React from 'react';
import { TouchableOpacity, Image, useWindowDimensions } from 'react-native';
import { setRedailBtnState } from '../../actions/status';

import Config from './../../config';
import { useDispatch } from 'react-redux';

import { getStyle } from '../styles';

const Backdrop = () => {
  const dispatch = useDispatch();
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height)

  return (
    <TouchableOpacity
      style={styles.backdrop}
      onPress={() => dispatch(setRedailBtnState(false))}>
      <Image
        style={styles.full}
        source={{
          uri: Config.IMG_URL + '/nav/backdrop.png',
        }}
      />
    </TouchableOpacity>
  );
};

export default Backdrop;

import React from 'react';
import { View, TouchableOpacity, Text, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setLoadingState,
  setHelpMessage,
  setRebeccaState,
} from '../../actions/status';
import ProgramsCompleteHelp from '../../components/programsCompleteHelp';
import { completeModule } from '../../actions/programModules';

import { getProgramStyles } from './styles';
import { getPlayerData } from '../../actions/player';
import { useModal } from '../../components/ModalProvider';
import AlertPopup from '../../components/alertPopup';
import { SOUNDS } from '../../constants/sfx';
import PractiseMissionCompleteMessage from './practiseMissionCompleteMessage';
import Config from '../../config';
import useMediaQuery from '../../hooks/useMediaQuery';

const MarkComplete = ({ navigate }) => {
  const programModuleId = useSelector(state => state.status.programModule.id);
  const isCompleted = useSelector(
    state => state.status.programModule.transaction.isCompleted,
  );
  const levelId = useSelector(state => state.player.level.id);

  const window = useWindowDimensions();
  const { openModal } = useModal();
  const { isMobile } = useMediaQuery();

  const styles = getProgramStyles(window.width, window.height);

  const dispatch = useDispatch();

  const compModule = async id => {
    if (!isCompleted) {
      dispatch(setLoadingState(true));
      const { error, data } = await dispatch(completeModule(id));
      dispatch(setLoadingState(false));
      if (error) {
        alert(
          (error && error.errors && error?.errors[0].detail) ||
          'some error occurred please try again later',
        );
        navigate('ProgramModules');
        return;
      } else if (data) {
        openModal({
          open: true,
          component: AlertPopup,
          blur: true,
          data: {
            sound: SOUNDS.boardModal,
            heading: "Well Done!",
            headingMarginTop: window.height * 0.23,
            yesText: "Okay",
            singleButton: true,
            width: isMobile ? "29%" : "28%",
            height: isMobile ? "94%" : "87%",
            modalBackground: Config.IMG_URL + "/abilities/modal/onestar_modal.png",
            messageComponent: () => <PractiseMissionCompleteMessage
              values={{
                ep: data?.awarded_ep,
                xp: data?.awarded_xp,
                cronos: data?.awarded_cronos,
              }}
            />,
            success: () => {
            },
            failure: () => {
            }
          }
        })
      }
    }
    const { error: plError } = await dispatch(getPlayerData());
    if (plError) {
      alert(
        'some error occurred on fetching player data please try again later',
      );
    }
    if (levelId === 1) {
      dispatch(setHelpMessage(ProgramsCompleteHelp));
      dispatch(setRebeccaState(true));
    }
    navigate('ProgramModules');
  };

  return (
    <View style={styles.markeModuleCompleteCtn}>
      <TouchableOpacity
        style={styles.markeModuleCompleteBtn}
        onPress={() => compModule(programModuleId)}>
        <Text style={styles.markeModuleCompleteBtnTxt}>
          {isCompleted ? 'finish' : 'Mark as Complete'}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

MarkComplete.propTypes = {
  navigate: PropTypes.func,
};

export default React.memo(MarkComplete);

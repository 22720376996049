import React, { useState } from 'react'
import { Text, useWindowDimensions } from 'react-native'
import { TouchableOpacity } from 'react-native'
import { View } from 'react-native'
import { useModal } from '../../../components/ModalProvider'
import { $black, $white } from '../../../constants/colors'
import useMediaQuery from '../../../hooks/useMediaQuery'
import ForgotPasswordScreen from '../../auth/forgotPassword'
import LoginScreen from '../../auth/login'
import MenuComponent from './MenuComponent'

const AuthModal = ({
    data, closeModal
}) => {
    const { width, height} = useWindowDimensions();
    const [isForget, setIsForget] = useState(false);

    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height,
                backgroundColor: "rgba(0,0,0,0.7)"
            }}
            onClick={() => {
                closeModal()
            }}
        >
            <div
                style={{
                    maxWidth: "800px",
                    width: width*0.5,
                    height: height*0.81
                }}
                onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                }}
            >
                {isForget ? 
                <ForgotPasswordScreen modal onLoginClick={() => setIsForget(false)} closeModal={closeModal} />:
                <LoginScreen modal onForgetClick={() => setIsForget(true)} closeModal={closeModal} />
                }
            </div>
        </div>
    )
}


const LoginComponent = ({menuShow, setMenushow, navigation}) => {
    const { isMobile } = useMediaQuery();
    const { height, width } = useWindowDimensions()
    const { openModal } = useModal();
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState("#f9c184")

    const openLoginModal = () => {
        if(isMobile){
            navigation.navigate("Login");
            // const userAgent = window.navigator.userAgent.toLowerCase(),
            // android = /android/.test(userAgent),
            // ios = /iphone|ipod|ipad/.test(userAgent);

            // if (ios) {
            //     // will navigate here to appstore
            //     window.location.href = "https://play.google.com/store/apps/details?id=com.superherowithin.app"
            // } else if(android) {
            //     // will navigate here to playstore
            //     window.location.href = "market://details?id=com.superherowithin.app"
            // }
            return;
        }
        openModal({
            open: true,
            component: AuthModal,
            data: {}
        })
    }

    return (
        <View
            style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <MenuComponent menuShow={menuShow} setMenushow={setMenushow} />
            <div
                style={{
                    display: "flex",
                    padding: isMobile ? "8px 10px" : "10px 15px",
                    borderWidth: 1,
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderColor: $black,
                    backgroundColor: buttonBackgroundColor,
                    cursor: "pointer"
                }}
                onClick={openLoginModal}
                onMouseEnter={() => setButtonBackgroundColor("#b17328")}
                onMouseLeave={() => setButtonBackgroundColor("#f9c184")}
            >
                <Text
                    style={{
                        fontFamily: "'Fredoka One', cursive",
                        fontSize: isMobile ? width*0.045 : height * 0.025,
                        fontWeight: '400',
                        color: $white,
                        visibility: "visible",
                    }}
                >
                    Player Login
                </Text>
            </div>
        </View>
    )
}

export default LoginComponent
import axios from './index';

import axiosConfig from './../constants/axiosConfig';
import Config from './../config';

export const getGames = async () =>
  axios.get(Config.API_URL + '/games', await axiosConfig({ token: true }));

export const getGame = async id =>
  axios.get(
    Config.API_URL + '/games/' + id,
    await axiosConfig({ token: true }),
  );

export const convertToCronos = async (amount, comment) =>
  axios.post(
    Config.API_URL + '/cronos_transactions/reward',
    {
      amount: amount,
      comment: comment
    },
    await axiosConfig({ token: true, gameId: true }),
  );

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, Text, useWindowDimensions, TouchableOpacity, Image, ImageBackground } from 'react-native';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { isEveryThingContributed } from './helper';
import Board from './board';
import Retry from './retry';

import { getStyle } from './styles';
import { $red, $white, $yellow } from '../../constants/colors';
import Config from '../../config';
import { useModal } from '../../components/ModalProvider';
import MissionReportModal from '../../components/missionReportModal';
import { $bdCartoon } from '../../constants/fonts';

let timerInerval;

const BoardCtn = () => {
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height);
  const players = useSelector(state => state.status.slectedCity.players);
  const playerId = useSelector(state => state.player.id);
  const city = useSelector(state => state.status.slectedCity);
  const requirements = useSelector(state => state.cities.requirements[city.id]);
  const contributions = useSelector(
    state => state.cities.contributions[city.id],
  );
  const { openModal, openGame } = useModal();
  const playerIsInGame = useMemo(() => {
    return players.find(data => parseInt(data) === parseInt(playerId));
  }, [players, playerId])

  const [percent, setPercent] = useState(0);

  // const dispatch = useDispatch();
  const getPercent = useCallback(() => {
    const startedAt = new Date(city.startedAt);
    const duration = city.duration;
    let percent = (Date.now() - startedAt) / duration / 1000;
    // Make sure the percentage is never negative
    percent = Math.max(percent, 0);
    // Make sure the percentage is never > 100
    percent = Math.min(percent * 100, 100).toFixed(2);
    if (percent >= 100) {
      clearInterval(timerInerval);
      // if (city.state !== 'retry' || city.state !== 'completed') {
      //   dispatch(getCities());
      // }
    }
    setPercent(percent);
  }, [city])

  useEffect(() => {
    if (players.length > 0) {
      getPercent();
      timerInerval = setInterval(getPercent, 1000);
    }
    return () => {
      clearInterval(timerInerval);
      timerInerval = null;
    };
  }, [players, getPercent]);

  if (city.isCompleted) {
    return (
      <>
        {Boolean(city.endVideoUrl && playerIsInGame) && <View
          style={{
            justifyContent: "flex-end",
            width: "100%",
            paddingHorizontal: window.width * 0.01,
            marginVertical: window.height * 0.014,
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <TouchableOpacity
            onPress={() => {
              openModal({
                open: true,
                component: MissionReportModal,
                data: {
                  city: city
                },
                blur: true
              })
            }}
          >
            <Image
              source={{
                uri: Config.IMG_URL + "/save_earth/report_button.png"
              }}
              style={{
                height: window.width * 0.0265,
                width: window.width * 0.116
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity style={[styles.boardBtn, { height: window.width * 0.0265 }]} onPress={() => {
            if (openGame) {
              openGame({
                open: true,
                name: "travel",
                blur: true,
                data: {
                  notFromMissionStart: true
                }
              })
            }
          }}>
            <ImageBackground
              resizeMode="contain"
              style={[styles.boardImg, { height: window.width * 0.0265, width: window.width * 0.1 }]}
              source={{
                uri: Config.IMG_URL + '/save_earth/btn.png',
              }}>
              <Text style={[styles.boardTxt, { fontSize: window.width * 0.01 }]}>Space Travel</Text>
            </ImageBackground>
          </TouchableOpacity>
        </View>}
        {Boolean(!playerIsInGame) ?
          <Board
            cityId={parseInt(city.id)}
            duration={parseInt(city.duration)}
            critical={city?.critical}
            percent={percent}
            requirements={requirements}
            contributions={contributions}
            hidePercent
            support
          />
          :
          <View style={styles.activeCtn}>
            <View
              style={[
                styles.activeProgress,
                styles.completedBg,
                { width: "100%" },
              ]}></View>
            <Text style={styles.activeTxt}>Completed</Text>
          </View>
        }
      </>
    );
  }

  if (city.state === 'retry') {
    return <Retry fare={requirements.fare} cityId={parseInt(city.id)} critical={city.critical} />;
  }

  if (
    isEveryThingContributed(requirements, contributions) ||
    playerIsInGame
  ) {
    return (
      <>
        {Boolean(playerIsInGame) &&
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: window?.height * 0.01,
            }}
          >
            <Text
              style={{
                color: $white,
                fontFamily: $bdCartoon,
                fontSize: window.height * 0.042,
                fontWeight: "200",
                textAlign: "center"
              }}
            >
              You are already a part of this mission. <br></br>You may join other missions while you wait for this mission to complete.
            </Text>
            <TouchableOpacity style={[styles.boardBtn, { height: window.width * 0.0265 }]} onPress={() => {
              if (openGame) {
                openGame({
                  open: true,
                  name: "travel",
                  blur: true,
                  data: {
                    notFromMissionStart: true
                  }
                })
              }
            }}>
              <ImageBackground
                resizeMode="contain"
                style={[styles.boardImg, { height: window.width * 0.0265, width: window.width * 0.1 }]}
                source={{
                  uri: Config.IMG_URL + '/save_earth/btn.png',
                }}>
                <Text style={[styles.boardTxt, { fontSize: window.width * 0.01 }]}>Space Travel</Text>
              </ImageBackground>
            </TouchableOpacity>
          </View>
        }
        <View style={styles.activeCtn}>
          <View style={[styles.activeProgress, { width: percent + '%', backgroundColor: city.isFailed ? $red : $yellow }]}></View>
          <View style={styles.activeBg}></View>
          <Text style={styles.activeTxt}>{city.isFailed ? "Failed" : "(" + percent + "%) Planet Mission in Progress.."}</Text>
        </View>
      </>
    );
  }

  return (
    <Board
      cityId={parseInt(city.id)}
      duration={parseInt(city.duration)}
      critical={city?.critical}
      percent={percent}
      requirements={requirements}
      contributions={contributions}
    />
  );
};

BoardCtn.propTypes = {
  city: PropTypes.object,
  superpowers: PropTypes.number,
  virtues: PropTypes.number,
  superpowersByName: PropTypes.object,
  virtuesByName: PropTypes.object,
  requirements: PropTypes.object,
  contributions: PropTypes.object,
};

export default BoardCtn;

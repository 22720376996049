import React from 'react'
import { useWindowDimensions } from 'react-native'
import { Text } from 'react-native'
import { View } from 'react-native'
import { $bdCartoon } from '../../../constants/fonts'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { PAGE_ID } from '../constants'
import TryNowForm from './TryNowForm'


const TopSection = () => {
    const { height, width } = useWindowDimensions();
    const { isMobile } = useMediaQuery()

    return (
        <View
            style={
               {
                justifyContent: "center",
                alignItems: "flex-start",
                paddingLeft: isMobile ? "15px" : "10%",
                width: "100%",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.5)",
               }

            }
            nativeID={PAGE_ID.top_section}
        >
            <View>
                <Text
                    style={{
                        color: '#f9c184',
                        fontFamily: $bdCartoon,
                        fontSize: isMobile ? width*0.11 : height * 0.075,
                        width: isMobile ? "100%" : "60%"
                    }}
                >
                    Multiplayer Gamification For Asynchronous Learning
                </Text>
            </View>
            <View>
                <Text
                    style={{
                        color: '#f9c184',
                        fontFamily: $bdCartoon,
                        fontSize: isMobile ? width*0.075 : height * 0.045
                    }}
                >
                A tool to gamify just about any kind of content
                </Text>
            </View>
           <TryNowForm />
        </View>
    )
}

export default TopSection
import createReducer from '../lib/createReducer';
import { ADD_MESSAGE, LOGOUT, LEAVE_GAME } from '../constants/actions';

/**
 * [id]{
 *   id
 *   member_id
 *   msg
 *   type
 *   time
 *   name
 * }
 */
const initialState = {};

export const chat = createReducer(initialState, {
  [ADD_MESSAGE](state, { payload }) {
    return { ...state, ...payload };
  },
  [LEAVE_GAME](state, { payload }) {
    return initialState;
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

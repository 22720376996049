import React from 'react' 
import { ImageBackground, Text, TouchableOpacity, View } from 'react-native'
import Config from '../../../config';
import { $darkBlack, $darkYellow, $lightPurple, $orange } from '../../../constants/colors';
import { $bdCartoon } from '../../../constants/fonts';


const PlayerWinCard = ({
    width,
    height,
    playerDetails,
    type,
    onPress,
    disable
}) => {


    return (
        <TouchableOpacity disabled={disable} onPress={onPress}>
        <ImageBackground
            style={{
                width: width,
                height: height,
                justifyContent: "center",
                alignItems: "center"
            }}
            source={{
                uri: Config.IMG_URL+`/leaderboard/${type === "1" ? "1st_place" : type === "2" ? "2nd_place" : type === "3" ? "3rd_place" : "nth_place"}.png`
            }}
        >
            <View
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    flexDirection: "row",
                    justifyContent: "center"
                }}
            >
                <Text
                    style={{
                        fontSize: height*0.35,
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 3,
                        color: type?.toString() === "1" ? $darkYellow : type === "2" ? $lightPurple  : type === "3" ? $orange : $darkBlack
                    }}
                >
                    {
                        type

                    }
                </Text>
                <Text
                     style={{
                        fontSize: height*0.24,
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 3,
                        color:  type?.toString() === "1" ? $darkYellow : type === "2" ? $lightPurple  : type === "3" ? $orange : $darkBlack,
                        marginLeft: 4
                    }}
                >
                    {
                        type === "1" ? "ST" : type === "2" ? "ND" : type === "3" ? "RD" : "TH"

                    }
                </Text>
            </View>
            <View
                style={{
                    width: "64%",
                    alignItems: "center",
                }}
            >
                <Text
                     style={{
                        fontSize: height*0.14,
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 3,
                        color:  type?.toString() === "1" ? $darkYellow : type === "2" ? $lightPurple  : type === "3" ? $orange : $darkBlack,
                        marginTop: height*0.2,
                    }}
                    numberOfLines={1}
                >
                    {playerDetails?.name}
                </Text>
            </View>
            {Boolean(playerDetails?.tag) && <View
                style={{
                    width: "64%",
                    alignItems: "center",
                }}
            >
                <Text
                     style={{
                        fontSize: height*0.1,
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 3,
                        color:  type?.toString() === "1" ? $darkYellow : type === "2" ? $lightPurple  : type === "3" ? $orange : $darkBlack,
                        marginTop: height*0.02,
                        textAlign: "center"
                    }}
                    numberOfLines={1}
                >
                    {playerDetails?.tag}
                </Text>
            </View>}
            <View
                style={{
                    width: "64%",
                    alignItems: "center",
                }}
            >
                <Text
                     style={{
                        fontSize: height*0.14,
                        fontFamily: $bdCartoon,
                        fontWeight: "900",
                        letterSpacing: 3,
                        color: type?.toString() === "1" ? $darkYellow : type === "2" ? $lightPurple  : type === "3" ? $orange : $darkBlack,
                        marginTop: height*0.01,
                    }}
                    numberOfLines={1}
                >
                    {playerDetails?.points+" PTS"}
                </Text>
            </View>
        </ImageBackground>
        </TouchableOpacity>
    )
}

export default PlayerWinCard;
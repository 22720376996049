import React from 'react'
import { Image, ImageBackground, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import Config from '../config';
import { $yellow } from '../constants/colors';
import { $bdCartoon, $robotolight } from '../constants/fonts';
import { SOUNDS } from '../constants/sfx';
import VideoSlide from '../screens/programSlides/videoSlide';
import { useSFX } from './SFXConfig';

export const getStyle = (width, height) => StyleSheet.create({
    leftContainer: {
      
    },
    desc: {
      fontFamily: $robotolight,
      fontSize: height*0.035,
      lineHeight: height * 0.037
    },
  
    descHead: {
      color: $yellow,
      fontFamily: $bdCartoon,
      fontSize: height*0.08,
    },
  
    leftBg: {
      height: height*0.82,
      position: 'relative',
      width: width*0.7,
      zIndex: 100,
    },
    closeContainer: {
        height: height*0.04,
        right: width*0.13,
        position: 'absolute',
        top: height*0.06,
        width: height*0.04,
      },
    
    closeImg: {
        height: '100%',
        width: '100%',
    },
  });

const MissionReportModal = ({
    closeModal,
    data
}) => {
    const { width, height } = useWindowDimensions();
    const { city } = data;
    const styles = getStyle(width, height);
    const { playSound } = useSFX();

    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height
            }}
            onStartShouldSetResponder={() => {
                // closeModal()
                return false;
            }}
        >
           <ImageBackground
                resizeMode="stretch"
                source={{ uri: Config.IMG_URL + '/save_earth/new_bg_left.png' }}
                style={styles.leftBg}>
                <View style={styles.leftContainer} >
                    {Boolean(city?.endVideoUrl) && 
                    <>
                        <Text style={[styles.descHead,{
                            position: "absolute",
                            top: 20,
                            left: height*0.06,
                            zIndex: 1
                        }]}>{city.displayName}</Text>
                        <View
                            style={{
                            position: "relative",
                            width: "98%",
                            height: "100%",
                            marginTop: "0.6%",
                            marginLeft: "0.7%"
                            }}
                        >
                            <VideoSlide
                                url={city?.endVideoUrl}
                                hideSkipControl
                            />
                        </View>
                    </>
                    }
                </View>
            </ImageBackground>
            <View style={styles.closeContainer}>
                <TouchableOpacity
                    style={styles.closeImg}
                    onPress={() => {
                        if(playSound){
                            playSound({
                                sound: SOUNDS.general
                            })
                        }
                        closeModal()
                    }}>
                    <Image
                        resizeMode="stretch"
                        style={styles.closeImg}
                        source={{
                            uri: Config.IMG_URL + '/save_earth/close.png',
                        }}
                    />
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default MissionReportModal;
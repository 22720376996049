import React from 'react';
import { View, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import PropTypes from 'prop-types';

import { $overlay } from '../constants/colors';

const ModalComponent = ({ children, visible, onTouchOutside }) => {
  if (!visible) return <></>;

  return (
    <View style={styles.topView}>
      <TouchableWithoutFeedback
        style={styles.bg}
        onPress={() => (onTouchOutside ? onTouchOutside() : '')}>
        <View style={styles.conatiner}>
          <TouchableWithoutFeedback style={styles.wrapper}>
            {children}
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = StyleSheet.create({
  bg: {
    height: '100%',
    left: 0,
    padding: 50,
    top: 0,
    width: '100%',
  },

  conatiner: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  topView: {
    backgroundColor: $overlay,
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 10000
  },

  wrapper: {
    height: '100%',
    width: '100%',
  },
});

ModalComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  visible: PropTypes.bool,
  onTouchOutside: PropTypes.func,
};

export default ModalComponent;

export const contibuteCount = (contribution, name) => {
  return contribution.filter(d => d.name === name).length;
};

export const isEveryThingContributed = (requirements, contributions) => {
  const superpowers = requirements.superpowers.every(
    s =>
      s.count ===
      contributions.superpowers.filter(d => d.name === s.name).length,
  );
  const virtues = requirements.virtues.every(s => {
    const totalCount = s.count;
    const count = contributions.virtues.filter(d => d.name === s.name).length;
    return totalCount === count;
  });
  const isLastSuperpowerContibuter =
    contributions.superpowers.length > 0 &&
    contributions.superpowers[contributions.superpowers.length - 1]
      .cityMissionId;
  const isLastVirtueContibuter =
    contributions.virtues.length > 0 &&
    contributions.virtues[contributions.virtues.length - 1].cityMissionId;
  return (
    superpowers &&
    virtues &&
    isLastSuperpowerContibuter &&
    isLastVirtueContibuter
  );
};

import React from 'react';
import { View, SafeAreaView, Text, FlatList, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';
import Badges from './badges';

import { getStyle } from './styles';
import { useSelector } from 'react-redux';

const BadgesCtn = ({ type, label, navigation }) => {
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height);
  const cityId = useSelector(state => state.status.slectedCity.id);
  const requirements = useSelector(state => state.cities.requirements[cityId]);

  return (
    <View style={styles.badgesContainer}>
      <Text style={[styles[type], styles.badgeTxt]}>{label.toUpperCase()}</Text>
      <SafeAreaView
        style={styles.badgesSafeArea}
        onStartShouldSetResponder={() => true}>
        <FlatList
          data={requirements?.[type]}
          renderItem={({ item }) => (
            <Badges name={item.name} type={type} count={item.count} navigation={navigation} item={item} />
          )}
          keyExtractor={item => item.name}
        />
      </SafeAreaView>
    </View>
  );
};

BadgesCtn.propTypes = {
  type: PropTypes.string,
};

export default BadgesCtn;

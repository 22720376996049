import {
  getPlayer,
  setActiveSuperPower,
  setActiveVirtue,
  getProfileChoices,
  updatePlayerCharacter,
  updatePlayerTrait,
  updatePlayerSession,
  getGamePreference,
  setGamePreference,
  getEnableVirtues,
} from '../services/player';
import {
  SET_PLAYER,
  SET_LEVEL,
  SET_SUPERPOWERS,
  SET_VIRTUES,
  SET_ACTIVE_VIRTUES,
  SET_ACTIVE_SUPERPOWERS,
  LEAVE_GAME,
  SET_ABILITIES,
  SET_MUSIC_PLAYING,
  SET_SFX_VALUE,
  SET_ENABLE_VIRTUES
} from '../constants/actions';
import AsyncStorage from '@react-native-community/async-storage';
import { setSocket } from '../services/socket';
import { loadGameData } from './games';

export const getPlayerData = () => async dispatch => {
  try {
    const { data } = await getPlayer().catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    const playerProfile = data.data.attributes.profile.data;
    const relationships = data?.data?.relationships;
    const playerSessions = data.data.attributes.sessions.data.map(session => {
      return {
        id: session.id,
        levelTemplateId: session.attributes.level_template_id,
        stage: session.attributes.stage,
        isTutorial: session.attributes.is_tutorial,
        isCompleted: session.attributes.is_completed,
      };
    });
    setVirtuesSuperPowers(data, dispatch);
    const dispatchData = {
      id: data.data.id,
      name: data.data.attributes.name,
      chat_token: data?.data?.attributes?.chat_token,
      isEnabled: data.data.attributes.is_enabled,
      isActive: data.data.attributes.is_active,
      xp: data.data.attributes.xp,
      ep: data.data.attributes.ep,
      cronos: data.data.attributes.cronos,
      hasProfile: data.data.attributes.has_profile,
      tag: data.data.attributes.tag,
      profile: {
        id: playerProfile?.id,
        // eslint-disable-next-line camelcase
        characterId: playerProfile?.attributes.character_id,
        // eslint-disable-next-line camelcase
        characterName: playerProfile?.attributes.character_name,
        // eslint-disable-next-line camelcase
        traitId: playerProfile?.attributes.trait_id,
        trait: playerProfile?.attributes.trait,
      },
      sessions: playerSessions,
      current_mission: relationships?.current_mission,
      currentMissionId: data?.data?.attributes?.current_mission_id
    };

    await AsyncStorage.setItem(
      'gameLevel',
      playerSessions.length.toString() || '1',
    );
    dispatch({
      type: SET_PLAYER,
      payload: { data: dispatchData },
    });
    dispatch({
      type: SET_LEVEL,
      payload: { id: playerSessions.length, state: playerSessions[0].stage },
    });
    dispatch({
      type: SET_ABILITIES,
      payload: { tab: playerSessions.length === 1 ? 'virtues' : 'superpowers' },
    });
    return { data: dispatchData };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

const setVirtuesSuperPowers = ({ included }, dispatch) => {
  try {
    let superPowers = [];
    let virtues = [];

    let activeSuperpowers = {};
    let activeVirtues = {};

    included.forEach(element => {
      const data = {
        id: element.id,
        state: element.attributes.state,
        name: element.attributes.name,
        displayName: element.attributes.display_name,
        description: element.attributes.description,
        requiredEp: element.attributes.required_ep,
        isEnabled: element.attributes.is_enabled,
        isDisabled: element.attributes.is_disabled,
        isActivated: element.attributes.is_activated,
        disableImageUrl: element.attributes.disabled_image_url,
        enableImageUrl: element.attributes.enabled_image_url
      };
      if (element.type === 'super_power') {
        superPowers.push(data);
        if (data.isActivated) {
          activeSuperpowers[element.id] = data;
        }
      } else if (element.type === 'virtue') {
        virtues.push(data);
        if (data.isActivated) {
          activeVirtues[element.id] = data;
        }
      }
    });
    dispatch({
      type: SET_SUPERPOWERS,
      payload: {
        data: superPowers.sort((a, b) => a.requiredEp - b.requiredEp),
      },
    });
    dispatch({
      type: SET_VIRTUES,
      payload: { data: virtues.sort((a, b) => a.requiredEp - b.requiredEp) },
    });
    dispatch({
      type: SET_ACTIVE_SUPERPOWERS,
      payload: activeSuperpowers,
    });
    dispatch({
      type: SET_ACTIVE_VIRTUES,
      payload: activeVirtues,
    });
    return { res: 'done' };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const setSuperPower = id => async (dispatch, getState) => {
  try {
    await setActiveSuperPower(id).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    const state = getState();
    let cost = 0;
    let activeSuperpowers = state.status.superpowers;

    const updatedData = state.superpowers.map(d => {
      if (d.id === id) {
        d.isActivated = true;
        d.state = 'activated';
        cost = d.requiredEp;
        activeSuperpowers[id] = d;
      }
      return d;
    });

    const updatedPlayerData = state.player;
    updatedPlayerData.ep = state.player.ep - cost;

    dispatch({
      type: SET_PLAYER,
      payload: { data: updatedPlayerData },
    });

    dispatch({
      type: SET_SUPERPOWERS,
      payload: { data: updatedData },
    });

    dispatch({
      type: SET_ACTIVE_SUPERPOWERS,
      payload: activeSuperpowers,
    });
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const setVirtue = id => async (dispatch, getState) => {
  try {
    await setActiveVirtue(id).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });

    const state = getState();
    let cost = 0;
    let activeVirtues = state.status.virtues;

    const updatedData = state.virtues.map(d => {
      if (d.id === id) {
        d.isActivated = true;
        d.state = 'activated';

        cost = d.requiredEp;
        activeVirtues[id] = d;
      }
      return d;
    });

    const updatedPlayerData = state.player;
    updatedPlayerData.ep = state.player.ep - cost;

    dispatch({
      type: SET_PLAYER,
      payload: { data: updatedPlayerData },
    });

    dispatch({
      type: SET_VIRTUES,
      payload: { data: updatedData },
    });

    dispatch({
      type: SET_ACTIVE_VIRTUES,
      payload: activeVirtues,
    });
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const getCharacterChoices = () => async dispatch => {
  try {
    const { data } = await getProfileChoices().catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    return { ...data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const getEnabledVirtuesData = (id) => async dispatch => {
  try {
    const { data } = await getEnableVirtues(id).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    if(data?.virtue_ids?.length){
      dispatch({
        type: SET_ENABLE_VIRTUES,
        payload: {
          virtues: data?.virtue_ids
        }
      })
    }
    return { ...data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const getPreferences = (playerId) => async dispatch => {
  try {
    const { data } = await getGamePreference(playerId).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    console.log(data, "--preference--");
    await dispatch({
      type: SET_MUSIC_PLAYING,
      payload: {
        play: data?.music === undefined ? true : data?.music
      }
    })
    await dispatch({
      type: SET_SFX_VALUE,
      payload: {
        play: data?.sfx === undefined ? true : data?.sfx
      }
    })
    if(data?.music === undefined && data?.sfx === undefined){
      await setGamePreference(playerId, {
        music: true,
        sfx: true
      })
    }
    return { ...data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const updateCharacter = params => async (dispatch, getState) => {
  try {
    const { data } = await updatePlayerCharacter(params).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    const state = getState();
    const updatedPlayerData = state.player;
    updatedPlayerData.name = params.name;
    updatedPlayerData.profile.characterId = params.character_id;
    updatedPlayerData.profile.characterName = params.character_name;
    dispatch({
      type: SET_PLAYER,
      payload: { data: updatedPlayerData },
    });
    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const updateTrait = async params => {
  try {
    const { data } = await updatePlayerTrait(params).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const updateSession = params => async (dispatch, getState) => {
  try {
    const { data } = await updatePlayerSession(params).catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    const id = params.stage === 'completed' ? 2 : 1;
    const activeLevel = { id, state: params.stage };
    await AsyncStorage.setItem(
      'gameLevel',
      id.toString(),
    );
    await dispatch({
      type: SET_LEVEL,
      payload: activeLevel,
    });
    const gameId = getState()?.player.slectedGameId;
    if(id === 2){
      dispatch(loadGameData(gameId, true));
    }
    return { data };
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const leaveGame = () => async dispatch => {
  try {
    await AsyncStorage.removeItem('selectedGame');
    await AsyncStorage.removeItem('gameLevel');
    setSocket().unsubscribe();
    dispatch({
      type: LEAVE_GAME,
    });
  } catch (error) {
    console.log(error);
    return { error };
  }
};

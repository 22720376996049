import React from 'react';
import { ImageBackground, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import LeftCtn from './leftCtn';
import RightCtn from './rightCtn';

import Config from '../../config';
import { getProgramModulesStyle } from './styles';

const PracticePracticeMissionScreen = ({ navigation }) => {
  const window = useWindowDimensions()

  const styles = getProgramModulesStyle(window.width, window.height)

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/practice_missions/background.png' }}
      style={styles.container}>
      <LeftCtn navigation={navigation} />
      <RightCtn navigate={navigation.navigate} />
    </ImageBackground>
  );
};

PracticePracticeMissionScreen.propTypes = {
  navigation: PropTypes.object,
};

export default PracticePracticeMissionScreen;

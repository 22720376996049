import React from 'react';
import { ImageBackground, useWindowDimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Config from './../../config';
import { setRedailBtnState } from '../../actions/status';
import * as RootNav from './../RootNav';

import RedailBtn from './redailBtn';
import Backdrop from './backdrop';

import { getStyle } from './../styles';
import BackBtn from './backBtn';

const RadialNavigation = () => {
  const isRedailBtnOpen = useSelector(state => state.status.isRedailBtnOpen);
  const window = useWindowDimensions()
  const styles = getStyle(window.width, window.height)

  const activeRoute = useSelector(state => state.status.nav);
  const dispatch = useDispatch();

  const navigate = to => {
    RootNav.navigate(to);
    dispatch(setRedailBtnState(false));
  };

  if (isRedailBtnOpen) {
    return (
      <>
        <Backdrop />
        <ImageBackground
          style={styles.navBg}
          resizeMode="contain"
          source={{
            uri: Config.IMG_URL + '/nav/bg.png',
          }}>
          <RedailBtn
            type={activeRoute === 'SaveEarth' ? 'active' : 'inactive'}
            styleName="btnSaveEarth"
            name="save_earth"
            navigate={() => navigate('SaveEarth')}
          />

          <RedailBtn
            type={activeRoute === 'Chat' ? 'active' : 'inactive'}
            styleName="btnChat"
            name="chat"
            navigate={() => navigate('Chat')}
          />

          <RedailBtn
            type={activeRoute === 'Abilities' ? 'active' : 'inactive'}
            styleName="btnAbilities"
            name="abilities"
            navigate={() => navigate('Abilities')}
          />

          <RedailBtn
            type={activeRoute.includes('Program') ? 'active' : 'inactive'}
            styleName="btnPracticeMission"
            name="practice_missions"
            navigate={() => navigate('PracticeMission')}
          />

          <RedailBtn
            type={activeRoute === 'Dashboard' ? 'active' : 'inactive'}
            styleName="btnDashboard"
            name="mission_control"
            navigate={() => navigate('Dashboard')}
          />

          <BackBtn />
        </ImageBackground>
      </>
    );
  }
  return <></>;
};

export default RadialNavigation;

// Logout
export const LOGOUT = 'LOGOUT';

// Naviagtion
export const NAV_ACTIVE = 'NAV_ACTIVE';

// Games
export const SET_GAMES = 'SET_GAMES';
export const SET_CURRENT_GAMES = 'SET_CURRENT_GAMES';
export const SET_GAME_PLAYERS = 'SET_GAME_PLAYERS';

// User
export const SET_USER = 'SET_USER';

// Player
export const SET_PLAYER = 'SET_PLAYER';
export const SET_SUPERPOWERS = 'SET_SUPERPOWERS';
export const UPDATE_PLAYER_CRONOS = 'UPDATE_PLAYER_CRONOS';
export const UPDATE_SUPERPOWER_ATTRIBUTES = 'UPDATE_SUPERPOWER_ATTRIBUTES';
export const UPDATE_VIRTUE_ATTRIBUTES = 'UPDATE_VIRTUE_ATTRIBUTES';
export const SET_VIRTUES = 'SET_VIRTUES';
export const SET_ENABLE_VIRTUES = 'SET_ENABLE_VIRTUES';
export const LEAVE_GAME = 'LEAVE_GAME';

// Program & Program Modules
export const SET_PROGRAMS = 'SET_PROGRAMS';
export const UPDATE_PROGRAMS = 'UPDATE_PROGRAMS';
export const SET_PROGRAM_MODULES = 'SET_PROGRAM_MODULES';
export const SET_PROGRAM_MODULES_QUESTIONS = 'SET_PROGRAM_MODULES_QUESTIONS';

// status
export const SET_ACTIVE_PROGRAM = 'SET_ACTIVE_PROGRAM';
export const SET_MUSIC_PLAYING = 'SET_MUSIC_PLAYING';
export const SET_SFX_VALUE = 'SET_SFX_VALUE';
export const SET_ACTIVE_PROGRAM_MODULE = 'SET_ACTIVE_PROGRAM_MODULE';
export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const SET_SELECTED_GAME_ID = 'SET_SELECTED_GAME_ID';
export const SET_ACTIVE_SUPERPOWERS = 'SET_ACTIVE_SUPERPOWERS';
export const SET_ACTIVE_VIRTUES = 'SET_ACTIVE_VIRTUES';
export const SET_ACTIVE_NAV = 'SET_ACTIVE_NAV';
export const SET_REDAIL_BTN = 'SET_REDAIL_BTN';
export const SET_HELP_BTN = 'SET_HELP_BTN';
export const SET_HELP_MESSAGE = 'SET_HELP_MESSAGE';
export const SET_REBECCA = 'SET_REBECCA';
export const SET_LEVEL = 'SET_LEVEL';
export const SET_ABILITIES = 'SET_ABILITIES';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_COLOR = 'SET_LOADING_COLOR';
export const SET_SETTING = 'SET_SETTING';
export const SET_CITY_CONTRIB_VISIBLE = 'SET_CITY_CONTRIB_VISIBLE';
export const SET_CITY_DESC_VISIBLE = 'SET_CITY_DESC_VISIBLE';
export const SET_SELECTED_CITY = 'SET_SELECTED_CITY';
export const UPDATE_SELECTED_CITY = 'UPDATE_SELECTED_CITY';

// save earth
export const CITIES = 'CITIES';
export const HIDE_MISSION_POPUP = 'HIDE_MISSION_POPUP';
export const SAVE_UPCOMING_CITY = 'SAVE_UPCOMING_CITY';
export const TOGGLE_UPCOMING_CITY_BANNER = 'TOGGLE_UPCOMING_CITY_BANNER';
export const SHOW_MISSION_POPUP = 'SHOW_MISSION_POPUP';
export const CLEAR_RELEASED_MISSIONS = 'CLEAR_RELEASED_MISSIONS';
export const CITY_REQUIREMENTS = 'CITY_REQUIREMENTS';
export const CITY_CONTRIBUTIONS = 'CITY_CONTRIBUTIONS';
export const SET_CITY_CONTRIBUTION = 'SET_CITY_CONTRIBUTION';
export const SET_LEADERBOARD = 'SET_LEADERBOARD';
export const UPDATE_CITY_MISSION_ATTRIBUTES = 'UPDATE_CITY_MISSION_ATTRIBUTES';
export const ADD_CITY_COMPLETED_OR_FAILED_DATA = 'ADD_CITY_COMPLETED_OR_FAILED_DATA';
export const REMOVE_CITY_COMPLETED_OR_FAILED_DATA = 'REMOVE_CITY_COMPLETED_OR_FAILED_DATA';

// chat
export const ADD_MESSAGE = 'ADD_MESSAGE';

//leaderboard
export const SET_LEADERBOARD_DATA = 'SET_LEADERBOARD_DATA'
export const SET_TEAM_LEADERBOARD_DATA = 'SET_TEAM_LEADERBOARD_DATA'

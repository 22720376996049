import React from 'react';
import { View, TouchableOpacity, Text, useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectCharacterStyle } from './styles';
import { updateSession } from '../../actions/player';

const SelectCharacterBtn = () => {
  const playerId = useSelector(state => state.player.id);
  const window = useWindowDimensions();
  const styles = getSelectCharacterStyle(window.width, window.height);

  const dispatch = useDispatch();

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.btn}
        onPress={() =>
          dispatch(
            updateSession({ id: playerId, stage: 'profile', level_id: '1' }),
          )
        }>
        <Text style={styles.txt}>Select Character</Text>
      </TouchableOpacity>
    </View>
  );
};

export default SelectCharacterBtn;

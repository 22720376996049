import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  useWindowDimensions,
} from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { requestLogin } from './../../../actions/auth';
import { setLoadingState } from '../../../actions/status';

import { getLoginStyle } from './styles';
import { $bdCartoon } from '../../../constants/fonts';
import { useSFX } from '../../../components/SFXConfig';
import { SOUNDS } from '../../../constants/sfx';
import useMediaQuery from '../../../hooks/useMediaQuery';
import FormBGContainer from '../FormBGContainer';

const LoginScreen = ({ navigation, modal, onForgetClick, closeModal }) => {
  const [username, setUsername] = useState('');
  const { playSound } = useSFX();
  const [password, setPassword] = useState('');
  const [validForm, setValidForm] = useState(true);
  const w = useWindowDimensions();
  const { isMobile } = useMediaQuery();

  const styles = getLoginStyle(w.width, w.height, isMobile);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (username && password) {
      dispatch(setLoadingState(true));
      const { error } = await dispatch(requestLogin(username, password));
      dispatch(setLoadingState(false));
      setValidForm(true);
      if (error) {
        alert(error);
      } else {
        if(modal){
          closeModal();
        }
        if (playSound) {
          playSound({
            sound: SOUNDS.joinGame,
            force: true
          })
        }
      }
    } else {
      alert('email and password is required');
      setValidForm(false);
    }
  };

  const onPressForgot = () => {
    if(modal){
      onForgetClick()
    } else {
      navigation.navigate('ForgotPassword');
    }
  };

  let passwordRef = '';

  return (
    <FormBGContainer hideBG={modal}>
      <Text style={styles.formTxt}>Email</Text>
      <TextInput
        value={username}
        onChangeText={username => setUsername(username)}
        returnKeyType={'next'}
        onSubmitEditing={() => passwordRef.focus()}
        disableFullscreenUI={true}
        autoCapitalize="none"
        blurOnSubmit={false}
        style={[
          styles.formInput,
          validForm ? '' : styles.invalidForm,
        ]}
        autoCompleteType="email"
      />
      <Text style={styles.formTxt}>Password</Text>
      <TextInput
        ref={input => {
          passwordRef = input;
        }}
        onSubmitEditing={handleSubmit}
        value={password}
        onChangeText={password => setPassword(password)}
        disableFullscreenUI={true}
        secureTextEntry={true}
        style={[
          styles.formInput,
          validForm ? '' : styles.invalidForm,
        ]}
        autoCompleteType="password"
      />
      <View style={styles.otherOpt}>
        <TouchableOpacity onPress={onPressForgot}>
          <Text style={styles.forgotLabel}>Forgot Password?</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        style={styles.submitButton}
        onPress={handleSubmit}
      >
        <Text
          style={[styles.formTxt, { color: "white", fontFamily: $bdCartoon, letterSpacing: 4 }]}
        >
          Begin Journey
        </Text>
      </TouchableOpacity>
    </FormBGContainer>
  );
};

LoginScreen.propTypes = {
  navigation: PropTypes.object,
};

export default LoginScreen;

import React, { useState } from 'react';
import { View, ImageBackground, Text, TouchableOpacity, useWindowDimensions, ScrollView } from 'react-native';
import PropTypes from 'prop-types';

import Config from '../../config';
import RadioButtons from '../../components/radioButtons';
import { submitAnswer } from '../../actions/programModules';

import { getProgramStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingState } from '../../actions/status';
import { $blue } from '../../constants/colors';
import QuestionStatement from '../../components/questionWithLink';
import CheckboxButtons from '../../components/checkboxButtons';

const QuestionSlide = ({ id }) => {
  const [ans, setAns] = useState({});
  const programModuleId = useSelector(state => state.status.programModule.id);
  const questionData = useSelector(state => state.programModules.questions);
  const question = questionData[id];

  const window = useWindowDimensions()

  const styles = getProgramStyles(window.width, window.height);

  const dispatch = useDispatch();

  const getSelectedValue = (ans, question) => {
    const selectedAns = ans;
    const currentSlectedAns = { [question.id]: ans };
    setAns({ ...selectedAns, ...currentSlectedAns });
  };

  const submitAns = async question => {
    const currentAns = ans[question.id];
    if (!currentAns) {
      alert('please select an option');
      return;
    }
    dispatch(setLoadingState({ state: true, color: $blue }));
    const answerData = {
      programModuleId: programModuleId,
      questionId: question.id,
    };
    if(question?.multiple){
      answerData.answerIds = currentAns;
    } else {
      answerData.answerId = currentAns?.id;
    }
    await dispatch(submitAnswer(answerData));
    dispatch(setLoadingState(false));
  };

  return (
    <View style={styles.carouselQuestionCtn}>
      <ImageBackground
        source={{
          uri: Config.IMG_URL + '/practice_missions/slide_bg.png',
        }}
        style={styles.carouselQuestionBg}
        resizeMode="contain">
        <ScrollView
          ref={ref => {
            if(ref?.setNativeProps)
            ref.setNativeProps({scroll:"show_scroll"})
          }}
        >
          <QuestionStatement
            statement={question.statement} 
          />
          {Boolean(question?.multiple) ? 
          <CheckboxButtons
            getSelectedValue={value => getSelectedValue(value, question)}
            options={question.options}
            keys={{ id: 'id', text: 'answer' }}
            selectIds={question.transaction.answerIds || []}
            showSelectedBadge={!!question.transaction.answerIds}
          />
          :
          <RadioButtons
            getSelectedValue={value => getSelectedValue(value, question)}
            options={question.options}
            keys={{ id: 'id', text: 'answer' }}
            selectId={question.transaction.answerId}
            showSelectedBadge={!!question.transaction.answerId}
          />}
        </ScrollView>
        <View
          style={{
            width: "85%",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            minHeight: window.height*0.2,
            flex: 1
          }}
        >
          {!(question.transaction.answerId || question.transaction.answerIds) && (
            <TouchableOpacity
              style={styles.carouselQuestionSubmitCtn}
              onPress={() => submitAns(question)}>
              <ImageBackground
                source={{
                  uri: Config.IMG_URL + '/icons/btn_bg.png',
                }}
                style={styles.carouselQuestionSubmitBg}
                resizeMode="contain">
                <Text style={styles.carouselQuestionSubmit}>Submit</Text>
              </ImageBackground>
            </TouchableOpacity>
          )}
        </View>
      </ImageBackground>
    </View>
  );
};

QuestionSlide.propTypes = {
  id: PropTypes.number,
};

export default React.memo(QuestionSlide);

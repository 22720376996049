import { StyleSheet } from 'react-native';
import { $yellow, $white } from '../../constants/colors';

export const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    height: 50,
    justifyContent: 'center',
    margin: 10,
    width: '80%',
  },

  container: {
    alignItems: 'center',
    backgroundColor: $white,
    display: 'flex',
    height: '70%',
    justifyContent: 'center',
    width: '60%',
  },
});

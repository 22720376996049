import AsyncStorage from '@react-native-community/async-storage';
import { SET_USER_LOGIN, LOGOUT } from '../constants/actions';
import { login, logout } from '../services/auth';
import { setSocket } from '../services/socket';

export const requestLogin = (username, password) => async dispatch => {
  let curResolve = null;
  login({ username, password })
    .then(async result => {
      if (result?.error) {
        console.log(result, "--error--")
      }
      if (result?.data) {
        let data = result.data;
        const loginData = {
          id: data.user.id,
          accessToken: data.access_token,
          tokenType: data.token_type,
          email: username,
        };
        await AsyncStorage.setItem('userToken', loginData.accessToken);
        dispatch({
          type: SET_USER_LOGIN,
          payload: true,
        });

        curResolve({ data });
      }
    }).catch(error => {
      if (error === undefined) {
        curResolve({ error: 'Please check your internet connection' });
        return
      }
      curResolve(error);
    });

  return new Promise((resolve, reject) => {
    curResolve = resolve;
  })
};

export const requestLogout = () => async dispatch => {
  try {
    await logout().catch(error => {
      if (error.response === undefined) {
        throw new Error({ msg: 'Please check your internet connection' });
      }
      throw error.response.data;
    });
    await AsyncStorage.clear();
    setSocket().unsubscribe();
    dispatch({
      type: LOGOUT,
    });
  } catch (error) {
    console.log(error);
    return { error };
  }
};

import { StyleSheet } from 'react-native';
import { $bdCartoon } from '../../constants/fonts';
import { $yellow, $white, $black } from '../../constants/colors';

export const getVideoStyle = (width, height) => StyleSheet.create({
  carouselView: {
    height: height,
    width: width,
  },

  container: {
    height: height,
    width: width,
  },

  imageLoading: {
    left: '48%',
    position: 'absolute',
    top: '45%',
  },
});

export const getSelectCharacterStyle = (width, height) =>  StyleSheet.create({
  btn: {
    backgroundColor: $yellow,
    justifyContent: "center",
    alignItems: "center",
    padding: height*0.02,
    shadowColor: $black,
    shadowOffset: {height: 4, width: 4},
    shadowRadius: 10,
    shadowOpacity: 0.5,
    elevation: 10,
    maxWidth: width*0.12
  },

  container: {
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: '42%',
    zIndex: 10,
  },

  txt: {
    color: $white,
    fontFamily: $bdCartoon,
    letterSpacing: 2,
    fontSize: height*0.05,
    fontWeight: '400',
    textTransform: "capitalize",
    textAlign: "center"
  },
});

import
ActionCable
    from 'actioncable'
import AsyncStorage from '@react-native-community/async-storage';
import Config from "../config";
import { UPDATE_SUPERPOWER_ATTRIBUTES, UPDATE_VIRTUE_ATTRIBUTES, UPDATE_CITY_MISSION_ATTRIBUTES, ADD_CITY_COMPLETED_OR_FAILED_DATA, SET_ENABLE_VIRTUES } from '../constants/actions';
import { getPlayerData } from '../actions/player';
import { getCities } from '../actions/cities';

let socket = null;

async function initializeSocket() {
    if (socket) {
        return;
    }
    const access_token = await AsyncStorage.getItem('userToken');
    if (access_token) {
        const url = Config.WEB_SOCKET_URL + "?access_token=" + access_token;
        socket = ActionCable.createConsumer(url)
    }
}

// initializeSocket();

export const setSocket = () => {
    let gameId = null;
    let playerId = null;
    let newDispatch = null;
    let channel = null;
    let playerChannel = null;

    const handleConnected = () => {
        console.log("--cable-connected--")
    }

    const handleReceived = (data) => {
        if (data && data.data?.type === "super_power") {
            newDispatch({
                type: UPDATE_SUPERPOWER_ATTRIBUTES,
                payload: {
                    data: {
                        isEnabled: data.data.attributes.is_enabled,
                        isDisabled: data.data.attributes.is_disabled,
                        isActivated: data.data.attributes.is_activated,
                        id: data.data.id
                    }
                }
            })
        }
        if (data && data.data?.type === "virtue") {
            newDispatch({
                type: UPDATE_VIRTUE_ATTRIBUTES,
                payload: {
                    data: {
                        isEnabled: data.data.attributes.is_enabled,
                        isDisabled: data.data.attributes.is_disabled,
                        isActivated: data.data.attributes.is_activated,
                        id: data.data.id
                    }
                }
            })
        }
        if (data && data.data?.type === "city_mission") {
            const item = data.data;
            newDispatch({
                type: UPDATE_CITY_MISSION_ATTRIBUTES,
                payload: {
                    data: {
                        startedAt: item.attributes.started_at,
                        endedAt: item.attributes.ended_at,
                        state: item.attributes.state,
                        percentCompletion: item.attributes.percent_completion,
                        isInactive: item.attributes.is_inactive,
                        isActive: item.attributes.is_active,
                        isCompleted: item.attributes.is_completed,
                        isFailed: item.attributes.is_failed,
                        initiatorId: item.attributes.initiator_id,
                        duration: item.attributes.duration,
                        id: data.data.id
                    }
                }
            })
            if (item?.attributes?.is_boss_fight) {
                newDispatch(getPlayerData);
                newDispatch(getCities());
            }
            if (item.attributes?.is_completed || item.attributes.is_failed || item.attributes.state === "retry") {
                if (item.attributes?.is_completed) {
                    newDispatch(getPlayerData());
                }
                newDispatch({
                    type: ADD_CITY_COMPLETED_OR_FAILED_DATA,
                    payload: {
                        data: {
                            startedAt: item.attributes.started_at,
                            endedAt: item.attributes.ended_at,
                            state: item.attributes.state,
                            percentCompletion: item.attributes.percent_completion,
                            isInactive: item.attributes.is_inactive,
                            isActive: item.attributes.is_active,
                            isCompleted: item.attributes.is_completed,
                            isFailed: item.attributes.is_failed,
                            initiatorId: item.attributes.initiator_id,
                            duration: item.attributes.duration,
                            id: data.data.id,
                            ...item
                        }
                    }
                })
            }
        }
    }

    const handleDisconnected = () => {
        console.log("--cable-disconnected--")
    }
    const playerHandleConnected = () => {
        console.log("--player--cable-connected--")
    }

    const playerHandleReceived = (data) => {
        if (data && data.data?.type === "virtues") {
            newDispatch({
                type: UPDATE_VIRTUE_ATTRIBUTES,
                payload: {
                    data: {
                        isEnabled: data.data.attributes.is_enabled,
                        isDisabled: data.data.attributes.is_disabled,
                        isActivated: data.data.attributes.is_activated,
                        id: data.data.id
                    }
                }
            })
            if (data?.data?.attributes?.is_enabled) {
                newDispatch({
                    type: SET_ENABLE_VIRTUES,
                    payload: {
                        single: true,
                        id: data?.data?.id
                    }
                })
            }
        }
    }

    const playerHandleDisconnected = () => {
        console.log("--player--cable-disconnected--")
    }


    return {
        subscribe: async (game, dispatch, player) => {
            if (!socket) {
                await initializeSocket();
            }
            if (gameId && channel) {
                //@to-do unscribe
                channel.removeListener('received', handleReceived)
                    .removeListener('connected', handleConnected)
                    .removeListener('rejected', handleDisconnected)
                    .removeListener('disconnected', handleDisconnected);
                channel.unsubscribe();
                channel = null;
            }
            if (playerId && playerChannel) {
                //@to-do unscribe
                playerChannel.removeListener('received', playerHandleReceived)
                    .removeListener('connected', playerHandleConnected)
                    .removeListener('rejected', playerHandleDisconnected)
                    .removeListener('disconnected', playerHandleDisconnected);
                playerChannel.unsubscribe();
                playerChannel = null;
            }
            gameId = game;
            playerId = player;
            newDispatch = dispatch;
            channel = socket.subscriptions.create({
                channel: "GameChannel",
                id: gameId
            }, {
                connected: handleConnected,
                received: handleReceived,
                disconnected: handleDisconnected
            })
            playerChannel = socket.subscriptions.create({
                channel: "PlayerChannel",
                id: playerId
            }, {
                connected: playerHandleConnected,
                received: playerHandleReceived,
                disconnected: playerHandleDisconnected
            })
        },
        unsubscribe: async () => {
            if (gameId && channel) {
                //@to-do unscribe
                channel.removeListener('received', handleReceived)
                    .removeListener('connected', handleConnected)
                    .removeListener('rejected', handleDisconnected)
                    .removeListener('disconnected', handleDisconnected);
                channel.unsubscribe();
                channel = null;
                gameId = null;
            }
            if (playerId && playerChannel) {
                //@to-do unscribe
                playerChannel.removeListener('received', playerHandleReceived)
                    .removeListener('connected', playerHandleConnected)
                    .removeListener('rejected', playerHandleDisconnected)
                    .removeListener('disconnected', playerHandleDisconnected);
                playerChannel.unsubscribe();
                playerChannel = null;
            }
        }
    }
}
import React from 'react';
import { ImageBackground, ScrollView, Text, useWindowDimensions, View } from 'react-native';
import PropTypes from 'prop-types';

import { getStyle } from './styles';
import Config from '../../config';
import VideoSlide from '../programSlides/videoSlide';

const LedtCtn = ({ city }) => {
  const window = useWindowDimensions();
  const styles = getStyle(window.width, window.height)

  return (
    <ImageBackground
      resizeMode="stretch"
      source={{ uri: Config.IMG_URL + '/save_earth/new_bg_left.png' }}
      style={styles.leftBg}>
      <View style={styles.leftContainer} onStartShouldSetResponder={() => true}>
        {Boolean(city?.videoUrl) ?
          <>
            <Text style={[styles.descHead, {
              position: "absolute",
              top: 20,
              left: window.height * 0.06,
              zIndex: 1
            }]}>{city.displayName}</Text>
            <View
              style={{
                position: "relative",
                width: "84.5%",
                height: "100%",
                marginTop: "0.6%",
                marginLeft: "0.7%"
              }}
            >
              <VideoSlide
                url={city?.videoUrl}
                hideSkipControl
              />
            </View>
          </> :
          <ScrollView
            contentContainerStyle={{
              paddingVertical: window.height * 0.03,
              paddingRight: window.height * 0.24,
              paddingLeft: window.height * 0.04
            }}
          >
            <Text style={styles.descHead}>{city.displayName}</Text>
            <Text style={styles.desc}>{city.description}</Text>
          </ScrollView>
        }
      </View>
    </ImageBackground>
  );
};

LedtCtn.propTypes = {
  city: PropTypes.object,
};

export default LedtCtn;

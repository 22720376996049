import React from 'react';
import PropTypes from 'prop-types';

import { getVideoStyle } from './styles';
import Video from '../../lib/video/Video';
import { useWindowDimensions } from 'react-native';


const VideoSlide = ({ url, pause, videoStyle }) => {
  const window = useWindowDimensions()
  const styles = getVideoStyle(window.width, window.height);

  return (
    <>
      <Video
        paused={pause}
        source={{ uri: url }}
        posterResizeMode="center"
        resizeMode="cover"
        style={styles.carouselView}
        videoStyle={videoStyle || {
          width: "100%"
        }}
      />
    </>
  );
};

VideoSlide.propTypes = {
  url: PropTypes.string,
};

export default React.memo(VideoSlide);

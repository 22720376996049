import { StyleSheet } from 'react-native';
import { $blue, $red } from './../constants/colors';
import { $bdCartoon } from '../constants/fonts';

export const NavBtnStyles = StyleSheet.create({
  btn: {
    bottom: 10,
    height: 60,
    left: -10,
    position: 'absolute',
    width: 70,
    zIndex: 100000,
  },

  full: {
    height: '100%',
    width: '100%',
  },
});

export const getStyle = (width, height) =>  StyleSheet.create({
  backBtn: {
    bottom: height*0.05,
    height: height*0.1,
    left: height*0.03,
    position: 'absolute',
    width: height*0.16,
  },

  backdrop: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },

  btnAbilities: {
    left: '31%',
    top: '56%',
  },

  btnBg: {
    display: 'flex',
    height: height*0.13,
    justifyContent: 'center',
    width: height*0.49,
  },

  btnChat: {
    left: '30%',
    top: '24%',
  },

  btnCtn: {
    position: 'absolute',
  },

  btnDashboard: {
    left: '21%',
    top: '72%',
  },

  btnImg: {
    height: height*0.08,
    marginLeft: height*0.008,
    marginTop: height*0.007,
    width: height*0.105,
  },

  btnImgactive: {
    marginLeft: height*0.027,
    marginTop: -(height*0.009),
  },

  btnPracticeMission: {
    left: '34%',
    top: '40%',
  },

  btnSaveEarth: {
    left: '19%',
    top: '10%',
  },

  btnTxt: {
    color: $blue,
    fontFamily: $bdCartoon,
    left: height*0.13,
    fontSize: height*0.025,
    position: 'absolute',
  },

  btnTxtactive: {
    color: $red,
    paddingBottom: height*0.01,
    left: height*0.15,
  },

  full: {
    height: '100%',
    width: '100%',
  },

  navBg: {
    height: '100%',
    left: -(width*0.03),
    position: 'absolute',
    top: 0,
    width: '50%',
    zIndex: 1
  },
});

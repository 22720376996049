import createReducer from '../lib/createReducer';
import {
  SET_ACTIVE_PROGRAM,
  SET_ACTIVE_PROGRAM_MODULE,
  SET_ACTIVE_SUPERPOWERS,
  SET_ACTIVE_VIRTUES,
  SET_ACTIVE_NAV,
  SET_REDAIL_BTN,
  SET_HELP_BTN,
  SET_HELP_MESSAGE,
  SET_REBECCA,
  SET_ABILITIES,
  SET_LOADING,
  SET_CITY_DESC_VISIBLE,
  SET_CITY_CONTRIB_VISIBLE,
  SET_SETTING,
  SET_LOADING_COLOR,
  LOGOUT,
  LEAVE_GAME,
  SET_SELECTED_CITY,
  UPDATE_SELECTED_CITY,
  SET_LEADERBOARD,
  UPDATE_CITY_MISSION_ATTRIBUTES,
  HIDE_MISSION_POPUP,
  SHOW_MISSION_POPUP,
  SET_MUSIC_PLAYING,
  SET_SFX_VALUE,
  SET_ENABLE_VIRTUES
} from '../constants/actions';
import { $yellow } from '../constants/colors';

const initialState = {
  slectedCity: {},
  program: {},
  programModule: {},
  nav: '',
  isRedailBtnOpen: false,
  superpowers: {},
  superpowersByName: {},
  virtues: {},
  virtuesByName: {},
  loading: false,
  loadingColor: $yellow,
  isRebeccaVisible: false,
  isHelpVisible: false,
  isSettingVisible: false,
  isCityDescVisible: false,
  isCityContribVisible: false,
  showMissionPopupModal: true,
  helpMessage: '',
  helpAudio: '',
  abilities: {
    superPower: 'civilian',
    tab: 'superpowers',
  },
  leaderboard: {
    tab: "team_score"
  },
  musicIsPlaying: false,
  sfxIsON: false,
  virtue_enabled: []
};

export const status = createReducer(initialState, {
  [SET_ACTIVE_PROGRAM](state, { payload }) {
    return { ...state, program: { ...payload } };
  },
  [SET_ACTIVE_PROGRAM_MODULE](state, { payload }) {
    return { ...state, programModule: { ...payload } };
  },
  [SET_MUSIC_PLAYING](state, { payload }) {
    return { ...state, musicIsPlaying: payload.play };
  },
  [SET_SFX_VALUE](state, { payload }) {
    return { ...state, sfxIsON: payload.play };
  },
  [SHOW_MISSION_POPUP](state) {
    return { ...state, showMissionPopupModal: true };
  },
  [HIDE_MISSION_POPUP](state) {
    return { ...state, showMissionPopupModal: false };
  },
  [SET_ACTIVE_SUPERPOWERS](state, { payload }) {
    const superpowersByName = {};
    Object.keys(payload).map(
      data => (superpowersByName[payload[data].name] = payload[data]),
    );
    return { ...state, superpowers: { ...payload }, superpowersByName };
  },
  [SET_ACTIVE_VIRTUES](state, { payload }) {
    const virtuesByName = {};
    Object.keys(payload).map(
      data => (virtuesByName[payload[data].name] = payload[data]),
    );
    return { ...state, virtues: { ...payload }, virtuesByName };
  },
  [SET_ENABLE_VIRTUES](state, { payload }) {
    if(payload?.single){
      let virtues = state?.virtue_enabled || [];
      virtues?.push(payload?.id);
      return { ...state, virtue_enabled: [...virtues]}
    }
    return { ...state, virtue_enabled: payload?.virtues };
  },
  [SET_ACTIVE_NAV](state, { payload }) {
    return { ...state, nav: payload };
  },
  [SET_ABILITIES](state, { payload }) {
    const abilities = state.abilities;
    return { ...state, abilities: { ...abilities, ...payload } };
  },
  [SET_LEADERBOARD](state, { payload }) {
    const leaderboard = state.leaderboard;
    return { ...state, leaderboard: { ...leaderboard, ...payload } };
  },
  [SET_LOADING](state, { payload }) {
    return { ...state, loading: payload };
  },
  [SET_LOADING_COLOR](state, { payload }) {
    return { ...state, loadingColor: payload };
  },
  [SET_REDAIL_BTN](state, { payload }) {
    return { ...state, isRedailBtnOpen: payload };
  },
  [SET_HELP_BTN](state, { payload }) {
    return { ...state, isHelpVisible: payload };
  },
  [SET_HELP_MESSAGE](state, { payload }) {
    if(payload?.message === ''){
      return {
        ...state,
        helpMessage: '',
        helpAudio: ''
      }
    }
    if(payload?.message){
      return { ...state, helpMessage: payload?.message, helpAudio: payload?.audio}
    }
    return { ...state, helpAudio: payload?.audio };
  },
  [SET_REBECCA](state, { payload }) {
    return { ...state, isRebeccaVisible: payload };
  },
  [SET_SETTING](state, { payload }) {
    return { ...state, isSettingVisible: payload };
  },
  [SET_CITY_DESC_VISIBLE](state, { payload }) {
    return { ...state, isCityDescVisible: payload };
  },
  [SET_CITY_CONTRIB_VISIBLE](state, { payload }) {
    return { ...state, isCityContribVisible: payload };
  },
  [SET_SELECTED_CITY](state, { payload }) {
    return { ...state, slectedCity: payload };
  },
  [UPDATE_CITY_MISSION_ATTRIBUTES](state, { payload: { data } }) {
    let cityData = state?.slectedCity;
    if(cityData?.id && cityData.id.toString() === data.id.toString()){
      cityData = {
        ...cityData,
        ...data
      }
      return { ...state, slectedCity: cityData };
    }
    return state;
  },
  [UPDATE_SELECTED_CITY](state, { payload }) {
    return {
      ...state,
      slectedCity: { ...payload, players: [...payload.players] },
    };
  },
  [LEAVE_GAME](state, { payload }) {
    return {
      ...initialState,
      sfxIsON: state.sfxIsON
    };
  },
  [LOGOUT](state, { payload }) {
    return {
      ...initialState,
      sfxIsON: state.sfxIsON
    };
  },
});

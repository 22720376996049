import React from 'react';
import { View, ImageBackground, Image } from 'react-native';

import { styles } from './styles';
import Config from '../../config';

const SplashScreen = () => {
  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/login/new-login-bg-sw.png' }}
      style={styles.container}>
      <View style={styles.headingContainer}>
        <Image
          source={{ uri: Config.IMG_URL + '/login/title_text.png' }}
          resizeMode="contain"
          style={styles.headingBg}
        />
      </View>
    </ImageBackground>
  );
};

export default SplashScreen;

import React, { useState, useEffect } from 'react';
import {
  Image,
  View,
  TextInput,
  Keyboard,
  ActivityIndicator,
  useWindowDimensions,
  TouchableOpacity,
  Text,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Config from '../../config';
import { updateCharacter } from '../../actions/player';
import { setLoadingState } from '../../actions/status';

import { getRightCtnStyle } from './styles';
import { $liteYellow, $yellow } from '../../constants/colors';
import ModalDropDown from '../../components/ModalDropDown';
import { $bdCartoon, $robotoRegular } from '../../constants/fonts';
import { verifyCharacterName } from '../../services/player';
import { useModal } from '../../components/ModalProvider';
import AlertPopup from '../../components/alertPopup';
import { SOUNDS } from '../../constants/sfx';

let loaderTimeout = null

const RightCtn = ({ navigate, traits, selectedCharacter, player }) => {
  const [name, setName] = useState('');
  const [selectedTag, setSelectedTag] = useState({
    label: '',
    value: '',
    key: ''
  });
  const [avatarType, setAavatarType] = useState('civilian');
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();

  const playerId = useSelector(state => state.player.id);
  const game_tags = useSelector(state => state?.games?.currentGame?.game_tags);
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const styles = getRightCtnStyle(window.width, window.height);

  useEffect(() => {
    if (selectedCharacter.name || player.profile.characterName) {
      setLoading(true);
    }
  }, [selectedCharacter, player.profile]);

  const handlePress = async name => {
    if (!selectedCharacter.name && !player.profile.characterName) {
      alert('please select a character first');
      Keyboard.dismiss();
      return;
    }
    if (!name && !player.name) {
      alert("field can't be blank");
      Keyboard.dismiss();
      return;
    }
    if (game_tags?.length && !selectedTag?.value && !player?.tags) {
      alert("Please select a team.");
      return;
    }
    const verifyData = await verifyCharacterName(playerId, name || player?.name);
    if (verifyData?.data) {
      openModal({
        open: true,
        component: AlertPopup,
        blur: true,
        data: {
          sound: SOUNDS.helpButton,
          heading: "Uh Oh!",
          message: verifyData?.data || "Name is either taken or Invalid. Please change the name.",
          yesText: "Okay",
          singleButton: true,
        }
      })
      return;
    }
    await dispatch(setLoadingState(true));
    const updatedValue = {
      id: playerId,
      name: name || player.name,
      character_name: selectedCharacter.name || player.profile.characterName,
      character_id: selectedCharacter.id || player.profile.characterId,
    }
    if (game_tags?.length && selectedTag?.value) {
      updatedValue.tag = selectedTag?.value
    }
    const { error } = await dispatch(
      updateCharacter(updatedValue),
    );
    dispatch(setLoadingState(false));
    if (error) {
      alert(
        (error && error.errors && error?.errors[0].detail) ||
        'some error occurred please try again later',
      );
      return;
    }
    navigate('Traits', { traits });
  };

  const changeAvatar = type => {
    Keyboard.dismiss();
    if (loaderTimeout) {
      clearTimeout(loaderTimeout)
      loaderTimeout = null
    }
    setLoading(true);
    loaderTimeout = setTimeout(() => {
      setLoading(false)
    }, 1000)
    setAavatarType(type);
  };

  return (
    <View style={styles.container}
    >
      <TouchableOpacity
        onPressIn={() => { changeAvatar('spacehero'); return true; }}
        onPressOut={() => changeAvatar('civilian')}
        activeOpacity={1}
        style={{
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "6%",
        }}
      >
        <Image
          resizeMode="contain"
          style={styles.image}
          onLoadEnd={() => setLoading(false)}
          source={{
            uri:
              Config.IMG_URL +
              `/characters/${selectedCharacter.name ||
              player.profile.characterName}_${avatarType}.png`,
            cache: "reload"
          }}
        />
        {loading && (
          <ActivityIndicator
            style={styles.imageLoading}
            size="large"
            color={$yellow}
          />
        )}
        <View
          style={{
            position: "absolute",
            right: 10,
          }}
        >
          <Text
            style={{
              fontFamily: $robotoRegular,
              color: $liteYellow,
              fontSize: window.height * 0.016,
              textAlign: "right",
              marginBottom: 10
            }}
          >
            Click and hold the character to see their spacesuit
          </Text>
        </View>
      </TouchableOpacity>
      <View style={styles.inputContainer}>
        <TextInput
          style={styles.input}
          value={name || player.name}
          onChangeText={setName}
          placeholder="Write your name"
          disableFullscreenUI={true}
          onSubmitEditing={() => handlePress(name)}
        />
        {Boolean(game_tags?.length) &&
          <ModalDropDown
            placeholder="Select Team"
            options={game_tags}
            selected={selectedTag}
            onSelectionChange={setSelectedTag}
          />}
        <TouchableOpacity
          style={[styles.submitButton, { marginTop: 3 }]}
          onPress={() => handlePress(name)}
        >
          <Text
            style={{
              color: "white",
              fontFamily: $bdCartoon,
              letterSpacing: 4,
              fontSize: window.height * 0.03
            }}
          >
            Next
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

RightCtn.propTypes = {
  selectedCharacter: PropTypes.object,
  player: PropTypes.object,
  traits: PropTypes.array,
  navigate: PropTypes.func,
};

export default RightCtn;

import { StyleSheet } from 'react-native';
import { $white, $black } from './../../../constants/colors';
import { $robotoRegular } from './../../../constants/fonts';

export const RightPanelStyles = (width, height) => StyleSheet.create({
  wrapper: {
    display: 'flex',
    flex: 1.5,
    flexDirection: 'column',
  },
});

export const TimerStyles = (width, height) => StyleSheet.create({
  bg: {
    height: height*0.32,
    position: 'absolute',
    right: 0,
    width: height*0.4,
    top: height*0.09
  },

  detonation: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingBottom: 5,
  },

  timer: {
    fontFamily: $robotoRegular,
    fontSize: height*0.06,
    lineHeight: height*0.06,
    fontWeight: '400',
  },

  timerDays: {
    fontFamily: $robotoRegular,
    fontSize: height*0.064,
    lineHeight: height*0.07,
    fontWeight: '400',
  },

  txt: {
    fontFamily: $robotoRegular,
    fontSize: height*0.045,
    fontWeight: '400',
  },

  wrapper: { flex: 1.6 },
});

export const StatStyles = (width, height) => StyleSheet.create({
  cronosContainer: { left: -(height*0.09) },

  cronosIcon: { height: height*0.11, width: height*0.11, left: height*0.01 },

  epContainer: { left: -(height*0.055) },

  epIcon: { height: height*0.11, width: height*0.11 },

  iconHeading: {
    color: $black,
    flex: 4,
    fontSize: height*0.03,
    fontWeight: '700',
    position: 'relative',
    left: height*0.025
  },

  iconValue: {
    color: $white,
    flex: 5,
    fontSize: height*0.03,
    fontWeight: '700',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
  },

  statsContainer: {
    alignItems: 'center',
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 10,
    position: 'relative',
  },

  statsIcon: {
    position: 'relative',
    right: -10,
    zIndex: 99,
  },

  statsTop: {
    flex: 1.8,
    flexDirection: 'column',
    marginTop: height*0.08,
  },

  statsWindow: {
    display: 'flex',
    flexDirection: 'row',
    height: height*0.06,
    left: -20,
    position: 'relative',
    width: height*0.2,
  },

  xpContainer: { left: -(height*0.075) },

  xpIcon: { height: height*0.1, width: height*0.1 },
  xpStatMain: {
    left: -(height*0.015),
    top: -(height*0.006),
    justifyContent: 'center',
    alignItems: 'center'
  },
  epStatMain: {
    top: -(height*0.014),
    left: -(height*0.026),
    justifyContent: 'center',
    alignItems: 'center'
  },
  cronosStatMain: {
    left: -(height*0.024),
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export const ChestStyles = (width, height) => StyleSheet.create({
  chest: {
    height: '50%',
    width: '50%',
  },

  chestGlow: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  chestWindowBg: {
    bottom: -5,
    height: 120,
    left: -6,
    position: 'absolute',
    width: 210,
  },

  chestWindowWrapper: { flex: 1.8 },
});

import React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import { useDispatch } from 'react-redux';

import { setRebeccaState } from '../../actions/status';
import Config from '../../config';

import { styles } from './styles';

const RebeccaIcon = () => {
  const dispatch = useDispatch();

  return (
    <TouchableOpacity
      onPress={() => dispatch(setRebeccaState(false))}
      style={styles.rebecca}>
      <Image
        style={styles.full}
        resizeMode="contain"
        source={{
          uri: Config.IMG_URL + '/rebecca/astro.png',
        }}
      />
    </TouchableOpacity>
  );
};

export default RebeccaIcon;

import React from 'react'
import { Image } from 'react-native';
import { View } from 'react-native'
import Config from '../../../config';
import useMediaQuery from '../../../hooks/useMediaQuery';
import './menu.css'


const Header = ({ setMenuShow, showMenu }) => {
    const { isMobile } = useMediaQuery()

    return (
        <View
            style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
            }}
        >
            {isMobile && 
                <>
                    <input checked={showMenu} onClick={() => setMenuShow(!showMenu)} class="side-menu" type="checkbox" id="side-menu"/>
                    <label class="hamb" for="side-menu"><span class="hamb-line"></span></label>
                </>
            }
            <Image
                source={{ uri: Config.IMG_URL + '/login/title_text.png' }}
                resizeMode="contain"
                style={{
                    width: isMobile ? "120px" : "150px",
                    height: "60px"
                }}
                />
        </View>
    )
}

export default Header
import React from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import { $robotoBold, $robotoRegular } from '../constants/fonts';
import { $yellow } from '../constants/colors';
import * as RootNav from './../navigation/RootNav';

const SaveEarthHelp = () => {
  const name = useSelector(state => state.player.name);

  return (
    <>
      <View style={styles.container}>
        <ScrollView>
          <Text style={styles.name}>{name},</Text>
          <Text style={styles.txt}>
            Congratulations on completing this test mission. Now that you know how to do the missions, let's head over to the Abilities tab to learn how to unlock Equipment and Virtues.
          </Text>
        </ScrollView>
      </View>
      <TouchableOpacity
        style={styles.btn}
        onPress={() => RootNav.navigate('Abilities')}>
        <Text>Go to Abilities tab </Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    borderRadius: 5,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    marginLeft: 10,
    marginTop: 10,
    position: 'relative',
    top: -5,
    width: 150,
  },

  container: {
    height: '74%',
    paddingHorizontal: 10,
  },

  name: {
    fontFamily: $robotoBold,
    fontWeight: '900',
    fontSize: 16,
    marginVertical: 10,
  },

  txt: {
    fontFamily: $robotoRegular,
    fontSize: 16,
    marginRight: 10,
    fontWeight: '400',
  },
});

export default SaveEarthHelp;

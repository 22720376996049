import React from 'react'
import { Text, useWindowDimensions, ImageBackground } from 'react-native';
import Config from '../../../config';
import { $bdCartoon } from '../../../constants/fonts';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { PAGE_ID } from '../constants';

const AboutCompany = () => {
    const { height } = useWindowDimensions()
    const { isMobile } = useMediaQuery()

    return (
        <section >
            <div 
                style={{
                    display: "flex",
                    paddingBottom: "20px",
                    backgroundColor: "rgba(0,0,0,0.4)",
                    paddingTop: "60px"
                }}
                id={PAGE_ID.publisher}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 10px",
                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: isMobile ? "100%" : "85%"
                        }}
                    >
                        <div className="about_sidebar wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.2s" style={{visibility: "visible", animationDuration: "1.2s", animationDelay: "0.2s", animationName: "fadeInUp"}}>
                            <div className="about_title">
                                <img width="100" src="https://d33wubrfki0l68.cloudfront.net/7e69b744d3fd80615e5504b21329f3c2f9189e50/ffc34/assets/img/logo/gamitaricon.png" />
                                <h5 style={{marginLeft: "15px", fontFamily: "'Fredoka One', cursive"}}>ABOUT GAMITAR</h5>
                                <h2  style={{ fontFamily: $bdCartoon }}>we build games for you so you can focus on the important stuff: enabling learning</h2>
                            </div>
                            <div className="about_desc" 
                                style={{
                                    marginBottom: "0px"
                                }}
                            >
                                <p style={{ fontFamily: "'Fredoka One', cursive", fontSize: isMobile ? "1.1rem" : "1.4rem" }}>Gamitar is a young and empowered company focused on developing games for 
                                    learning practioners who can use these games for their own learning 
                                    programs. 
                                </p>
                                <p style={{ fontFamily: "'Fredoka One', cursive", fontSize: isMobile ? "1.1rem" : "1.4rem" }}>We are inspired by two key things: opportunity for growth through learning and 
                                    the power in games to create change. This drives us to never stop believing in
                                    the technologies we create. 
                                </p>
                                <p style={{ fontFamily: "'Fredoka One', cursive", fontSize: isMobile ? "1.1rem" : "1.4rem" }}> Thus far we have built two games: <a target="_blank" href='https://evivve.com/' alt="" >Evivve - The Leadership Game</a> and Superhero Within.
                                    Each one is unique from another. Evivve is designed for facilitators and practitioners 
                                    to gamify their virtual classNameroom experiences and enable learning on a set of competencies. 
                                    Superhero Within on the other hand, is an asyncronous game designed for continuous and 
                                    on-demand learning with fully customizable content insertion features. 
                                </p>
                            </div>
                            <div className="about_btn"
                                style={{
                                    marginBottom: "20px"
                                }}
                            >
                                <a className="btn btn-link wow animated" href="https://gamitar.com" target="_blank" style={{visibility: "visible"}} >
                                <ImageBackground
                                    source={{
                                        uri: Config.IMG_URL + '/save_earth/btn.png'
                                    }}
                                    style={{
                                        paddingHorizontal: "15px",
                                        paddingVertical: "10px",
                                        height: "120px",
                                        width: "300px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "35px",
                                    }}
                                >
                                    <Text
                                        style={[{
                                            fontFamily: "'Fredoka One', cursive",
                                            fontSize: height * 0.048,
                                            fontWeight: '400',
                                        }, { color: '#f9c184'}]}
                                    >
                                        Visit Website  
                                    </Text>
                                </ImageBackground>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutCompany;

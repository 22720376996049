import React from 'react';
import { View, TouchableOpacity, Image, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';

import Config from '../../config';
import { getStyle } from './styles';
import useMediaQuery from '../../hooks/useMediaQuery';

const CloseBtn = ({ close }) => {
  const window = useWindowDimensions()
  const { isMobile } = useMediaQuery()
  const styles = getStyle(window.width, window.height);

  return (
    <View style={[styles.closeContainer, {
      top: isMobile ? undefined : 0,
      bottom: isMobile && "70vh",
      right: isMobile && "-6px"
    }]}>
      <TouchableOpacity style={styles.closeImg} onPress={() => close()}>
        <Image
          resizeMode="contain"
          style={styles.closeImg}
          source={{
            uri: Config.IMG_URL + '/save_earth/close.png',
          }}
        />
      </TouchableOpacity>
    </View>
  );
};

CloseBtn.propTypes = {
  close: PropTypes.func,
};

export default CloseBtn;

import React, { useCallback, useEffect, useState } from 'react'
import { Image, ImageBackground, Text, TextInput, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import { requestLogout } from '../actions/auth';
import { leaveGame, updateCharacter } from '../actions/player';
import Config from '../config';
import { SET_MUSIC_PLAYING, SET_SFX_VALUE } from '../constants/actions';
import { $brown, $darkGreen, $white } from '../constants/colors';
import { $bdCartoon, $robotoRegular } from '../constants/fonts';
import { SOUNDS } from '../constants/sfx';
import useMediaQuery from '../hooks/useMediaQuery';
import { getProfileDetails } from '../services/chat';
import { setGamePreference } from '../services/player';
import Badges from './chat/badges';
import SendCronos from './chat/sendCronos';
import { useSFX } from './SFXConfig';


const getEquipmentAndVirtues = ({ included }) => {
    try {
        let superPowers = [];
        let virtues = [];

        let activeSuperpowers = {};
        let activeVirtues = {};

        included.forEach(element => {
            const data = {
                id: element.id,
                state: element.attributes.state,
                name: element.attributes.name,
                displayName: element.attributes.display_name,
                description: element.attributes.description,
                requiredEp: element.attributes.required_ep,
                isEnabled: element.attributes.is_enabled,
                isDisabled: element.attributes.is_disabled,
                isActivated: element.attributes.is_activated,
            };
            if (element.type === 'super_power') {
                superPowers.push(data);
                if (data.isActivated) {
                    activeSuperpowers[element.id] = data;
                }
            } else if (element.type === 'virtue') {
                virtues.push(data);
                if (data.isActivated) {
                    activeVirtues[element.id] = data;
                }
            }
        });
        return {
            equipments: activeSuperpowers,
            virtues: activeVirtues
        };
    } catch (error) {
        console.log(error);
        return {
            equipments: {},
            virtues: {}
        };
    }
}

const ChatProfileDetailPopup = ({
    closeModal,
    data
}) => {
    const { playSound } = useSFX();
    const { width, height } = useWindowDimensions();
    const [playerDetails, setPlayerDetails] = useState({});
    const [updatedPlayerName, setPlayerName] = useState("");
    const [edit, setShowEdit] = useState(false);
    const [missions, setMissions] = useState({});
    const player = useSelector(state => state?.player);
    const city_missions = useSelector(state => state.cities.data);
    const musicIsPlaying = useSelector(state => state.status.musicIsPlaying);
    const sfxIsON = useSelector(state => state.status.sfxIsON);
    const dispatch = useDispatch();
    const { isMobile } = useMediaQuery();

    useEffect(() => {
        setPlayerName(data?.user?.name);
        getPlayerDetail(data?.user?.id)
    }, [data])

    useEffect(() => {
        if (city_missions) {
            const missions = {};
            city_missions.forEach(mission => {
                if (mission.players.find(player => parseInt(player) === parseInt(data?.user?.id))) {
                    missions[mission.name] = {
                        ...mission
                    }
                }
            })
            setMissions(missions);
        }
    }, [city_missions, data])

    const getPlayerDetail = async (id) => {
        const res = await getProfileDetails(id);
        if (!res) {
            return
        }
        const { equipments, virtues } = getEquipmentAndVirtues(res.data)
        const playerProfile = res?.data?.data.attributes?.profile?.data;

        setPlayerDetails({
            equipments,
            virtues,
            trait: playerProfile?.attributes.trait,
            xp: res?.data.data?.attributes?.xp,
        })
    }

    const updatePlayerName = useCallback(async () => {
        if (player?.name === updatedPlayerName) {
            return;
        }
        const updatedValue = {
            id: player.id,
            name: updatedPlayerName,
            character_name: player.profile.characterName,
            character_id: player.profile.characterId,
        }
        if (player?.tag) {
            updatedValue.tag = player.tag
        }
        const { error } = await dispatch(
            updateCharacter(updatedValue),
        );
        if (error) {
            alert(error.message);
        }
    }, [
        updatedPlayerName,
        player,
        dispatch
    ])

    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height
            }}
            onStartShouldSetResponder={() => {
                if (ChatProfileDetailPopup.hold) {
                    ChatProfileDetailPopup.hold = false;
                    return false;
                }
                closeModal()
                return false;
            }}
        >

            <View
                style={{
                    width: "65%",
                    height: "80%",
                    minHeight: height * 0.6,
                    minWidth: width * 0.22
                }}
                onStartShouldSetResponder={() => {
                    ChatProfileDetailPopup.hold = true
                    return false;
                }}
            >
                <ImageBackground
                    source={{
                        uri: Config.IMG_URL + "/chat/profile_modal.png"
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row"
                    }}
                >
                    {data?.fromDashboard &&
                        <View
                            style={{
                                position: "absolute",
                                top: height * 0.06,
                                left: 0,
                                right: 0,
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: $bdCartoon,
                                    fontWeight: "600",
                                    fontSize: height * 0.065,
                                    color: $brown,
                                    letterSpacing: 4
                                }}
                            >
                                Settings
                            </Text>
                        </View>
                    }
                    <View
                        style={{
                            flex: 1,
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <View
                            style={{
                                width: height * 0.3,
                                height: height * 0.3,
                                borderRadius: height * 0.15,
                                backgroundColor: $darkGreen,
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Image
                                source={{
                                    uri: data?.user?.image
                                }}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        </View>
                        <View
                            style={{
                                flexDirection: "row"
                            }}
                        >
                            {Boolean(edit) ?
                                <TextInput
                                    value={updatedPlayerName}
                                    onChangeText={val => setPlayerName(val)}
                                    returnKeyType={'next'}
                                    onSubmitEditing={updatePlayerName}
                                    disableFullscreenUI={true}
                                    autoCapitalize="none"
                                    blurOnSubmit={false}
                                    style={{
                                        borderColor: $white,
                                        borderRadius: 5,
                                        borderWidth: 1,
                                        paddingVertical: 7,
                                        height: height * 0.06,
                                        paddingHorizontal: height * 0.01,
                                        fontSize: height * 0.037,
                                        color: $white,
                                        fontWeight: "600",
                                        fontFamily: $robotoRegular,
                                        marginVertical: 5,
                                        width: "70%",
                                        marginLeft: "4%"
                                    }}
                                />
                                :
                                <Text
                                    style={{
                                        fontFamily: $bdCartoon,
                                        fontWeight: "900",
                                        fontSize: height * 0.08,
                                        color: $darkGreen,
                                        letterSpacing: height * 0.005
                                    }}
                                >
                                    {data?.fromDashboard ? updatedPlayerName : data?.user?.name}
                                </Text>}
                            {(data?.fromDashboard && !edit) &&
                                <TouchableOpacity
                                    onPress={() => {
                                        setShowEdit(!edit);
                                    }}
                                    style={{
                                        marginLeft: 20,
                                        marginTop: 5
                                    }}
                                >
                                    <Image
                                        resizeMode="contain"
                                        style={{
                                            width: height * 0.04,
                                            height: height * 0.04,
                                            zIndex: 1
                                        }}
                                        source={{ uri: Config.IMG_URL + `/dashboard/${edit ? "tick" : "edit"}.png` }}
                                    />
                                </TouchableOpacity>
                            }
                            {(data?.fromDashboard && edit) &&
                                <TouchableOpacity
                                    onPress={() => {
                                        updatePlayerName();
                                        setShowEdit(!edit);
                                    }}
                                    style={{
                                        marginLeft: 20,
                                        marginTop: 3
                                    }}
                                >
                                    <Image
                                        resizeMode="contain"
                                        style={{
                                            width: height * 0.14,
                                            height: height * 0.06,
                                            zIndex: 1
                                        }}
                                        source={{ uri: Config.IMG_URL + `/save_earth/ok_button.png` }}
                                    />
                                </TouchableOpacity>
                            }
                        </View>
                        <View
                            style={{
                                flexDirection: "row"
                            }}
                        >
                            <Image
                                resizeMode="contain"
                                style={{
                                    width: height * (isMobile ? 0.11 : 0.06),
                                    height: height * (isMobile ? 0.11 : 0.06),
                                    zIndex: 1
                                }}
                                source={{ uri: Config.IMG_URL + `/icons/xp.png` }}
                            />
                            <ImageBackground
                                resizeMode="contain"
                                style={{
                                    width: height * (isMobile ? 0.24 : 0.14),
                                    height: height * (isMobile ? 0.1 : 0.05),
                                    flexDirection: "row",
                                    marginLeft: -height * (isMobile ? 0.03 : 0.015),
                                    marginTop: height * 0.003
                                }}
                                source={{
                                    uri: Config.IMG_URL + '/dashboard/stat_window.png',
                                }}>
                                <Text
                                    style={{
                                        fontFamily: $robotoRegular,
                                        fontSize: height * (isMobile ? 0.04 : 0.02),
                                        marginLeft: height * (isMobile ? 0.022 : 0.015),
                                        fontWeight: "600",
                                        marginTop: height * (isMobile ? 0.022 : 0.013)
                                    }}
                                > XP </Text>
                                <Text
                                    style={{
                                        fontFamily: $robotoRegular,
                                        fontSize: height * (isMobile ? 0.04 : 0.02),
                                        marginLeft: height * 0.022,
                                        fontWeight: "600",
                                        marginTop: height * (isMobile ? 0.022 : 0.013)
                                    }}
                                >{playerDetails?.xp}</Text>
                            </ImageBackground>
                        </View>
                        <View
                            style={{
                                paddingHorizontal: height * 0.05
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: $robotoRegular,
                                    fontWeight: "400",
                                    fontSize: height * 0.03,
                                    color: $brown,
                                    textAlign: "center"
                                }}
                            >
                                {playerDetails?.trait}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "88%"
                        }}
                    >
                        {data?.fromDashboard ?
                            <View
                                style={{
                                    marginTop: height * 0.1,
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                <View>
                                    <Text
                                        style={{
                                            fontFamily: $robotoRegular,
                                            fontWeight: "600",
                                            fontSize: height * 0.04,
                                            color: $brown,
                                            letterSpacing: 2
                                        }}
                                    >
                                        Sound
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        width: "60%",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: height * 0.03
                                    }}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: $robotoRegular,
                                                fontWeight: "600",
                                                fontSize: height * 0.04,
                                                color: $brown,
                                                letterSpacing: 2
                                            }}
                                        >
                                            Music
                                        </Text>
                                    </View>
                                    <TouchableOpacity
                                        onPress={async () => {
                                            if (playSound) {
                                                playSound({
                                                    sound: SOUNDS.musicSetting
                                                })
                                            }
                                            const { data: prefSuccessData } = await setGamePreference(data?.user?.id, {
                                                music: !musicIsPlaying,
                                                sfx: sfxIsON
                                            })
                                            if (prefSuccessData)
                                                dispatch({
                                                    type: SET_MUSIC_PLAYING,
                                                    payload: {
                                                        play: !musicIsPlaying
                                                    }
                                                })
                                        }}
                                    >
                                        <Image
                                            style={{
                                                width: height * 0.084,
                                                height: height * 0.041
                                            }}
                                            source={`${Config.IMG_URL}/dashboard/${musicIsPlaying ? "on" : "off"}_button.png`}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <View
                                    style={{
                                        width: "60%",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: height * 0.03
                                    }}
                                >
                                    <View>
                                        <Text
                                            style={{
                                                fontFamily: $robotoRegular,
                                                fontWeight: "600",
                                                fontSize: height * 0.04,
                                                color: $brown,
                                                letterSpacing: 2
                                            }}
                                        >
                                            SFX
                                        </Text>
                                    </View>
                                    <TouchableOpacity
                                        onPress={async () => {
                                            if (playSound) {
                                                playSound({
                                                    sound: SOUNDS.musicSetting
                                                })
                                            }
                                            const { data: prefSuccessData } = await setGamePreference(data?.user?.id, {
                                                music: musicIsPlaying,
                                                sfx: !sfxIsON
                                            })
                                            if (prefSuccessData)
                                                dispatch({
                                                    type: SET_SFX_VALUE,
                                                    payload: {
                                                        play: !sfxIsON
                                                    }
                                                })
                                        }}
                                    >
                                        <Image
                                            style={{
                                                width: height * 0.084,
                                                height: height * 0.041
                                            }}
                                            source={`${Config.IMG_URL}/dashboard/${sfxIsON ? "on" : "off"}_button.png`}
                                        />
                                    </TouchableOpacity>
                                </View>
                            </View>
                            : <View
                                style={{
                                    width: "100%"
                                }}
                            >
                                <Badges type={"equipments"} data={playerDetails?.equipments || {}} />
                                <Badges type={"virtues"} data={playerDetails?.virtues || {}} />
                                <Badges type={"missions"} data={missions || {}} />
                            </View>}
                        <View
                            style={{
                                flex: 1,
                                width: "100%",
                                justifyContent: "center"
                            }}
                        >
                            {data?.fromDashboard ?
                                <View
                                    style={{
                                        width: "100%",
                                        marginTop: height * 0.04,
                                        alignItems: "center"
                                    }}
                                >
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (playSound) {
                                                playSound({
                                                    sound: SOUNDS.leaveGameButton
                                                })
                                            }
                                            closeModal();
                                            dispatch(leaveGame());
                                        }}
                                        style={{
                                            marginBottom: height * 0.03
                                        }}
                                    >
                                        <Image
                                            style={{
                                                width: height * 0.28,
                                                height: height * 0.1
                                            }}
                                            source={Config.IMG_URL + "/dashboard/leavegame.png"}
                                        />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            if (playSound) {
                                                playSound({
                                                    sound: SOUNDS.logoutButton
                                                })
                                            }
                                            closeModal();
                                            dispatch(requestLogout());
                                        }}
                                    >
                                        <Image
                                            style={{
                                                width: height * 0.28,
                                                height: height * 0.1
                                            }}
                                            source={Config.IMG_URL + "/dashboard/logout.png"}
                                        />
                                    </TouchableOpacity>
                                </View>
                                : <SendCronos
                                    user={data.user}
                                />}
                        </View>
                    </View>
                </ImageBackground>
            </View>
        </View>
    )
}

export default ChatProfileDetailPopup;
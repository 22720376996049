import axios from './index';
import axiosConfig from './../constants/axiosConfig';
import Config from './../config';

export const login = data =>
  axios.post(Config.API_URL + '/auth/login', data, axiosConfig());

export const logout = async () => {
  const headers = await axiosConfig({ token: true, gameId: true });
  const token = headers.headers.Authorization.replace('Bearer ', '');
  return axios.post(Config.API_URL + `/auth/invalidate`, {
    token_type_hint: 'access_token',
    token,
  });
};

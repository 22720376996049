import AsyncStorage from '@react-native-community/async-storage';

const axiosConfig = async data => {
  const userToken = data?.token ? await AsyncStorage.getItem('userToken') : '';
  const gameId = data?.gameId ? await AsyncStorage.getItem('selectedGame') : '';
  const levelId = data?.levelId ? await AsyncStorage.getItem('gameLevel') : '1';
  return {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + userToken,
      'x-game-id': gameId,
      'x-level-id': levelId,
    },
  };
};

export default axiosConfig;

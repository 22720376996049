import React from 'react';
import { View, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setLoadingState } from '../../actions/status';
import { setCurrentGame, loadGameData } from '../../actions/games';

import { getGameStyle } from './styles';
// import { setSocket } from '../../services/socket';
import { SOUNDS } from '../../constants/sfx';
import { useSFX } from '../../components/SFXConfig';

const GameList = ({ game }) => {
  const { playSound } = useSFX();
  const dispatch = useDispatch();
  const window = useWindowDimensions();
  const styles = getGameStyle(window.width, window.height);

  const handleSelect = async game => {
    if(playSound){
      playSound({
        sound: SOUNDS.joinGame,
        force: true
      })
    }
    dispatch(setLoadingState(true));
    await dispatch(setCurrentGame(game));
    await dispatch(loadGameData(game.id, true));
    // setSocket().subscribe(game.id, dispatch);
    dispatch(setLoadingState(false));
  };

  return (
    <>
      <View style={styles.gameContainer}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <View>
            <img
              src={
                game?.icon
              }
              style={{
                height: window.height*0.09,
                minHeight: 40,
                marginRight: 20,
                borderRadius: 5
              }}
            />
          </View>
          <Text style={styles.gameHead}>{game.name}</Text>
          <Text style={[styles.badge, styles[game.state]]}>{game.state}</Text>
        </View>
        <TouchableOpacity
          style={[styles.btn, styles[game.state === 'active' && 'btnJoin']]}
          onPress={() => handleSelect(game)}>
          <Text style={styles.btnTxt}>
            {game.state === 'active' ? 'Join' : 'View'}
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.hr} />
    </>
  );
};

GameList.propTypes = {
  game: PropTypes.object,
};

export default GameList;

import React from 'react'
import { $bdCartoon } from '../../../constants/fonts';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { PAGE_ID } from '../constants';
import './designer.css'


const AboutDesigner = () => {
    const { isMobile } = useMediaQuery()

    return (
        <section >
            <div 
                style={{
                    display: "flex",
                    paddingTop: "60px",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    paddingBottom: "30px"
                }}
               id={PAGE_ID.designer}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 10px",
                }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: isMobile ? "center" : "flex-end",
                            width: isMobile ? "100%" :  "50%"
                        }}
                    >
                        <div className="about_thumb wow fadeInUp" style={{visibility: "visible"}} >
                            <img width="550" height="550" src="https://d33wubrfki0l68.cloudfront.net/08d297c8eba0d183c8f236bc7c5c95c5dc4f77fe/6cf07/assets/img/others/mohsin-memon.png" alt="" />
                            <div className="about_video_btn">
                                <a rel="noreferrer" className="linkedin" href="https://www.linkedin.com/in/mohsinmem" target="_blank">
                                    {/* <i className="icofont-linkedin" style="font-size: 50px;"></i> */}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: isMobile ? "100%" :  "50%"
                        }}
                    >
                        <div className="about_sidebar" style={{visibility: "visible", maxWidth: isMobile ? "100%" : "85%"}}>
                            <div className="about_title">
                                <h5 style={{ fontFamily: "'Fredoka One', cursive" }}>ABOUT MOHSIN MEMON</h5>
                                <h2 style={{ fontFamily: $bdCartoon }}>"games hold the potential to create immense change"</h2>
                            </div>
                            <div className="about_desc">
                                <p style={{ fontFamily: "'Fredoka One', cursive", fontSize: isMobile ? "1.1rem" : "1.4rem" }}>Mohsin is a learning facilitator and game designer who also loves 
                                    snowboarding the Himalayan mountains. He has designed and developed over 
                                    50 games which have been played by
                                    leaders of organizations like Uber, Deloitte, PwC, Coca Cola, 
                                    Motorola, Marsh, HSBC and many more.</p>
                                <p style={{ fontFamily: "'Fredoka One', cursive", fontSize: isMobile ? "1.1rem" : "1.4rem" }}>What inspires Mohsin to build games for learning is his own experience
                                    and relationship with education and learning. Growing up in the United States, 
                                    Mohsin did not have access to higher (tertiary) education which propelled him to 
                                    explore learning through other mediums. He believes games like Sid Meier's 
                                    Civilization among others have played a significant role in his ability to
                                    understand and make sense of the world around him.
                                </p>
                                <p style={{ fontFamily: "'Fredoka One', cursive", fontSize: isMobile ? "1.1rem" : "1.4rem" }}> Today Mohsin works to design and develop games that can make a positive difference 
                                    in the lives of people who play it. Take a look at the company that Mohsin founded:

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutDesigner;
import { StyleSheet } from 'react-native';
import { $robotoRegular } from '../../constants/fonts';
import { $yellow } from '../../constants/colors';
import { $zIndexMax } from '../../constants/zIndex';

export const HelpBtnstyles = StyleSheet.create({
  btn: {
    height: 50,
    position: 'absolute',
    right: 20,
    top: -2,
    width: 60,
    zIndex: $zIndexMax-10,
  },

  full: {
    height: '100%',
    width: '100%',
  },
});

export const LeaveGameStyles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    height: 50,
    justifyContent: 'center',
    position: 'absolute',
    right: 100,
    width: 250,
  },

  leaveBtn: {
    top: 230,
  },

  logoutBtn: {
    top: 300,
  },
});

export const BackdropStyles = StyleSheet.create({
  backdrop: {
    left: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
  },

  bg: {
    height: '90%',
    position: 'absolute',
    right: '-26%',
    top: '-10%',
    width: '100%',
  },
});

export const styles = StyleSheet.create({
  backBtn: {
    height: 20,
    position: 'absolute',
    right: 30,
    top: 10,
    width: 40,
  },
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    marginLeft: 10,
    marginTop: 10,
    position: 'relative',
    top: -5,
    width: 180,
  },

  bg: {
    height: 200,
    position: 'absolute',
    right: 100,
    top: 45,
    width: 355,
    paddingRight: 10
  },

  full: {
    height: '100%',
    width: '100%',
  },

  message: {
    fontFamily: $robotoRegular,
    fontSize: 20,
    paddingHorizontal: 10,
    fontWeight: '400',
  },

  messageCtn: {
    height: '100%',
    padding: 5,
    width: '100%',
    paddingRight: 20
  },

  rebecca: {
    height: 80,
    position: 'absolute',
    right: 10,
    top: 40,
    width: 80,
    zIndex: $zIndexMax,
  },
});

import React, { useCallback } from 'react';
import {
  View,
  ImageBackground,
  Text,
  Image,
  TouchableOpacity,
  useWindowDimensions
} from 'react-native';
import PropTypes from 'prop-types';

import Config from '../../config';
import { getProgramStyle } from './styles';

const CarouselItems = ({ item, index, currIndex, setProgram }) => {
  const window = useWindowDimensions()
  const styles = getProgramStyle(window.width, window.height)
  const bgImage =
    Config.IMG_URL +
    `/practice_missions/${
      currIndex === index ? 'active_slide' : 'secondary_slide'
    }.png`;

  const buttonImage = useCallback(() => {
      if(item.isCompleted){
        return Config.IMG_URL + '/practice_missions/review.png';
      } else {
        let completedModules = 0;
        item.programModules.forEach(program => {
          if(program?.transaction?.isCompleted){
            completedModules += 1
          }
        })
        if(completedModules === 0){
          return Config.IMG_URL + '/practice_missions/begin.png';
        }
        if(completedModules < item?.programModules?.length){
          return Config.IMG_URL + '/practice_missions/continue.png'
        }
        return Config.IMG_URL + '/practice_missions/review.png'
      }
  }, [item])

  return (
    <ImageBackground
      key={index}
      resizeMode="contain"
      source={{ uri: bgImage }}
      style={styles.carouselItemsContainer}>
      <View style={styles.carouselItems}>
        {/* <Text style={styles.carouselNumber}>{displayIndex}</Text> //Commenting due to HSBC request// */}
        <Text style={[styles.carouselText,{
          fontSize: window?.height*0.06 - Math.min(Math.max((item?.name?.length - 30)*0.22, 0), window?.height*0.05)
        }]}>{item.name}</Text>
        <Text style={styles.carouselDescription}>{item.description}</Text>
      </View>
      {Boolean(currIndex === index) && (
        <View style={styles.carouselItemsBtn}>
          <TouchableOpacity onPress={() => setProgram(item, index)} style={{ height: '100%'}}>
            <Image
              source={{
                uri: buttonImage(),
              }}
              style={[styles.carouselBeginButton]}
            />
          </TouchableOpacity>
        </View>
      )}
    </ImageBackground>
  );
};

CarouselItems.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  currIndex: PropTypes.number,
  setProgram: PropTypes.func,
};

export default React.memo(CarouselItems);

import {
  SET_ACTIVE_PROGRAM,
  SET_ACTIVE_PROGRAM_MODULE,
  SET_USER_LOGIN,
  SET_SELECTED_GAME_ID,
  SET_ACTIVE_SUPERPOWERS,
  SET_ACTIVE_VIRTUES,
  SET_ACTIVE_NAV,
  SET_REDAIL_BTN,
  SET_HELP_BTN,
  SET_REBECCA,
  SET_LOADING,
  SET_ABILITIES,
  SET_HELP_MESSAGE,
  SET_CITY_DESC_VISIBLE,
  SET_CITY_CONTRIB_VISIBLE,
  SET_LOADING_COLOR,
  SET_SETTING,
  SET_SELECTED_CITY,
  UPDATE_SELECTED_CITY,
  SET_LEADERBOARD
} from '../constants/actions';
import { $yellow } from '../constants/colors';

export const setActiveProgram = (data, index) => async dispatch => {
  dispatch({
    type: SET_ACTIVE_PROGRAM,
    payload: { index, ...data },
  });
};

export const setActiveProgramModule = data => async dispatch => {
  dispatch({
    type: SET_ACTIVE_PROGRAM_MODULE,
    payload: data,
  });
};

export const setActiveSuperpowers = data => async dispatch => {
  dispatch({
    type: SET_ACTIVE_SUPERPOWERS,
    payload: data,
  });
};

export const setActiveVirtues = data => async dispatch => {
  dispatch({
    type: SET_ACTIVE_VIRTUES,
    payload: data,
  });
};

export const setActiveNav = data => async dispatch => {
  dispatch({
    type: SET_ACTIVE_NAV,
    payload: data,
  });
};

export const setAbilities = data => async dispatch => {
  dispatch({
    type: SET_ABILITIES,
    payload: data,
  });
};

export const setLeaderboard = data => async dispatch => {
  dispatch({
    type: SET_LEADERBOARD,
    payload: data,
  });
};

export const setRedailBtnState = data => async dispatch => {
  dispatch({
    type: SET_REDAIL_BTN,
    payload: data,
  });
};

export const setLoadingState = data => async dispatch => {
  if (typeof data === 'boolean') {
    dispatch({
      type: SET_LOADING,
      payload: data.state || data,
    });
  }
  dispatch({
    type: SET_LOADING_COLOR,
    payload: data.color || $yellow,
  });
};

export const setRebeccaState = data => async dispatch => {
  dispatch({
    type: SET_REBECCA,
    payload: data,
  });
};

export const setSettingState = data => async dispatch => {
  dispatch({
    type: SET_SETTING,
    payload: data,
  });
};

export const setCityDescState = data => async dispatch => {
  dispatch({
    type: SET_CITY_DESC_VISIBLE,
    payload: data,
  });
};

export const setCityContribState = data => async dispatch => {
  dispatch({
    type: SET_CITY_CONTRIB_VISIBLE,
    payload: data,
  });
};

export const setSelectedCity = data => async dispatch => {
  dispatch({
    type: SET_SELECTED_CITY,
    payload: data,
  });
};

export const updateSelectedCity = data => async dispatch => {
  dispatch({
    type: UPDATE_SELECTED_CITY,
    payload: data,
  });
};

export const setHelpBtnState = data => async dispatch => {
  dispatch({
    type: SET_HELP_BTN,
    payload: data,
  });
};

export const setHelpMessage = (data, audioFile) => async dispatch => {
  dispatch({
    type: SET_HELP_MESSAGE,
    payload: {
      message: data,
      audio: audioFile
    },
  });
};

export const setSelectedGameId = data => async dispatch => {
  dispatch({
    type: SET_SELECTED_GAME_ID,
    payload: data,
  });
};

export const setUserLogin = data => async dispatch => {
  dispatch({
    type: SET_USER_LOGIN,
    payload: data,
  });
};

import React from 'react';
import { View, ImageBackground, Text, Image, useWindowDimensions, TouchableOpacity } from 'react-native';
import Config from '../../config';
import { useSelector } from 'react-redux';

import { getInfoStyles } from './styles';
import ChatProfileDetailPopup from '../../components/chatProfileDetailPopup';
import { useModal } from '../../components/ModalProvider';
import { useSFX } from '../../components/SFXConfig';
import { SOUNDS } from '../../constants/sfx';

const Info = ({
  top,
  left,
  crono
}) => {
  const player = useSelector(state => state.player);
  const ep = useSelector(state => state.player.ep);
  const cronos = useSelector(state => state.player.cronos);
  const window = useWindowDimensions();
  const { openModal } = useModal();
  const { playSound } = useSFX();

  const styles = getInfoStyles(window.width, window.height)

  return (
    <View style={[styles.info, Boolean(top && left) && {
      top: top,
      left: left
    }]}>
      <TouchableOpacity
        onPress={() => {
          if(playSound){
            playSound({
              sound: SOUNDS.settingButton
            })
          }
           openModal({
            open: true,
            component: ChatProfileDetailPopup,
            data: {
              fromDashboard: true,
              user: {
                name: player.name,
                id: player.id,
                image: Config.IMG_URL + `/characters/${player.profile.characterName}_civilian.png`
              }
            },
            blur: true
          })
        }}
      >
        <Text style={styles.name}>{player?.name}</Text>
      </TouchableOpacity>
      <View style={styles.ep}>
        <Image
          resizeMode="contain"
          style={[styles[`${ crono ? "cronoIcon" : "epIcon"}`]]}
          source={{ uri: Config.IMG_URL + `/icons/${crono ? "cronos" : "ep"}.png` }}
        />
        <ImageBackground
          resizeMode="contain"
          style={styles[`${ crono ? "cronoBg" : "epBg"}`]}
          source={{ uri: Config.IMG_URL + '/dashboard/stat_window.png' }}>
          <Text style={styles.epHeading}> {crono ? "C" : "EP"} </Text>
          <Text style={styles[`${ crono ? "cronoValue" : "epValue"}`]}>{crono ? cronos : ep}</Text>
        </ImageBackground>
      </View>
    </View>
  );
};

export default Info;

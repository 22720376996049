import React, { useState } from 'react';
import { View, ImageBackground, useWindowDimensions, ScrollView, TextInput } from 'react-native';

import Config from '../../../config';
import TabHeader from './tabHeader';

import { getTabStyle } from './styles';
import PlayerWinCard from '../rightPanel/playerWinCard';
import PlayerInlineCard from './playerInlineCard';
import { useSelector } from 'react-redux';
import { $black, $white } from '../../../constants/colors';
import { $robotoRegular } from '../../../constants/fonts';

let timeout = null;

const Tabs = ({
  setSelectedIndex,
  selectedIndex,
  setSearchParams,
  searchParams,
  isTag
}) => {
  const window = useWindowDimensions()
  const styles = getTabStyle(window.width, window.height);
  const leaderboardData = useSelector(state => state.leaderboards.data);
  const tab = useSelector(state => state.status.leaderboard.tab);
  const [text, setText] = useState(searchParams || "");

  console.log(isTag, "---is tab--");

  return (
    <View style={styles.container}>
      {Boolean(isTag) && <TabHeader key={"team_score"} type={"team_score"} label={"Team Score"} left setSelectedIndex={setSelectedIndex} /> }
      <TabHeader key={"player_score"} type={"player_score"} label={"Player Score"} right setSelectedIndex={setSelectedIndex} />
      <ImageBackground
        resizeMode="stretch"
        source={{ uri: Config.IMG_URL + '/abilities/window_bg.png' }}
        style={styles.bg}>
          <View
            style={{
              flexDirection: "row",
              marginTop: window.height*0.06,
              marginLeft: window.height*0.06,
              width: "86%",
              justifyContent: leaderboardData?.[tab]?.length >= 3 ? "space-between" : 
                leaderboardData?.[tab]?.length < 2 ?  "center" :
                leaderboardData?.[tab]?.length === 2 ? "flex-start" : "space-between"
              ,
              alignItems: "flex-end",
            }}
          >
              {Boolean(leaderboardData?.[tab]?.length >= 2) && <PlayerWinCard 
                playerDetails={leaderboardData?.[tab]?.[1]}
                type="2"
                height={window.height*0.19}
                width={window.height*0.28}
                onPress={() => {
                  setSelectedIndex(1);
                }}
              />}
             <View
              style={{
                paddingBottom: window.height*0.06,
                marginLeft: leaderboardData?.[tab]?.length === 2 ? window.height*0.03 : undefined
              }}
             >
              {Boolean(leaderboardData?.[tab]?.[0]) && <PlayerWinCard 
                  playerDetails={leaderboardData?.[tab]?.[0]}
                  type="1"
                  height={window.height*0.27}
                  width={window.height*0.43}
                  onPress={() => {
                    setSelectedIndex(0);
                  }}
                />}
             </View>
              {Boolean(leaderboardData?.[tab]?.length >= 3) && <PlayerWinCard 
                playerDetails={leaderboardData?.[tab]?.[2]}
                type="3"
                height={window.height*0.19}
                width={window.height*0.28}
                onPress={() => {
                  setSelectedIndex(2);
                }}
              />}
          </View>
          {Boolean(tab === "player_score") &&
            <View
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginTop: window.height*0.03,
                  marginLeft: window.height*0.08
                }}
            >
              <TextInput
                value={text}
                onChangeText={val => {
                  setText(val)
                  if(timeout){
                    clearTimeout(timeout);
                    timeout = null;
                  }
                  timeout = setTimeout(() => {
                    setSearchParams(val)
                  }, 200)
                }}
                returnKeyType={'next'}
                disableFullscreenUI={true}
                autoCapitalize="none"
                blurOnSubmit={false}
                placeholder="Search player..."
                style={{
                    borderColor: $black,
                    borderRadius: 5,
                    borderWidth: 4,
                    paddingVertical: 10,
                    height: window.height*0.06,
                    fontSize: window.height*0.03,
                    paddingHorizontal: window.height*0.01,
                    color: $white,
                    fontWeight: "400",
                    fontFamily: $robotoRegular
                }}
            />
          </View>}
          <ScrollView
            style={{
              flex: 1,
              marginTop: Boolean(tab === "player_score") ? window.height*0.02 : window.height*0.04
            }}
            contentContainerStyle={{
              marginLeft: window.height*0.057
            }}
          >
               {Boolean(leaderboardData?.[tab]?.length >= 4) &&
                  leaderboardData?.[tab]?.filter((_,index) => index >= 3).map((player, index) => {

                    return <PlayerInlineCard
                          key={player.name}
                          playerDetails={player}
                          onPress={() => {
                            setSelectedIndex(index+3);
                          }}
                          selected={selectedIndex === (index+3)}
                    />
                  })
               }
          </ScrollView>
      </ImageBackground>
    </View>
  );
};

export default Tabs;

import { StyleSheet } from 'react-native';

export const getStyle = (width, height, isMobile) => StyleSheet.create({
    background: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: isMobile ? 'flex-start' : "center",
        width: '100%',
    },

    formBg: {
        height: height * 0.6,
        left: height * 0.002,
        position: 'absolute',
        top: height * 0.09,
        width: width * 0.7,
        opacity: 0.6,
    },

    formConatiner: {
        paddingVertical: isMobile ? '3%' : "10%",
        paddingHorizontal: isMobile ? "15%" : "10%",
        marginTop: !isMobile && "5%",
        width: "100%",
        height: "100%"
    },

    formConatinerBg: {
        position: 'absolute',
        width: '100%',
        height: "100%",
        top: isMobile ? "4%" : "10%",
        opacity: 0.6
    },
    headingBg: {
        height: height * 0.4,
        position: 'absolute',
        width: width * 0.37,
        zIndex: 99,
        top: -(height * 0.14),
        left: width * 0.15
    },
    wrapper: {
        width: width * 0.7,
        height: height * 0.7,
        position: 'relative'
    },
});

import React, { useState } from 'react';
import { View, Text, TextInput, Keyboard, useWindowDimensions, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

import { getSignupStyles } from './styles';
import { $bdCartoon } from '../../../constants/fonts';

const Step1 = ({ email, sumbit }) => {
  const [name, setName] = useState('');
  const [validForm, setValidForm] = useState(true);
  const window = useWindowDimensions()

  const styles = getSignupStyles(window.width, window.height);

  const handleSubmit = () => {
    if (name && email) {
      sumbit({ name, email, step: 1 });
      setValidForm(true);
    } else {
      alert('name and email is required');
      setValidForm(false);
    }
  };

  return (
    <View style={styles.formConatiner}>
      <Text style={styles.formTxt}>Name</Text>
      <TextInput
        value={name}
        onChangeText={name => setName(name)}
        returnKeyType={'next'}
        onSubmitEditing={Keyboard.dismiss}
        disableFullscreenUI={true}
        blurOnSubmit={false}
        style={[styles.formInput, validForm ? '' : styles.invalidForm]}
      />
      <Text style={styles.formTxt}>Email</Text>
      <TextInput
        onSubmitEditing={handleSubmit}
        value={email}
        disableFullscreenUI={true}
        editable={false}
        style={styles.formInput}
      />
      <TouchableOpacity
        style={[styles.submitButton, { marginTop: 10 }]}
        onPress={handleSubmit}
      >
          <Text
            style={[styles.formTxt, { color: "white", fontFamily: $bdCartoon, letterSpacing: 4 }]}
          >
            Setup your login password
          </Text>
      </TouchableOpacity>
    </View>
  );
};

Step1.propTypes = {
  sumbit: PropTypes.func,
  email: PropTypes.string,
};

export default Step1;

import React from 'react';
import { TouchableOpacity, Image, useWindowDimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setSuperPower, setVirtue } from '../../actions/player';
import Config from '../../config';

import { getActivateBadgeStyles } from './styles';
import { setAbilities, setLoadingState } from '../../actions/status';
import { SOUNDS } from '../../constants/sfx';
import AlertPopup from '../../components/alertPopup';
import { useModal } from '../../components/ModalProvider';
import AbilityActivateMessage from './AbilityActivateMessage';
import useMediaQuery from '../../hooks/useMediaQuery';

const ActivateBadge = ({ badge, hideDesc, navigation }) => {
  const ep = useSelector(state => state.player.ep);
  const tab = useSelector(state => state.status.abilities.tab);
  const levelId = useSelector(state => state.player.level.id);
  const window = useWindowDimensions();
  const { isMobile } = useMediaQuery();
  const styles = getActivateBadgeStyles(window.width, window.height)
  const { openModal } = useModal();

  const dispatch = useDispatch();

  const activateBadge = async badge => {
    dispatch(setLoadingState(true));
    if (levelId === 1) {
      dispatch(setLoadingState(false));
      dispatch(setAbilities({ tab: "superpowers", superPower: 'civilian' }))
      return;
    }
    if (ep < parseInt(badge.requiredEp)) {
      openModal({
        open: true,
        component: AlertPopup,
        blur: true,
        data: {
          sound: SOUNDS.helpButton,
          heading: "Uh Oh!",
          yesText: "Yes",
          noText: "No, Later",
          message: "You do not have sufficient EP to activate this ability.\n\n" +
            "Would you like to head to Practice Missions where you can earn EP?",
          success: () => {
            navigation.navigate("PracticeMission")
          },
          failure: () => {

          }
        }
      })
      dispatch(setLoadingState(false));
      return;
    }
    let result = {};
    if (tab === 'superpowers') {
      result = await dispatch(setSuperPower(badge.id));
    } else {
      result = await dispatch(setVirtue(badge.id));
    }
    dispatch(setLoadingState(false));
    hideDesc();
    if (result?.error) {
      const error = result?.error;
      alert(
        (error && error.errors && error?.errors[0].detail) ||
        'some error happened',
      );
    } else {
      openModal({
        open: true,
        component: AlertPopup,
        blur: true,
        data: {
          sound: SOUNDS.boardModal,
          yesText: "Okay",
          noText: "View Missions",
          headingMarginTop: window.height * 0.23,
          width: isMobile ? "29%" : "28%",
          height: isMobile ? "94%" : "87%",
          heading: "Activated!",
          modalBackground: Config.IMG_URL + "/abilities/modal/onestar_modal.png",
          messageComponent: () => <AbilityActivateMessage item={{ ...badge, type: tab.substring(0, tab.length - 1) }} />,
          success: () => {
          },
          failure: () => {
            navigation.navigate("SaveEarth")
          }
        }
      })
    }
  };

  if (!badge.isActivated && (badge.isEnabled || tab === "superpowers")) {
    return (
      <TouchableOpacity
        onPress={() => activateBadge(badge)}
        style={styles.activateBtnCtn}>
        <Image
          resizeMode="contain"
          source={{
            uri: Config.IMG_URL + '/abilities/activate_button.png',
          }}
          style={styles.activateBtn}
        />
      </TouchableOpacity>
    );
  }
  return <></>;
};

ActivateBadge.propTypes = {
  badge: PropTypes.object,
  hideDesc: PropTypes.func,
  navigation: PropTypes.object
};

export default ActivateBadge;

import React, { useState } from 'react';
import { Image, ActivityIndicator, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';
import { $yellow } from '../../constants/colors';

import { getVideoStyle } from './styles';

const ImageSlides = ({ url }) => {
  const [loading, setLoading] = useState(true);
  const window = useWindowDimensions()
  const styles = getVideoStyle(window.width, window.height);

  return (
    <>
      <Image
        source={{ uri: url }}
        style={styles.carouselView}
        onLoadEnd={() => setLoading(false)}
        resizeMode="contain"
      />
      {loading && (
        <ActivityIndicator
          style={styles.imageLoading}
          size="large"
          color={$yellow}
        />
      )}
    </>
  );
};

ImageSlides.propTypes = {
  url: PropTypes.string,
};

export default React.memo(ImageSlides);

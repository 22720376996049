import React, { useState } from 'react'
import { TextInput, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { $black, $blue, $white, $red } from '../../../constants/colors';

import { StyleSheet } from 'react-native';
import { useModal } from '../../../components/ModalProvider';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { PAGE_ID } from '../constants';
import { guestContactUs } from '../services/welcome';
import { ActivityIndicator } from 'react-native';
import { verifyEmail } from '../util';

export const getFormStyle = (width, height, isMobile) => StyleSheet.create({
    formInput: {
        borderColor: '#f9c184',
        borderRadius: 5,
        borderWidth: 0.7,
        marginBottom: isMobile ? 10 : 6,
        marginTop: 1,
        paddingVertical: 7,
        height: height * (isMobile ? -0.15 : 0.05),
        paddingHorizontal: height * 0.02,
        fontSize: height * 0.025,
        textTransform: "lowercase",
        outline: "none",
        color: $white
    },

    formTxt: {
        fontFamily: "'Fredoka One', cursive",
        fontSize: isMobile ? height *0.024 : height * 0.03,
        marginVertical: "3px",
        fontWeight: '400',
        color: '#f9c184'
    },
    invalidForm: {
      borderColor: $red,
    },
    otherOpt: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 5,
    },
    submitButton: {
      height: height * 0.1,
      width: "100%",
      backgroundColor: "rgb(33, 150, 243)",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: height * 0.008,
      shadowColor: "black",
      shadowOffset: { height: 4, width: 2 },
      shadowOpacity: 0.5,
      shadowRadius: 10,
      elevation: 10
    },
    row: {
      flexDirection: "row",
      justifyContent: "space-between",
    }
  });


export const EnrollmentForm = ({
    data, closeModal
}) => {
    const { width, height } = useWindowDimensions();
    const { isMobile } = useMediaQuery();
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [message, setMessage] = useState("");
    const [showCompleteText, setShowCompleteText] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invalidText, setInvalidText] = useState("");
    const [validForm, setValidForm] = useState({
        email: true,
        firstName: true,
        lastName: true,
        companyName: true,
        message: true
    });

    const styles = getFormStyle(width, height, isMobile);

    const validateForm = async () => {
        if(!verifyEmail(email)){
            setValidForm({
                ...validForm,
                email: false
            })
            setInvalidText("Email field is empty or not valid")
            return;
        }
        if(!companyName){
            setValidForm({
                ...validForm,
                companyName: false
            })
            setInvalidText("Company Name field is empty or not valid")
            return;
        }
        if(!firstName){
            setValidForm({
                ...validForm,
                firstName: false
            })
            setInvalidText("First Name field is empty.")
            return;
        }
        if(!lastName){
            setValidForm({
                ...validForm,
                lastName: false
            })
            setInvalidText("Last Name field is empty.")
            return;
        }
        if(!message){
            setValidForm({
                ...validForm,
                message: false
            })
            setInvalidText("Message should not be empty.")
            return;
        }
        setValidForm({
            email: true,
            firstName: true,
            lastName: true,
            phoneNumber: true,
            companyName: true,
            message: true
        })
        const payload = {
            "first_name": firstName,
            "last_name": lastName,
            "company_name": companyName,
            "email": email,
            "message": message
        }
        setLoading(true);
        setInvalidText("");
        // call api
        const { errors } =  await guestContactUs(payload);
        setLoading(false)
        if(errors){
            setInvalidText(errors?.[0]?.detail)
        } else {
            setShowCompleteText(true)
        }
    }
    
    return (
        <View
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                width: width,
                height: height
            }}
        >
            <View
                style={{
                    maxWidth: "800px",
                    width: width*0.5,
                    height: isMobile ? height : showCompleteText ? "260px" : height*0.75,
                    minWidth: isMobile ? width : "450px",
                    backgroundColor: isMobile ? "#2a2929" : "rgba(30,30,30,0.96)",
                    borderRadius: "10px",
                    boxShadow: "1px 1px 10px #888888"
                }}
            >
                {loading && <div
                style={{
                    position: 'absolute',
                    top: "0px",
                    left: "0px",
                    right: "0px",
                    bottom: "0px",
                    zIndex: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "rgb(0,0,0,0.2)",
                    transition: "background-color 1000ms ease-out",
                }}
            >
                {Boolean(loading) &&
                    <ActivityIndicator
                        size="large"
                        color={$blue}
                    />
                }
            </div>}
                <View
                    style={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                        paddingHorizontal: "20px",
                        paddingVertical: "12px"
                    }}
                >
                    <Text style={[styles.formTxt, { fontSize: height*0.04, color: '#f9c184' }]}>
                        Contact Us
                    </Text>
                    <TouchableOpacity
                        onClick={closeModal}
                    >
                        <Text style={[styles.formTxt, { color: $red}]}>
                            Close
                        </Text>
                    </TouchableOpacity>
                </View>
                {showCompleteText ?
                <View
                    style={
                        [{
                            width: "100%",
                            borderTopWidth: 1,
                            borderTopStyle: "solid",
                            borderTopColor: '#f9c184',
                            paddingTop: "20px",
                            paddingHorizontal: "20px",
                        },
                        isMobile ? {
                            height: "40%",
                            justifyContent: "flex-end",
                            alignItems: "center"
                        } : {}
                    ]
                    }
                >
                    <Text style={[styles.formTxt, { fontSize: isMobile ? height * 0.028 : height*0.034, color: '#f9c184', textAlign: "center" }]}>
                    Thank you for reaching out. {"\n"}We’ll be in touch with you soon!
                    </Text>
                </View>
                    :
                <View
                    style={{
                        height: isMobile ?  height*0.72 - 125 : height*0.7 - 125,
                        width: "100%",
                        overflow: "scroll",
                        borderTopWidth: 1,
                        borderTopStyle: "solid",
                        borderTopColor: '#f9c184',
                        paddingTop: "20px",
                        paddingHorizontal: "20px",
                    }}
                >
                    <View
                        style={styles.row}
                    >
                        <View
                            style={{width: "48%"}}
                        >
                            <Text style={styles.formTxt}>First name</Text>
                            <TextInput
                                value={firstName}
                                onChangeText={setFirstName}
                                returnKeyType={'next'}
                                disableFullscreenUI={true}
                                autoCapitalize="words"
                                blurOnSubmit={false}
                                style={[
                                styles.formInput,
                                validForm.firstName ? '' : styles.invalidForm,
                                {
                                    textTransform: "capitalize"
                                }
                                ]}
                            />
                        </View>
                       <View style={{width: "48%"}}>
                        <Text style={styles.formTxt}>Last name</Text>
                            <TextInput
                                value={lastName}
                                onChangeText={setLastName}
                                returnKeyType={'next'}
                                disableFullscreenUI={true}
                                autoCapitalize="words"
                                blurOnSubmit={false}
                                style={[
                                styles.formInput,
                                validForm.lastName ? '' : styles.invalidForm,
                                {
                                    textTransform: "capitalize"
                                }
                                ]}
                            />
                       </View>
                    </View>
                    <Text style={styles.formTxt}>Email</Text>
                    <TextInput
                        value={email}
                        onChangeText={setEmail}
                        returnKeyType={'next'}
                        disableFullscreenUI={true}
                        autoCapitalize="none"
                        blurOnSubmit={false}
                        style={[
                        styles.formInput,
                        validForm.email ? '' : styles.invalidForm,
                        ]}
                        autoCompleteType="email"
                    />
                    <Text style={styles.formTxt}>Company name</Text>
                    <TextInput
                        value={companyName}
                        onChangeText={setCompanyName}
                        returnKeyType={'next'}
                        disableFullscreenUI={true}
                        autoCapitalize="sentences"
                        blurOnSubmit={false}
                        style={[
                        styles.formInput,
                        validForm.companyName ? '' : styles.invalidForm,
                        ]}
                    />
                    <Text style={styles.formTxt}>Message</Text>
                    <TextInput
                        value={message}
                        onChangeText={setMessage}
                        returnKeyType={'next'}
                        disableFullscreenUI={true}
                        autoCapitalize="sentences"
                        blurOnSubmit={false}
                        multiline={true}
                        style={[
                        styles.formInput,
                        validForm.message ? '' : styles.invalidForm,
                        [{
                            height: "100px"
                        }]
                        ]}
                    />
                </View>}
                <View
                    style={{
                        justifyContent: invalidText ? "space-between" : showCompleteText ? "center" : "flex-end",
                        paddingTop: isMobile ? "20px" : "10px",
                        paddingHorizontal: "20px",
                        flexDirection: isMobile ? "column" : "row"
                    }}
                >
                    {invalidText && 
                        <View>
                            <Text style={[styles.formTxt, { color: $red, paddingRight: "10px", paddingBottom: isMobile && "10px"}]}>
                                {invalidText}
                            </Text>
                        </View>
                    }
                    <TouchableOpacity
                        style={{
                            paddingHorizontal: "15px",
                            paddingVertical: "10px",
                            borderWidth: 1,
                            borderRadius: 5,
                            borderStyle: "solid",
                            borderColor: $black,
                            backgroundColor: '#f9c184',
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onPress={() => {
                            if(showCompleteText){
                                closeModal()
                            } else {
                                validateForm()
                            }
                        }}
                    >
                        <Text
                            style={[styles.formTxt, { color: $white}]}
                        >
                            {showCompleteText ? "OK" : "Submit"}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )

}


const ContactUs = () => {
    const { openModal } = useModal()
    const { height } = useWindowDimensions()

    const onContactUsClick = () => {
        openModal({
            open: true,
            component: EnrollmentForm,
            blur: true,
            data: {
            }
        })
    }

    return (
        <div
         style={{
            width: "100%",
            height: "80px",
            backgroundColor: "rgba(0,0,0,0.2)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "30px 0px 30px 0px"
         }}
         id={PAGE_ID.contact_us}
        >
            <TouchableOpacity
                onPress={onContactUsClick}
            >
                <Text style={{
                    fontSize: height*0.025,
                    color: $white,
                    fontWeight: "600",
                    fontFamily: "'Fredoka One', cursive",
                }}>
                    <a href='#' alt="">Got Questions?</a>
                </Text>
            </TouchableOpacity>
        </div>
    )
}

export default ContactUs
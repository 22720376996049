import { StyleSheet } from 'react-native';
import { $yellow, $white, $blue, $black, $gray } from './../../../constants/colors';
import { $robotoBold, $bdCartoon } from './../../../constants/fonts';

export const getLeftPanelStyles = (width, height) => StyleSheet.create({
  container: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    marginLeft: 30,
    marginTop: 40,
    zIndex: 1
  },

  dashboardTitle: {
    color: $blue,
    fontFamily: $robotoBold,
    fontSize: height*0.04,
    fontWeight: '900',
  },

  playerTitle: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.07,
    textShadowColor: $black,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 5,
  },

  settings: {
    height: height*0.038,
    left: height*0.21,
    position: 'absolute',
    top: height*0.002,
    width: height*0.038,
  },
});

export const LogStyles = StyleSheet.create({
  flatList: { height: '90%' },

  logContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 8,
  },

  logText: {
    color: $yellow,
    fontSize: 16,
    fontWeight: 'bold',
  },

  logTextContainer: {
    flexDirection: 'row',
  },

  logTextLeft: {
    flex: 1.5,
    paddingLeft: 5,
  },

  logTextRight: {
    flex: 1,
  },

  logWindowContainer: {
    flex: 9,
    flexDirection: 'row',
  },

  logWindowImg: {
    height: '95%',
    width: '92%',
  },

  logs: {
    color: $white,
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 15,
    width: '90%',
  },

  safeArea: { margin: 20 },
});

export const getTabHeaderstyles = (width, height) => StyleSheet.create({
  active: {
    height: height*0.09,
    zIndex: 9,
  },

  activeTxt: {
    color: $blue,
    textShadowColor: $black,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 5,
  },

  bg: {
    height: height*0.08,
    width: height*0.54,
    position: "absolute",
    top: "3%"
  },

  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  txt: {
    color: $gray,
    fontFamily: $bdCartoon,
    textAlign: 'center',
    fontSize: height*0.06,
    fontWeight: "600",
    letterSpacing: 3
  },
});

export const getTabStatStyles = (width, height) => StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    left: '2%',
    position: 'absolute',
    top: '5%',
    zIndex: 99,
    justifyContent: 'center'
  },

  img: {
    height: height*0.04,
    marginLeft: 15,
    marginRight: 5,
    width: height*0.04
  },

  txt: {
    fontFamily: $robotoBold,
    fontSize: height*0.032,
    fontWeight: '900',
  },
});

export const getTabStyle = (width, height) => StyleSheet.create({
  bg: {
    height: height * 0.92,
    width: '100%',
  },

  container: {
    justifyContent: 'flex-start',
    paddingTop: "4%",
    marginLeft: "2%",
    zIndex: 1,
    width: height*1.22
  },

  headerContainer: {
    alignItems: 'flex-end',
    bottom: -(height*0.03),
    display: 'flex',
    flexDirection: 'row',
    left: '2%',
    position: 'relative',
    width: '93.5%',
    zIndex: 1,
  },
});

import React, { useEffect, useState } from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { $robotoRegular } from '../constants/fonts';
import { $yellow } from '../constants/colors';
import { useDispatch } from 'react-redux';
import { setHelpMessage, setRebeccaState } from '../actions/status';
import { FTLI_HELP_AUDIO } from '../constants/helpMessages';

const data = [
  //`Practice missions will help you gain EPs and Cronos. The more points you earn, you’ll be able to travel from city to city and unlock superpowers and virtues  `,
  //`Finish your first practice mission and meet me back here where once you’re done. `,
  `Practice missions will help you gain EPs and Cronos. As you earn more EP, you'll be able to afford more valuable Equipment and Virtues which will be essential in the future missions`,
  `So let's give it a shot: Finish your first practice mission and meet me back here once you’re done. `,
];

// 11, 12 help audio

const ProgramsHelp = () => {
  const [index, setIndex] = useState(0);

  const dispatch = useDispatch();

  const handlePress = async () => {
    if (index === data.length - 1) {
      dispatch(setRebeccaState(false));
      return;
    }
    setIndex(index + 1);
  };

  useEffect(() => {
    dispatch(setHelpMessage(null, FTLI_HELP_AUDIO[index+10]))
  }, [index, dispatch])

  return (
    <>
      <View style={styles.container}>
        <ScrollView>
          <Text style={styles.txt}>{data[index]}</Text>
        </ScrollView>
      </View>
      <TouchableOpacity style={styles.btn} onPress={() => handlePress()}>
        <Text>{index === data.length - 1 ? 'Close' : 'Next'}</Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    marginLeft: 10,
    marginTop: 10,
    position: 'relative',
    top: -5,
    width: 180,
  },

  container: {
    height: '74%',
    paddingHorizontal: 10,
  },

  txt: {
    fontFamily: $robotoRegular,
    fontSize: 16,
    marginRight: 10,
    marginTop: 10,
    fontWeight: '400',
  },
});

export default ProgramsHelp;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ImageSlides from './imageSlide';
import QuestionSlide from './questionSlide';
import VideoSlide from './videoSlide';

const CarouselItems = ({ id, type, index, currentIndex }) => {
  const image = useSelector(state => state.programModules.images[id]);
  const question = useSelector(state => state.programModules.questions[id]);
  const video = useSelector(state => state.programModules.videos[id]);
  // if (type === 'image') {
  //   return <VideoSlide url={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"} />;
  // }

  if (type === 'video') {
    return <VideoSlide url={video?.url || ''} pause={index !== currentIndex} />;
  }
  
  if (type === 'image') {
    return <ImageSlides url={image?.url || ''} />;
  }

  if (type === 'question') {
    return <QuestionSlide id={parseInt(question?.id) || 0} />;
  }


  return <></>;
};

CarouselItems.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
};

export default React.memo(CarouselItems);

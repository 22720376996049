import React, { useEffect } from 'react';
import { ImageBackground } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { setActiveNav, setHelpMessage, setRebeccaState } from '../../actions/status';
import DashboardHelp from '../../components/dashboardHelp';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import Avatar from './avatar';
import Config from './../../config';

import { styles } from './styles';
import { DASHBOARD_HELP_AUDIO, DASHBOARD_HELP_MESSAGE } from '../../constants/helpMessages';

const DashboardScreen = ({ navigation }) => {
  const levelId = useSelector(state => state.player.level.id);
  const nav = useSelector(state => state.status.nav);
  // const window = useWindowDimensions()

  const dispatch = useDispatch();

  useEffect(() => {
    if(nav === ""){
      dispatch(setActiveNav("Dashboard"));
    }
    if(nav === "Dashboard")
    if (levelId === 1) {
      dispatch(setHelpMessage(DashboardHelp));
      dispatch(setRebeccaState(true));
    } else {
      dispatch(setHelpMessage(DASHBOARD_HELP_MESSAGE, DASHBOARD_HELP_AUDIO));
      dispatch(setRebeccaState(false));
    }
  }, [dispatch, levelId, nav])

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/dashboard/background.png' }}
      resizeMode="cover"
      style={styles.container}>
         {/* <View
        style={{
          position: "absolute",
          top: -window.height*0.002,
          left: window.width*0.45
        }}
      >
          <TouchableOpacity
            onPress={() => {
              navigation?.navigate("Leaderboard");
            }}
          >
            <Text
              style={{
                fontFamily: $bdCartoon,
                color: $yellowShadow,
                fontSize: window.height*0.045,
                fontWeight: "600",
                letterSpacing: 2
              }}
            >Leaderboard</Text>
          </TouchableOpacity>
      </View> */}
      <LeftPanel navigation={navigation} />
      <Avatar />
      <RightPanel navigation={navigation} />
    </ImageBackground>
  );
};

export default DashboardScreen;

import React, { useEffect, useState } from 'react';
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { $robotoRegular } from '../constants/fonts';
import { $yellow } from '../constants/colors';
import * as RootNav from '../navigation/RootNav';
import { useDispatch } from 'react-redux';
import { setHelpMessage } from '../actions/status';
import { FTLI_HELP_AUDIO } from '../constants/helpMessages';

const data = [
  //`Here’s a cool tip: Clicking on a Superpower will let you see how  you’ll look like when saving a city using that ability. `,
  //`To activate more Superpowers and Virtues, you will have to finish more  practice missions. `,
  `Here’s a cool tip: Clicking on an Equipment will let you see what you’ll look like when using that equipment.${'\n\n'}Click the "?" icon (on the top right) when you're ready to move ahead `,
  `So you must be wondering: where do you get EP's (Effort Points) from? \n\nIn order to earn EP, you need to do Practice Missions. They are designed to help you master your abilities.`,
];

// 9, 10 help audio

const SuperpowerHelp = () => {
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch()

  const handlePress = async () => {
    if (index === data.length - 1) {
      RootNav.navigate('PracticeMission');
      return;
    }
    setIndex(index + 1);
  };

  useEffect(() => {
    dispatch(setHelpMessage(null, FTLI_HELP_AUDIO[index+8]))
  }, [index, dispatch])

  return (
    <>
      <View style={styles.container}>
        <ScrollView>
          <Text style={styles.txt}>{data[index]}</Text>
        </ScrollView>
      </View>
      <TouchableOpacity style={styles.btn} onPress={() => handlePress()}>
        <Text>
          {index === data.length - 1 ? 'Go To Practice Mission' : 'Next'}
        </Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  btn: {
    alignItems: 'center',
    backgroundColor: $yellow,
    display: 'flex',
    height: 30,
    justifyContent: 'center',
    marginLeft: 10,
    marginTop: 10,
    position: 'relative',
    top: -5,
    width: 180,
  },

  container: {
    height: '74%',
    paddingHorizontal: 10,
  },

  txt: {
    fontFamily: $robotoRegular,
    fontSize: 16,
    marginRight: 10,
    marginTop: 10,
    fontWeight: '400',
  },
});

export default SuperpowerHelp;

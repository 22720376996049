import React, { createRef } from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  FlatList,
  useWindowDimensions,
  Text
} from 'react-native';
import PropTypes from 'prop-types';

import AbilityBadgeIcon from '../../../components/abilityBadgeIcon';

import { getBadgeStyles } from './styles';
import Config from '../../../config';
import { $darkGreen, $white } from '../../../constants/colors';
import { $robotoRegular } from '../../../constants/fonts';

const List = ({ type, data }) => {
  const flatList = createRef();
  const window = useWindowDimensions()

  const styles = getBadgeStyles(window.width, window.height);

  return (
    <View style={styles.badgeWrapper}>
       <TouchableOpacity
        style={styles.scrollBarBtnCtn}
        onPress={() => {
          flatList.current.scrollToIndex({
            index: 0
          });
        }}>
        <Image
          style={[styles.scrollBarBtn,
          {
            transform: [
              { rotate: "180deg"
              }
            ],
            marginTop: -15
          }
          ]}
          resizeMode={'center'}
          source={{
            uri: Config.IMG_URL + '/dashboard/vertical_scroll_button.png',
          }}
        />
      </TouchableOpacity>
      <View style={styles.badge}
      >
        <FlatList
          horizontal
          ref={flatList}
          data={Object.keys(data)}
          renderItem={({ item }) => (
            <View style={{ height: window.height*0.11, alignItems: "center", justifyContent: "center" }}>
              {Boolean(type === "missions") ?
              <View
                style={{
                  paddingHorizontal: window.height*0.03,
                  paddingVertical: window.height*0.013,
                  backgroundColor: $darkGreen,
                  borderRadius: window.height*0.01,
                  marginBottom: 10
                }}
              >
                  <Text
                    style={{
                      color: $white,
                      fontSize: window.height*0.03,
                      fontWeight: "600",
                      fontFamily: $robotoRegular,
                      textTransform: "capitalize",
                      letterSpacing: 2
                    }}
                  >
                      {item}
                  </Text>
              </View>:
              <AbilityBadgeIcon
                type={type === "equipments" ? "superpowers" : type}
                name={data[item].name}
                style={{ height: window.height*0.1, width: window.height*0.09 }}
                bgStyle={{ height: window.height*0.11, width: window.height*0.1 }}
                item={data[item]}
              />
              }
            </View>
          )}
          keyExtractor={item => data[item].name}
        />
      </View>
      <TouchableOpacity
        style={styles.scrollBarBtnCtn}
        onPress={() => {
          flatList.current.scrollToEnd();
        }}>
        <Image
          style={styles.scrollBarBtn}
          resizeMode={'center'}
          source={{
            uri: Config.IMG_URL + '/dashboard/vertical_scroll_button.png',
          }}
        />
      </TouchableOpacity>
    </View>
  );
};

List.propTypes = {
  type: PropTypes.string.isRequired,
};

export default List;

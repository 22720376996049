import createReducer from '../lib/createReducer';
import { SET_VIRTUES, LOGOUT, LEAVE_GAME, UPDATE_VIRTUE_ATTRIBUTES } from '../constants/actions';

/**
 * array of objects
 * {
 *   id
 *   state
 *   name
 *   displayName
 *   description
 *   requiredEp
 *   isEnabled
 *   isDisabled
 *   isActivated
 * }
 */
const initialState = [];

export const virtues = createReducer(initialState, {
  [SET_VIRTUES](state, { payload: { data } }) {
    return [...data];
  },
  [UPDATE_VIRTUE_ATTRIBUTES](state, { payload: { data } }) {
    return state.map(vir => {
      if(vir.id === data.id){
        vir = {
          ...vir,
          ...data
        }
      }
      return vir
    });
  },
  [LEAVE_GAME](state, { payload }) {
    return initialState;
  },
  [LOGOUT](state, { payload }) {
    return initialState;
  },
});

import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector } from 'react-redux';

import AbilitiesScreen from './../screens/abilities';
import CharacterSelction from '../screens/characterSelction';
import CharacterTraits from '../screens/characterTraits';
import SaveEarthScreen from './../screens/saveEarth';
import FtliSlides from '../screens/ftliSlides';
import Rebecca from '../components/rebecca';
import Loading from '../components/loading';
import { PracticeMission } from './index';
import DashboardScreen from '../screens/dashboard';

const Stack = createStackNavigator();

const Inactive = () => (
  <Stack.Navigator headerMode={'none'}>
    <Stack.Screen name="FtliSlides" component={FtliSlides} />
  </Stack.Navigator>
);

const Profile = () => (
  <>
    <Stack.Navigator headerMode={'none'}>
      <Stack.Screen name="Character" component={CharacterSelction} />
      <Stack.Screen name="Traits" component={CharacterTraits} />
    </Stack.Navigator>
    <Rebecca />
  </>
);

const Others = () => (
  <>
    <Stack.Navigator headerMode={'none'}>
      <Stack.Screen name="SaveEarth" component={SaveEarthScreen} />
      <Stack.Screen name="Abilities" component={AbilitiesScreen} />
      <Stack.Screen name="PracticeMission" component={PracticeMission} />
      <Stack.Screen name="Dashboard" component={DashboardScreen} />
    </Stack.Navigator>
    <Rebecca />
  </>
);

const FtliNav = () => {
  const levelState = useSelector(state => state.player.level.state);

  if (levelState === 'inactive') {
    return <Inactive />;
  }
  
  if (levelState === 'profile') {
    return <Profile />;
  }

  if (['save_earth', 'practice_missions'].includes(levelState)) {
    return <Others />;
  }

  return (
    <Stack.Navigator headerMode={'none'}>
      <Stack.Screen
        name="Loading"
        state={true}
        component={Loading}
        initialParams={{ imageBg: true }}
      />
    </Stack.Navigator>
  );
};

export default FtliNav;

import { StyleSheet } from 'react-native';
import { $bdCartoon, $robotoBold, $robotoRegular } from '../../constants/fonts';
import { $black, $white, $yellow, $blue, $gray } from '../../constants/colors';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
});

export const AvatarStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },

  image: {
    height: '105%',
    width: '100%',
  },
});

export const getTabHeaderstyles = (width, height, isMobile) => StyleSheet.create({
  active: {
    height: height * 0.085,
    zIndex: 9,
  },

  activeTxt: {
    color: $blue,
    textShadowColor: $black,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 5,
  },

  bg: {
    flex: 1,
    height: height * (isMobile ? 0.09 : 0.07),
    width: '100%'
  },

  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  txt: {
    color: $gray,
    fontFamily: $bdCartoon,
    textAlign: 'center',
    fontSize: height * 0.04
  },
});

export const getTabStatStyles = (width, height) => StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    left: '2%',
    position: 'absolute',
    top: '5%',
    zIndex: 99,
    justifyContent: 'center'
  },

  img: {
    height: height * 0.04,
    marginLeft: 15,
    marginRight: 5,
    width: height * 0.04
  },

  txt: {
    fontFamily: $robotoBold,
    fontSize: height * 0.032,
    fontWeight: '900',
  },
});

export const getTabStyle = (width, height, isMobile) => StyleSheet.create({
  bg: {
    height: height * 0.75,
    padding: 5,
    paddingTop: 40,
    width: '100%',
  },

  container: {
    flex: 1.4,
    justifyContent: 'flex-end',
  },

  headerContainer: {
    alignItems: 'flex-end',
    bottom: isMobile ? -8 : -13,
    display: 'flex',
    flexDirection: 'row',
    left: '5%',
    position: 'relative',
    width: '85%',
  },
});

export const getActivateBadgeStyles = (width, height) => StyleSheet.create({
  activateBtn: {
    height: '100%',
    width: '100%',
  },

  activateBtnCtn: {
    height: height * 0.13,
    position: 'absolute',
    right: 40,
    top: height * 0.086,
    width: height * 0.21,
  },
});

export const getBadgeDescStyles = (width, height, isMobile) => StyleSheet.create({
  bg: {
    height: '100%',
    left: height * 0.01,
    padding: height * 0.014,
    position: 'relative',
    top: -(height * 0.02),
    width: '98%',
  },

  container: {
    height: height * 0.3,
    width: '96%',
    marginBottom: height * 0.03
  },

  costBg: {
    height: height * (isMobile ? 0.047 : 0.045),
    paddingLeft: 20,
    position: 'relative',
    top: -2,
    width: height * (isMobile ? 0.21 : 0.15),
    justifyContent: 'center'
  },

  costConatiner: {
    display: 'flex',
    flexDirection: 'row',
    height: height * (isMobile ? 0.05 : 0.04),
    position: 'absolute',
    right: height * (isMobile ? 0.13 : 0.12),
    top: 0,
    width: height * (isMobile ? 0.15 : 0.12)
  },

  costHead: {
    fontFamily: $robotoBold,
    fontSize: height * 0.026,
    paddingRight: 5,
    fontWeight: '900',
  },

  costTxt: {
    fontFamily: $robotoBold,
    fontSize: height * 0.025,
    paddingLeft: height * 0.028,
    top: height * 0.018,
    fontWeight: '900',
  },

  desc: {
    paddingLeft: 10,
    paddingRight: 15,
    paddingTop: 5,
  },

  head: {
    fontFamily: $bdCartoon,
    width: '50%',
    fontSize: height * 0.035
  },

  txt: {
    fontFamily: $robotoRegular,
    fontSize: height * 0.03,
    paddingRight: height * 0.02,
    fontWeight: '400',
  },

  txtCtn: {
    height: height * 0.1,
  },
});

export const getBadgeStyles = (width, height) => StyleSheet.create({
  arrow: {
    bottom: -(height * 0.035),
    height: height * 0.04,
    left: height * 0.12,
    position: 'absolute',
    width: height * 0.04,
    zIndex: 999,
  },

  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: height * 0.06,
  },

  wrapper: {
    alignItems: 'center',
    height: height * 0.18,
    margin: 5,
    marginBottom: height * 0.046,
    padding: 5,
    width: '21.5%',
    zIndex: 99,
  },
});

export const getInfoStyles = (width, height) => StyleSheet.create({
  ep: {
    display: 'flex',
    flexDirection: 'row',
  },

  epBg: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: height * 0.09,
    width: height * 0.19,
    left: -(height * 0.039)
  },
  cronoBg: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: height * 0.09,
    width: height * 0.19,
    left: -(height * 0.039),
    top: height * 0.01
  },

  epHeading: {
    color: $black,
    fontFamily: $bdCartoon,
    fontSize: height * 0.042,
    paddingLeft: height * 0.021,
  },

  epIcon: {
    height: height * 0.12,
    left: height * 0.021,
    position: 'relative',
    width: height * 0.16,
    zIndex: 9,
  },
  cronoIcon: {
    height: height * 0.12,
    left: height * 0.021,
    position: 'relative',
    width: height * 0.16,
    zIndex: 9,
  },

  epValue: {
    color: $white,
    fontFamily: $robotoBold,
    fontSize: height * 0.029,
    fontWeight: '900',
    position: "absolute",
    left: height * 0.09
  },
  cronoValue: {
    color: $white,
    fontFamily: $robotoBold,
    fontSize: height * 0.029,
    fontWeight: '900',
    position: "absolute",
    left: height * 0.09
  },

  info: {
    left: 0,
    position: 'absolute',
    top: 10,
    zIndex: 10000,
  },

  name: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height * 0.08,
    left: height * 0.07,
    marginTop: 5,
    position: 'relative',
    textShadowColor: $black,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: height * 0.02,
  },
});

import axios from './index';
import Config from '../config';
import axiosConfig from './../constants/axiosConfig';

export const getPlayer = async () =>
  axios.get(
    Config.API_URL + '/players/me',
    await axiosConfig({ token: true, gameId: true }),
  );

export const getEnableVirtues = async (playerId) =>
  axios.get(
    Config.API_URL + `/players/${playerId}/pending_virtue_notifications`,
    await axiosConfig({ token: true, gameId: true }),
  );

export const setVirtueNotifications = async (playerId, id) =>
  axios.put(
    Config.API_URL + `/players/${playerId}/set_virtue_notification`,
    {
      virtue_id: id
    },
    await axiosConfig({ token: true, gameId: true }),
  );

export const setActiveSuperPower = async id =>
  axios.put(
    Config.API_URL + '/super_powers/' + id + '/activate',
    {},
    await axiosConfig({ token: true, gameId: true }),
  );

export const setActiveVirtue = async id =>
  axios.put(
    Config.API_URL + '/virtues/' + id + '/activate',
    {},
    await axiosConfig({ token: true, gameId: true }),
  );

export const setCurrentMission = async (playerId, missionId) =>
  axios.put(
    Config.API_URL + `/players/${playerId}/update_current_mission`,
    {
      current_mission_id: missionId
    },
    await axiosConfig({ token: true, gameId: true }),
  );

export const getProfileChoices = async () =>
  axios.get(
    Config.API_URL + '/players/profile_choices',
    await axiosConfig({ token: true, gameId: true }),
  );

export const updatePlayerCharacter = async data =>
  axios.put(
    Config.API_URL + `/players/${data.id}/update_character`,
    data,
    await axiosConfig({ token: true, gameId: true }),
  );

export const updatePlayerTrait = async data =>
  axios.put(
    Config.API_URL + `/players/${data.id}/update_trait`,
    data,
    await axiosConfig({ token: true, gameId: true }),
  );

export const verifyCharacterName = async (playerId, name) =>
  axios.get(
    Config.API_URL + `/players/${playerId}/validate_character?name=${name}`,
    await axiosConfig({ token: true, gameId: true }),
  );

export const updatePlayerSession = async data =>
  axios.put(
    Config.API_URL + `/players/${data.id}/update_session`,
    data,
    await axiosConfig({ token: true, gameId: true }),
  );

export const setGamePreference = async (playerId, preference) =>
  axios.put(
    Config.API_URL + `/players/${playerId}/set_game_preferences`,
    {
      preferences: preference
    },
    await axiosConfig({ token: true, gameId: true }),
  );

export const getGamePreference = async (playerId) =>
  axios.get(
    Config.API_URL + `/players/${playerId}/game_preferences`,
    await axiosConfig({ token: true, gameId: true }),
  );

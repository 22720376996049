import React, { Component, createRef } from "react";
import { openFullscreen } from "./utils/open-fullscreen";
import { closeFullscreen } from "./utils/close-fullscreen";
import Config from "../../config";
import { increaseBackgroundAudioVolume, slowBackgroundAudioVolume } from "../../helpers";

class Video extends Component {
	_root = createRef();

	state = {
		showSeekControls: false,
		youTube: false,
		pause: true,
		isLoading: true,
	}

	get _url() {
		const { source } = this.props;
		return typeof source !== "number" ? source.uri : source;
	}

	setNativeProps = () => { };

	seek = (time, _) => {
		const element = this._root.current;
		if (element) {
			element.currentTime = time;
		}
	};

	save = () => {
		console.log("Saving in local..");
		return Promise.resolve();
	};

	presentFullscreenPlayer = () => {
		const element = this._root.current;
		if (element) {
			openFullscreen(element);
		}
	};

	dismissFullscreenPlayer = () => {
		closeFullscreen();
	};

	onFocus = () => {
		const element = this._root.current;
		element.play();
		this.setState({
			pause: false
		})
	}

	onBlur = () => {
		const element = this._root.current;
		element.pause();
		this.setState({
			pause: true
		})
	}

	componentDidMount() {
		const { fullscreen, rate, seek, source, stopWindowFocusFunctionality } = this.props;
		const element = this._root.current;
		if (source?.uri && source?.uri?.includes("https://www.youtube.com/embed")) {
			this.setState({
				youTube: true
			})
		}
		window.addEventListener("blur", this.onBlur);
		document.addEventListener("pause", this.onBlur);
		if(!stopWindowFocusFunctionality) {
			window.addEventListener("focus", this.onFocus);
			document.addEventListener("resume", this.onFocus);
		}


		if (element) {
			if (fullscreen) {
				openFullscreen(element);
			}

			element.addEventListener("progress", this._onProgress);
			element.addEventListener("seeking", this._onSeek);
			element.addEventListener("ended", this._onEnd);

			if (rate) {
				element.playbackRate = rate;
			}

			if (seek) {
				this.seek(seek);
			}
		}
	}

	componentWillUnmount() {
		const element = this._root.current;
		const { stopWindowFocusFunctionality } = this.props;
		increaseBackgroundAudioVolume('video');
		window.removeEventListener("blur", this.onBlur);
		document.removeEventListener("pause", this.onBlur);
		if(!stopWindowFocusFunctionality){
			window.removeEventListener("focus", this.onFocus);
			document.removeEventListener("resume", this.onFocus);
		}
		element.removeEventListener("progress", this._onProgress);
		element.removeEventListener("seeking", this._onSeek);
		element.removeEventListener("ended", this._onEnd);
		this._root.current = null;
	}

	componentDidUpdate(prevProps) {
		const { fullscreen, rate, seek, currentTime, paused, source } = this.props;
		const element = this._root.current;
		if (source?.uri && source?.uri?.includes("https://www.youtube.com/embed") && !this?.state?.youTube) {
			this.setState({
				youTube: true
			})
		} else if (source?.uri && !source?.uri?.includes("https://www.youtube.com/embed") && this.state.youTube) {
			this.setState({
				youTube: false
			})
		}
		if (prevProps?.paused !== this.props.paused)
			if (!this.props.paused) {
				slowBackgroundAudioVolume('video');
			} else {
				increaseBackgroundAudioVolume('video');
			}

		if (element) {
			if (fullscreen !== prevProps.fullscreen) {
				if (fullscreen) {
					openFullscreen(element);
				} else {
					closeFullscreen();
				}
			}

			if (rate !== prevProps.rate && rate) {
				element.playbackRate = rate;

				if (this.props.onPlaybackRateChange) {
					this.props.onPlaybackRateChange({
						playbackRate: rate
					});
				}
			}

			if (seek !== prevProps.seek && seek) {
				element.currentTime = seek;
			}

			if (currentTime !== prevProps.currentTime && currentTime) {
				element.currentTime = currentTime;
			}

			if (paused !== prevProps.paused && paused !== undefined) {
				if (paused) {
					element.pause();
					this.setState({
						pause: true
					})
				} else {
					element.play();
					this.setState({
						pause: false
					})
				}
			}
		}
	}

	_onProgress = () => {
		const element = this._root.current;
		if (this.props.onProgress && element) {
			this.props.onProgress({
				currentTime: element.currentTime,

				// @todo add support for these values
				playableDuration: 0,
				seekableDuration: 0
			});
		}
	};

	_onLoadStart = () => {
		if (this.props.onLoadStart) {
			this.props.onLoadStart();
		}
	};

	_onLoad = () => {
		const element = this._root.current;
		const { playbackRate } = this.props;
		if(playbackRate && element.playbackRate !== playbackRate){
			element.playbackRate = playbackRate
		}
		if (!this.props.paused) {
			slowBackgroundAudioVolume('video');
			element?.play();
		}
		if (this.props.onLoad && element) {
			this.props.onLoad({
				canPlayFastForward: true,
				canPlayReverse: true,
				canPlaySlowForward: true,
				canStepBackward: true,
				canStepForward: true,
				canPlaySlowReverse: true,
				currentTime: element.currentTime,
				duration: element.duration,
				naturalSize: {
					height: element.videoHeight,
					width: element.videoWidth,
					orientation: "horizontal"
				}
			});
		}
		setTimeout(() => {
			this.setState({
				isLoading: false
			})
		}, 50)
	};

	_onError = (error) => {
		if (this.props.onError) {
			this.props.onError({
				error: {
					"": "",
					errorString:
						error instanceof Error ? error.message : "Unexpected error"
				}
			});
		}
	};

	_onSeek = () => {
		const element = this._root.current;
		if (this.props.onSeek && element) {
			this.props.onSeek({
				currentTime: element.currentTime,

				// @todo add support for these values
				seekTime: 0,
				target: 0
			});
		}
	};

	_onEnd = () => {
		if (this.props.onEnd) {
			this.props.onEnd();
		}
	};

	_handleShowSeekControls = () => {
		this.seekTimeinterval = setTimeout(() => {
			this.setState({
				showSeekControls: false
			})
		}, 1500)
		this.setState({
			showSeekControls: true
		})
	}

	_resetShowSeekControls = () => {
		clearTimeout(this.seekTimeinterval);
		if (!this.showSeekControls) {
			this.setState({
				showSeekControls: true
			})
		}
		this.seekTimeinterval = setTimeout(() => {
			this.setState({
				showSeekControls: false
			})
		}, 1500)
	}

	render = () => {
		const { volume, controls, playerId, paused, poster, videoStyle, hideSkipControl, muted, loop, playButton } = this.props;
		const dispalyNoneOnCondition = this.state.isLoading ? { display: "none" } : {}

		if (this.state.youTube)
			return <iframe src={this._url + "?autoplay=1&mute=0"}
				frameborder='0'
				allow='autoplay; encrypted-media'
				allowfullscreen
				title='video'
				style={{
					width: "100%",
					height: "100%"
				}}
			/>
		else
			return <>
				<video
					src={this._url}
					onLoadStart={this._onLoadStart}
					onLoadedData={this._onLoad}
					onError={this._onError}
					onProgress={this._onProgress}
					onSeeking={this._onSeek}
					onEnded={this._onEnd}
					onPlay={() => {
						this.setState({
							pause: false
						})
					}}
					onPause={() => {
						this.setState({
							pause: true
						})
					}}
					onLoadedMetadata={this.props.onTimedMetadata}
					onCanPlay={this.props.onReadyForDisplay}
					onStalled={this.props.onPlaybackStalled}
					volume={volume}
					muted={muted}
					loop={loop}
					onClick={() => {
						if(playButton){
							return
						}
						if (this.state.pause) {
							this._root.current?.play();
							increaseBackgroundAudioVolume('video');
						}
						else {
							this._root.current?.pause();
							slowBackgroundAudioVolume('video');
						}
						this.setState({
							pause: !this.state.pause
						})
					}}
					controls={controls}
					ref={this._root}
					autoPlay={!paused}
					style={videoStyle ? { ...videoStyle, ...dispalyNoneOnCondition } : dispalyNoneOnCondition}
					poster={poster}
					onMouseOver={this._handleShowSeekControls}
					onMouseMove={this._resetShowSeekControls}
					playsInline
					id={"player"+(playerId || "1")}
				>
				</video>
				{Boolean(this.state.showSeekControls && !hideSkipControl) &&
					<>
						<div
							style={{
								position: 'absolute',
								zIndex: 100,
								width: '5%',
								minWidth: "60px",
								top: "39%",
								left: '10%'
							}}
							className="seekButton"
							onClick={() => {
								this.seek(this._root.current.currentTime - 10)
							}}
						>
							<img
								src={Config.IMG_URL + "/icons/seek_rewind.png"}
								style={{
									height: "100%",
									width: "100%"
								}}
								alt="rewind"
							/>
						</div>
						{playButton && <div
							style={{
								position: 'absolute',
								zIndex: 100,
								width: '7%',
								minWidth: "60px",
								minHeight: "60px",
								height: "9%",
								top: "39%",
								left: '47%'
							}}
							className="playPauseButton"
							onClick={() => {
								if(this.state.pause){
									this._root.current.play();
								} else {
									this._root.current.pause();
								}
							}}
						>	
							<span
								style={{
									fontFamily: "'Fredoka One', cursive",
									fontSize: "1.5vw",
									fontWeight: '400',
									color: '#f9c184'
								}}
							>
								{this.state.pause ? "Play" : "Pause"}
							</span>
						</div>
	}
						<div
							style={{
								position: 'absolute',
								zIndex: 100,
								width: '5%',
								minWidth: "60px",
								top: "39%",
								right: '10%'
							}}
							className="seekButton"
							onClick={() => {
								this.seek(this._root.current.currentTime + 10)
							}}
						>
							<img
								src={Config.IMG_URL + "/icons/seek_forward.png"}
								style={{
									height: "100%",
									width: "100%"
								}}
								alt={"forward"}
							/>
						</div>
					</>
				}
			</>
	};
}

export default Video;

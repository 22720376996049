import React from 'react'
import Config from '../../../config'
import Video from '../../../lib/video/Video'
import { PAGE_ID } from '../constants'
import TryNowForm from './TryNowForm'


const IntroVideoSection = () => {

    return (
        <div
            style={{
                width: "100%",
                position: "relative",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-between"
            }}
            id={PAGE_ID.intro_video}
        >
            <Video
                stopWindowFocusFunctionality
                source={{ uri: Config.VID_URL + "/landing.mp4"}}
                posterResizeMode="center"
                resizeMode="cover"
                videoStyle={{
                  width: "100%",
                }}
                playerId={"_intro_video"}
                playButton
                paused={true}
            />
            <TryNowForm section={true} />
        </div>
    )
}

export default IntroVideoSection
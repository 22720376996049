import { StyleSheet } from 'react-native';
import { $bdCartoon, $robotoBold, $robotoRegular } from '../../constants/fonts';
import { $yellow, $black } from '../../constants/colors';

export const getProgramModulesStyle = (width, height) => StyleSheet.create({
  carouselDescription: {
    color: $black,
    fontFamily: $robotoRegular,
    fontSize: height*0.035,
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'center',
    fontWeight: '400',
    width: "85%"
  },
  carouselItems: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginBottom: 15,
    padding: height*0.03,
  },
  carouselItemsBg: {
    height: '100%',
    width: '100%',
    position: "relative"
  },
  carouselItemsBtn: {
    height: height*0.15,
    resizeMode: 'contain',
    width: width * 0.18,
  },
  carouselItemsBtnCtn: {
    marginTop: 10,
  },
  carouselItemsContainer: {
    alignItems: 'center',
    height: height*0.87,
    width: height*0.72,
    marginTop: height*0.04,
    justifyContent: 'center',
    resizeMode: 'contain',
    position: "relative"
  },
  carouselNavBtn: {
    height: height * 0.14,
    width: width * 0.05,
  },
  carouselNavBtnContainer: {
    justifyContent: 'center',
    zIndex: 10,
  },
  carouselNumber: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: 50,
    textShadowColor: $black,
    textShadowOffset: { width: 5, height: 5 },
    textShadowRadius: 10,
  },
  carouselText: {
    color: $black,
    fontFamily: $robotoBold,
    fontSize: height*0.065,
    textAlign: 'center',
    fontWeight: '900',
    width: "86%"
  },
  carouselView: {
    alignItems: 'center',
    height: '90%',
    justifyContent: 'center',
  },
  carouselViewContainer: {
    flex: 3,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  headerText: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.07,
    marginTop: 5,
    textAlign: 'center',
    textShadowColor: $black,
    textShadowOffset: { width: 5, height: 5 },
    textShadowRadius: height*0.006,
  },
  leftBack: {
    height: '100%',
    width: '100%',
  },
  leftBackContainer: {
    height: '16%',
    left: 15,
    position: 'absolute',
    top: height*0.23,
    width: '20%',
  },
  leftBg: {
    alignItems: 'center',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
    zIndex: 99,
  },
  leftDesc: {
    fontFamily: $robotoRegular,
    fontSize: 22,
    paddingHorizontal: 10,
    paddingRight: 20,
    textAlign: 'center',
    fontWeight: '400',
  },
  leftIndex: {
    color: $yellow,
    fontFamily: $bdCartoon,
    fontSize: height*0.28,
    textAlign: 'center',
    textShadowColor: $black,
    textShadowOffset: { width: 5, height: 5 },
    textShadowRadius: height*0.012,
  },
  leftName: {
    fontFamily: $robotoBold,
    fontSize: height*0.08,
    textAlign: 'center',
    fontWeight: '900',
    paddingLeft: 10,
    paddingRight: 20,
    width: "100%"
  },
});

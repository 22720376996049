import React, { useState, useEffect } from 'react';
import { Text, View, ImageBackground, useWindowDimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Carousel from '../../lib/carousel/Carousel';
import PropTypes from 'prop-types';

import {
  setActiveProgram,
  setHelpMessage,
  setRebeccaState,
} from '../../actions/status';
import CarouselNavBtn from './../../components/carouselNavBtn';
import ProgramsHelp from '../../components/programsHelp';
import Config from '../../config';
import CarouselItems from './carouselItems';

import { getProgramStyle } from './styles';
import { PRACTICE_MISSION_HELP_AUDIO, PRACTICE_MISSION_HELP_MESSAGE } from '../../constants/helpMessages';
import Info from '../abilities/info';
import useMediaQuery from '../../hooks/useMediaQuery';

const ProgramModuleScreen = ({ navigation }) => {
  const [currIndex, setCurrentIndex] = useState(0);
  const { isMobile } = useMediaQuery();

  const programsData = useSelector(state => state.programs);
  const levelId = useSelector(state => state.player.level.id);
  const nav = useSelector(state => state.status.nav);
  const window = useWindowDimensions()
  const styles = getProgramStyle(window.width, window.height)
  const dispatch = useDispatch()

  useEffect(() => {
    if (nav === "Programs")
      if (levelId === 1) {
        dispatch(setHelpMessage(ProgramsHelp));
        dispatch(setRebeccaState(true));
      } else {
        dispatch(setHelpMessage(PRACTICE_MISSION_HELP_MESSAGE, PRACTICE_MISSION_HELP_AUDIO))
        dispatch(setRebeccaState(false));
      }
  }, [dispatch, nav, levelId]);

  const sortedTutorialPrograms = programsData
    .filter(elem => parseInt(elem.levelTemplateId) === 1 && !elem.isCompleted)
    .sort((a, b) => a.sort - b.sort);
  const sortedMainPrograms = programsData
    .filter(elem => parseInt(elem.levelTemplateId) === 2 && !elem.isCompleted)
    .sort((a, b) => a.sort - b.sort);

  const sortedCompletedPrograms = programsData
    .filter(elem => elem.isCompleted)
    .sort((a, b) => new Date(b.completedOn).getTime() - new Date(a?.completedOn));

  const programs = [...sortedMainPrograms, ...sortedTutorialPrograms, ...sortedCompletedPrograms];

  let carouselRef = '';

  const setProgram = (item, index) => {
    dispatch(setActiveProgram(item, index));
    navigation.navigate('ProgramModules');
  };

  return (
    <ImageBackground
      source={{ uri: Config.IMG_URL + '/practice_missions/background.png' }}
      style={styles.container}>
      <Info top={1} left={-window.height * 0.05} />
      <View style={styles.headerViewContainer}>
        <Text style={styles.headerText}>Practice Missions</Text>
      </View>
      <CarouselNavBtn type="left" prev={() => carouselRef.snapToPrev()} />
      <View style={styles.carouselView}>
        <Carousel
          initialNumToRender={2}
          windowSize={2}
          ref={c => (carouselRef = c)}
          data={programs}
          enableSnap
          enableMomentum
          renderItem={({ item, index }) => (
            <CarouselItems
              item={item}
              index={index}
              currIndex={currIndex}
              setProgram={setProgram}
            />
          )}
          sliderWidth={window.width}
          itemWidth={window.width * 0.33}
          containerCustomStyle={styles.carouselCustomStyle}
          onSnapToItem={(index) => {
            if (isMobile) {
              setCurrentIndex(index);
            } else {
              setCurrentIndex(carouselRef.currentIndex)
            }
          }}
        />
      </View>
      <CarouselNavBtn type="right" next={() => carouselRef.snapToNext()} />
    </ImageBackground>
  );
};

ProgramModuleScreen.propTypes = {
  navigation: PropTypes.object,
};

export default ProgramModuleScreen;

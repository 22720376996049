import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
});

export const AvatarStyles = StyleSheet.create({
  avatar: {
    height: '116%',
    width: '100%',
  },
  wrapper: {
    flex: 3,
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-end"
  },
});

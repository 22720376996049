import React, { useCallback, useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';

import DashboardScreen from './../screens/dashboard';
import AbilitiesScreen from './../screens/abilities';
import ChatScreen from './../screens/chat';
import ProgramsScreen from './../screens/programs';
import ProgramModuleScreen from './../screens/programModules';
import SlidesScreen from '../screens/programSlides';
import SaveEarthScreen from './../screens/saveEarth';
import GamesScreen from './../screens/games';
import Auth from '../screens/auth';
import LoginScreen from './../screens/auth/login';
import SignupScreen from '../screens/auth/signup';
import WelcomePage from '../screens/WelcomePage'
import ForgotPasswordScreen from '../screens/auth/forgotPassword';
import ResetPasswordScreen from '../screens/auth/resetPassword';
import Settings from './../components/settings';

import { loadGameData } from './../actions/games';
import { navigationRef } from './RootNav';
import RadialNavigation from './redailNav';
import { setActiveNav } from '../actions/status';
import NavBtn from './navBtn';
import Rebecca from '../components/rebecca';

import { getUserData } from '../actions/user';
import { requestLogout } from '../actions/auth';
import FtliNav from './ftliNav';
import Loading from '../components/loading';
// import { setSocket } from '../services/socket';
import LeaderboardScreen from '../screens/leaderboard';
import { useModal } from '../components/ModalProvider';
import { View } from 'react-native';
import Config from '../config';
import { setDispatch, setLoggedIn } from '../services';

const Stack = createStackNavigator();

export const PracticeMission = () => (
  <Stack.Navigator headerMode={'none'}>
    <Stack.Screen name="Programs" component={ProgramsScreen} />
    <Stack.Screen name="ProgramModules" component={ProgramModuleScreen} />
    <Stack.Screen name="ProgramSlides" component={SlidesScreen} />
  </Stack.Navigator>
);

const ProtectedRoutes = () => {
  const { blur } = useModal()
  
  return (
  <>
  <View
    style={{
      width: "100%",
      height: "100%",
      filter: blur ? "blur(5px)" : "blur(0px)"
    }}
  >
    <Stack.Navigator headerMode={'none'}>
      <Stack.Screen name="Dashboard" component={DashboardScreen} />
      <Stack.Screen name="Leaderboard" component={LeaderboardScreen} />
      <Stack.Screen name="Abilities" component={AbilitiesScreen} />
      <Stack.Screen name="PracticeMission" component={PracticeMission} />
      <Stack.Screen name="SaveEarth" component={SaveEarthScreen} />
      <Stack.Screen name="Chat" component={ChatScreen} />
    </Stack.Navigator>
  </View>
    <NavBtn />
    <Rebecca />
    <Settings />
    <RadialNavigation />
  </>
)
}

const PublicRoutes = () => (
  <Stack.Navigator headerMode={'none'}>
    <Stack.Screen name="Auth" component={Auth} />
    <Stack.Screen name="Superhero Within" component={WelcomePage} />
    <Stack.Screen name="Login" component={LoginScreen} />
    <Stack.Screen name="Signup" component={SignupScreen} />
    <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />
    <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
  </Stack.Navigator>
);

const GamesRoute = () => (
  <Stack.Navigator headerMode={'none'}>
    <Stack.Screen name="Games" component={GamesScreen} />
  </Stack.Navigator>
);

// Gets the current screen from navigation state
const getActiveRouteName = state => {
  const route = state?.routes[state.index];
  if (route?.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }
  return route?.name;
};

var backgroundAudio = null;
let selectedGameId = null;

export const getBackgroundAudioRef = () => {
  return backgroundAudio;
}

const Navigation = () => {
  const [backgroundMusicPlay, setBackgroundMusicPlay] = useState(false);

  const isUserLoggedIn = useSelector(state => state.player.isLoggedIn);
  const slectedGameId = useSelector(state => state.player.slectedGameId);
  const levelId = useSelector(state => state.player.level.id);
  const musicIsPlaying = useSelector(state => state.status.musicIsPlaying);
  // const playerId = useSelector(state => state.player.id);
  const levelState = useSelector(state => state.player.level.state);

  const dispatch = useDispatch();

  const checkUser = useCallback(async () => {
    const { error } = await dispatch(getUserData());
    if (error && error.errors && error?.errors[0]?.detail === 'Invalid Token') {
      alert('session expired');
      dispatch(requestLogout());
      setLoggedIn(false)
    } 
  },[dispatch])

  useEffect(() => {
    setDispatch(dispatch);
  }, [dispatch])

  useEffect(() => {
    if (isUserLoggedIn) {
      checkUser();
      setLoggedIn(true);
    }
  }, [isUserLoggedIn, checkUser])

  useEffect(() => {
    if (slectedGameId && selectedGameId === null) {
      selectedGameId = slectedGameId;
      dispatch(loadGameData(slectedGameId));
    }
  }, [slectedGameId, levelId, dispatch]);

  const SwitchNavigation = () => {
    if (isUserLoggedIn && !slectedGameId) {
      return <GamesRoute />;
    }
    if (isUserLoggedIn && slectedGameId) {
      if (parseInt(levelId) === 1) {
        return <FtliNav />;
      }
      return <ProtectedRoutes />;
    }
  };

  useEffect(() => {
    if(slectedGameId && isUserLoggedIn){
        setBackgroundMusicPlay(true);
    } else {
      setBackgroundMusicPlay(false);
    }
  }, [slectedGameId, isUserLoggedIn, levelId, levelState])

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={state => {
        const currentRouteName = getActiveRouteName(state);
        dispatch(setActiveNav(currentRouteName));
      }}
    >
       {!isUserLoggedIn ? <PublicRoutes /> : SwitchNavigation()}
      <Loading />
      {(backgroundMusicPlay && musicIsPlaying) &&
      <audio 
        ref={(ref) => {
          backgroundAudio = ref;
          if(ref){
            ref.volume = 0.4
          }
        }}
        id="background_audio"
        src={Config.IMG_URL+"/sounds/background_score.mp3"}
        hidden
        loop
        autoPlay={musicIsPlaying}
        onLoadedData={() => {
          document.onclick = () => {
            if(backgroundAudio && musicIsPlaying){ 
              backgroundAudio.play()
              backgroundAudio.volume = 0.4
              document.onclick = null
            }
          }
        }}
      />
      }
    </NavigationContainer>
  );
};

export default Navigation;

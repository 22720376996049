export const DASHBOARD_HELP_MESSAGE = [
    `This is Mission Control. Here you're able to see your current status and progress.`,
    `Your XP is the total amount of experience points you've gain from learning and completing missions.`,
    `Your EP is the amount of points you currently have to use towards unlocking abilities`,
    `The leaderboard shows how you and your teams are performing in comparison`,
    `The timer down below informs you of how much time you have left to complete the current mission(s).`
]
export const DASHBOARD_HELP_AUDIO = [
    `dbhelp1.mp3`,
    `dbhelp2.mp3`,
    `dbhelp3.mp3`,
    `dbhelp4.mp3`,
    `dbhelp5.mp3`,
]

export const ABILITY_HELP_MESSAGE = [
    `This is your Abilities section. Here you get to unlock various abilities that you might need in order to complete the missions you might embark on.`,
    `You'll need to use EP (Effort Points) in order to "Activate" these abilities.`,
    `Remember, you may lose the ability(ies) depending on the nature of the mission you use them for. See help section in "Save Earth" for more information.`,
]
export const ABILITY_HELP_AUDIO = [
    `abhelp1.mp3`,
    `abhelp2.mp3`,
    `abhelp3.mp3`,
]

export const PRACTICE_MISSION_HELP_MESSAGE = [
    `This is where you earn your EP (Effort Points) and XP. You'll only have one chance to earn points from each practice mission.`,
    `In the cases where you answer questions during practice missions, remember to answer them correctly, or you will not earn points from completing the practice missions.`,
    `Earning more points here will influence your rank on the leaderboard `
]

export const PRACTICE_MISSION_HELP_AUDIO = [
    `pmhelp1.mp3`,
    `pmhelp2.mp3`,
    `pmhelp3.mp3`,
]

export const CHAT_HELP_MESSAGE = [
    `Here you can connect with members from your organization and core team.${'\n\n'}The org wide communication channel will help you to invite members to a mission so you can complete important missions by joining forces with them.`,
    `However, your core team channel is designed to help you bring more members from your core team to join the Save Earth missions. The more members from your core team that join a mission, the higher you rank on the leaderboard.`,
]

export const CHAT_HELP_AUDIO = [
    `chelp1.mp3`,
    `chelp2.mp3`,
]

export const SAVE_EARTH_HELP_MESSAGE = [
    `Here is where all the action happens. You join forces with other teams and with members of your own team to complete important missions that are essential to help you get ahead and save our planet.`,
    `In order to initiate a mission, you must first meet the minimum abilities requirements. Once you have the requisite abilities, you can use them in the mission to "Board" the ship towards your destination. If the mission is already complete, you'll have the opportunity to support the mission with your abilities.`,
    `Secondly, you'll notice there are two numbers next to the requirements (example: 4/10). The first number indicates how many team members have already joined the mission. And the second number indicates, how many are required to complete the mission successfully.`,
    `Once you initiate the mission, you'll see a yellow bar along with the % of completion at the bottom of the mission page. This indicates how complete the mission is. This is also the amount of time you and your team members have, in order to join the mission before it fails.`,
    `Remember, some missions are "Critical"⚠️ and they will cause you to lose the abilities you used for the mission if you fail them. Such missions will be indicated with the caution symbol ⚠️ so you can be aware of them before starting them.`
]

export const SAVE_EARTH_HELP_AUDIO = [
    `sehelp1.mp3`,
    `sehelp2.mp3`,
    `sehelp3.mp3`,
    `sehelp4.mp3`,
    `sehelp5.mp3`,
]

export const LEADERBOARD_HELP_MESSAGES = [
    `This is your leaderboard, here you get to see your score as an individual and as a team`,
    `If you want to rise to the top of the leaderboard, it's important for you to understand how these scores are calculated. Let's take a look:`,
    `Player Score: 
    Your individual player score is calculated based on the following elements (in order of priority and weightage): 
    1. Practice Mission Scores (esp. quizzes and tasks)
    2. Contributions towards "Save Earth" Missions
    3. Retrying Failed Missions
    4. Unlocked Abilities
    5. Interaction in Chat`,
    `Team Score:
    Your team score is calculated based on the following (in order of priority and weightage)
    1. Practice Mission scores of each player in a specific team
    2. The number of players from a specific team that contribute towards "Save Earth" Missions
    3. The number of players from a specific team that retry failed missions
    4. The number of players from a specific team that have unlocked abilities
    5. The number of players from a specific team that interact in Chat
    
    IMPORTANT: The number of players in your team does not affect your team score. However, the quality of activities performed by the members of your team will affect your team score. In order to make the scoring fair to teams of varying sizes, we take an average of the team's performance and balance it against the size of the largest team competing. `,
    `Want to rise on the leaderboard? Do the following: 
    1. Make sure you don't miss the opportunity to earn EP and XP through Practice Missions
    2. Unlock the right abilities that will help you join/retry missions as many times as possible
    3. Try to bring other members of your team into the mission by using your team specific chat`,
]

export const LEADERBOARD_HELP_AUDIO = [
    `lbhelp1.mp3`,
    `lbhelp2.mp3`,
    `lbhelp3.mp3`,
    `lbhelp4.mp3`,
    `lbhelp5.mp3`,
]

export const FTLI_HELP_AUDIO = [
    "ftli1.mp3",
    "ftli2.mp3",
    "ftli3.mp3",
    "ftli4.mp3",
    "ftli5.mp3",
    "ftli6.mp3",
    "ftli7.mp3",
    "ftli8.mp3",
    "ftli9.mp3",
    "ftli10.mp3",
    "ftli11.mp3",
    "ftli12.mp3",
    "ftli13.mp3",
    "ftli14.mp3",
    "ftli15.mp3",
    "ftli16.mp3",
    "ftli17.mp3",
    "ftli18.mp3",
    "ftli19.mp3",
]

